import { format, getDay } from "date-fns";
import de from "date-fns/locale/de";

const dateFormatter = (date, language) => {
  if (language === "en") return format(date, "yyyy-MM-dd");
  if (language === "de") return format(date, "dd.MM.yyyy");
  throw new Error("no-language");
};

const getDateObjectByTime = (time) => {
  const isTime = /\d\d:\d\d(:\d\d)?/;
  if (!isTime.test(time)) throw new Error(`wrong time format: ${time}`);

  let resultTime = time;

  // short date
  if (time.split(":").length === 2) resultTime = `${time}:00`;

  // time is equivalent to seconds since 1970-01-01
  return new Date(`1970-01-01 ${resultTime}`);
};

const formatDate = ({
  date,
  short = true,
  withTime = false,
  showDay = false,
}) => {
  const formatTo = short
    ? `${showDay ? "E, " : ""}dd.MM.yy${withTime ? " HH:mm" : ""}`
    : `${showDay ? "EEEE, " : ""}dd. MMMM y${withTime ? ", HH:mm" : ""}`;
  // will format the Date from yyyy-mm-dd hh:mm:ss to dd. Month YYYY hh:mm Uhr
  // see https://date-fns.org/v2.19.0/docs/format
  // example: 2020-12-31 12:59:16 -> 31. Dezember 2020 12:59 Uhr
  const formattedDate = format(new Date(date), formatTo, { locale: de });
  return `${formattedDate}${withTime ? " Uhr" : ""}`;
};

const getWeekDay = (date, throwsError = false) => {
  const iDay = getDay(new Date(date));
  let weekdayTranslationKey = "";

  switch (iDay) {
    case 0:
      weekdayTranslationKey = "app.appointments.sunday";
      break;
    case 1:
      weekdayTranslationKey = "app.appointments.monday";
      break;
    case 2:
      weekdayTranslationKey = "app.appointments.tuesday";
      break;
    case 3:
      weekdayTranslationKey = "app.appointments.wednesday";
      break;
    case 4:
      weekdayTranslationKey = "app.appointments.thursday";
      break;
    case 5:
      weekdayTranslationKey = "app.appointments.friday";
      break;
    case 6:
      weekdayTranslationKey = "app.appointments.saturday";
      break;
    default:
      if (throwsError) {
        throw new Error(`Could not match weekday ${iDay}`);
      }
      weekdayTranslationKey = "";
      break;
  }

  return weekdayTranslationKey;
};

const isSameDay = (d1, d2) =>
  d1?.getFullYear() === d2?.getFullYear() &&
  d1?.getMonth() === d2?.getMonth() &&
  d1?.getDate() === d2?.getDate();

export {
  dateFormatter,
  formatDate,
  getWeekDay,
  isSameDay,
  getDateObjectByTime,
};
