import React, { PureComponent } from "react";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import { Button as BsButton } from "react-bootstrap";
import { Loading } from "../../globalComponents/Loading";

const CustomButton = styled(BsButton)`
  background-color: ${(props) => props.theme.btnBg} !important;
  border-color: ${(props) => props.theme.btnBg} !important;
`;

class Button extends PureComponent {
  render() {
    const buttonProps = { ...this.props };

    const link = this.props.href ? this.props.href : null;
    delete buttonProps.href;

    const disabled = this.props.processing
      ? this.props.processing
      : this.props.disabled
      ? this.props.disabled
      : false;

    const processing = this.props.processing ? this.props.processing : false;
    delete buttonProps.processing;

    return link ? (
      <LinkContainer to={link}>
        <CustomButton {...buttonProps}>{this.props.children}</CustomButton>
      </LinkContainer>
    ) : (
      <CustomButton {...buttonProps} disabled={disabled}>
        {processing ? (
          <Loading color="#ffffff" />
        ) : (
          <span>{this.props.children}</span>
        )}
      </CustomButton>
    );
  }
}

export default Button;
