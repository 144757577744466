import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import Conf from "../../Conf";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../CustomBootstrap/Button";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import { UPDATE_DATE_MUTATION } from "./SaveAppointment.graphql";

class SaveAppointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress: false,
    };

    this.parseError = parseError.bind(this);
  }

  onClick() {
    if (this.props.onValidate()) {
      this.setState({ inProgress: true });

      this.props
        .updateDate({
          variables: {
            info: this.props.info,
            timeStart: this.props.timeSlot.split("-")[0],
            timeEnd: this.props.timeSlot.split("-")[1],
            id: this.props.appointmentId,
          },
        })
        .then(() => {
          this.props.onFinish();
          this.setState({
            inProgress: false,
          });
        })
        .catch((error) => {
          this.setState({ inProgress: false });
          const errorTextTranslationKey = this.parseError(
            error,
            Conf.errorContext.appointmentUpdateDate
          );

          this.props.onError(error, errorTextTranslationKey);
        });
    }
  }

  render() {
    return this.state.inProgress ? (
      <LoadingCol height="50" />
    ) : (
      <div className="d-grid">
        <Button onClick={this.onClick.bind(this)} variant="danger" size="lg">
          <FormattedMessage id="app.appointments.save" />
        </Button>
      </div>
    );
  }
}

const SaveAppointmentWithMutations = compose(
  graphql(UPDATE_DATE_MUTATION, { name: "updateDate" })
)(SaveAppointment);

export const SaveAppointmentButton = injectIntl(SaveAppointmentWithMutations);
