import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import "./Option.scss";

class Option extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.onChange(e);
  }

  render() {
    return (
      <Form.Select
        onChange={this.onChange}
        className={[
          "form-control",
          "basic-option",
          this.props.alignRight ? "basic-option--align-right" : "",
        ].join(" ")}
      >
        {this.props.defaultValue && (
          <option value="choose" defaultValue="choose">
            {this.props.prepend}
            {this.props.defaultValue}
          </option>
        )}
        {this.props.items.map((item) => (
          <option value={item} key={item}>
            {this.props.prepend}
            {item}
          </option>
        ))}
      </Form.Select>
    );
  }
}

Option.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  alignRight: PropTypes.bool,
  prepend: PropTypes.string,
};

export default Option;
