import { gql } from "@apollo/client";

export const APPOINTMENTS_QUERY = gql`
  query appointments($cursor: String, $limit: Int!, $past: Boolean) {
    guest {
      id
      datesConnection(first: $limit, after: $cursor, past: $past) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            __typename
            id
            date
            timeStart
            timeEnd
            room {
              name
              emailNote
            }
            therapyOffer {
              id
              title
              emailNote
            }
            eventTherapist {
              id
              isOnline
              eventTherapistOnline {
                id
                canGuestJoin
              }
              guestState
              offers {
                slots {
                  guestState
                  timeStart
                  timeEnd
                }
                offer {
                  id
                }
              }
              therapist {
                id
                photo
                displayName
              }

              roomEvent {
                id
                type
                dateBegin
                timeBegin
              }
            }
            ... on EventGuest {
              info
              payment
            }
            ... on EventGuestReservation {
              binding
              reservationPayment: payment
            }
          }
        }
      }
    }
    config {
      modules {
        ... on ModuleConfigCalendar {
          dateEditableUntil
          dateCancelableUntil
        }
      }
    }
  }
`;
