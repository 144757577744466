import React, { Component } from "react";
import styled from "styled-components";
// import { FaAngleLeft } from "react-icons/fa";
import Icon from "react-icon-base";
import Conf from "../Conf";

const FaAngleLeft = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m26.5 12.1q0 0.3-0.2 0.6l-8.8 8.7 8.8 8.8q0.2 0.2 0.2 0.5t-0.2 0.5l-1.1 1.1q-0.3 0.3-0.6 0.3t-0.5-0.3l-10.4-10.4q-0.2-0.2-0.2-0.5t0.2-0.5l10.4-10.4q0.3-0.2 0.5-0.2t0.6 0.2l1.1 1.1q0.2 0.2 0.2 0.5z" />
    </g>
  </Icon>
);

const CustomFaAngleLeft = styled(FaAngleLeft)`
  color: ${() => Conf.theme.color.links};
  font-size: 40px;
`;

const TitleElementLeft = styled("div")`
  cursor: pointer;
  padding: 5px 0;
  top: 0;
  margin-left: -10px;
`;

/**
 * Some black magic to handel non-deep-linked view back actions
 * @todo make a proper solution or remove after implantation of all deep links
 * @type {null}
 */
window.customBackAction = null;

export class Backlink extends Component {
  static goBack(props) {
    if (window.customBackAction) {
      const back = window.customBackAction;
      window.customBackAction = null;
      back();
    } else if (props?.goBack) props.goBack();
    else window.history.back();
  }

  render() {
    return (
      <TitleElementLeft onClick={() => Backlink.goBack(this.props)}>
        <CustomFaAngleLeft className="arrow" />
      </TitleElementLeft>
    );
  }
}
