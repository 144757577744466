import React, { Component } from "react";
import {
  Alert,
  Row,
  Col,
  FormLabel,
  Form,
  FormControl,
  FormText,
  Modal,
} from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import { format, subDays, compareAsc } from "date-fns";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import Button from "../CustomBootstrap/Button";
import Conf from "../../Conf";
import { PRESCRIPTION_UPLOAD_MUTATION } from "./Upload.graphql";
import { DayPickerInput } from "../../globalComponents/DayPickerInput";

const CustomFormControl = styled(FormControl)`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

const ButtonContent = styled("div")`
  padding: 15px 0;
`;

class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prescriptionText: "",
      prescriptionDate: null,
      prescriptionCredit: null,
      file: [],
      showForm: false,
      uploadIsDone: false,
      uploadError: false,
      errorMessage: null,
      uploadInProgress: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.parseError = parseError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    let value = "";

    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      default:
        value = target.value;
    }

    this.setState({ [name]: value });
  }

  handleFileInputChange(event) {
    const aFile = [];
    aFile.push(event.target.files[0]);
    this.setState({ file: aFile });
  }

  submitForm(event) {
    event.preventDefault();

    if (event.target.checkValidity()) {
      this.setState({ uploadInProgress: true });

      let errorTextTranslationKey = null;

      this.props
        .mutate({
          variables: {
            prescriptionText: this.state.prescriptionText
              ? this.state.prescriptionText
              : this.props.offer.therapyOffer.title,
            prescriptionCredit: this.state.prescriptionCredit,
            prescriptionDate: this.state.prescriptionDate,
            file: this.state.file,
          },
        })
        .then(({ data }) => {
          if (data.uploadPrescription === true) {
            this.setState({
              uploadIsDone: true,
              uploadInProgress: false,
            });
          } else {
            errorTextTranslationKey = this.parseError(
              data.errors,
              Conf.errorContext.prescriptionUpload,
              ".processing"
            );
            this._alertModal.open(
              this.props.intl.formatMessage({ id: "app.global.error" })
            );
            this.setState({
              uploadError: true,
              uploadInProgress: false,
              errorMessage: this.props.intl.formatMessage({
                id: errorTextTranslationKey,
              }),
            });
          }
        })
        .catch((errors) => {
          errorTextTranslationKey = this.parseError(
            errors,
            Conf.errorContext.prescriptionUpload,
            ".transmission"
          );
          this.setState({
            uploadError: true,
            uploadInProgress: false,
            errorMessage: this.props.intl.formatMessage({
              id: errorTextTranslationKey,
            }),
          });
        });
    }
  }

  handleDateChange(date) {
    this.setState({
      prescriptionDate: format(date, "yyyy-MM-dd"),
      date,
    });
  }

  /**
   * get range of dates from where
   * within you are allowed to select days
   * */
  static getEnabledDays = (d) => {
    const today = new Date();
    const past = subDays(today, 6 * 7 - 1);
    return !(compareAsc(d, today) === -1 && compareAsc(d, past) === 1);
  };

  render() {
    if (!this.state.showForm) {
      return (
        <div className="d-grid">
          <Button
            variant="primary"
            size="lg"
            onClick={() => this.setState({ showForm: true })}
          >
            <FormattedMessage id="app.prescriptions.upload.button" />
          </Button>
        </div>
      );
    }

    return (
      <Modal
        show={this.state.showForm}
        onHide={() => {
          this.setState({ showForm: false });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="app.prescriptions.upload.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.uploadError ? (
            <Alert variant="danger">
              <p>{this.state.errorMessage}</p>
            </Alert>
          ) : (
            ""
          )}
          {this.state.uploadIsDone ? (
            <Alert variant="success">
              <p>
                <FormattedMessage id="app.prescriptions.upload.successful" />
              </p>
            </Alert>
          ) : (
            ""
          )}

          <Form horizontal onSubmit={this.submitForm}>
            <Row className="mb-3">
              <Col sm={3} className="text-end">
                <FormLabel>
                  <FormattedMessage id="app.prescriptions.upload.prescriptionText.label" />
                </FormLabel>
              </Col>
              <Col sm={9}>
                <CustomFormControl
                  disabled={
                    this.state.uploadInProgress || this.state.uploadIsDone
                  }
                  value={this.state.prescriptionText}
                  type="text"
                  name="prescriptionText"
                  pattern=".{3,}"
                  onChange={this.handleInputChange}
                  required="required"
                />
                <FormText>
                  <FormattedMessage id="app.prescriptions.upload.prescriptionText.help" />
                </FormText>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={3} className="text-end">
                <FormLabel>
                  <FormattedMessage id="app.prescriptions.upload.prescriptionCredit.label" />
                </FormLabel>
              </Col>
              <Col sm={9}>
                <CustomFormControl
                  disabled={
                    this.state.uploadInProgress || this.state.uploadIsDone
                  }
                  type="text"
                  placeholder="0"
                  name="prescriptionCredit"
                  pattern="[0-9]{1,3}"
                  onChange={this.handleInputChange}
                  required="required"
                />
                <FormText>
                  <FormattedMessage id="app.prescriptions.upload.prescriptionCredit.help" />
                </FormText>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={3} className="text-end">
                <FormLabel>
                  <FormattedMessage id="app.prescriptions.upload.prescriptionDate.label" />
                </FormLabel>
              </Col>
              <Col sm={9}>
                <DayPickerInput
                  value={this.state.date}
                  disabledDays={Upload.getEnabledDays}
                  locale={this.props.intl.locale}
                  onDayChange={this.handleDateChange}
                  disabled={
                    this.state.uploadInProgress || this.state.uploadIsDone
                  }
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={3} className="text-end">
                <FormLabel>&nbsp;</FormLabel>
              </Col>
              <Col sm={9}>
                <FormControl
                  disabled={
                    this.state.uploadInProgress || this.state.uploadIsDone
                  }
                  type="file"
                  name="file"
                  accept=".jpg,.jpeg,.pdf"
                  onChange={this.handleFileInputChange}
                  required="required"
                />
                <FormText>
                  <FormattedMessage id="app.prescriptions.upload.file.help" />
                </FormText>
              </Col>
            </Row>

            <ButtonContent>
              {this.state.uploadIsDone ? (
                <div className="d-grid">
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => window.location.reload()}
                  >
                    <FormattedMessage id="app.modal.close" />
                  </Button>
                </div>
              ) : (
                <div className="d-grid">
                  <Button
                    processing={this.state.uploadInProgress}
                    type="submit"
                    variant="primary"
                    size="lg"
                  >
                    <FormattedMessage id="app.prescriptions.upload.submit" />
                  </Button>
                </div>
              )}
            </ButtonContent>
          </Form>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  }
}

export default graphql(PRESCRIPTION_UPLOAD_MUTATION)(injectIntl(Upload));
