/**
 * App Container
 */
import React, { useMemo } from "react";
import "../css/App.css";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  from,
} from "@apollo/client";
import styled from "styled-components";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { theme } from "../globalComponents/Theme";
import { getBearer, removeBearer, getLanguage } from "../utils/localStorage";
import { LanguageProvider } from "../context/LanguageContext/LanguageProvider";
import Frontend from "../Frontend";

const Wrapper = styled("div")`
  font-family: "${theme.fontFamily}", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
`;

const uploadLink = createUploadLink({ uri: "/api/graphql" });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.warn(
        `[GraphQL error]: Message: ${message}, 
            Location: ${JSON.stringify(locations)}
             Path: ${path}`
      );
    });
  if (networkError) {
    // eslint-disable-next-line no-console
    console.warn(`[Network error]: ${networkError}`);
    if (networkError.statusCode !== 422 && getBearer()) {
      removeBearer();
      window.location.href = "/login";
    }
  }
});

const App = () => {
  const [selectedLanguage, setSelectedLanguage] = React.useState(getLanguage());

  const authLink = useMemo(
    () =>
      new ApolloLink((operation, forward) => {
        const token = getBearer();

        operation.setContext(({ headers }) => ({
          headers: {
            authorization: token ? `Bearer ${token}` : "",
            "Accept-Language": selectedLanguage,
            ...headers,
          },
        }));
        return forward(operation);
      }),
    [selectedLanguage]
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        link: from([authLink, errorLink, uploadLink]),
        cache: new InMemoryCache(),
      }),
    [authLink, errorLink, uploadLink]
  );

  return (
    <ApolloProvider client={client}>
      <Wrapper className="App">
        <LanguageProvider
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        >
          <Frontend />
        </LanguageProvider>
      </Wrapper>
    </ApolloProvider>
  );
};

export default App;
