import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { ThemeProvider } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import StaticContent from "../../components/StaticContent";
import { Loading } from "../../globalComponents/Loading";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Container from "../../globalComponents/Container";
import { Wrapper } from "../../globalComponents/Panel";
import CustomPanel from "../../globalComponents/CustomPanel";
import Button from "../../components/CustomBootstrap/Button";
import { theme } from "../../globalComponents/Theme";
import PriorityNewsMutation from "./PriorityNewsMutation";
import AlertModalBox from "../../globalComponents/AlertModalBox";

import { PRIORITY_NEWS_QUERY } from "./PriorityNews.graphql";

class PriorityNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentNewsIndex: 0,
      initMutationNewsId: null,
    };
    this.continue = this.continue.bind(this);
    this.next = this.next.bind(this);
    this.onError = this.onError.bind(this);
  }

  continue(id) {
    this.setState({ initMutationNewsId: id });
  }

  next() {
    this.setState((prevState) => ({
      currentNewsIndex: prevState.currentNewsIndex + 1,
      initMutationNewsId: null,
    }));
  }

  /**
   * opens modal box with error message
   *
   * @param error: returned from api
   * @param errorTextTranslationKey: returned form parseError
   */
  onError(error, errorTextTranslationKey) {
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  }

  render() {
    const priorityNews = !this.props.data.loading
      ? this.props.data.guest.news.filter((n) => n.priority === true)
      : [];

    if (
      !this.props.data.loading &&
      priorityNews.length === this.state.currentNewsIndex
    ) {
      return (
        <Redirect
          push
          to={{
            pathname: "/",
            hash: "priorityNewsDone",
          }}
        />
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <Container className="news-priority">
          <Helmet
            title={`${this.props.intl.formatMessage({
              id: "app.news.title",
            })} - ${this.props.intl.formatMessage({ id: "app.title" })}`}
          />
          <AlertModalBox
            title="error"
            text="error"
            ref={(modal) => {
              this._alertModal = modal;
            }}
          />
          <Wrapper>
            <CustomPanel>
              {this.props.data.loading ? (
                <LoadingCol />
              ) : (
                <StaticContent
                  staticContent={priorityNews[this.state.currentNewsIndex]}
                />
              )}
            </CustomPanel>

            {this.state.initMutationNewsId ? (
              <div>
                <PriorityNewsMutation
                  id={this.state.initMutationNewsId}
                  nextCallback={this.next}
                  onError={this.onError}
                />
                <Loading />
              </div>
            ) : (
              <div className="d-grid">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => {
                    this.continue(priorityNews[this.state.currentNewsIndex].id);
                  }}
                >
                  <FormattedMessage id="app.privacyPolicy.continue" />
                </Button>
              </div>
            )}
          </Wrapper>
        </Container>
      </ThemeProvider>
    );
  }
}

export default graphql(PRIORITY_NEWS_QUERY)(injectIntl(PriorityNews));
