import { gql } from "@apollo/client";

export const BONUS_QUERY = gql`
  query {
    guest {
      id
      bonusPoints {
        available
        open
        total
      }
    }
  }
`;
