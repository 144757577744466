import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import de from "date-fns/locale/de";
import enUs from "date-fns/locale/en-US";

import { getDateObjectByTime } from "../utils/formatDate";
import { LanguageContext } from "../context/LanguageContext/LanguageContext";

const NO_LANGUAGE_ERROR = "language not defined";
const NO_DATE_OR_TIME = "no date or time given";

const DateFormatter = ({ date, type, lang, time }) => {
  const languageContext = useContext(LanguageContext);
  const formatDate = useMemo(() => {
    if (date) return date;
    if (time) return getDateObjectByTime(time);
    throw new Error(NO_DATE_OR_TIME);
  }, [date, time]);

  const formatType = useMemo(() => {
    if (date) return type;
    if (time) return "TIME";
    throw new Error(NO_DATE_OR_TIME);
  }, [date, time]);

  const formattedDate = useMemo(() => {
    switch (formatType) {
      case "DAY_WITH_SHORT_DATE_BR":
        switch (languageContext.languageContext.language) {
          case "de":
            return (
              <>
                {format(formatDate, "EEEEEE.", { locale: de })}
                <br />
                {format(formatDate, "dd.MM.", { locale: de })}
              </>
            );
          case "en":
            return (
              <>
                {format(formatDate, "EEEEEE.", { locale: enUs })}
                <br />
                {format(formatDate, "MM/dd", { locale: enUs })}
              </>
            );
          default:
            throw new Error(NO_LANGUAGE_ERROR);
        }

      case "DAY_WITH_DATE":
        switch (languageContext.languageContext.language) {
          case "de":
            return format(formatDate, "EEEE, P", { locale: de });
          case "en":
            return format(formatDate, "EEEE, P", { locale: enUs });
          default:
            throw new Error(NO_LANGUAGE_ERROR);
        }
      case "DAY_WITH_DATE_AND_TIME":
        switch (languageContext.languageContext.language) {
          case "de":
            return `${format(formatDate, "EEEE, P HH:mm", {
              locale: de,
            })} Uhr`;
          case "en":
            return format(formatDate, "EEEE, P hh:mm aaa", {
              locale: enUs,
            });
          default:
            throw new Error(NO_LANGUAGE_ERROR);
        }
      case "DAY_WITH_DATE_AND_TIME_BR":
        switch (languageContext.languageContext.language) {
          case "de":
            return (
              <>
                {format(formatDate, "EEEEEE. P", { locale: de })}
                <br />
                {format(formatDate, "HH:mm", { locale: de })} Uhr
              </>
            );
          case "en":
            return (
              <>
                {format(formatDate, "EEEEEE., P", { locale: enUs })}
                <br />
                {format(formatDate, "hh:mm aaa", { locale: enUs })}
              </>
            );
          default:
            throw new Error(NO_LANGUAGE_ERROR);
        }
      case "DATE":
        switch (languageContext.languageContext.language) {
          case "de":
            return format(formatDate, "P", { locale: de });
          case "en":
            return format(formatDate, "P", { locale: enUs });
          default:
            throw new Error(NO_LANGUAGE_ERROR);
        }
      case "TIME":
        switch (languageContext.languageContext.language) {
          case "de":
            return `${format(formatDate, "HH:mm", { locale: de })} Uhr`;
          case "en":
            return format(formatDate, "hh:mm aaa", { locale: enUs });
          default:
            throw new Error(NO_LANGUAGE_ERROR);
        }

      default:
        throw new Error("type not defined");
    }
  }, [date, type, lang]);

  return formattedDate;
};

DateFormatter.propTypes = {
  type: PropTypes.oneOf([
    "DAY_WITH_DATE",
    "DATE",
    "DAY_WITH_SHORT_DATE_BR",
    "DAY_WITH_DATE_AND_TIME",
    "DAY_WITH_DATE_AND_TIME_BR",
    "TIME",
  ]),
  date: PropTypes.instanceOf(Date),
  lang: PropTypes.oneOf(["de", "en"]),
};

export { DateFormatter };
