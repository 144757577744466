import { gql } from "@apollo/client";

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $street: String
    $zip: String
    $town: String
    $phonePrivate: String
    $emailPrivate: Email
    $enableEmailPrivate: Boolean
    $mobileJob: String
    $mobileProviderJob: String
  ) {
    updateGuest(
      guest: {
        street: $street
        zip: $zip
        town: $town
        phonePrivate: $phonePrivate
        emailPrivate: $emailPrivate
        enableEmailPrivate: $enableEmailPrivate
        mobileJob: $mobileJob
        mobileProviderJob: $mobileProviderJob
      }
    ) {
      id
      lastname
      firstname
      street
      emailJob
      phonePrivate
      town
      zip
      emailPrivate
      enableEmailPrivate
      mobileJob
      mobileProviderJob
    }
  }
`;
