import { gql } from "@apollo/client";

export const PRIVACY_POLICY_QUERY = gql`
  query PrivacyPolicyQuery {
    frontend {
      employee {
        privacyPolicy {
          title
          content
          id
        }
      }
    }
    guest {
      passwordExpired
      expirationWarning

      privacyPolicyAccepted {
        acceptedDate
        id
        privacyPolicy {
          id
        }
      }
    }
  }
`;

export const ACCEPT_MUTATION = gql`
  mutation accept($id: ID!) {
    acceptGuestPrivacyPolicy(id: $id)
  }
`;
