import React, { Component } from "react";
import Helmet from "react-helmet";
import { Form, FormGroup, Row, Col, Alert, FormText } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import FormControl from "../CustomBootstrap/FormControl";
import Button from "../CustomBootstrap/Button";
import Conf from "../../Conf";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import { SET_MUTATION } from "./Set.graphql";

const Container = styled("div")`
  min-height: calc(100% - 201px);
  padding-bottom: 52px;

  @media screen and (min-width: 768px) {
    min-height: calc(100% - 52px);
  }
`;

class Set extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resetKey: null,
      username: "",
      password: "",
      passwordRepeat: "",
      usernameValidationState: null,
      passwordRepeatValidationState: null,
      passwordValidationState: null,
      showMessage: false,
      success: false,
    };

    this.parseError = parseError.bind(this);
  }

  submitForm(event) {
    event.preventDefault();

    if (this.state.username === "") {
      this.setState({ usernameValidationState: "error" });
      return false;
    }
    this.setState({ usernameValidationState: "success" });

    /**
     * password must contain at least
     * - one capital letter
     * - one lowercase letter
     * - one number (digit)
     * - one special char of these @$!%*#?&_.-
     *
     * password is only allowed to contain letter/chars/digits
     * that are within the above listed restrictions
     */
    if (
      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/.test(
        this.state.password
      )
    ) {
      this.setState({ passwordValidationState: "error" });
      return false;
    }
    this.setState({ passwordValidationState: "success" });

    if (this.state.password !== this.state.passwordRepeat) {
      this.setState({ passwordRepeatValidationState: "error" });
      return false;
    }
    this.setState({ passwordRepeatValidationState: "success" });

    this.props
      .mutate({
        variables: {
          username: this.state.username,
          resetKey: this.state.resetKey,
          password: this.state.password,
        },
      })
      .then(({ data }) => {
        if (data.resetPassword) {
          this.setState({
            showMessage: true,
            success: true,
            username: "",
            password: "",
          });

          /** redirects user after 3 seconds * */
          setTimeout(() => {
            this.props.history.push("/login");
          }, 3000);
        } else {
          const error = { message: "default" };
          const errorTextTranslationKey = this.parseError(
            error,
            Conf.errorContext.passwordReset
          );

          this._alertModal.open(
            this.props.intl.formatMessage({ id: "app.global.error" }),
            this.props.intl.formatMessage({ id: errorTextTranslationKey })
          );
        }
      })
      .catch((error) => {
        const errorTextTranslationKey = this.parseError(
          error,
          Conf.errorContext.passwordSet
        );

        this._alertModal.open(
          this.props.intl.formatMessage({ id: "app.global.error" }),
          this.props.intl.formatMessage({ id: errorTextTranslationKey })
        );
      });
  }

  updateUsername(event) {
    this.setState({ username: event.target.value });
  }

  updatePassword(event) {
    this.setState({ password: event.target.value });
  }

  updatePasswordRepeat(event) {
    this.setState({ passwordRepeat: event.target.value });
  }

  setupView() {
    const sParams = this.props.location.search.replace("?", "");
    const aKeyValueString = sParams.split("&");
    const oKeyValuePair = {};

    aKeyValueString.forEach((keyValueString) => {
      const aKeyValue = keyValueString.split("=");
      oKeyValuePair[aKeyValue[0]] = aKeyValue[1];
    });

    if (oKeyValuePair.key) {
      this.setState({ resetKey: oKeyValuePair.key });
    }
    if (oKeyValuePair.username) {
      this.setState({ username: oKeyValuePair.username });
    }
  }

  componentDidMount() {
    this.setupView();
  }

  render() {
    return (
      <Container className="container" style={{ textAlign: "left" }}>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <Row>
          <Col xs={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
            <br />
            <br />{" "}
            {this.state.showMessage ? (
              <Alert variant={this.state.success ? "success" : "danger"}>
                <p className="text-left">
                  {this.state.success ? (
                    <FormattedMessage id="app.password.set.success" />
                  ) : (
                    <FormattedMessage id="app.password.set.fail" />
                  )}
                </p>
              </Alert>
            ) : (
              ""
            )}
            <FormattedMessage id="app.password.reset">
              {(message) => <Helmet title={message} />}
            </FormattedMessage>
            <Form onSubmit={(event) => this.submitForm(event)}>
              <FormGroup
                validationState={this.state.usernameValidationState}
                className="mb-3"
              >
                <FormattedMessage id="app.password.reset.username">
                  {(message) => (
                    <FormControl
                      autoComplete="none"
                      value={this.state.username}
                      size="lg"
                      type="text"
                      placeholder={message}
                      onChange={(event) => this.updateUsername(event)}
                    />
                  )}
                </FormattedMessage>
                {this.state.usernameValidationState === "error" ? (
                  <FormText style={{ color: "#dc3545" }}>
                    <FormattedMessage id="app.password.set.emptyUsername" />
                  </FormText>
                ) : (
                  ""
                )}
              </FormGroup>
              <FormGroup
                validationState={this.state.passwordValidationState}
                className="mb-3"
              >
                <FormattedMessage id="app.password.reset.password">
                  {(message) => (
                    <FormControl
                      autoComplete="none"
                      placeholder={message}
                      value={this.state.password}
                      size="lg"
                      type="password"
                      onChange={(event) => this.updatePassword(event)}
                    />
                  )}
                </FormattedMessage>
                {this.state.passwordValidationState === "error" ? (
                  <FormText style={{ color: "#dc3545" }}>
                    <FormattedMessage id="app.account.invalidPassword" />
                  </FormText>
                ) : (
                  ""
                )}
              </FormGroup>
              <FormGroup
                validationState={this.state.passwordRepeatValidationState}
                className="mb-3"
              >
                <FormattedMessage id="app.account.passwordRepeat">
                  {(message) => (
                    <FormControl
                      autoComplete="none"
                      placeholder={message}
                      value={this.state.passwordRepeat}
                      size="lg"
                      type="password"
                      onChange={(event) => this.updatePasswordRepeat(event)}
                      style={{ marginBottom: "3px" }}
                    />
                  )}
                </FormattedMessage>
                {this.state.passwordRepeatValidationState === "error" ? (
                  <FormText
                    style={{
                      display: "block",
                      paddingTop: "3px",
                      paddingBottom: "5px",
                      color: "#dc3545",
                    }}
                  >
                    <FormattedMessage id="app.account.invalidPasswordRepeat" />
                  </FormText>
                ) : (
                  ""
                )}
                <FormText>
                  <FormattedMessage id="app.account.passwordHint" />
                </FormText>
              </FormGroup>
              <FormGroup>
                <div>
                  <div className="d-grid">
                    <Button type="submit" variant="primary" size="lg">
                      <FormattedMessage id="app.password.set" />
                    </Button>
                  </div>
                </div>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default graphql(SET_MUTATION)(injectIntl(Set));
