import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";
import {
  CustomRow,
  CustomLeftCol,
  CustomRightCol,
  CustomListGroup,
  SecondLevelListGroupItem,
  Title,
  CustomFaAngleRight,
} from "../../globalComponents/List";
import Layout from "../../components/Layout";
import Subvention from "../../components/Budget/Subvention";
import Refund from "../../components/Budget/Refund";
import { Loading } from "../../globalComponents/Loading";
import { BUDGET_QUERY } from "./Budget.graphql";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 15px;
`;

const CustomListGroupItem = styled(SecondLevelListGroupItem)`
  font-weight: bold;

  &.open {
    background-color: #ffffff !important;
    color: #000000;
  }

  &.open svg {
    transform: rotate(0);
  }
`;

const BudgetOverview = styled("div")`
  border-bottom: 1px solid #b1b1b1;
  font-size: 18px;
  padding: 30px;
`;

class Budget extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const isMobile = window.innerWidth < 992;

    this.state = {
      showSubvention: !isMobile,
      showRefund: false,
      contentIsOpen: !isMobile,
      prevLocation: "",
      budgetLeft: null,
      budgetTotal: null,
      isMobile,
    };

    window.addEventListener("resize", this.updateIsMobile.bind(this));
  }

  formatPrice(number) {
    return this.props.intl.formatNumber(number, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  showSubvention() {
    this.props.history.push("/budget/subvention");
  }

  showRefund() {
    this.props.history.push("/budget/refund");
  }

  getBudgetOverview() {
    if (this.props.data.guest.guestSubventionLimit !== null) {
      this.setState({
        budgetLeft: this.formatPrice(
          this.props.data.guest.guestSubventionLimit.value -
            this.props.data.guest.guestSubventionLimit.currentValue
        ),
        budgetTotal: this.formatPrice(
          this.props.data.guest.guestSubventionLimit.value
        ),
      });
    }
  }

  updateIsMobile() {
    if (!this._isMounted) {
      return false;
    }
    const isMobile = window.innerWidth < 992;
    this.setState((prevState) => ({
      isMobile,
      showSubvention: !!(
        prevState.showSubvention === false &&
        prevState.showRefund === false &&
        !isMobile
      ),
      contentIsOpen: !isMobile,
    }));
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    if (!this.props.data.loading) {
      if (this.state.budgetLeft === null || this.state.budgetTotal === null) {
        this.getBudgetOverview();
      }
    }

    /** show subvention content */
    if (
      this.props.match.params.content === "subvention" &&
      this.state.prevLocation !== "subvention"
    ) {
      this.setState({
        showRefund: false,
        showSubvention: true,
        contentIsOpen: true,
        prevLocation: "subvention",
      });
    }

    /** show refund content */
    if (
      this.props.match.params.content === "refund" &&
      this.state.prevLocation !== "refund"
    ) {
      this.setState({
        showRefund: true,
        showSubvention: false,
        contentIsOpen: true,
        prevLocation: "refund",
      });
    }

    /** reset view to not display anything on the right side */
    if (
      this.props.match.params.content === "" &&
      this.state.contentIsOpen &&
      this.state.prevLocation !== ""
    ) {
      this.setState({
        showRefund: false,
        showSubvention: false,
        contentIsOpen: false,
        prevLocation: "",
      });
    }
  }

  getLayoutTitle(defaultTitle) {
    let newTitle = defaultTitle;
    if (this.state.isMobile && this.state.showSubvention)
      newTitle = "Budget Übersicht";
    if (this.state.isMobile && this.state.showRefund)
      newTitle = "Eingereichte Belege";
    return newTitle;
  }

  render() {
    return (
      <FormattedMessage id="app.budget.title">
        {(budgetTitle) => (
          <Layout {...this.props} title={this.getLayoutTitle(budgetTitle)}>
            <PaddingLessContainer className="budget">
              <Helmet title={budgetTitle} />
              <CustomRow>
                <CustomLeftCol xs={12} md={12} lg={4}>
                  <CustomListGroup className="list-group">
                    {this.state.budgetLeft !== null ||
                    this.state.budgetTotal !== null ? (
                      <BudgetOverview>
                        <p>
                          <b>
                            <FormattedMessage id="app.budget.currentBudget" />:
                          </b>
                        </p>
                        <p>
                          {this.state.budgetLeft}{" "}
                          {this.props.intl.formatMessage({
                            id: "app.budget.of",
                          })}{" "}
                          {this.state.budgetTotal}{" "}
                          {this.props.intl.formatMessage({
                            id: "app.budget.points",
                          })}
                        </p>
                      </BudgetOverview>
                    ) : this.props.data.loading === true ? (
                      <BudgetOverview>
                        <Loading
                          style={{
                            marginTop: "10px",
                          }}
                        />
                      </BudgetOverview>
                    ) : (
                      ""
                    )}
                    <CustomListGroupItem
                      onClick={() => this.showSubvention()}
                      className={[
                        "budget-overview",
                        this.state.showSubvention ? "open" : "",
                      ].join(" ")}
                    >
                      <Title>
                        <FormattedMessage id="app.budget.overview" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomListGroupItem>
                    <CustomListGroupItem
                      onClick={() => this.showRefund()}
                      className={[
                        "budget-receipts",
                        this.state.showRefund ? "open" : "",
                      ].join(" ")}
                    >
                      <Title>
                        <FormattedMessage id="app.budget.submittedReceipts" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomListGroupItem>
                  </CustomListGroup>
                </CustomLeftCol>

                <CustomRightCol
                  xs={12}
                  md={12}
                  lg={8}
                  className={this.state.contentIsOpen ? "open" : ""}
                >
                  <ScrollableDiv className="text-left">
                    {this.state.showSubvention ? <Subvention /> : ""}
                    {this.state.showRefund ? <Refund /> : ""}
                  </ScrollableDiv>
                </CustomRightCol>
              </CustomRow>
            </PaddingLessContainer>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default graphql(BUDGET_QUERY, {
  options: {
    fetchPolicy: "cache-and-network",
  },
})(injectIntl(Budget));
