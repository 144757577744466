import React, { Component } from "react";
import styled from "styled-components";
import { Collapse } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { CustomFaAngleRight, Header, Title } from "../../globalComponents/List";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Conf from "../../Conf";
import { withLanguage } from "../../context/LanguageContext/withLanguage";
import Button from "../CustomBootstrap/Button";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import { BUDGET_QUERY } from "./Subvention.graphql";
import { formatDate } from "../../utils/dateHelper";

const CustomHeader = styled(Header)`
  padding-left: 15px;

  &.open {
    padding: 0 15px 0 15px;
  }
`;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 1px solid #d4d4d4;
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

const P = styled("p")`
  padding-top: 15px;
  font-weight: bold;
`;

class Subvention extends Component {
  constructor(props) {
    super(props);

    this.state = { open: null };
    this.fetchMore = this.fetchMore.bind(this);
  }

  canGetMore() {
    return (
      this.props.data.guest.subventionRecordsConnection.edges.length !==
      this.props.data.guest.subventionRecordsConnection.totalCount
    );
  }

  fetchMore() {
    return this.props.data.fetchMore();
  }

  formatPrice(number) {
    return this.props.intl.formatNumber(number, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  static renderTherapist(node) {
    if (!node?.object?.eventTherapist?.therapist?.displayName) return null;
    return (
      <>
        <br />
        <FormattedMessage id="app.global.with" />{" "}
        {node.object.eventTherapist.therapist.displayName}
      </>
    );
  }

  /**
   * return content for object type:
   * EventGuest / BgmConceptTherapyOfferRefundRecord / PartnerPracticeEventGuest
   */
  getRightSide(node) {
    const objectType = node.object.__typename;

    if (objectType === "EventGuest") {
      const { roomEvent } = node.object.eventTherapist;

      /**
       * EventGuest has two different roomEvent types
       * on which the content differs in given data
       */
      switch (roomEvent.type) {
        case "DEFAULT":
          return (
            <P>
              <FormattedMessage id="app.offers.price.date.at" />
              {formatDate(
                node.object.date,
                this.props.languageContext.language
              )}
              {Subvention.renderTherapist(node)}
            </P>
          );
        case "COURSE":
        default:
          return (
            <P>
              <FormattedMessage
                id="app.offers.price.course.fromTo"
                values={{
                  from: formatDate(
                    roomEvent.dateBegin,
                    this.props.languageContext.language
                  ),
                  to: formatDate(
                    roomEvent.dateEnd,
                    this.props.languageContext.language
                  ),
                }}
              />
              {Subvention.renderTherapist(node)}
            </P>
          );
      }
    }

    if (objectType === "BgmConceptTherapyOfferRefundRecord") {
      return (
        <P>
          <FormattedMessage
            id="app.offers.price.receipt.fromOver"
            values={{
              from: formatDate(
                node.object.creationDate,
                this.props.languageContext.language
              ),
              over: this.formatPrice(node.object.gross),
            }}
          />
        </P>
      );
    }

    if (objectType === "PartnerPracticeEventGuest") {
      return (
        <P>
          <FormattedMessage id="app.offers.price.visited.at" />
          {formatDate(node.object.date, this.props.languageContext.language)}
          <br />
          <FormattedMessage id="app.global.with" />
          {node.object.partnerPracticeEventList.partnerPracticeLocation.name}
        </P>
      );
    }

    if (objectType === "BgmConceptTherapyOfferExternUsage") {
      return (
        <P>
          <FormattedMessage id="app.offers.extern.serviceProvider" />:{" "}
          {
            node.object.bgmConceptTherapyOfferExtern.serviceProviderLocation
              .name
          }
          <br />
          <br />
          {node.object.salesPrice > 0 ? (
            <span>
              <FormattedMessage id="app.offers.extern.price" />
              :&nbsp;{this.formatPrice(node.object.salesPrice)}
              <FormattedMessage id="app.global.priceUnit" />
              <br />
              <FormattedMessage id="app.appointments.paymentMethod" />:
              <FormattedMessage
                id={`app.appointments.paymentMethod.${node.object.payment}`}
              />
              <br />
            </span>
          ) : (
            ""
          )}
          {node.object.note ? (
            <span>
              <br />
              <FormattedMessage id="app.offers.extern.note" />:<br />{" "}
              {node.object.note}
              <br />
            </span>
          ) : (
            ""
          )}
          <br />
          <FormattedMessage id="app.offers.extern.bookingDate" />:{" "}
          {formatDate(
            node.object.creationDate,
            this.props.languageContext.language
          )}
          <br />
          <FormattedMessage id="app.offers.extern.state" />:
          <FormattedMessage
            id={`app.offers.extern.state.${node.object.state}`}
          />
        </P>
      );
    }
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    return (
      <div>
        {this.props.data.guest.subventionRecordsConnection.edges.length ===
        0 ? (
          <VerticallyAlignedMiddle height={window.innerHeight / 2}>
            <span className="large">
              <FormattedMessage id="app.budget.noBudget" />
            </span>
          </VerticallyAlignedMiddle>
        ) : (
          this.props.data.guest.subventionRecordsConnection.edges.map(
            (edge) => (
              <div key={edge.cursor}>
                {edge.node.companySubvention.therapyOffer.title ? (
                  <div>
                    <CustomHeader
                      className={this.state.open === edge.cursor ? "open" : ""}
                      onClick={() =>
                        this.state.open === edge.cursor
                          ? this.setState({ open: null })
                          : this.setState({ open: edge.cursor })
                      }
                    >
                      <Title>
                        {edge.node.companySubvention.therapyOffer.title}
                        <br />
                        <small className="text-muted">
                          {this.formatPrice(edge.node.gross)}
                          <FormattedMessage id="app.global.priceUnit" />
                          &nbsp;
                          {edge.node.gross === 1 ? (
                            <FormattedMessage id="app.offers.price.subventionSingular" />
                          ) : (
                            <FormattedMessage id="app.offers.price.subvention" />
                          )}
                          &nbsp;
                          <FormattedMessage id="app.global.at" />{" "}
                          {formatDate(
                            edge.node.date,
                            this.props.languageContext.language
                          )}
                        </small>
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomHeader>
                    <Wrapper>
                      <Collapse in={this.state.open === edge.cursor}>
                        <div>
                          <Content>{this.getRightSide(edge.node)}</Content>
                        </div>
                      </Collapse>
                    </Wrapper>
                  </div>
                ) : null}
              </div>
            )
          )
        )}
        {!this.props.data.loading && this.canGetMore() ? (
          <div>
            <br />
            <div className="d-grid">
              <Button onClick={this.fetchMore} variant="primary" size="lg">
                <FormattedMessage id="app.global.loadMore" />
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const ITEMS_PER_PAGE = Conf.general.itemsPerPage;
const withData = graphql(BUDGET_QUERY, {
  options: () => ({
    variables: {
      cursor: null,
      limit: ITEMS_PER_PAGE,
    },
    fetchPolicy: "cache-and-network",
  }),
  props: ({ data: { loading, guest, config, fetchMore } }) => ({
    data: {
      loading,
      guest,
      config,
      fetchMore: () =>
        fetchMore({
          variables: {
            cursor:
              guest.subventionRecordsConnection.edges[
                guest.subventionRecordsConnection.edges.length - 1
              ].cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            const ret = {
              ...prev,
              guest: {
                subventionRecordsConnection: {
                  edges: [
                    ...prev.guest.subventionRecordsConnection.edges,
                    ...fetchMoreResult.guest.subventionRecordsConnection.edges,
                  ],
                  totalCount:
                    fetchMoreResult.guest.subventionRecordsConnection
                      .totalCount,
                  __typename:
                    fetchMoreResult.guest.subventionRecordsConnection
                      .__typename,
                },
                __typename: fetchMoreResult.guest.__typename,
              },
            };

            // "fixed" missing id throwing an exception
            ret.guest.id = "-1";

            return ret;
          },
        }),
    },
  }),
});

export default withLanguage(withData(injectIntl(Subvention)));
