import * as React from "react";
import { LanguageContext } from "./LanguageContext";

export function withLanguage(Component) {
  return function LanguageComponent(props) {
    return (
      <LanguageContext.Consumer>
        {(contexts) => <Component {...props} {...contexts} />}
      </LanguageContext.Consumer>
    );
  };
}
