import { gql } from "@apollo/client";

export const OFFER_QUERY = gql`
  query offerQuery($id: ID!) {
    bgmConcept {
      offer(id: $id) {
        id
        bgmConceptTherapyOfferTags {
          therapyOfferTag {
            id
            icon
            color
            label
          }
        }
        bgmConceptTherapyOfferAttribute {
          id
          therapyOfferAttribute {
            id
            icon
            title
          }
          therapyOfferAttributeValue {
            id
            title
            icon
          }
        }
        therapyOffer {
          id
          icon
          desc
          isInLimit
          images {
            id
            url
            name
          }
          videos {
            id
            url
            name
          }
          downloads {
            id
            url
            name
          }
          hidePrices
          priceMatrix {
            price {
              total
              companySubventionAmount
              net
              tax
              count
            }
            validFrom
            validTo
          }
        }
        showCommingSoon
        refund {
          id
          valueText
        }
        events {
          id
        }
        courses {
          id
          events {
            id
          }
        }
        extern {
          id
          variants {
            id
            __typename
            description
            ... on BgmConceptTherapyOfferExternVariantCustom {
              title
            }
            ... on BgmConceptTherapyOfferExternVariantEventOnline {
              dateBegin
              dateEnd
              plattformName
            }
            lang
            ... on BgmConceptTherapyOfferExternVariantEventLocation {
              dateBegin
              dateEnd
              locationName
              street
              streetNumber
              zipCode
              city
              country
            }
          }
        }
      }
    }
    guest {
      id
      location {
        country
      }
      guestSubventionLimit {
        id
        currentValue
        value
        gross
      }
    }
    config {
      modules {
        ... on ModuleConfigFrontend {
          employee {
            showPrices
            showSubventionValue
          }
        }
      }
    }
  }
`;
