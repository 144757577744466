import styled from "styled-components";

export const CustomPanel = styled("div")`
  background-color: #ffffff;
  border-radius: 8px;
  margin: 10px 0;
  min-height: 80px;
  padding: 15px;
  text-align: left;

  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 0;
  }
`;

export const Wrapper = styled("div")`
  margin: 10px 0 30px;
`;

export const H4 = styled("h4")`
  margin: 0;
  padding: 0 15px;
  text-align: left;
  font-size: 18px;
  color: #333;
`;

export const ImageWrapper = styled("div")`
  text-align: center;
  margin: 0;
  margin-bottom: 5px;
  height: 90px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  video {
    width: 100%;
  }
`;
