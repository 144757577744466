import React, { Component } from "react";
import styled from "styled-components";
import { Collapse } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { CustomFaAngleRight, Header, Title } from "../../globalComponents/List";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Conf from "../../Conf";
import Button from "../CustomBootstrap/Button";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import { withLanguage } from "../../context/LanguageContext/withLanguage";
import { formatDate } from "../../utils/dateHelper";

import { BUDGET_QUERY } from "./Conversions.graph.ql";

const CustomHeader = styled(Header)`
  padding-left: 15px;

  &.open {
    padding: 0 15px 0 15px;
  }
`;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 1px solid #d4d4d4;
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

class Conversions extends Component {
  constructor(props) {
    super(props);

    this.state = { open: null };

    this.fetchMore = this.fetchMore.bind(this);
  }

  canGetMore() {
    return (
      this.props.data.guest.bonusConversionsConnection.edges.length !==
      this.props.data.guest.bonusConversionsConnection.totalCount
    );
  }

  fetchMore() {
    return this.props.data.fetchMore();
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    return (
      <div>
        {this.props.data.guest.bonusConversionsConnection.edges.length === 0 ? (
          <VerticallyAlignedMiddle height={window.innerHeight / 2}>
            <span className="large">
              <FormattedMessage id="app.bonus.noConversions" />
            </span>
          </VerticallyAlignedMiddle>
        ) : (
          this.props.data.guest.bonusConversionsConnection.edges.map((edge) => (
            <div key={edge.cursor}>
              {edge.node.bonus.title ? (
                <div>
                  <CustomHeader
                    className={this.state.open === edge.cursor ? "open" : ""}
                    onClick={() =>
                      this.state.open === edge.cursor
                        ? this.setState({ open: null })
                        : this.setState({ open: edge.cursor })
                    }
                  >
                    <Title>
                      {edge.node.bonus.title}
                      <br />
                      <small className="text-muted">
                        {edge.node.points}
                        <FormattedMessage id="app.bonus.points" />
                        <FormattedMessage id="app.global.at" />{" "}
                        {formatDate(
                          edge.node.creationDate,
                          this.props.languageContext.language
                        )}
                      </small>
                    </Title>
                    <CustomFaAngleRight className="arrow" />
                  </CustomHeader>
                  <Wrapper>
                    <Collapse in={this.state.open === edge.cursor}>
                      <div>
                        <Content>
                          {edge.node.bonus.deliver === true ? (
                            <div>
                              <strong>
                                <FormattedMessage id="app.bonus.shop.deliverAddress" />
                              </strong>
                              <br /> {edge.node.firstname}
                              {edge.node.lastname}
                              <br /> {edge.node.street}
                              <br /> {edge.node.zip}
                              {edge.node.town}
                            </div>
                          ) : (
                            <i>
                              <FormattedMessage id="app.bonus.shop.imediately" />
                            </i>
                          )}
                        </Content>
                      </div>
                    </Collapse>
                  </Wrapper>
                </div>
              ) : null}
            </div>
          ))
        )}
        {!this.props.data.loading && this.canGetMore() ? (
          <div>
            <br />
            <div className="d-grid">
              <Button onClick={this.fetchMore} variant="primary" size="lg">
                <FormattedMessage id="app.global.loadMore" />
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const ITEMS_PER_PAGE = Conf.general.itemsPerPage;
const withData = graphql(BUDGET_QUERY, {
  options: () => ({
    variables: {
      cursor: null,
      limit: ITEMS_PER_PAGE,
    },
    fetchPolicy: "cache-and-network",
  }),
  props: ({ data: { loading, guest, config, fetchMore } }) => ({
    data: {
      loading,
      guest,
      config,
      fetchMore: () =>
        fetchMore({
          variables: {
            cursor:
              guest.bonusConversionsConnection.edges[
                guest.bonusConversionsConnection.edges.length - 1
              ].cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            return {
              ...prev,
              guest: {
                bonusConversionsConnection: {
                  edges: [
                    ...prev.guest.bonusConversionsConnection.edges,
                    ...fetchMoreResult.guest.bonusConversionsConnection.edges,
                  ],
                  totalCount:
                    fetchMoreResult.guest.bonusConversionsConnection.totalCount,
                  __typename:
                    fetchMoreResult.guest.bonusConversionsConnection.__typename,
                },
                __typename: fetchMoreResult.guest.__typename,
              },
            };
          },
        }),
    },
  }),
});

export default withLanguage(withData(injectIntl(Conversions)));
