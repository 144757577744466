import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from "../CustomBootstrap/Button";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";
import JoinOnlineEventButton from "../JoinOnlineEventButton/JoinOnlineEventButton";
import { DateFormatter } from "../../globalComponents/DateFormatter";

class NextDateWidget extends Component {
  static removeSeconds(time) {
    const aTimeComponent = time.toString().split(":");
    let timeWITHOUTSeconds;
    for (let index = 0; index < aTimeComponent.length - 1; index++) {
      switch (index) {
        case 0:
          timeWITHOUTSeconds = aTimeComponent[index];
          break;
        case 1:
          timeWITHOUTSeconds = `${timeWITHOUTSeconds}:${aTimeComponent[index]}`;
          break;
        default:
          return;
      }
    }

    return timeWITHOUTSeconds;
  }

  render() {
    return (
      <Wrapper className="widget widget-next-date">
        <H4>
          <FormattedMessage id="app.home.widget.nextDate.title" />
        </H4>
        <CustomPanel className="widget-next-date-wrapper">
          <div style={{ marginBottom: "20px" }}>
            <strong>{this.props.data.nextDate.therapyOffer.title}</strong>
            <br />{" "}
            <DateFormatter
              date={new Date(this.props.data.nextDate.date)}
              type="DAY_WITH_DATE"
            />{" "}
            <DateFormatter time={this.props.data.nextDate.timeStart} />
            {" - "}
            <DateFormatter time={this.props.data.nextDate.timeEnd} />
          </div>
          {this.props.data.nextDate.eventTherapist &&
          this.props.data.nextDate.eventTherapist.isOnline ? (
            <div className="d-grid">
              <JoinOnlineEventButton
                style={{ marginBottom: "10px" }}
                title={
                  this.props.data.nextDate.eventTherapist.eventTherapistOnline
                    .canGuestJoin
                    ? this.props.intl.formatMessage({
                        id: "app.home.widget.nextDate.joinOnlineEventButton.hintCanJoin",
                      })
                    : this.props.intl.formatMessage({
                        id: "app.home.widget.nextDate.joinOnlineEventButton.hintCantJoin",
                      })
                }
                variant="primary"
                size="lg"
                canJoin={
                  this.props.data.nextDate.eventTherapist.eventTherapistOnline
                    .canGuestJoin
                }
                eventTherapistOnline={
                  this.props.data.nextDate.eventTherapist.eventTherapistOnline
                }
              >
                <FormattedMessage id="app.home.widget.nextDate.joinOnlineEventButton" />
              </JoinOnlineEventButton>
            </div>
          ) : null}
          <div className="d-grid">
            <Button href="/appointments" variant="primary" size="lg">
              <FormattedMessage id="app.home.widget.nextDate.button" />
            </Button>
          </div>
        </CustomPanel>
      </Wrapper>
    );
  }
}

export default injectIntl(NextDateWidget);
