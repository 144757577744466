import React, { Component } from "react";
import { withApollo, graphql } from "@apollo/client/react/hoc";
// import { graphql } from "graphql";
import { flowRight as compose } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Card, ProgressBar, Alert } from "react-bootstrap";
import styled from "styled-components";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../CustomBootstrap/Button";
import {
  QUESTIONNAIRE_QUERY,
  ANSWER_QUESTIONNAIRE_GROUP_MUTATION,
} from "./Questionnaire.graphql";
import QuestionnaireGroup from "./QuestionnaireGroup";

const QuestionnairePanel = styled((props) => <Card {...props} />)`
  border-radius: 8px !important;
  padding: 20px;
  font-size: 1.2em;

  @media only screen and (min-width: 768px) {
    margin: 30px;
  }
  @media only screen and (min-width: 1060px) {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  max-width: 1000px;

  Button {
    margin-top: 50px;
    float: right;
  }
`;

const CustomHTML = styled("div")`
  font-size: 0.9em;
  h1 {
    font-size: 1.5em;
  }
`;

const CustomProgressBar = styled((props) => <ProgressBar {...props} />)`
  position: relative;
  height: 30px !important;
  .progress-bar {
    background-color: #e3ea8f !important;
  }
`;

const ProgressInfo = styled("div")`
  margin: auto;
  position: absolute;
  width: 100%;
  font-size: 1em;
  color: #646567;
  line-height: 30px;
`;

const BEGINNING = "BEGINNING";
const RUNNING = "RUNNING";
const FINISHED = "FINISHED";

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.sendAnswers = this.sendAnswers.bind(this);
    this.getTimeLeft = this.getTimeLeft.bind(this);

    this.state = {
      state: BEGINNING,
      questionnaireGroup: null,
      loading: false,
      error: null,
    };
  }

  sendAnswers(answers) {
    this.setState({ loading: true });
    this.props
      .mutate({
        variables: {
          code: this.props.code,
          answers,
        },
      })
      .then((props) => {
        if (props.data.errors !== undefined) {
          const code = props.data.errors.message.match(/[0-9]{3}(x[0-9]+)?/g);
          this.setState({
            loading: false,
            error: code || "default",
          });
        } else {
          this.props.data.refetch();
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        const code = error.message.match(/[0-9]{3}(x[0-9]+)?/g);
        this.setState({
          loading: false,
          error: code || "default",
        });
      });
  }

  getTimeLeft() {
    if (this.state.state === FINISHED) {
      return 0;
    }
    if (this.props.data.loading === true) {
      return 0;
    }
    return this.props.data.surveys.surveyState.progress === 0
      ? this.props.survey.predictedTime
      : Math.ceil(
          this.props.survey.predictedTime *
            (1 - this.props.data.surveys.surveyState.progress / 100)
        );
  }

  componentDidUpdate(prev) {
    if (prev.data.loading === true && this.props.data.loading === false) {
      this.setState({
        state:
          this.props.data.surveys.surveyState.completed === true
            ? FINISHED
            : // eslint-disable-next-line react/no-access-state-in-setstate
            this.state.questionnaireGroup === null &&
              this.props.survey.text !== ""
            ? BEGINNING
            : RUNNING,
        questionnaireGroup:
          this.props.data.surveys.surveyState.questionnaireGroup,
      });
    }
  }

  render() {
    if (this.state.loading || this.props.data.loading === true) {
      return <LoadingCol />;
    }

    let errorMessage = null;

    if (this.state.loading === true) {
      return <LoadingCol />;
    }

    if (this.state.error !== null) {
      errorMessage = `app.survey.questionnaire.error.${this.state.error}`;
    }

    const progressInfo = (
      <ProgressInfo>
        <FormattedMessage
          id={
            this.state.state === FINISHED
              ? "app.survey.questionnaire.progress.finished"
              : "app.survey.questionnaire.progress"
          }
          values={{ minutes: `${this.getTimeLeft()}` }}
        />
      </ProgressInfo>
    );

    return (
      <QuestionnairePanel>
        {this.props.survey.predictedTime > 0 ? (
          <CustomProgressBar
            now={this.props.data.surveys.surveyState.progress}
            label={progressInfo}
          />
        ) : (
          ""
        )}
        {this.state.state === BEGINNING ? (
          <CustomHTML>
            {React.createElement("div", {
              dangerouslySetInnerHTML: { __html: this.props.survey.text },
            })}
            <Button
              onClick={() => {
                this.setState({ state: RUNNING });
              }}
              variant="primary"
              size="lg"
            >
              <FormattedMessage id="app.survey.start" />
            </Button>
          </CustomHTML>
        ) : (
          <div>
            {this.state.state === RUNNING ? (
              <div>
                {errorMessage !== null ? (
                  <Alert variant="danger">
                    <h4>
                      <FormattedMessage id="app.survey.questionnaire.error" />
                    </h4>
                    {React.createElement("p", {
                      dangerouslySetInnerHTML: {
                        __html: this.props.intl.formatMessage({
                          id: errorMessage,
                        }),
                      },
                    })}
                  </Alert>
                ) : (
                  ""
                )}
                <QuestionnaireGroup
                  questionGroup={this.state.questionnaireGroup}
                  onComplete={this.sendAnswers}
                />
              </div>
            ) : (
              <CustomHTML>
                {React.createElement("div", {
                  dangerouslySetInnerHTML: { __html: this.props.survey.finish },
                })}
              </CustomHTML>
            )}
          </div>
        )}
      </QuestionnairePanel>
    );
  }
}

export default compose(
  withApollo,
  graphql(QUESTIONNAIRE_QUERY, {
    options: (props) => ({
      variables: { code: props.code },
      fetchPolicy: "network",
      notifyOnNetworkStatusChange: true,
    }),
  }),
  graphql(ANSWER_QUESTIONNAIRE_GROUP_MUTATION)
)(injectIntl(Questionnaire));
