import { gql } from "@apollo/client";

export const CATALOG_QUERY = gql`
  query catalogQuery {
    guest {
      id
      town
      zip
      street
    }
    bonusProgram {
      catalog {
        id
        title
        image
        points
        description
        deliver
      }
    }
  }
`;

export const UPDATE_GUEST_MUTATION = gql`
  mutation updateGuest($guest: GuestInput!) {
    updateGuest(guest: $guest) {
      id
      zip
      town
      street
    }
  }
`;

export const CONVERT_BPOINTS_MUTATION = gql`
  mutation convertBonusPoints($bonusId: ID!) {
    convertBonusPoints(bonusId: $bonusId) {
      id
    }
  }
`;
