import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";

import Home from "./containers/Home";
import Login from "./containers/Login";
import Password from "./containers/Password";
import Offers from "./containers/Offers";
import Calendar from "./containers/Calendar";
import Logout from "./containers/Logout";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Appointments, {
  PAGE_PAST,
  PAGE_FUTURE,
  PAGE_PRESCRIPTIONS,
} from "./containers/Appointments";

import Budget from "./containers/Budget";
import Bonus, {
  PAGE_SHOP,
  PAGE_COLLECT,
  PAGE_COLLECTIONS,
  PAGE_CONVERSIONS,
} from "./containers/Bonus";

import GuestCards from "./containers/GuestCards";
import Register from "./containers/Register";
import News from "./containers/News/News";
import PriorityNews from "./containers/PriorityNews";
import Account from "./containers/Account";
import Imprint from "./containers/Imprint";
import Contact from "./containers/Contact";
import About from "./containers/About";
import Faq from "./containers/FAQ/FAQ";
import PublicPrivacyPolicy from "./containers/PublicPrivacyPolicy";
import Language from "./containers/Language/Language";

export default class Router extends React.PureComponent {
  render() {
    return (
      <BrowserRouter>
        <>
          {this.props.modules.includes("HOME") ? (
            <PrivateRoute
              exact
              path="/"
              component={(props) => (
                <Home {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("CALENDAR") ? (
            <PrivateRoute
              exact
              path="/calendar"
              component={(props) => (
                <Calendar {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}

          {this.props.modules.includes("DATES") ? (
            <PrivateRoute
              exact
              path="/appointments"
              component={(props) => (
                <Appointments {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("DATES") ? (
            <PrivateRoute
              exact
              path="/appointments/future"
              component={(props) => (
                <Appointments
                  {...props}
                  modules={this.props.modules}
                  page={PAGE_FUTURE}
                />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("DATES") ? (
            <PrivateRoute
              exact
              path="/appointments/past"
              component={(props) => (
                <Appointments
                  {...props}
                  modules={this.props.modules}
                  page={PAGE_PAST}
                />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("PRESCRIPTIONS") ? (
            <PrivateRoute
              exact
              path="/appointments/prescriptions"
              component={(props) => (
                <Appointments
                  {...props}
                  modules={this.props.modules}
                  page={PAGE_PRESCRIPTIONS}
                />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("OFFERS") ? (
            <PrivateRoute
              exact
              path="/offers"
              component={(props) => (
                <Offers {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("OFFERS") ? (
            <PrivateRoute
              exact
              path="/offers/:id"
              component={(props) => (
                <Offers {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("OFFERS") ? (
            <PrivateRoute
              exact
              path="/offers/:id/:action"
              component={(props) => (
                <Offers {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("OFFERS") ? (
            <PrivateRoute
              exact
              path="/offers/:id/:variant/:action"
              component={(props) => (
                <Offers {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("SUBVENTIONS") ? (
            <PrivateRoute
              exact
              path="/budget"
              component={(props) => (
                <Budget {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("SUBVENTIONS") ? (
            <PrivateRoute
              exact
              path="/budget/:content"
              component={(props) => (
                <Budget {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("BONUS") ? (
            <PrivateRoute
              exact
              path="/bonus"
              component={(props) => (
                <Bonus {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}

          {this.props.modules.includes("BONUS") ? (
            <PrivateRoute
              exact
              path="/bonus/shop"
              component={(props) => (
                <Bonus
                  {...props}
                  modules={this.props.modules}
                  page={PAGE_SHOP}
                />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("BONUS") ? (
            <PrivateRoute
              exact
              path="/bonus/collections"
              component={(props) => (
                <Bonus
                  {...props}
                  modules={this.props.modules}
                  page={PAGE_COLLECTIONS}
                />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("BONUS") ? (
            <PrivateRoute
              exact
              path="/bonus/conversions"
              component={(props) => (
                <Bonus
                  {...props}
                  modules={this.props.modules}
                  page={PAGE_CONVERSIONS}
                />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("BONUS") ? (
            <PrivateRoute
              exact
              path="/bonus/collect"
              component={(props) => (
                <Bonus
                  {...props}
                  modules={this.props.modules}
                  page={PAGE_COLLECT}
                />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("GUESTCARDS") ? (
            <PrivateRoute
              exact
              path="/cards"
              component={(props) => (
                <GuestCards
                  {...props}
                  modules={this.props.modules}
                  page={null}
                />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("NEWS") ? (
            <PrivateRoute
              exact
              path="/news"
              component={(props) => (
                <News {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}

          {this.props.modules.includes("ACCOUNT") ? (
            <PrivateRoute
              exact
              path="/account"
              component={(props) => (
                <Account {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}

          {this.props.modules.includes("REGISTER") ? (
            <Route
              exact
              path="/register/:key/:identifier"
              component={(props) => (
                <Register {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("REGISTER") ? (
            <Route
              exact
              path="/register/:key"
              component={(props) => (
                <Register {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}
          {this.props.modules.includes("REGISTER") ? (
            <Route
              exact
              path="/register"
              component={(props) => (
                <Register {...props} modules={this.props.modules} />
              )}
            />
          ) : (
            ""
          )}

          <PrivateRoute
            exact
            path="/priority-news"
            component={(props) => (
              <PriorityNews {...props} modules={this.props.modules} />
            )}
          />
          <PrivateRoute
            exact
            path="/contact"
            component={(props) => (
              <Contact {...props} modules={this.props.modules} />
            )}
          />
          <PrivateRoute
            exact
            path="/logout"
            component={(props) => (
              <Logout {...props} modules={this.props.modules} />
            )}
          />
          <PrivateRoute
            exact
            path="/privacy-policy"
            component={(props) => (
              <PrivacyPolicy
                {...props}
                redirect={false}
                modules={this.props.modules}
              />
            )}
          />
          <PrivateRoute
            exact
            path="/privacy-policy-accept"
            component={(props) => (
              <PrivacyPolicy {...props} redirect modules={this.props.modules} />
            )}
          />

          <Route
            exact
            path="/public-privacy-policy"
            component={(props) => (
              <PublicPrivacyPolicy {...props} modules={this.props.modules} />
            )}
          />
          <Route
            exact
            path="/faq"
            component={(props) => (
              <Faq {...props} modules={this.props.modules} />
            )}
          />
          <Route
            exact
            path="/imprint"
            component={(props) => (
              <Imprint {...props} modules={this.props.modules} />
            )}
          />
          <Route
            exact
            path="/about"
            component={(props) => (
              <About {...props} modules={this.props.modules} />
            )}
          />

          <Route
            path="/login"
            component={(props) => (
              <Login {...props} modules={this.props.modules} />
            )}
          />
          <Route
            path="/password/reset"
            component={(props) => (
              <Password {...props} modules={this.props.modules} />
            )}
          />
          <Route
            path="/password/set"
            component={(props) => (
              <Password {...props} modules={this.props.modules} />
            )}
          />
          <Route
            path="/language"
            component={(props) => (
              <Language {...props} modules={this.props.modules} />
            )}
          />
          <Route
            path="/password/renew"
            component={(props) => (
              <Password {...props} modules={this.props.modules} />
            )}
          />
          <Route
            path="/password/expire"
            component={(props) => (
              <Password {...props} modules={this.props.modules} />
            )}
          />
        </>
      </BrowserRouter>
    );
  }
}
