import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Helmet from "react-helmet";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";
import {
  SecondLevelListGroupItem,
  Title,
  CustomListGroup,
  CustomFaAngleRight,
  CustomLeftCol,
  CustomRightCol,
  CustomRow,
} from "../../globalComponents/List";
import AppointmentList from "../../components/Appointments/AppointmentList";
import PrescriptionList from "../../components/Prescriptions/PrescriptionList";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 0px;
  width: calc(100%);
`;

const CustomListGroupItem = styled(SecondLevelListGroupItem)`
  font-weight: bold;

  &.open {
    background-color: #ffffff !important;
    color: #000000;
  }

  &.open svg {
    transform: rotate(0);
  }
`;

export const PAGE_FUTURE = "future";
export const PAGE_PAST = "past";
export const PAGE_PRESCRIPTIONS = "prescriptions";

class Appointments extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const isMobile = window.innerWidth < 992;

    this.state = {
      page:
        props.page !== undefined ? props.page : isMobile ? null : PAGE_FUTURE,
      isMobile,
    };

    window.addEventListener("resize", this.updateIsMobile.bind(this));
  }

  showFuture() {
    this.props.history.push("/appointments/future");
  }

  showPast() {
    this.props.history.push("/appointments/past");
  }

  showPrescriptions() {
    this.props.history.push("/appointments/prescriptions");
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateIsMobile() {
    if (!this._isMounted) {
      return false;
    }
    const isMobile = window.innerWidth < 992;
    this.setState({
      isMobile,
      page: this.props.page === null && !isMobile ? PAGE_FUTURE : null,
    });
  }

  getContent() {
    switch (this.state.page) {
      case PAGE_FUTURE:
        return <AppointmentList />;
      case PAGE_PAST:
        return <AppointmentList past />;
      case PAGE_PRESCRIPTIONS:
        return <PrescriptionList />;
      default:
        return "";
    }
  }

  componentDidUpdate() {
    if (this.state.page !== this.props.page && this.props.page !== undefined) {
      this.setState({ page: this.props.page });
    } else if (this.state.isMobile !== true && this.state.page === null) {
      this.setState({ page: PAGE_FUTURE });
    }
  }

  getLayoutTitle(defaultTitle) {
    if (!this.state.isMobile) {
      return defaultTitle;
    }

    switch (this.state.page) {
      case PAGE_FUTURE:
        return this.props.intl.formatMessage({
          id: "app.appointments.futureAppointments",
        });
      case PAGE_PAST:
        return this.props.intl.formatMessage({
          id: "app.appointments.pastAppointments",
        });
      case PAGE_PRESCRIPTIONS:
        return this.props.intl.formatMessage({
          id: "app.appointments.prescriptions",
        });
      default:
        return defaultTitle;
    }
  }

  render() {
    return (
      <FormattedMessage id="app.appointments.title">
        {(appointmentsTitle) => (
          <Layout
            {...this.props}
            title={this.getLayoutTitle(appointmentsTitle)}
          >
            <PaddingLessContainer className="appointments">
              <Helmet title={appointmentsTitle[0]} />
              <CustomRow>
                <CustomLeftCol xs={12} md={12} lg={4}>
                  <CustomListGroup className="list-group">
                    <CustomListGroupItem
                      onClick={() => this.showFuture()}
                      className={this.state.page === PAGE_FUTURE ? "open" : ""}
                    >
                      <Title>
                        <FormattedMessage id="app.appointments.futureAppointments" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomListGroupItem>

                    <CustomListGroupItem
                      onClick={() => this.showPast()}
                      className={this.state.page === PAGE_PAST ? "open" : ""}
                    >
                      <Title>
                        <FormattedMessage id="app.appointments.pastAppointments" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomListGroupItem>

                    {this.props.modules.includes("PRESCRIPTIONS") ? (
                      <CustomListGroupItem
                        onClick={() => this.showPrescriptions()}
                        className={
                          this.state.page === PAGE_PRESCRIPTIONS ? "open" : ""
                        }
                      >
                        <Title>
                          <FormattedMessage id="app.appointments.prescriptions" />
                        </Title>
                        <CustomFaAngleRight className="arrow" />
                      </CustomListGroupItem>
                    ) : (
                      ""
                    )}
                  </CustomListGroup>
                </CustomLeftCol>
                <CustomRightCol
                  xs={12}
                  md={12}
                  lg={8}
                  className={this.state.page !== null ? "open" : ""}
                >
                  <ScrollableDiv className="text-left">
                    {this.getContent()}
                  </ScrollableDiv>
                </CustomRightCol>
              </CustomRow>
            </PaddingLessContainer>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default injectIntl(Appointments);
