import React, { Component } from "react";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { Col, Row, Badge } from "react-bootstrap";
import Button from "../CustomBootstrap/Button";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";
import { getBearer } from "../../utils/localStorage";
import { dateFormatter } from "../../utils/formatDate";
import { withLanguage } from "../../context/LanguageContext/withLanguage";

const ImageWrapper = styled("div")`
  text-align: center;
  margin-bottom: 5px;
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
  height: 100px;
  overflow: hidden;
`;

const EventBox = styled(CustomPanel)`
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  border: 1px solid #fff;
  .booked {
    border: 1px solid #f00;
  }
  :hover {
    border: 1px solid #d4d700;
  }
  cursor: pointer;
  Button {
  }
  H4 {
    font-size: 14px;
    height: 50px;
  }
  position: relative;
  @media only screen and (min-width: 768px) {
    Button {
      position: absolute;
      bottom: 10px;
      width: 90%;
    }
    Description {
      p {
        font-size: 0.9em;
      }
    }
  }
`;

const DateWrapper = styled("p")`
  font-size: 0.75em;
`;

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // eslint-disable-line no-param-reassign
  }
}

class CompanyEventWidget extends Component {
  render() {
    const numEvents = 3;
    let selEvents = [];
    const avEvents = [];
    const navEvents = [];
    const eventUrl = `https://${window.location.hostname.replace(
      "services",
      "events"
    )}`;

    const bearer = `#B${getBearer()}`;

    this.props.data.offers.map((eventOffer) => {
      let av = false;
      eventOffer.eventDays.forEach((day) => {
        day.events.forEach((event) => {
          if (!av && event.guestState === "AVAILABLE") {
            avEvents.push(eventOffer);
            av = true;
          }
        });
      });
      if (!av) {
        navEvents.push(eventOffer);
      }
      return eventOffer;
    });

    if (avEvents.length < numEvents) {
      shuffleArray(navEvents);
      selEvents = [
        ...avEvents,
        ...navEvents.slice(0, numEvents - avEvents.length),
      ];
      shuffleArray(selEvents);
    } else {
      shuffleArray(avEvents);
      selEvents = avEvents.slice(0, numEvents);
    }

    return (
      <Wrapper className="widget widget-event">
        <H4>{this.props.data.title}</H4>
        <CustomPanel>
          <Row>
            {selEvents.map((eventOffer) => {
              const dates = [];
              let className = "danger";
              let label = "ausgebucht";
              eventOffer.eventDays.forEach((day) => {
                day.events.forEach((event) => {
                  if (event.guestState === "BOOKED") {
                    className = "info";
                    label = "gebucht";
                  }
                  if (
                    className !== "info" &&
                    event.guestState === "AVAILABLE"
                  ) {
                    className = "success";
                    label = "verfügbar";
                  }
                });
                const date = dateFormatter(
                  new Date(day.date),
                  this.props.languageContext.language
                );
                dates.push(date);
              });
              return (
                <Col xs={12} sm={4} key={eventOffer.offer.id}>
                  <EventBox
                    className={`event-box ${className}`}
                    onClick={() => {
                      window.open(
                        `${eventUrl}/offers/${eventOffer.offer.id}${bearer}`
                      );
                    }}
                  >
                    <ImageWrapper>
                      {eventOffer.offer.images.length > 0 ? (
                        <img
                          src={JSON.parse(eventOffer.offer.images[0].url)}
                          alt={eventOffer.offer.title}
                          height="75px"
                          width="100%"
                        />
                      ) : (
                        ""
                      )}
                    </ImageWrapper>
                    <H4>{eventOffer.offer.title}</H4>
                    {this.props.data.dateBegin !== this.props.data.dateEnd ? (
                      <DateWrapper>am: {dates.join(", ")}</DateWrapper>
                    ) : (
                      ""
                    )}
                    <Row>
                      <Col
                        style={{
                          textAlign: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Badge bg={className} style={{}}>
                          {label}
                        </Badge>{" "}
                      </Col>
                    </Row>
                  </EventBox>
                </Col>
              );
            })}
          </Row>
        </CustomPanel>
        <div className="d-grid">
          <Button
            onClick={() => {
              window.open(eventUrl + bearer);
            }}
            variant="primary"
            size="lg"
          >
            <FormattedMessage id="app.home.widget.companyEvent.button" />
          </Button>
        </div>
      </Wrapper>
    );
  }
}

export default withLanguage(injectIntl(CompanyEventWidget));
