import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { injectIntl } from "react-intl";
import { FormCheck, FormGroup } from "react-bootstrap";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import Conf from "../../Conf";
import { UPDATE_EMAIL_CONFIG_MUTATION } from "./SaveEmailSettings.graphql";

class SaveEmailSettings extends Component {
  constructor(props) {
    super(props);

    this.state = { checked: this.props.emailConfig.allowed };

    this.onChange = this.onChange.bind(this);
    this.parseError = parseError.bind(this);
  }

  onChange(event) {
    this.setState({ checked: event.target.checked });

    this.props
      .mutate({
        variables: {
          emailId: this.props.emailConfig.email.id,
          allowed: event.target.checked,
        },
      })
      .then(() => {
        this.props.onFinish();
      })
      .catch((error) => {
        const errorTextTranslationKey = this.parseError(
          error,
          Conf.errorContext.accountSaveEmailSettings
        );

        this.props.onError(error, errorTextTranslationKey);
      });
  }

  render() {
    return (
      <FormGroup>
        <FormCheck
          onChange={this.onChange}
          checked={this.state.checked}
          name={this.props.emailConfig.email.id}
          label={this.props.emailConfig.email.title}
        />
      </FormGroup>
    );
  }
}

export default graphql(UPDATE_EMAIL_CONFIG_MUTATION)(
  injectIntl(SaveEmailSettings)
);
