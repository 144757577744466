import React, { Component } from "react";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Alert,
  FormControl,
  FormGroup,
  FormLabel,
  Card,
} from "react-bootstrap";
import styled from "styled-components";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../CustomBootstrap/Button";
import { COMPANY_REGISTER_KEY_QUERY } from "./RequestKey.graphql";

const RegisterKeyPanel = styled((props) => <Card {...props} />)`
  border-radius: 8px !important;
  @media only screen and (min-width: 768px) {
    width: 50%;
    margin: auto;
    margin-top: 10%;
  }
`;

export function validateRegisterKey(registerKey) {
  return /^[ÄÖÜ!A-Z0-9a-z-]{8,}$/.test(registerKey);
}

class RequestRegisterKey extends Component {
  constructor(props) {
    super(props);

    let registerKey = null;
    let userRegisterKey = "";
    let errorRegisterKey = null;
    if (props.registerKey !== null) {
      if (/^[ÄÖÜ!A-Z0-9a-z-]{8,}$/.test(props.registerKey)) {
        registerKey = props.registerKey;
        userRegisterKey = props.registerKey;
      } else {
        errorRegisterKey = props.registerKey;
        userRegisterKey = props.registerKey;
      }
    }
    const loading = false;

    this.setRegisterKey = this.setRegisterKey.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getValidationState = this.getValidationState.bind(this);

    this.state = {
      registerKey,
      errorRegisterKey,
      userRegisterKey,
      loading,
      error: null,
    };
  }

  getValidationState() {
    if (this.state.userRegisterKey.length === 0) {
      return null;
    }
    if (
      this.state.userRegisterKey !== this.state.errorRegisterKey &&
      validateRegisterKey(this.state.userRegisterKey)
    ) {
      return "success";
    }
    return "error";
  }

  setRegisterKey() {
    if (this.getValidationState() === "success") {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const registerKey = this.state.userRegisterKey;
      this.setState({
        registerKey,
        errorRegisterKey: null,
      });
      this.checkRegisterKey(registerKey);
    }
  }

  checkRegisterKey(registerKey) {
    this.setState({ loading: true });

    this.props.client
      .query({
        query: COMPANY_REGISTER_KEY_QUERY,
        variables: { registerKey: registerKey.toLowerCase() },
      })
      .then(({ data, errors }) => {
        if (errors !== undefined && errors !== null) {
          this.setState({
            loading: false,
            errorRegisterKey: "",
            registerKey: null,
          });
        } else {
          this.props.onUpdate(data.companyRegisterKey, null);
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          errorRegisterKey: "",
          registerKey: null,
        });
      });
  }

  static formatRegisterKey(registerKey) {
    const formatRegisterKey = registerKey
      .toUpperCase()
      .replace(/[^ÄÖÜ!A-Z0-9-]/g, "");
    return formatRegisterKey;
  }

  handleChange(e) {
    this.setState({
      userRegisterKey: RequestRegisterKey.formatRegisterKey(e.target.value),
    });
  }

  componentDidUpdate(prev) {
    if (prev.data !== undefined) {
      if (prev.data.loading === true && this.props.data.loading === false) {
        if (this.props.data.error) {
          this.setState((prevState) => ({
            errorRegisterKey: prevState.registerKey,
            registerKey: null,
          }));
        } else {
          this.props.onUpdate(this.props.data.companyRegisterKey, null);
        }
      }
    }
  }

  render() {
    let errorMessage = null;

    if (
      this.state.loading === true ||
      (this.props.data !== undefined && this.props.data.loading === true)
    ) {
      return <LoadingCol />;
    }

    if (this.props.data !== undefined && this.props.data.error) {
      errorMessage = `app.register.registerKey.error.${this.props.data.error.message.match(
        /[0-9]{3}(x[0-9]+)?/g
      )}`;
    } else if (this.state.errorRegisterKey !== null) {
      errorMessage = "app.register.registerKey.error";
    } else if (this.state.error !== null) {
      errorMessage = "app.register.registerKey.error.auto";
    }

    return (
      <div>
        {errorMessage !== null ? (
          <Alert variant="danger" onDismiss={this.handleDismiss}>
            <h4>
              <FormattedMessage id="app.global.error" />
            </h4>
            {React.createElement("p", {
              dangerouslySetInnerHTML: {
                __html: this.props.intl.formatMessage({ id: errorMessage }),
              },
            })}
          </Alert>
        ) : (
          ""
        )}

        {this.state.registerKey === null && this.state.error === null ? (
          <RegisterKeyPanel>
            <Card.Body>
              <form
                onSubmit={(event) => {
                  this.setRegisterKey();
                  event.preventDefault();
                  return false;
                }}
              >
                <FormGroup controlId="formBasicText">
                  <FormLabel>
                    <FormattedMessage id="app.register.registerKey.label" />
                  </FormLabel>
                  <FormControl
                    type="text"
                    value={this.state.userRegisterKey}
                    placeholder={this.props.intl.formatMessage({
                      id: "app.register.registerKey.placeholder",
                    })}
                    onChange={this.handleChange}
                    isValid={this.getValidationState() === "success"}
                    isInvalid={this.getValidationState() === "error"}
                  />
                  <FormControl.Feedback />
                  <p className="form-text">
                    <FormattedMessage id="app.register.registerKey.hint" />
                  </p>
                </FormGroup>
                <div className="d-grid">
                  <Button
                    disabled={this.getValidationState() !== "success"}
                    onClick={this.setRegisterKey}
                    variant="primary"
                    size="lg"
                  >
                    <FormattedMessage id="app.register.registerKey.submit" />
                  </Button>
                </div>
              </form>
            </Card.Body>
          </RegisterKeyPanel>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(
  withApollo,
  graphql(COMPANY_REGISTER_KEY_QUERY, {
    options: (props) => ({
      variables: {
        registerKey: props.registerKey === null ? "" : props.registerKey,
      },
      fetchPolicy: "network",
    }),
    skip: (props) =>
      props.registerKey === null || !validateRegisterKey(props.registerKey),
  })
)(injectIntl(RequestRegisterKey));
