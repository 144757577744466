import React, { PureComponent } from "react";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import FittedImage from "react-fitted-image";
import Button from "../CustomBootstrap/Button";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";
import { Loading } from "../../globalComponents/Loading";

const ImageWrapper = styled("div")`
  text-align: center;
  margin-bottom: 5px;
  height: 125px;
  overflow: hidden;
`;

const EventBox = styled(CustomPanel)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding: 10px;
  margin: 0;
  border: 1px solid #fff;
  float: left;
  .booked {
    border: 1px solid #f00;
  }
  :hover {
    border: 1px solid #d4d700;
  }
  cursor: pointer;
  Button {
  }
  H4 {
    font-size: 14px;
    minheight: 50px;
    padding: 0px 0 18px 0;
    flex-grow: 1;
    color: #333;
  }
  position: relative;
  @media only screen and (min-width: 768px) {
    Button {
      position: absolute;
      bottom: 10px;
      width: 90%;
    }
    Description {
      p {
        font-size: 0.9em;
      }
    }
  }
`;

class HighlightEventsWidget extends PureComponent {
  render() {
    const numEvents = 3;
    // only show offers with events
    const selEvents = this.props.data.offers
      .filter((offer) => offer?.events?.length > 0)
      .slice(0, numEvents);

    return (
      <Wrapper className="widget widget-highlight">
        <H4>
          <FormattedMessage id="app.home.widget.highlightEvents.title" />
        </H4>
        <CustomPanel>
          <Row>
            {selEvents.map((eventOffer) => (
              <Col
                xs={12}
                sm={
                  selEvents.length === 1 ? 12 : selEvents.length === 2 ? 6 : 4
                }
                key={eventOffer.therapyOffer.id}
              >
                <EventBox className="event-box">
                  <ImageWrapper
                    onClick={() => {
                      this.props.history.push(`/offers/${eventOffer.id}`);
                    }}
                  >
                    {eventOffer.therapyOffer.images.length > 0 ? (
                      <FittedImage
                        style={{
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                        }}
                        loader={<Loading />}
                        fit="cover"
                        src={JSON.parse(eventOffer.therapyOffer.images[0].url)}
                        alt={eventOffer.therapyOffer.title}
                      />
                    ) : (
                      ""
                    )}
                  </ImageWrapper>
                  <H4
                    onClick={() => {
                      this.props.history.push(`/offers/${eventOffer.id}`);
                    }}
                  >
                    {eventOffer.therapyOffer.title}
                  </H4>
                  <div className="d-grid">
                    <Button
                      href={`/offers/${eventOffer.id}/book-date`}
                      variant="primary"
                      size="lg"
                    >
                      <FormattedMessage id="app.home.widget.highlightEvents.button.book" />
                    </Button>
                  </div>
                </EventBox>
              </Col>
            ))}
          </Row>
        </CustomPanel>
        {this.props.data.offers.length > 3 ? (
          <div className="d-grid">
            <Button
              onClick={() => {
                this.props.history.push("/offers");
              }}
              variant="primary"
              size="lg"
            >
              <FormattedMessage id="app.home.widget.highlightEvents.button" />
            </Button>
          </div>
        ) : (
          ""
        )}
      </Wrapper>
    );
  }
}

export default injectIntl(HighlightEventsWidget);
