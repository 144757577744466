import React, { Component } from "react";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import * as queryString from "query-string";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import { Header, CustomFaAngleRight, Title } from "../../globalComponents/List";
import Button from "../CustomBootstrap/Button";
import Conf from "../../Conf";
import Icon from "../../globalComponents/Icon";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import OfferBanner from "./OfferBanner";
import { priceWithTaxAdded } from "../../utils/withTax";
import ExternalOfferVariants from "./ExternalOfferVariants";
import { EXTERNAL_VARIANTS } from "../../config/offer/external-variants";
import { withLanguage } from "../../context/LanguageContext/withLanguage";
import { formatDate } from "../../utils/dateHelper";
import MediaSlider, { IMAGE, VIDEO } from "../../containers/MediaSlider";
import { OFFER_QUERY } from "./Offer.graphql";
import "./Offer.scss";

const backendURL = require("../../config/backend/server");

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

const PaddingDiv = styled("div")`
  padding: 15px 30px;
`;

const CollapseHelper = styled("div")``;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 1px solid #d4d4d4;

  @media screen and (min-width: 768px) {
    padding: 0 75px;
  }
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
  video {
    display: block;
    width: 100%;
  }
  &.borderless {
    padding: 0;
  }
`;

const ButtonContent = styled("div")`
  padding: 15px 0;
`;

const MiniIcon = styled(Icon)`
  svg {
    height: 24px;
    width: 24px;
    margin-top: 4px;
    margin-left: 4px;
  }
`;

export const getVideoItems = (therapyOffer) =>
  therapyOffer.videos.map((video, index) => ({
    url: JSON.parse(video.url),
    name: video.name,
    type: VIDEO,
    posterUrl: `${backendURL}/static/therapyoffers/${
      therapyOffer.id
    }/images/poster-${index + 1}.jpg`,
  }));

export const getImageItems = (therapyOffer) => {
  const isPoster = /(poster-\d\.jpg)/;
  return therapyOffer.images
    .filter((image) => !isPoster.test(image.url))
    .map((image) => ({
      url: JSON.parse(image.url),
      name: image.name,
      type: IMAGE,
    }));
};

class Offer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      desc: true,
      images: true,
      videos: true,
      downloads: true,
      price: true,
      refund: false,
      extern: false,
      courses: false,
      events: false,
      isInLimit: false,
      currentOffer: 0,
      variantsAvailable: false,
      BgmConceptTherapyOfferExternVariantEventOnline: true,
      BgmConceptTherapyOfferExternVariantEventLocation: true,
      BgmConceptTherapyOfferExternVariantCustom: true,
    };
  }

  static getMediaSliderItems = (therapyOffer) => [
    ...getVideoItems(therapyOffer),
    ...getImageItems(therapyOffer),
  ];

  showOfferUpload(offer) {
    if (this.state.isInLimit) {
      this.props.showOfferUpload(offer);
    } else {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: "app.offers.noinLimit" })
      );
    }
  }

  showOfferExtern(offer, variant = "") {
    if (this.state.isInLimit) {
      this.props.showOfferExtern(offer, variant);
    } else {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: "app.offers.noinLimit" })
      );
    }
  }

  showOfferBookCourse(offer) {
    if (this.state.isInLimit) {
      this.props.showOfferBookCourse(offer);
    } else {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: "app.offers.noinLimit" })
      );
    }
  }

  showOfferBookDate(offer) {
    if (this.state.isInLimit) {
      this.props.showOfferBookDate(offer);
    } else {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: "app.offers.noinLimit" })
      );
    }
  }

  componentDidUpdate() {
    if (!this.props.data.loading) {
      if (this.state.currentOffer !== this.props.data.bgmConcept.offer.id) {
        const { isInLimit } = this.props.data.bgmConcept.offer.therapyOffer;
        this.setState({
          currentOffer: this.props.data.bgmConcept.offer.id,
          isInLimit,
        });

        const error =
          queryString.parse(this.props.history.location.search).error !==
          undefined
            ? queryString.parse(this.props.history.location.search).error
            : null;
        if (error !== null) {
          this._alertModal.open(
            this.props.intl.formatMessage({ id: "app.global.error" }),
            this.props.intl.formatMessage({
              id: parseError(
                { message: error },
                Conf.errorContext.offerGlobalError
              ),
            })
          );
        }
      }

      if (
        this.props.data.bgmConcept.offer.extern !== null &&
        this.props.data.bgmConcept.offer.extern.variants.length &&
        !this.state.variantsAvailable
      ) {
        this.setState({
          variantsAvailable:
            !!this.props.data.bgmConcept.offer.extern.variants.length,
        });
      }
    }
  }

  formatPrice(number) {
    return this.props.intl.formatNumber(number, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  showOfferNotAvailableNotice() {
    this._alertModal.open(
      this.props.intl.formatMessage({
        id: "app.offers.notAvailableNotice.title",
      }),
      this.props.intl.formatMessage({
        id: "app.offers.notAvailableNotice.text",
      })
    );
  }

  showOfferExternButton() {
    const { offer } = this.props.data.bgmConcept;
    return (
      <ButtonContent>
        <div className="d-grid">
          <Button
            variant="primary"
            size="lg"
            onClick={() => this.showOfferExtern(offer)}
          >
            <FormattedMessage id="app.offers.useExternal" />
          </Button>
        </div>
      </ButtonContent>
    );
  }

  showOfferExternWithVariant(offer, variantId) {
    this.showOfferExtern(offer, variantId);
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    const { offer } = this.props.data.bgmConcept;
    const config = this.props.data.config.modules.filter((w) => w.employee)[0]
      .employee;
    const budgetTotal =
      this.props.data.guest.guestSubventionLimit !== null
        ? this.props.data.guest.guestSubventionLimit.value
        : null;
    const budgetLeft =
      this.props.data.guest.guestSubventionLimit !== null
        ? this.props.data.guest.guestSubventionLimit.value -
          this.props.data.guest.guestSubventionLimit.currentValue
        : null;
    const budgetGross =
      this.props.data.guest.guestSubventionLimit !== null
        ? this.props.data.guest.guestSubventionLimit.gross
        : null;

    const showNotAvailableNotice = offer.showCommingSoon;

    const { intl } = this.props;

    return (
      <ScrollableDiv>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <MediaSlider
          items={Offer.getMediaSliderItems(
            this.props.data.bgmConcept.offer.therapyOffer
          )}
        />
        <PaddingDiv>
          <Header
            className={this.state.desc ? "open" : ""}
            onClick={() =>
              this.setState((prevState) => ({ desc: !prevState.desc }))
            }
          >
            <Icon className="icon-themed" src={Conf.offerDetailIcons.desc} />
            <Title className="offer-title">
              <FormattedMessage id="app.offers.description" />
            </Title>
            <div className="offer__attributes-wrapper">
              {offer.bgmConceptTherapyOfferTags.map((tag) => (
                <OfferBanner {...tag} key={tag.therapyOfferTag.id} isTag />
              ))}
              {offer.bgmConceptTherapyOfferAttribute.map((attribute) => (
                <MiniIcon
                  key={attribute.therapyOfferAttributeValue.id}
                  src={JSON.parse(attribute.therapyOfferAttributeValue.icon)}
                  title={attribute.therapyOfferAttributeValue.title}
                />
              ))}
            </div>
            <CustomFaAngleRight className="arrow" />
          </Header>
          <Wrapper>
            <Collapse in={this.state.desc}>
              <CollapseHelper>
                <Content className="custom-content">
                  {React.createElement("p", {
                    dangerouslySetInnerHTML: {
                      __html: offer.therapyOffer.desc,
                    },
                  })}
                  {React.createElement("p", {
                    dangerouslySetInnerHTML: {
                      __html: offer.therapyOffer.descAdd,
                    },
                  })}
                </Content>
              </CollapseHelper>
            </Collapse>
          </Wrapper>
          {offer.therapyOffer.downloads &&
          offer.therapyOffer.downloads.length >= 1 ? (
            <div>
              <Header
                className={this.state.downloads ? "open" : ""}
                onClick={() => {
                  this.setState((prevState) => ({
                    downloads: !prevState.downloads,
                  }));
                }}
              >
                <Icon
                  className="icon-themed"
                  src={Conf.offerDetailIcons.downloads}
                />
                <Title>
                  <FormattedMessage id="app.offers.downloads" />
                </Title>
                <CustomFaAngleRight className="arrow" />
              </Header>
              <Wrapper>
                <Collapse in={this.state.downloads}>
                  <CollapseHelper>
                    {offer.therapyOffer.downloads.map((download) => (
                      <Content key={download.name}>
                        <a
                          href={JSON.parse(download.url)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {download.name}
                        </a>
                      </Content>
                    ))}
                  </CollapseHelper>
                </Collapse>
              </Wrapper>
            </div>
          ) : (
            ""
          )}
          {!offer.therapyOffer.hidePrices ? (
            <div>
              <Header
                className={this.state.price ? "open" : ""}
                onClick={() => {
                  this.setState((prevState) => ({ price: !prevState.price }));
                }}
              >
                <Icon
                  className="icon-themed"
                  src={Conf.offerDetailIcons.price}
                />
                <Title>
                  <FormattedMessage id="app.offers.price" />
                </Title>
                <CustomFaAngleRight className="arrow" />
              </Header>
              <Wrapper>
                <Collapse in={this.state.price}>
                  <CollapseHelper>
                    <Content>
                      {offer.events.length >= 1 ||
                      offer.extern !== null ||
                      (offer.courses.length === 0 && !offer.refund) ? (
                        <div>
                          {offer.therapyOffer.priceMatrix.map((priceMatrix) => {
                            const subventionValue =
                              priceWithTaxAdded(
                                priceMatrix.price.net,
                                priceMatrix.price.tax
                              ) - priceMatrix.price.total;

                            return (
                              <p
                                key={`${priceMatrix.validFrom}-${priceMatrix.validTo}`}
                              >
                                {config.showPrices ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        {this.props.intl.formatMessage({
                                          id: "app.offers.price.total.tooltip",
                                        })}
                                      </Tooltip>
                                    }
                                  >
                                    <span style={{ cursor: "help" }}>
                                      <strong>
                                        <FormattedMessage id="app.offers.price.total" />
                                        :&nbsp;
                                      </strong>
                                      {this.formatPrice(
                                        priceMatrix.price.net *
                                          (1 + priceMatrix.price.tax / 100)
                                      )}
                                      <FormattedMessage id="app.global.priceUnit" />
                                      <br />
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  ""
                                )}
                                {config.showSubventionValue ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        {this.props.intl.formatMessage({
                                          id: "app.offers.price.subvention.tooltip",
                                        })}
                                      </Tooltip>
                                    }
                                  >
                                    <span style={{ cursor: "help" }}>
                                      <strong>
                                        <FormattedMessage id="app.offers.price.subvention" />
                                        :&nbsp;
                                      </strong>
                                      {this.formatPrice(subventionValue)}
                                      <FormattedMessage id="app.global.priceUnit" />
                                      <br />
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  ""
                                )}
                                {budgetTotal !== null ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        {this.props.intl.formatMessage({
                                          id: "app.offers.price.budget.tooltip",
                                        })}
                                      </Tooltip>
                                    }
                                  >
                                    <span style={{ cursor: "help" }}>
                                      <strong>
                                        <FormattedMessage id="app.offers.price.budget" />
                                        :&nbsp;
                                      </strong>
                                      {this.formatPrice(
                                        budgetGross
                                          ? priceMatrix.price
                                              .companySubventionAmount *
                                              (1 + priceMatrix.price.tax / 100)
                                          : priceMatrix.price
                                              .companySubventionAmount
                                      )}
                                      <FormattedMessage id="app.global.priceUnit" />
                                      <br />
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  ""
                                )}
                                {priceMatrix.price.total > 0 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        {this.props.intl.formatMessage({
                                          id: "app.offers.price.price.tooltip",
                                        })}
                                      </Tooltip>
                                    }
                                  >
                                    <span style={{ cursor: "help" }}>
                                      <strong>
                                        <FormattedMessage id="app.offers.price.price" />
                                        :&nbsp;
                                      </strong>
                                      {this.formatPrice(
                                        priceMatrix.price.total
                                      )}
                                      <FormattedMessage id="app.global.priceUnit" />
                                      <br />
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  ""
                                )}
                                {priceMatrix.price.count !== null &&
                                priceMatrix.price.count > -1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        {this.props.intl.formatMessage({
                                          id: "app.offers.price.count.tooltip",
                                        })}
                                      </Tooltip>
                                    }
                                  >
                                    <span style={{ cursor: "help" }}>
                                      <strong>
                                        <FormattedMessage id="app.offers.price.count" />
                                        :&nbsp;
                                      </strong>
                                      {priceMatrix.price.count === 1 ? (
                                        <FormattedMessage id="app.offers.price.count.once" />
                                      ) : (
                                        <span>
                                          {priceMatrix.price.count}
                                          <FormattedMessage id="app.offers.price.count.fac" />
                                        </span>
                                      )}
                                      <br />
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  ""
                                )}
                                {priceMatrix.validFrom !== null ||
                                priceMatrix.validTo !== null ? (
                                  <small>
                                    <FormattedMessage id="app.offers.price.valid" />{" "}
                                    {priceMatrix.validFrom !== null ? (
                                      <span>
                                        &nbsp;
                                        <FormattedMessage id="app.offers.price.valid.from" />{" "}
                                        {formatDate(
                                          priceMatrix.validFrom,
                                          this.props.languageContext.language
                                        )}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {priceMatrix.validTo !== null ? (
                                      <span>
                                        &nbsp;
                                        <FormattedMessage id="app.offers.price.valid.to" />{" "}
                                        {formatDate(
                                          priceMatrix.validTo,
                                          this.props.languageContext.language
                                        )}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </p>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                      {offer.courses.length >= 1 ? (
                        <div>
                          {offer.therapyOffer.priceMatrix.map((priceMatrix) => (
                            <p
                              key={`${priceMatrix.validFrom}-${priceMatrix.validTo}`}
                            >
                              {config.showPrices ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      {this.props.intl.formatMessage({
                                        id: "app.offers.price.course.tooltip",
                                      })}
                                    </Tooltip>
                                  }
                                >
                                  <span style={{ cursor: "help" }}>
                                    <strong>
                                      <FormattedMessage id="app.offers.price.course.price" />
                                      :&nbsp;
                                    </strong>
                                    {this.formatPrice(
                                      offer.courses[0].events.length *
                                        priceMatrix.price.total
                                    )}
                                    <FormattedMessage id="app.global.priceUnit" />
                                    <br />
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}
                              {config.showSubventionValue ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      {this.props.intl.formatMessage({
                                        id: "app.offers.price.course.subvention.tooltip",
                                      })}
                                    </Tooltip>
                                  }
                                >
                                  <span style={{ cursor: "help" }}>
                                    <strong>
                                      <FormattedMessage id="app.offers.price.course.subvention" />
                                      :&nbsp;
                                    </strong>
                                    {this.formatPrice(
                                      offer.courses[0].events.length *
                                        (priceWithTaxAdded(
                                          priceMatrix.price.net,
                                          priceMatrix.price.tax
                                        ) -
                                          priceMatrix.price.total)
                                    )}
                                    <FormattedMessage id="app.global.priceUnit" />
                                    <br />
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}
                              {budgetTotal !== null ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      {this.props.intl.formatMessage({
                                        id: "app.offers.price.course.budget.tooltip",
                                      })}
                                    </Tooltip>
                                  }
                                >
                                  <span style={{ cursor: "help" }}>
                                    <strong>
                                      <FormattedMessage id="app.offers.price.course.budget" />
                                      :&nbsp;
                                    </strong>
                                    {this.formatPrice(
                                      budgetGross
                                        ? offer.courses[0].events.length *
                                            priceMatrix.price
                                              .companySubventionAmount *
                                            (1 + priceMatrix.price.tax / 100)
                                        : offer.courses[0].events.length *
                                            priceMatrix.price
                                              .companySubventionAmount
                                    )}
                                    <FormattedMessage id="app.global.priceUnit" />
                                    <br />
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}
                              {priceMatrix.validFrom !== null ||
                              priceMatrix.validTo !== null ? (
                                <small>
                                  <FormattedMessage id="app.offers.price.valid" />{" "}
                                  {priceMatrix.validFrom !== null ? (
                                    <span>
                                      &nbsp;
                                      <FormattedMessage id="app.offers.price.valid.from" />{" "}
                                      {formatDate(
                                        priceMatrix.validFrom,
                                        this.props.languageContext.language
                                      )}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {priceMatrix.validTo !== null ? (
                                    <span>
                                      &nbsp;
                                      <FormattedMessage id="app.offers.price.valid.to" />{" "}
                                      {formatDate(
                                        priceMatrix.validTo,
                                        this.props.languageContext.language
                                      )}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </small>
                              ) : (
                                ""
                              )}
                            </p>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {offer.refund && offer.events.length === 0 ? (
                        <p>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                {this.props.intl.formatMessage({
                                  id: "app.offers.price.refund.price.tooltip",
                                })}
                              </Tooltip>
                            }
                          >
                            <span style={{ cursor: "help" }}>
                              <strong>
                                <FormattedMessage id="app.offers.price.refund.price" />
                                :&nbsp;
                              </strong>
                              {offer.refund.valueText}
                              <br />
                            </span>
                          </OverlayTrigger>
                        </p>
                      ) : (
                        ""
                      )}
                      {budgetTotal !== null ? (
                        <p>
                          <strong>
                            <FormattedMessage id="app.offers.price.availableBudget" />
                            :&nbsp;
                          </strong>
                          {this.formatPrice(budgetLeft)}&nbsp;/&nbsp;
                          {this.formatPrice(budgetTotal)}
                          <FormattedMessage id="app.global.priceUnit" />
                        </p>
                      ) : (
                        ""
                      )}
                    </Content>
                  </CollapseHelper>
                </Collapse>
              </Wrapper>
            </div>
          ) : (
            ""
          )}
          {offer.extern !== null ? (
            <div>
              {this.props.data.bgmConcept.offer.extern.variants.length > 0
                ? [
                    {
                      name: EXTERNAL_VARIANTS.ONLINE,
                      title: intl.formatMessage({
                        id: "app.offers.extern.variants.BgmConceptTherapyOfferExternVariantEventOnline",
                      }),
                      icon: Conf.offerDetailIcons.externVariantOnline,
                      type: "online",
                      variants:
                        this.props.data.bgmConcept.offer.extern.variants.filter(
                          (subvariant) =>
                            subvariant.__typename === EXTERNAL_VARIANTS.ONLINE
                        ),
                    },
                    {
                      name: EXTERNAL_VARIANTS.LOCATION,
                      title: intl.formatMessage({
                        id: "app.offers.extern.variants.BgmConceptTherapyOfferExternVariantEventLocation",
                      }),
                      icon: Conf.offerDetailIcons.externVariantLocation,
                      type: "location",
                      variants:
                        this.props.data.bgmConcept.offer.extern.variants.filter(
                          (subvariant) =>
                            subvariant.__typename === EXTERNAL_VARIANTS.LOCATION
                        ),
                    },
                    {
                      name: EXTERNAL_VARIANTS.CUSTOM,
                      title:
                        this.props.data.bgmConcept.offer.extern
                          .variantCustomLabel ||
                        intl.formatMessage({
                          id: "app.offers.extern.variants.BgmConceptTherapyOfferExternVariantCustom",
                        }),
                      icon: Conf.offerDetailIcons.externVariantCustom,
                      type: "custom",
                      customLabel:
                        this.props.data.bgmConcept.offer.extern
                          .variantCustomLabel,
                      variants:
                        this.props.data.bgmConcept.offer.extern.variants.filter(
                          (subvariant) =>
                            subvariant.__typename === EXTERNAL_VARIANTS.CUSTOM
                        ),
                    },
                  ].map(
                    (variant) =>
                      variant.variants.length >= 1 && (
                        <div key={variant.name}>
                          <Header
                            className={this.state[variant.name] ? "open" : ""}
                            onClick={() => {
                              this.setState((prevState) => ({
                                [variant.name]: !prevState[variant.name],
                              }));
                            }}
                          >
                            <Icon className="icon-themed" src={variant.icon} />
                            <Title>
                              {variant.customLabel || (
                                <FormattedMessage
                                  id={`app.offers.extern.variants.${variant.name}`}
                                />
                              )}
                            </Title>
                            <CustomFaAngleRight className="arrow" />
                          </Header>
                          <Wrapper>
                            <Collapse in={this.state[variant.name]}>
                              <CollapseHelper>
                                <Content>
                                  <ExternalOfferVariants
                                    variants={variant.variants}
                                    type={variant.type}
                                    showOfferExtern={this.showOfferExternWithVariant.bind(
                                      this,
                                      offer
                                    )}
                                    country={
                                      this.props.data.guest.location.country
                                    }
                                  />
                                </Content>
                              </CollapseHelper>
                            </Collapse>
                          </Wrapper>
                        </div>
                      )
                  )
                : this.showOfferExternButton()}
            </div>
          ) : (
            ""
          )}
          <br />{" "}
          {offer.events.length >= 1 ? (
            <ButtonContent>
              <div className="d-grid">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => this.showOfferBookDate(offer)}
                >
                  <FormattedMessage id="app.offers.book" />
                </Button>
              </div>
            </ButtonContent>
          ) : (
            ""
          )}
          {offer.courses.length !== 0 ? (
            <ButtonContent>
              <div className="d-grid">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => this.showOfferBookCourse(offer)}
                >
                  <FormattedMessage id="app.offers.bookCourse" />
                </Button>
              </div>
            </ButtonContent>
          ) : (
            ""
          )}
          {offer.refund ? (
            <ButtonContent>
              <div className="d-grid">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => this.showOfferUpload(offer)}
                >
                  <FormattedMessage id="app.offers.uploadReceipt" />
                </Button>
              </div>
            </ButtonContent>
          ) : (
            ""
          )}
          {showNotAvailableNotice &&
          offer.extern === null &&
          !offer.refund &&
          offer.courses.length === 0 &&
          offer.events.length === 0 ? (
            <ButtonContent>
              <div className="d-grid">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => this.showOfferNotAvailableNotice()}
                >
                  <FormattedMessage id="app.offers.notAvailableNotice.button" />
                </Button>
              </div>
            </ButtonContent>
          ) : (
            ""
          )}
        </PaddingDiv>
      </ScrollableDiv>
    );
  }
}

export default graphql(OFFER_QUERY, {
  options: (props) => ({
    variables: { id: props.offerId },
    fetchPolicy: "network-only",
  }),
})(withLanguage(injectIntl(Offer)));
