import React, { PureComponent } from "react";
import { BeatLoader } from "halogenium";
import Conf from "../Conf";
import VerticallyAlignedMiddle from "../components/VerticallyAlignedMiddle";

export class LoadingCol extends PureComponent {
  render() {
    return (
      <VerticallyAlignedMiddle
        height={this.props.height ? this.props.height : "500"}
      >
        <BeatLoader
          className="loader"
          color={Conf.theme.color.buttonBackground}
        />
      </VerticallyAlignedMiddle>
    );
  }
}
