import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { ProgressBar } from "react-bootstrap";
import styled from "styled-components";
import Button from "../CustomBootstrap/Button";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";

const CustomProgressBar = styled(ProgressBar)`
  position: relative;
`;

const ProgressInfo = styled("div")`
  margin: auto;
  position: absolute;
  width: 100%;
`;

class SubventionBudgetWidget extends Component {
  formatPrice(number) {
    return this.props.intl.formatNumber(number, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  render() {
    const percentage =
      (this.props.data.budgetLeft / this.props.data.budgetTotal) * 100;

    return (
      <Wrapper className="widget widget-budget">
        <H4>
          <FormattedMessage id="app.home.widget.budget.title" />
        </H4>
        <CustomPanel>
          <FormattedMessage id="app.global.from">
            {(message) => (
              <CustomProgressBar
                now={percentage}
                label={
                  <ProgressInfo>
                    {" "}
                    {`${this.formatPrice(
                      this.props.data.budgetLeft
                    )} ${message} ${this.formatPrice(
                      this.props.data.budgetTotal
                    )}`}
                  </ProgressInfo>
                }
              />
            )}
          </FormattedMessage>
        </CustomPanel>
        <div className="d-grid gap-2">
          <Button variant="primary" size="lg" href="/budget/subvention">
            <FormattedMessage id="app.home.widget.budget.button" />
          </Button>
        </div>
      </Wrapper>
    );
  }
}

export default injectIntl(SubventionBudgetWidget);
