import { gql } from "@apollo/client";

export const PAYMENT_SELECT_QUERY = gql`
  query {
    guest {
      id
      availableCredit {
        therapyOffer {
          id
        }
        value
      }
      debitor {
        id
        currentSepaMandate {
          id
          cancelDate
        }
      }
    }
    config {
      modules {
        ... on ModuleConfigPayment {
          enabledEventPaymentTypes
        }
      }
    }
  }
`;
