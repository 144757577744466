import { gql } from "@apollo/client";

export const PRESCRIPTIONS_QUERY = gql`
  query prescriptions {
    guest {
      id
      prescriptions {
        id
        checked
        date
        text
        credit
        used
        receiptUrl
        events {
          id
          eventGuest {
            id
            date
          }
        }
      }
    }
  }
`;
