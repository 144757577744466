import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import BgmOffers from "./BgmOffers";
import {
  CustomFaAngleRight,
  CustomListGroup,
  SecondLevelListGroupItem,
  ListGroupItem,
  Title,
} from "../../globalComponents/List";
import Icon from "../../globalComponents/Icon";
import Conf from "../../Conf";

const MoreListGroupItem = styled(SecondLevelListGroupItem)`
  svg.arrow {
    transform: rotate(0deg);
  }
  svg path,
  svg circle {
    fill: #a8a8a8;
  }
`;

class OffersCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openCategory: this.props.currentCategoryId,
      page: this.goToPage(),
    };

    this.loadMore = this.loadMore.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  getClassFor(category) {
    let sClass = "list-group-item offer-cat";
    if (this.state.openCategory === category.id) sClass += " open";
    return sClass;
  }

  goToPage() {
    if (
      this.props.paging === 0 ||
      !this.props.openOffer ||
      !this.props.currentCategoryId
    ) {
      return 1;
    }
    let openCategory = null;
    this.props.categories.forEach((category) => {
      if (category.id === this.props.currentCategoryId) {
        openCategory = category;
      }
    });
    let num = 0;
    openCategory.bgmOffers.forEach((element, index) => {
      if (element.id === this.props.openOffer) {
        num = index + 1;
      }
    });
    return Math.ceil(num / this.props.paging);
  }

  loadMore() {
    this.setState((prevState) => ({ page: prevState.page + 1 }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentCategoryId !== this.props.currentCategoryId) {
      this.setState({
        page: this.goToPage(),
        openCategory: this.props.currentCategoryId,
      });
    }
  }

  render() {
    return (
      <CustomListGroup className="list-group">
        {this.props.categories.map((category) => {
          const showMore =
            this.props.paging > 0 &&
            this.props.filteredOffers[category.id].length >
              this.state.page * this.props.paging;
          const offers =
            this.props.paging === 0
              ? this.props.filteredOffers[category.id]
              : this.props.filteredOffers[category.id].slice(
                  0,
                  this.state.page * this.props.paging
                );

          if (offers.length === 0) {
            return "";
          }

          return (
            <div key={category.id.toString()}>
              <ListGroupItem
                className={this.getClassFor(category)}
                onClick={() =>
                  this.state.openCategory === category.id
                    ? this.setState({
                        openCategory: null,
                        page: 1,
                      })
                    : this.setState({
                        openCategory: category.id,
                        page: 1,
                      })
                }
              >
                {category.icon ? (
                  <Icon src={JSON.parse(category.icon)} className="grey" />
                ) : (
                  ""
                )}
                <Title>{category.title}</Title>
                <CustomFaAngleRight className="arrow" />
              </ListGroupItem>
              <Collapse in={this.state.openCategory === category.id}>
                <div>
                  <BgmOffers
                    offers={offers}
                    onOfferClick={this.props.onOfferClick}
                    openOffer={this.props.openOffer}
                  />
                  {showMore ? (
                    <MoreListGroupItem onClick={() => this.loadMore()}>
                      <Title>
                        <Icon src={Conf.navigation.more.src} />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </MoreListGroupItem>
                  ) : (
                    ""
                  )}
                </div>
              </Collapse>
            </div>
          );
        })}
      </CustomListGroup>
    );
  }
}

export default injectIntl(OffersCategories);
