import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import Conf from "../../Conf";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../CustomBootstrap/Button";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import { UPDATE_USER_MUTATION } from "./SaveAccounting.graphql";

class SaveAccounting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress: false,
    };

    this.parseError = parseError.bind(this);
  }

  onClick() {
    if (this.props.isModal && this.props.dataSaved) {
      this.props.onClose();
      return;
    }

    if (!this.props.editable && this.props.onValidate()) {
      this.setState({ inProgress: true });

      this.props
        .mutate({
          variables: {
            accountOwner: this.props.data.accountOwner,
            iban: this.props.data.iban,
            bic: this.props.data.bic,
          },
        })
        .then(() => {
          this.props.onFinish();
          this.setState({
            inProgress: false,
          });
        })
        .catch((error) => {
          this.setState({ inProgress: false });

          const errorTextTranslationKey = this.parseError(
            error,
            Conf.errorContext.accountSaveBasicData
          );

          this.props.onError(error, errorTextTranslationKey);
        });
    }
    this.props.setEditable();
  }

  render() {
    const { inProgress } = this.state;
    const { isModal, dataSaved } = this.props;

    const title = () => {
      if (isModal && dataSaved) {
        return "app.modal.close";
      }

      if (this.props.editable) {
        return "app.account.edit";
      }

      return "app.account.save";
    };

    return !isModal && inProgress ? (
      <LoadingCol />
    ) : (
      <div className="d-grid">
        <Button
          onClick={this.onClick.bind(this)}
          variant="danger"
          size="lg"
          processing={isModal && inProgress}
        >
          <FormattedMessage id={title()} />
        </Button>
      </div>
    );
  }
}

export default graphql(UPDATE_USER_MUTATION)(injectIntl(SaveAccounting));
