import React, { PureComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";
import styled, { ThemeProvider } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { Col, Row, Form, InputGroup, Card } from "react-bootstrap";
import * as queryString from "query-string";
import Container from "../../globalComponents/Container";
import { Wrapper } from "../../globalComponents/Panel";
import CustomPanel from "../../globalComponents/CustomPanel";
import { theme } from "../../globalComponents/Theme";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import FormControl from "../../components/CustomBootstrap/FormControl";
import Button from "../../components/CustomBootstrap/Button";
import Category from "./Category";
import { FAQ_QUERY } from "./FAQ.graphql";
import Conf from "../../Conf";
import { MiniIcon } from "../../components/Header/Search";

const SearchForm = styled(Form)`
  float: right;
  margin-top: 5px !important;
  margin-right: 5px;
  .form-group {
    float: right;
    width: 250px;
    margin: 0;
  }
  @media screen and (max-width: 489px) {
    float: none;
    clear: both;
    margin-right: 0;
    .form-group {
      float: none;
      width: auto;
      margin: 0;
    }
  }
  input {
    border-color: #8a8a8a !important;
    border-radius: 4px !important;
    padding-right: 40px !important;
    height: 34px !important;
  }
  input:focus {
    box-shadow: none !important;
  }
  input::placeholder {
    font-size: 14px;
    color: #999;
  }
  button {
    background-color: transparent !important;
    border: none;
    position: absolute !important;
    right: 0;
    z-index: 10 !important;
    padding: 0;
    height: 100%;
  }
`;

class Faq extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { search: Faq.getSearchValue(this.props) };

    this.startSearch = this.startSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  static getSearchValue(props) {
    return queryString.parse(props.history.location.search).search ?? "";
  }

  updateSearch(event) {
    this.setState({ search: event.target.value });
  }

  startSearch(event) {
    event.preventDefault();
    this.props.history.push(
      this.state.search
        ? `/faq/?search=${encodeURIComponent(this.state.search)}`
        : `/faq`
    );
  }

  search(categories) {
    if (!this.state.search) return categories;

    const filterFaq = (faq) =>
      faq.question.toLowerCase().includes(this.state.search.toLowerCase()) ||
      faq.answer.toLowerCase().includes(this.state.search.toLowerCase());

    return categories
      .filter((category) => category.faqs.some(filterFaq))
      .map((category) => ({
        ...category,
        faqs: category.faqs.filter(filterFaq),
      }));
  }

  render() {
    return (
      <FormattedMessage id="app.faq.title">
        {(faqTitle) => (
          <ThemeProvider theme={theme}>
            <Container className="faq">
              <Wrapper>
                <CustomPanel>
                  <Card.Body>
                    <Row>
                      <Col xs={12} sm={9}>
                        <Helmet
                          title={`${faqTitle} - ${this.props.intl.formatMessage(
                            {
                              id: "app.title",
                            }
                          )}`}
                        />
                        <h2 style={{ marginTop: "0" }}>{faqTitle}</h2>
                      </Col>
                      <Col xs={12} sm={3} style={{ padding: "0" }}>
                        <SearchForm
                          onSubmit={this.startSearch}
                          action="/faq/"
                          method="get"
                        >
                          <InputGroup>
                            <FormattedMessage id="app.faq.search.placeholder">
                              {(message) => (
                                <FormControl
                                  type="text"
                                  name="search"
                                  value={this.state.search}
                                  onChange={this.updateSearch}
                                  placeholder={message}
                                />
                              )}
                            </FormattedMessage>
                            <Button type="submit">
                              <MiniIcon src={Conf.globalIcons.search} />
                            </Button>
                          </InputGroup>
                        </SearchForm>
                      </Col>
                    </Row>
                    {this.props.data.loading ? (
                      <LoadingCol />
                    ) : (
                      this.search(
                        this.props.data.frontend.employee.faqCategories
                      ).map((category, index) => (
                        <Category
                          category={category}
                          key={index}
                          open={
                            this.search(
                              this.props.data.frontend.employee.faqCategories
                            ).length === 1
                          }
                        />
                      ))
                    )}
                  </Card.Body>
                </CustomPanel>
                <div className="d-grid">
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => {
                      this.props.history.push(`/`);
                    }}
                  >
                    <FormattedMessage id="app.staticcontent.close" />
                  </Button>
                </div>
              </Wrapper>
            </Container>
          </ThemeProvider>
        )}
      </FormattedMessage>
    );
  }
}

export default graphql(FAQ_QUERY, { options: { fetchPolicy: "network-only" } })(
  injectIntl(Faq)
);
