import React, { useRef, useCallback } from "react";
import FittedImage from "react-fitted-image";
import styled from "styled-components";
import { Loading } from "../../globalComponents/Loading";
import OfferBanner from "../Offers/OfferBanner";
import DetailPanel from "./DetailPanel";
import { ImageWrapper, CustomPanel, H4 } from "../../globalComponents/Panel";
import { useOutsideClickEventListener } from "../../hooks/useOutsideClickEventListener";
import { getVideoItems } from "../Offers/Offer";
import { ReactComponent as PlayButtonIcon } from "../../containers/VideoPlayer/img/play_button_outline.svg";

const VideoWrapper = styled("div")`
  position: relative;
  text-align: center;
  height: 90px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const PosterDiv = styled("div")`
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const VideoDiv = styled("div")`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

const EventBox = styled(CustomPanel)`
  padding: 0;
  margin: 0;
  width: 100%;
  /* border: 1px solid #ccc; */
  box-shadow: 1px 1px 2px #888;
  float: left;
  position: relative;
  .booked {
    border: 1px solid #f00;
  }
  height: 135px;
  :hover {
    border: 1px solid #d4d700;
  }
  cursor: pointer;
  Button {
  }
  H4 {
    display: block;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    word-wrap: break-word;
  }
  position: relative;
  @media only screen and (min-width: 768px) {
    Button {
      position: absolute;
      bottom: 10px;
      width: 90%;
    }
    Description {
      p {
        font-size: 0.9em;
      }
    }
  }
`;

const CategoryBarItem = (props) => {
  const eventBoxRef = useRef(null);
  const resetPanelId = useCallback(() => {
    if (
      `${props.category.id}-${props.eventOffer.id}` === props.activePanelId &&
      props.activePanelId !== null
    )
      props.hideCategoryBarDetails();
  }, [props.activePanelId, props.category.id, props.eventOffer.id]);
  useOutsideClickEventListener(eventBoxRef, resetPanelId);

  const videoItems = getVideoItems(props.eventOffer.therapyOffer);

  return (
    <EventBox
      ref={eventBoxRef}
      className="event-box"
      onClick={(event) => {
        props.showDetails(props.eventOffer.id, event);
      }}
    >
      {props.eventOffer.bgmConceptTherapyOfferTags.length > 0 && (
        <OfferBanner {...props.eventOffer.bgmConceptTherapyOfferTags[0]} />
      )}
      <ImageWrapper>
        {videoItems.length > 0 && (
          <VideoWrapper>
            <PosterDiv>
              <PlayButtonIcon width="35" height="35" className="control" />
            </PosterDiv>
            <VideoDiv>
              <video
                poster={videoItems[0].posterUrl}
                className="b-lazy b-loaded"
                controls={false}
                preload="none"
              >
                <source src={videoItems[0].url} type="video/mp4" />
              </video>
            </VideoDiv>
          </VideoWrapper>
        )}
        {props.eventOffer.therapyOffer.videos.length === 0 &&
          props.eventOffer.therapyOffer.images.length > 0 && (
            <FittedImage
              style={{
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
              loader={<Loading />}
              fit="cover"
              src={JSON.parse(props.eventOffer.therapyOffer.images[0].url)}
              alt={props.eventOffer.therapyOffer.title}
            />
          )}
      </ImageWrapper>
      <H4>{props.eventOffer.therapyOffer.title}</H4>
      <DetailPanel
        panelClass={`detail-panel ${
          props.num === 1
            ? "first"
            : props.num === props.numEvents
            ? "last"
            : ""
        }`}
        hidden={
          `${props.category.id}-${props.eventOffer.id}` !== props.activePanelId
        }
        history={props.history}
        intl={props.intl}
        priceMatrix={props.priceMatrix}
        eventOffer={props.eventOffer}
      />
    </EventBox>
  );
};

export default CategoryBarItem;
