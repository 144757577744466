import {
  differenceInMilliseconds,
  parseISO,
  formatISO,
  subDays,
  subHours,
  format,
} from "date-fns";
import de from "date-fns/locale/de";
import enUs from "date-fns/locale/en-US";

export const formatDate = (date, language) =>
  format(new Date(date), "P", {
    locale: language === "en" ? enUs : de,
  });

export const getDateFromISODateAndTime = (ISODate, startTime) => {
  const date = parseISO(ISODate);
  const time = startTime.split(":");
  if (time.length !== 3) throw new Error("wrong-time-format");
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    parseInt(time[0], 10),
    parseInt(time[1], 10),
    parseInt(time[2], 10)
  );
};

export const isEditableOrCancelable = (period_, startDate, startTime) => {
  // If period is not defined or null, set it to 0.
  const period = period_ === null || period_ === undefined ? 0 : period_;

  if (!["string", "number"].includes(typeof period))
    throw new Error("wrong-type");

  if (
    typeof period === "string" &&
    !period.match(/(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/g)
  ) {
    throw new Error("wrong-format");
  }

  // If period is an integer, suctract the corresponding hours from given datetime.
  if (Number.isSafeInteger(period)) {
    if (period < 0) throw new Error("no-negative-hours");
    return (
      differenceInMilliseconds(
        subHours(getDateFromISODateAndTime(startDate, startTime), period),
        new Date()
      ) >= 0
    );
  }

  /*
    If period is a time value given as an ISO string, the event is cancelable
    until the time the day before "startDate". "startTime" are ignored in
    this case
  */
  return (
    differenceInMilliseconds(
      getDateFromISODateAndTime(
        formatISO(subDays(new Date(startDate), 1), {
          representation: "date",
        }),
        period
      ),
      new Date()
    ) >= 0
  );
};
