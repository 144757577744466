import { gql } from "@apollo/client";

export const BOOK_DATE_MUTATION = gql`
  mutation bookDate(
    $eventTherapistId: ID!
    $therapyOfferId: ID
    $timeStart: Time!
    $timeEnd: Time!
    $paymentType: EventGuestPayment!
    $info: String
  ) {
    bookDate(
      date: {
        eventTherapistId: $eventTherapistId
        therapyOfferId: $therapyOfferId
        timeStart: $timeStart
        timeEnd: $timeEnd
        payment: $paymentType
        info: $info
      }
    ) {
      id
      preDateVoteLink {
        voteKey
      }
    }
  }
`;

export const RESERVATION_MUTATION = gql`
  mutation reservation(
    $eventTherapistId: ID!
    $therapyOfferId: ID
    $timeStart: Time!
    $timeEnd: Time!
    $payment: EventGuestPayment!
    $binding: ReservationBinding!
  ) {
    createDateReservation(
      reservation: {
        eventTherapistId: $eventTherapistId
        therapyOfferId: $therapyOfferId
        timeStart: $timeStart
        timeEnd: $timeEnd
        payment: $payment
        binding: $binding
      }
    ) {
      id
    }
  }
`;

export const VOTE_MUTATION = gql`
  mutation vote($key: String!) {
    vote(key: $key) {
      survey {
        id
        token
        dateBegin
        dateEnd
        title
        text
        finish
        predictedTime
        logo
        public
      }
      code
    }
  }
`;
