import React from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";
import "./OfferBanner.scss";

class OfferBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      color: "",
      colorDarkened: "",
    };
  }

  async componentDidMount() {
    await this.setState({
      color: this.props.therapyOfferTag.color,
      colorDarkened: chroma(this.props.therapyOfferTag.color).darken(1),
    });
  }

  render() {
    return (
      <div
        className={[
          "offer-banner",
          this.props.isTag ? "offer-banner-tag" : "",
        ].join(" ")}
        style={{ backgroundColor: this.state.color }}
      >
        {!this.props.isTag && (
          <div
            className="offer-banner-triangle"
            style={{ borderRightColor: this.state.colorDarkened }}
          />
        )}
        {this.props.therapyOfferTag.label}
      </div>
    );
  }
}

OfferBanner.propTypes = {
  therapyOfferTag: PropTypes.shape({
    color: PropTypes.string.isRequired,
    icon: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    lang: PropTypes.string,
  }),
  isTag: PropTypes.bool,
};

export default OfferBanner;
