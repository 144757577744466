import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Helmet from "react-helmet";
import Reset from "../../components/Password/Reset";
import Renew from "../../components/Password/Renew";
import ExpireWarning from "../../components/Password/ExpireWarning";
import Set from "../../components/Password/Set";
import Layout from "../../components/Layout";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";

class Password extends React.Component {
  getRightForm() {
    const aPath = this.props.match.path.split("/");

    if (aPath.includes("password") && aPath.includes("reset")) {
      return <Reset />;
    }

    if (aPath.includes("password") && aPath.includes("set")) {
      return (
        <Set history={this.props.history} location={this.props.location} />
      );
    }

    if (aPath.includes("password") && aPath.includes("renew")) {
      return <Renew />;
    }

    if (aPath.includes("password") && aPath.includes("expire")) {
      return <ExpireWarning />;
    }
  }

  getTitle() {
    const aPath = this.props.match.path.split("/");

    if (aPath.includes("password") && aPath.includes("reset"))
      return "app.password.reset.title";
    if (aPath.includes("password") && aPath.includes("set"))
      return "app.password.set.title";
    if (aPath.includes("password") && aPath.includes("renew"))
      return "app.password.renew.title";
    if (aPath.includes("password") && aPath.includes("expire"))
      return "app.password.renew.expire.title";
  }

  render() {
    const aPath = this.props.match.path.split("/");
    const isRenewPassword =
      (aPath.includes("password") && aPath.includes("renew")) ||
      (aPath.includes("password") && aPath.includes("expire"));

    return (
      <FormattedMessage id={this.getTitle()}>
        {(registerTitle) => (
          <Layout
            {...this.props}
            history={this.props.history}
            hideBacklink
            title={registerTitle}
            resetPassword={!isRenewPassword}
            renewPassword={isRenewPassword}
          >
            <PaddingLessContainer className="password">
              <Helmet
                title={`${registerTitle} - ${this.props.intl.formatMessage({
                  id: "app.title",
                })}`}
              />{" "}
              {this.getRightForm()}
            </PaddingLessContainer>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default injectIntl(Password);
