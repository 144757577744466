import { gql } from "@apollo/client";

export const PRESCRIPTION_UPLOAD_MUTATION = gql`
  mutation prescriptionUpload(
    $prescriptionText: String!
    $prescriptionCredit: Int!
    $prescriptionDate: Date!
  ) {
    uploadPrescription(
      text: $prescriptionText
      credit: $prescriptionCredit
      date: $prescriptionDate
    )
  }
`;
