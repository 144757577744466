import React, { PureComponent } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";
import Icon from "../../globalComponents/Icon";

const LinkA = styled(NavLink)`
  color: #8a8a8a;
  display: block;
  position: relative;
  text-align: center;
  float: left;
  width: 100%;
  margin-bottom: 20px;

  .icon {
    margin: 0;
    margin-right: 10px;
    float: left;
  }

  &:hover,
  &.active {
    color: ${(props) => props.theme.link};
    cursor: pointer;
    text-decoration: none;

    svg * {
      fill: ${(props) => props.theme.link} !important;
    }
  }
`;

const Label = styled("span")`
  display: block;
  float: left;
  line-height: 30px;
`;

class CreditWidget extends PureComponent {
  // TODO add bgmConceptCategoryTherapyOfferId for direct booking
  render() {
    return (
      <Wrapper className="widget widget-budget">
        <H4>
          <FormattedMessage id="app.home.widget.credit.title" />
        </H4>
        <CustomPanel>
          {this.props.data.availableCredit.map((credit) => (
            <LinkA key={credit.therapyOffer.id} to="/offers/" exact>
              {credit.therapyOffer.icon !== null ? (
                <Icon
                  src={JSON.parse(credit.therapyOffer.icon)}
                  className="icon inactive"
                />
              ) : (
                ""
              )}
              <Label>
                {credit.therapyOffer.title}: {credit.value}x{" "}
                {credit.therapyOffer.duration}
                <FormattedMessage id="app.home.widget.credit.minutes" />
              </Label>
            </LinkA>
          ))}
        </CustomPanel>
      </Wrapper>
    );
  }
}

export default injectIntl(CreditWidget);
