import { gql } from "@apollo/client";

export const COLLECT_QUERY = gql`
  query collectQuery {
    guest {
      id
      birthday
      firstname
      lastname
    }
  }
`;
export const SEND_GUEST_RECOMM_MUTATION = gql`
  mutation sendGuestRecommadation(
    $nameTo: String!
    $emailTo: String!
    $message: String!
  ) {
    sendGuestRecommadation(
      nameTo: $nameTo
      emailTo: $emailTo
      message: $message
    )
  }
`;
export const UPDATE_GUEST_MUTATION = gql`
  mutation updateGuest($guest: GuestInput!) {
    updateGuest(guest: $guest) {
      id
      birthday
    }
  }
`;
