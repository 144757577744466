import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import { injectIntl } from "react-intl";
import scrollIntoView from "scroll-into-view";
import styled from "styled-components";
import {
  CustomFaAngleRight,
  SecondLevelListGroupItem,
  Title,
} from "../../globalComponents/List";
import Icon from "../../globalComponents/Icon";
import { showMockup } from "../../globalComponents/MockupController";
import Conf from "../../Conf";

const MiniIcon = styled(Icon)`
  svg {
    height: 24px;
    width: 24px;
    margin-top: 4px;
    margin-left: 4px;
  }
`;

class BgmOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOffer: props.openOffer,
      offers: props.offers,
    };
  }

  setRef(offer, ref) {
    this.offerRef[offer.id] = ref;
  }

  getRef(offer) {
    return this.offerRef[offer.id];
  }

  static getOfferIcon(offer) {
    if (offer.icon) {
      return JSON.parse(offer.icon);
    }
    if (offer.therapyOffer.icon) {
      return JSON.parse(offer.therapyOffer.icon);
    }
    return Conf.globalIcons.empty;
  }

  getClassFor(offer) {
    let sClass = "list-group-item";
    if (this.props.openOffer === offer.id) {
      sClass += " open";
    }
    return sClass;
  }

  componentDidMount() {
    if (this.state.openOffer > 0) {
      const elem = this;
      setTimeout(() => {
        elem.scrollToOpenOffer();
      }, 1000);
    }
  }

  scrollToOpenOffer() {
    if (
      this.offerRef &&
      this.props.openOffer &&
      this.offerRef[this.props.openOffer]
    ) {
      scrollIntoView(
        // eslint-disable-next-line react/no-find-dom-node
        ReactDOM.findDOMNode(this.offerRef[this.props.openOffer]),
        {
          time: 250,
          align: {
            top: 0,
            topOffset: 0,
          },
        }
      );
    }
  }

  componentDidUpdate(prev) {
    if (prev.openOffer !== this.props.openOffer) {
      this.scrollToOpenOffer();
    }
    if (prev.offers !== this.props.offers) {
      this.setState({ offers: this.props.offers });
    }
  }

  render() {
    this.offerRef = [];
    return (
      <div>
        {this.state.offers.map((offer) => (
          <SecondLevelListGroupItem
            ref={(item) => {
              this.setRef(offer, item);
            }}
            className={this.getClassFor(offer)}
            onClick={() => this.props.onOfferClick(offer, this.getRef(offer))}
            key={(showMockup() ? Math.random() : "") + offer.id.toString()}
          >
            <Title>{offer.therapyOffer.title}</Title>
            <MiniIcon src={BgmOffers.getOfferIcon(offer)} />
            <CustomFaAngleRight className="arrow" />
          </SecondLevelListGroupItem>
        ))}
      </div>
    );
  }
}

export default injectIntl(BgmOffers);
