import { gql } from "@apollo/client";

export const BOOK_COURSE_MUTATION = gql`
  mutation bookCourse(
    $roomEventId: ID!
    $paymentType: EventGuestPayment!
    $info: String
  ) {
    bookCourse(
      course: { roomEventId: $roomEventId, payment: $paymentType, info: $info }
    )
  }
`;
