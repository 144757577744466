import React from "react";
import styled from "styled-components";
import { differenceInMilliseconds, parseISO } from "date-fns";
// import { FaPlusCircle } from "react-icons/fa";
// import { IoIosTimer } from "react-icons/io";
import BaseIcon from "react-icon-base";
import {
  EventName,
  EventPanel,
  EventTime,
  IconContainer,
} from "./StyledComponents";
import Icon from "../../globalComponents/Icon";
import Conf from "../../Conf";
import { CalendarModal } from "./CalendarModal";
import { DateFormatter } from "../../globalComponents/DateFormatter";

const FaPlusCircle = (props) => (
  <BaseIcon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m30.1 21.4v-2.8q0-0.6-0.4-1t-1-0.5h-5.7v-5.7q0-0.6-0.4-1t-1-0.4h-2.9q-0.6 0-1 0.4t-0.4 1v5.7h-5.7q-0.6 0-1 0.5t-0.5 1v2.8q0 0.6 0.5 1t1 0.5h5.7v5.7q0 0.5 0.4 1t1 0.4h2.9q0.6 0 1-0.4t0.4-1v-5.7h5.7q0.6 0 1-0.5t0.4-1z m7.2-1.4q0 4.7-2.3 8.6t-6.3 6.2-8.6 2.3-8.6-2.3-6.2-6.2-2.3-8.6 2.3-8.6 6.2-6.2 8.6-2.3 8.6 2.3 6.3 6.2 2.3 8.6z" />
    </g>
  </BaseIcon>
);

const IoIosTimer = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m19.8 3.8c8.9 0 16.2 7.2 16.2 16.2s-7.3 16.3-16.2 16.3-16.3-7.3-16.3-16.3 7.3-16.2 16.3-16.2z m-8.3 7.9c-0.2 0.2 6.7 9.4 6.7 9.4l0.1 0.1c0.4 0.4 0.8 0.8 1.5 0.8 1 0 1.9-0.9 1.9-2 0-0.5-0.2-1-0.6-1.4l-0.3-0.2s-9.1-6.8-9.3-6.7z m8.3 23.2c8.2 0 15-6.6 15-14.9s-6.8-15-15-15v7.5h1.2v-6.1c6.9 0.7 12.3 6.6 12.3 13.6 0 7.5-6 13.6-13.5 13.6s-13.7-6.1-13.7-13.6c0-3.7 1.5-7.2 4-9.7l-0.9-0.9c-2.7 2.7-4.4 6.5-4.4 10.6 0 8.3 6.7 14.9 15 14.9z" />
    </g>
  </Icon>
);

const LeftElement = styled("div")`
  padding: 0 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const RightElement = styled("div")`
  padding: 0 5px;
  width: auto;
`;

const CustomFaPlusCircle = styled(FaPlusCircle)`
  display: block;
  width: 30px;
  height: 30px;
  margin: 0;
  color: ${(props) => props.color};
`;

const CustomIoIosTime = styled(IoIosTimer)`
  display: block;
  width: 30px;
  height: 30px;
  margin: 0;
  color: ${Conf.theme.color.calendar.redAddIconColor};
`;

class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = { bookDate: false };

    this.onClick = this.onClick.bind(this);
    this.close = this.close.bind(this);
  }

  eventIsFull() {
    return this.props.data.freeSlots / this.props.data.totalSlots >= 1.0 / 3.0;
  }

  close() {
    this.setState({ bookDate: false });
  }

  renderSlotsIcon() {
    const date = parseISO(this.props.data.date);
    if (
      differenceInMilliseconds(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          this.props.data.start.substr(0, 2)
        ),
        new Date()
      ) > 0 &&
      this.props.isBookable
    ) {
      return (
        <IconContainer>
          {this.props.data.freeSlots > 0 ? (
            <CustomFaPlusCircle
              color={
                this.eventIsFull()
                  ? Conf.theme.color.calendar.greenAddIconColor
                  : Conf.theme.color.calendar.yellowAddIconColor
              }
            />
          ) : (
            <CustomIoIosTime />
          )}
        </IconContainer>
      );
    }
  }

  renderTabletIcon() {
    if (!this.props.data.icon) return;
    return (
      <IconContainer>
        <Icon src={JSON.parse(this.props.data.icon)} />
      </IconContainer>
    );
  }

  onClick() {
    const date = parseISO(this.props.data.date);
    if (
      !(
        differenceInMilliseconds(
          new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            this.props.data.start.substr(0, 2)
          ),
          new Date()
        ) > 0 && this.props.isBookable
      )
    )
      return;

    if (this.props.data.freeSlots >= 0) {
      // normal booking
      this.setState({ bookDate: true });
    } else {
      // book reservation if enabled, otherwise show modal: "no slot available"
    }
    this.setState({ bookDate: true });
  }

  render() {
    if (this.state.bookDate) {
      const events = [];
      events.push(this.props.data.event);

      const eventDay = {
        date: this.props.data.date,
        events,
      };
      const eventDays = [];
      eventDays.push(eventDay);

      /** creating needed object-structure for booking course * */
      const courses = [];
      const course = { ...this.props.data.event };
      course.id = course.roomEvent.id;
      course.events = events;
      course.day = course.roomEvent.day;
      course.therapyOffer = course.roomEvent.therapyOffer;
      course.dateBegin = course.roomEvent.dateBegin;
      course.dateEnd = course.roomEvent.dateEnd;

      /** in RoomEvent it is called timeBegin :) * */
      course.timeBegin = course.timeStart;

      courses.push(course);

      return this.state.bookDate ? (
        <CalendarModal
          bookDate={this.state.bookDate}
          data={this.props.data}
          close={this.close}
          history={this.props.history}
          enableCoupons={this.props.modules.includes("COUPONS")}
          employeeBudgetLeft={this.props.employeeBudgetLeft}
          config={this.props.config}
          week={this.props.week}
        />
      ) : (
        ""
      );
    }
    return (
      <EventPanel className="event-panel" onClick={this.onClick}>
        <LeftElement>
          {this.renderSlotsIcon()}
          {this.renderTabletIcon()}
        </LeftElement>

        <RightElement>
          <EventName>{this.props.data.title}</EventName>
          <EventTime>
            <DateFormatter time={this.props.data.start} />
            {" - "}
            <DateFormatter time={this.props.data.end} />
          </EventTime>
        </RightElement>
      </EventPanel>
    );
  }
}

export default Event;
