import fetch from "isomorphic-fetch";
import base64 from "base-64";
import md5 from "md5";
import Conf from "../../Conf";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import { setBearer } from "../../utils/localStorage";

const backendURL = require("../../config/backend/server");

function isNetworkError(e) {
  const error = e.toString();

  /** chrome and firefox differ in error message but both include "TypeError" and "fetch" * */
  return error.includes("TypeError") && error.includes("fetch");
}

export function handleSubmit(event) {
  /**
   * Validation
   */
  if (this.state.username === "") {
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: "app.login.loginErrorEmptyUsername" })
    );
    event.preventDefault();
    return false;
  }

  if (this.state.password === "") {
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: "app.login.loginErrorEmptyPassword" })
    );
    event.preventDefault();
    return false;
  }

  /**
   * Request preparations
   */
  const requestURL =
    process.env.NODE_ENV === "development"
      ? `${backendURL}/api/oauth`
      : "/api/oauth";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${base64.encode(
        `${Conf.api.clientId}:${Conf.api.clientPsw}`
      )}`,
      Accept: "application/json",
    },
    body: JSON.stringify({
      grant_type: "password",
      username: this.state.username,
      password: md5(this.state.password),
    }),
  };

  this.setState({ loading: true });

  fetch(requestURL, options)
    .then((response) => {
      this.setState({ loading: false });
      return response.json();
    })
    .then((json) => {
      if (json.access_token) {
        setBearer(json.access_token);
        if (!this.checkSaml()) {
          this.setState({
            loading: false,
            redirectHome: true,
          });
        }
      } else {
        /** object format that the ErrorHandler needs * */
        const error = { message: json.detail };

        const errorTextTranslationKey = parseError(
          error,
          Conf.errorContext.login
        );

        this._alertModal.open(
          this.props.intl.formatMessage({ id: "app.global.error" }),
          this.props.intl.formatMessage({ id: errorTextTranslationKey })
        );

        this.setState({ loading: false });
      }
    })
    .catch((error) => {
      let errorTextTranslationKey = "";

      isNetworkError(error)
        ? (errorTextTranslationKey = parseError(
            error,
            Conf.errorContext.noInternet
          ))
        : (errorTextTranslationKey = parseError(
            error,
            Conf.errorContext.login
          ));

      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: errorTextTranslationKey })
      );

      this.setState({ loading: false });
    });

  event.preventDefault();
}

export function handleChange(event) {
  const { target } = event;
  const { name } = target;
  const { value } = target;

  this.setState({ [name]: value });
}
