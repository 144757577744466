import { gql } from "@apollo/client";

export const BOOK_EXTERN_MUTATION = gql`
  mutation bookExtern(
    $bgmConceptTherapyOfferExternId: ID!
    $serviceProviderLocationId: ID
    $paymentType: EventGuestPayment!
    $info: String
    $variantType: BgmConceptTherapyOfferExternVariantType
    $variantId: ID
    $action: ServiceProviderInterfaceExportAction!
  ) {
    triggerExternalOffer(
      booking: {
        bgmConceptTherapyOfferExternId: $bgmConceptTherapyOfferExternId
        serviceProviderLocationId: $serviceProviderLocationId
        payment: $paymentType
        therapyPackageId: 2
        note: $info
        variantType: $variantType
        variantId: $variantId
      }
      action: $action
    ) {
      id
    }
  }
`;
