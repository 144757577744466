const BEARER = "bearer";
const LANGUAGE = "language";
const PASSWORD_EXPIRED = "expired";

export const setBearer = (value) => localStorage.setItem(BEARER, value);

export const getBearer = () => localStorage.getItem(BEARER);

export const removeBearer = () => localStorage.removeItem(BEARER);

export const setLanguage = (value) => localStorage.setItem(LANGUAGE, value);

export const getLanguage = () => localStorage.getItem(LANGUAGE);

export const removeLanguage = () => localStorage.removeItem(LANGUAGE);

export const setIsPasswordExpired = (value) =>
  localStorage.setItem(PASSWORD_EXPIRED, value);

export const getIsPasswordExpired = () =>
  localStorage.getItem(PASSWORD_EXPIRED) === "true";
