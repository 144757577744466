import { gql } from "@apollo/client";

export const BUDGET_QUERY = gql`
  query {
    guest {
      id
      guestSubventionLimit {
        id
        currentValue
        value
      }
    }
  }
`;
