import Conf from "../Conf";

export const theme = {
  link: Conf.theme.color.links,
  btnBg: Conf.theme.color.buttonBackground,
  btnText: Conf.theme.color.buttonText,
  fontFamily: Conf.theme.font.family,

  calendar: {
    eventBackground: Conf.theme.color.calendar.eventBackground,
    addIconColor: Conf.theme.color.calendar.addIconColor,
    yellowAddIconColor: Conf.theme.color.calendar.yellowAddIconColor,
    clockIconColor: Conf.theme.color.calendar.clockIconColor,
    editIconColor: Conf.theme.color.calendar.editIconColor,
    redEditIconColor: Conf.theme.color.calendar.redEditIconColor,
    rightArrowIconColor: Conf.theme.color.calendar.rightArrowIconColor,
    leftArrowIconColor: Conf.theme.color.calendar.leftArrowIconColor,
  },

  privacyPolicy: {
    titleColor: Conf.theme.color.privacyPolicy.titleColor,
    textColor: Conf.theme.color.privacyPolicy.textColor,
  },

  events: {
    available: {
      background: Conf.theme.color.events.available.background,
      text: Conf.theme.color.events.available.text,
    },
    low_available: {
      background: Conf.theme.color.events.low_available.background,
      text: Conf.theme.color.events.low_available.text,
    },
    not_available: {
      background: Conf.theme.color.events.not_available.background,
      text: Conf.theme.color.events.not_available.text,
    },
    booked: {
      background: Conf.theme.color.events.booked.background,
      text: Conf.theme.color.events.booked.text,
    },
    reserved: {
      background: Conf.theme.color.events.reserved.background,
      text: Conf.theme.color.events.reserved.text,
    },
  },
};
