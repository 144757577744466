import {
  addDays,
  addMinutes,
  differenceInMilliseconds,
  subHours,
  addHours,
  getHours,
  getMinutes,
  getDate,
  isSameDay,
} from "date-fns";

/**
 * given sDate and sTimeStart (time) is bookable if it meets editable limitation
 * @param dateEditableUntil: -24, 17:30:00, null
 * @param sDate (format: '2017-04-29')
 * @param sTimeStart (format: '17:30:00' or '17:00')
 */
export function isBookable(dateEditableUntil, sDate, sTimeStart) {
  /** radix is needed when using parseInt in general */
  const iRadix = 10;
  const date = new Date(sDate);

  /** if no time range is given it is bookable */
  if (dateEditableUntil === null) return true;

  const oToday = new Date();

  /** if dateEditableUntil is of type INT */
  if (dateEditableUntil === parseInt(dateEditableUntil, iRadix).toString()) {
    /** selected day, hour and minute */
    const selHour = sTimeStart.substr(0, 2);
    const selMinute = sTimeStart.substr(3, 2);

    /** set correct hour and minute to selected day */
    const selDayWithSelHourAndSelMinutes = addMinutes(
      addHours(date, selHour),
      selMinute
    );

    /** substract hours (dateEditableUntil) from selected day */
    const result = subHours(selDayWithSelHourAndSelMinutes, dateEditableUntil);

    /**
     * return true
     * - if selected day minus hours (dateEditableUntil) is still in the future
     * return false
     * - if selected day minus hours (dateEditableUntil) is in the past
     * */
    return differenceInMilliseconds(result, oToday) > 0;
  }

  /**
   * if dateEditableUntil is a time string of: 17:30:00
   * return true
   * - if date is higher than tomorrow
   * - if date is tomorrow and given time-limit (e.g. 11:59:00) has not passed todays time (11:59:00)
   * */
  if (dateEditableUntil.match(/[0-9]{2}:[0-9]{2}:[0-9]{2}/g)) {
    const currentDateTime = new Date();

    /** early return if selected date is today */
    if (isSameDay(date, currentDateTime)) return false;

    const oDate = new Date(sDate);
    const oTomorrow = addDays(new Date(), 1);

    /** early return if selected day is after tomorrow
     *  - we checked against today already
     *  - past is not selectable
     * */
    if (getDate(oDate) !== getDate(oTomorrow)) return true;
    const hour = dateEditableUntil.substr(0, 2);
    const minute = dateEditableUntil.substr(3, 2);

    /**
     * return true
     * - if current hour is equal to limit hour and current minute is smaller equal to limit minute
     * return false
     * - if current hour is equal to limit hour and current minute has passed limit minute
     */
    if (getHours(oToday) === parseInt(hour, iRadix))
      return getMinutes(oDate) <= parseInt(minute, iRadix);

    /**
     * return true
     * - if current hour is smaller to limit hour
     * return false
     * - if current hour has passed limit hour */
    return getHours(oToday) < parseInt(hour, iRadix);
  }

  /** */
  return true;
}
