import { gql } from "@apollo/client";

export const GUEST_CARDS_QUERY = gql`
  query guestcards($cursor: String, $limit: Int!) {
    guest {
      id
      guestCardsConnection(first: $limit, after: $cursor) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            __typename
            id
            credit
            used
            salesDate
            blockDate
            therapyOffer {
              id
              title
              icon
            }
          }
        }
      }
    }
  }
`;
