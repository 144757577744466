import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";
import { CustomFaAngleRight, Title } from "../../globalComponents/List";
import { Content, CustomHeader } from "./Styles";

const Question = styled("div")`
  margin-bottom: 15px;
`;

class QuestionAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  render() {
    return (
      <Question className="faq-question">
        <CustomHeader
          className={this.state.open ? "open" : ""}
          onClick={this.toggle}
        >
          <Title> {this.props.faq.question} </Title>
          <CustomFaAngleRight className="arrow" />
        </CustomHeader>
        <Collapse in={this.state.open}>
          <Content>
            <p> {this.props.faq.answer} </p>
          </Content>
        </Collapse>
      </Question>
    );
  }
}

export default QuestionAnswer;
