import React, { useReducer, useState } from "react";
import { useSwipeable } from "react-swipeable";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CustomFaAngleRight } from "../globalComponents/List";
import VideoPlayer from "./VideoPlayer";

export const IMAGE = "image";
export const VIDEO = "video";

const NEXT_ELEMENT = "NEXT";
const PREVIOUS_ELEMENT = "PREV";

const reducer = (state, action) => {
  switch (action.type) {
    case PREVIOUS_ELEMENT:
      return {
        ...state,
        dir: PREVIOUS_ELEMENT,
        sliding: true,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1,
      };
    case NEXT_ELEMENT:
      return {
        ...state,
        dir: NEXT_ELEMENT,
        sliding: true,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1,
      };
    case "stopSliding":
      return { ...state, sliding: false };
    default:
      return state;
  }
};

const getInitialState = (itemsLength) => ({
  pos: itemsLength - 1,
  sliding: false,
  dir: NEXT_ELEMENT,
});

const getOrder = (index, position, itemsLength, dir) => {
  if (dir === NEXT_ELEMENT)
    return index - position < 0
      ? itemsLength - Math.abs(index - position)
      : index - position;
  return index - 1 - position < 0
    ? itemsLength - Math.abs(index - position)
    : index - position;
};

const CarouselContainer = styled.div`
  display: flex;
  transition: ${(props) => (props.sliding ? "none" : "transform 1s ease")};
  transform: ${(props) => {
    if (!props.sliding) return "translateX(calc(-100%))";
    if (props.dir === PREVIOUS_ELEMENT) return "translateX(calc(2 * (-100%)))";
    return "translateX(0%)";
  }};
`;

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CarouselSlot = styled.div`
  flex: 1 0 100%;
  order: ${(props) => props.order};
  video {
    display: block;
    width: 100%;
  }
`;

const SingleSlot = styled.div`
  flex: 1 0 100%;
  video {
    display: block;
    width: 100%;
  }
`;

const CustomFaAngleL = styled(CustomFaAngleRight)`
  transform: rotate(90deg);
  cursor: pointer;
  &.disabled path {
    fill: transparent;
  }
`;
const CustomFaAngleR = styled(CustomFaAngleRight)`
  transform: rotate(-90deg);
  cursor: pointer;
  &.disabled path {
    fill: transparent;
  }
`;

const SliderPanel = styled("div")`
  padding-top: 8px;
  height: 40px;
  width: 100%;
  text-align: center;
`;

const ProgressElement = styled("span")`
  display: inline-block;
  vertical-align: middle;
  margin: 3px;
  height: 10px;
  width: 10px;
  background-color: ${(props) => (props.active ? "#ff6200" : " #a8a8a8")};
  border-radius: 50%;
`;

const MediaSlider = ({ items }) => {
  const itemsLength = items.length;
  const [state, dispatch] = useReducer(reducer, getInitialState(itemsLength));
  const [isStopped, setIsStopped] = useState(false);

  const slide = (dir) => {
    setIsStopped(true);

    dispatch({ type: dir, numItems: itemsLength });
    setTimeout(() => {
      dispatch({ type: "stopSliding" });
    }, 50);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT_ELEMENT),
    onSwipedRight: () => slide(PREVIOUS_ELEMENT),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const renderContent = (contentMedia) => {
    if (contentMedia.type === IMAGE)
      return (
        <img
          draggable={false}
          width="100%"
          src={contentMedia.url}
          alt={contentMedia.name}
        />
      );
    if (contentMedia.type === VIDEO)
      return (
        <VideoPlayer
          url={contentMedia.url}
          posterUrl={contentMedia.posterUrl}
          isStopped={isStopped}
          setIsStopped={setIsStopped}
        />
      );

    throw Error("media slider: media type not defined");
  };

  if (items.length === 0) return;

  if (items.length === 1)
    return <SingleSlot>{renderContent(items[0])}</SingleSlot>;

  return (
    <div {...handlers}>
      <Wrapper>
        <CarouselContainer dir={state.dir} sliding={state.sliding}>
          {state.dir === PREVIOUS_ELEMENT && <CarouselSlot order={-1} />}
          {items.map((m, index) => (
            <CarouselSlot
              key={`media-slider-${index}`}
              order={getOrder(index, state.pos, itemsLength, state.dir)}
            >
              {renderContent(m, index)}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </Wrapper>
      <SliderPanel className="slider-panel">
        <CustomFaAngleL onClick={() => slide(PREVIOUS_ELEMENT)} />
        {items.map((_, index) => (
          <ProgressElement
            key={`progress-element-${index}`}
            active={(state.pos + 1) % itemsLength === index}
            className={(state.pos + 1) % itemsLength === index ? "active" : ""}
          />
        ))}
        <CustomFaAngleR onClick={() => slide(NEXT_ELEMENT)} />
      </SliderPanel>
    </div>
  );
};

Option.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.shape({
        type: IMAGE,
        name: PropTypes.string,
        url: PropTypes.string,
      }),
      PropTypes.shape({
        type: VIDEO,
        name: PropTypes.string,
        url: PropTypes.string,
        posterUrl: PropTypes.string,
      }),
    ])
  ).isRequired,
};

export default MediaSlider;
