import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { Loading } from "../../globalComponents/Loading";
import AlertModalBox from "../../globalComponents/AlertModalBox";

import { PAYMENT_SELECT_QUERY } from "./PaymentSelect.graphql";

class PaymentSelectComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { methods: [] };

    this.refresh = this.refresh.bind(this);
    this._alertModal = null;
  }

  getPriceTotal() {
    if (this.props.eventOffer?.offer?.price?.total !== undefined)
      return this.props.eventOffer.offer.price.total;
    if (this.props.eventOffer?.price?.total !== undefined)
      return this.props.eventOffer.price.total;
    return undefined;
  }

  componentDidUpdate(prev) {
    if (this.props.refreshHandler !== undefined) {
      this.props.refreshHandler(this.refresh);
    }

    if (!this.props.data.loading && prev.data.loading) {
      let paymentMethods = null;

      this.props.data.config.modules.forEach((module) => {
        if (module.__typename === "ModuleConfigPayment") {
          paymentMethods = module.enabledEventPaymentTypes;
        }
      });

      const methods = [];
      let hasDirectDebit = false;

      if (this.getPriceTotal() === 0) {
        methods.push("SUBVENTION");
      } else {
        paymentMethods.forEach((paymentMethod) => {
          let check = true;

          switch (paymentMethod) {
            case "DIRECT_DEBIT":
              check = this.props.data.guest.debitor.currentSepaMandate !== null;
              hasDirectDebit = true;
              break;
            case "CREDIT":
              check = false;
              this.props.data.guest.availableCredit.forEach((credit) => {
                if (this.props.eventOffer.offer.id === credit.therapyOffer.id) {
                  check = true;
                }
              });
              break;
            case "SUBVENTION":
              check = this.getPriceTotal() === 0; // always return false as no eventOffer is given as props
              break;
            default:
              break;
          }
          if (check) {
            methods.push(paymentMethod);
          }
        });
      }

      this.setState({ methods });

      if (methods.length === 0 && hasDirectDebit) {
        this._alertModal.open(
          this.props.intl.formatMessage({ id: "app.global.note" }),
          this.props.intl.formatMessage({
            id: "app.appointments.paymentMethod.error.sepaMandateRequired",
          })
        );
      }

      if (this.props.onReady !== undefined) {
        this.props.onReady(methods, paymentMethods);
      }
    }
  }

  refresh() {
    this.props.data.refetch();
  }

  render() {
    if (this.props.data.loading || this.props.eventOffer === null) {
      return <Loading />;
    }

    const children = [];

    if (this.state.methods.length > 0 && this.getPriceTotal() > 0) {
      children.push(
        <FormattedMessage
          key="default"
          id="app.appointments.paymentMethod.default"
        >
          {(message) => <option value="">{message}</option>}
        </FormattedMessage>
      );
    }

    this.state.methods.forEach((paymentMethod, index) => {
      children.push(
        <FormattedMessage
          key={index}
          id={`app.appointments.paymentMethod.${paymentMethod}`}
        >
          {(message) => <option value={paymentMethod}>{message}</option>}
        </FormattedMessage>
      );
    });

    const selectProps = { ...this.props };
    delete selectProps.data;
    delete selectProps.intl;
    delete selectProps.eventOffer;
    delete selectProps.onReady;
    delete selectProps.refreshHandler;

    return (
      <div>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <select className="form-control" {...selectProps}>
          {children}
        </select>
      </div>
    );
  }
}

export const PaymentSelect = graphql(PAYMENT_SELECT_QUERY, {
  options: () => ({ fetchPolicy: "network-only" }),
})(injectIntl(PaymentSelectComponent));
