import { gql } from "@apollo/client";

export const EMAIL_SETTINGS_QUERY = gql`
  query {
    guest {
      id
      emailConfigs {
        id
        email {
          id
          title
        }
        allowed
      }
    }
  }
`;
