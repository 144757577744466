import React, { PureComponent } from "react";
import styled from "styled-components";

/**
 * Container Element
 *
 * @param children
 */
export const Div = styled("div")`
  width: 100% !important;
  padding: 0 15px !important;

  @media screen and (min-width: 1200px) {
    padding: 0 5px !important;
    width: 1190px !important;
  }
`;

class Container extends PureComponent {
  render() {
    const { children, className, ...other } = this.props;

    return (
      <Div {...other} className={`container ${className}`}>
        {children}
      </Div>
    );
  }
}

export default Container;
