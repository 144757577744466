import styled from "styled-components";
import { Header } from "../../globalComponents/List";

const Content = styled("div")`
  text-align: left;
  padding-left: 15px;

  p {
    padding: 0 15px;
  }
`;

const CustomHeader = styled(Header)`
  padding: 2px 15px;

  &.open {
    padding: 2px 15px;
  }
`;

export { Content, CustomHeader };
