import { gql } from "@apollo/client";

export const LOGIN_QUERY = gql`
  query {
    frontend {
      staticContent(type: EMPLOYEE_LOGIN) {
        title
        content
      }
    }
  }
`;
