import React, { Component } from "react";
import { Form, FormGroup, FormCheck } from "react-bootstrap";
import { graphql } from "@apollo/client/react/hoc";
import { ThemeProvider } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import Button from "../../components/CustomBootstrap/Button";
import Container from "../../globalComponents/Container";
import { Wrapper } from "../../globalComponents/Panel";
import CustomPanel from "../../globalComponents/CustomPanel";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import { theme } from "../../globalComponents/Theme";
import StaticContent from "../../components/StaticContent";
import PrivacyPolicyAccept from "./PrivacyPolicyAccept";
import { setIsPasswordExpired } from "../../utils/localStorage";
import { PRIVACY_POLICY_QUERY } from "./PrivacyPolicy.graphql";

/**
 * PrivacyPolicy Container
 */
class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accepted: false,
    };
  }

  componentDidMount() {
    this.props.data.refetch().then((data) => {
      this.setState({
        accepted:
          data.data.guest.privacyPolicyAccepted !== null &&
          data.data.guest.privacyPolicyAccepted.privacyPolicy.id ===
            data.data.frontend.employee.privacyPolicy.id,
      });
    });
  }

  render() {
    if (!this.props.data.loading) {
      if (this.state.accepted && this.props.redirect) {
        if (this.props.data.guest.passwordExpired) {
          setIsPasswordExpired(true);
          return (
            <Redirect
              push
              to={{
                pathname: "/password/renew",
              }}
            />
          );
        }

        setIsPasswordExpired(false);

        if (this.props.data.guest.expirationWarning) {
          return (
            <Redirect
              push
              to={{
                pathname: "/password/expire",
              }}
            />
          );
        }

        return (
          <Redirect
            push
            to={{
              pathname: "/",
              hash: "privacyPolicyAccepted",
            }}
          />
        );
      }

      return (
        <ThemeProvider theme={theme}>
          <Container className="privacy">
            <Wrapper>
              <CustomPanel>
                <StaticContent
                  staticContent={
                    this.props.data.frontend.employee.privacyPolicy
                  }
                  noParsing
                />
              </CustomPanel>
              {this.state.accepted ? (
                <Form>
                  <FormGroup>
                    <FormCheck
                      checked
                      disabled
                      label={<FormattedMessage id="app.privacyPolicy.accept" />}
                      className="d-inline-block pb-4"
                    />
                    <div className="d-grid">
                      <Button
                        onClick={() => {
                          window.history.go(-1);
                        }}
                        variant="primary"
                        size="lg"
                      >
                        <FormattedMessage id="app.staticcontent.close" />
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              ) : (
                <PrivacyPolicyAccept
                  privacyPolicyId={
                    this.props.data.frontend.employee.privacyPolicy.id
                  }
                />
              )}
            </Wrapper>
          </Container>
        </ThemeProvider>
      );
    }
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Wrapper>
            <CustomPanel>
              <LoadingCol />
            </CustomPanel>
          </Wrapper>
        </Container>
      </ThemeProvider>
    );
  }
}

export default graphql(PRIVACY_POLICY_QUERY)(injectIntl(PrivacyPolicy));
