import { isSameDay } from "date-fns";

/**
 * returns true
 * - if guestState of slot is other than AVAILABLE
 * - if date of currentDateTime is today AND if the time of currentDateTime has passed start time of slot
 *
 * @param sDate (e.g. 2017-04-01)
 * @param oTimeSlot
 * @returns {boolean}
 */
export function isTimeSlotDisabled(sDate, oTimeSlot) {
  /** radix is needed when using parseInt in general */
  const iRadix = 10;
  const date = new Date(sDate);

  let disabled = false;

  if (oTimeSlot.guestState !== "AVAILABLE") disabled = true;

  const currentDateTime = new Date();
  /** early return if selected date is not today */
  if (!isSameDay(date, currentDateTime)) return disabled;

  const currentHours = currentDateTime.getHours();
  const currentMinutes = currentDateTime.getMinutes();
  const timeSlotHours = parseInt(oTimeSlot.timeStart.split(":")[0], iRadix);
  const timeSlotMinutes = parseInt(oTimeSlot.timeStart.split(":")[1], iRadix);

  /**
   * if current hour has passed the slot hour
   * if current hour is same as slot hour but current minutes have passed the slot minutes
   */
  if (
    currentHours > timeSlotHours ||
    (currentHours === timeSlotHours && currentMinutes > timeSlotMinutes)
  )
    disabled = true;

  return disabled;
}

/**
 * checks whether given time with timeSlot is in the past
 * when the date is today
 * @param sDate (format: '2017-04-29')
 * @param oTimeSlot
 * @returns {boolean}
 */
export function isTimeSlotInThePast(sDate, oTimeSlot) {
  /** radix is needed when using parseInt in general */
  const iRadix = 10;

  const date = new Date(sDate);

  const currentDateTime = new Date();

  /** early return if selected date is not today
   *  (can only be future since past date is not selectable)
   * */
  if (!isSameDay(date, currentDateTime)) return false;

  const currentHours = currentDateTime.getHours();
  const currentMinutes = currentDateTime.getMinutes();
  const timeSlotHours = parseInt(oTimeSlot.timeStart.split(":")[0], iRadix);
  const timeSlotMinutes = parseInt(oTimeSlot.timeStart.split(":")[1], iRadix);

  /**
   * if current hour has passed the slot hour
   * if current hour is same as slot hour but current minutes have passed the slot minutes
   */
  return (
    currentHours > timeSlotHours ||
    (currentHours === timeSlotHours && currentMinutes > timeSlotMinutes)
  );
}
