import React, { PureComponent } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";
import {
  CustomFaAngleRight,
  CustomListGroup,
  ListGroupItem,
  Title,
} from "../../globalComponents/List";
import Icon from "../../globalComponents/Icon";

const CatList = styled(CustomListGroup)`
  overflow: hidden;
  height: 100%;
  -ms-overflow-style: none;
  @media screen and (max-width: 992px) {
    background-color: #ffffff;
    border: 0;
    position: inherit;
  }

  svg.arrow {
    transform: rotate(270deg);
  }
`;

class BgmConceptCategories extends PureComponent {
  render() {
    return (
      <Wrapper className="widget widget-categories">
        <H4>
          <FormattedMessage id="app.home.widget.categories.title" />
        </H4>
        <CustomPanel>
          <CatList className="list-group">
            {this.props.data.categories.map((category) => (
              <div key={category.id.toString()}>
                <ListGroupItem
                  className="offer-cat"
                  key={category.id}
                  onClick={() => {
                    this.props.history.push(
                      `/offers/?openCategory=${category.id}`
                    );
                  }}
                >
                  {category.icon ? (
                    <Icon src={JSON.parse(category.icon)} className="grey" />
                  ) : (
                    ""
                  )}
                  <Title>{category.title}</Title>
                  <CustomFaAngleRight className="arrow" />
                </ListGroupItem>
              </div>
            ))}
          </CatList>
        </CustomPanel>
      </Wrapper>
    );
  }
}

export default injectIntl(BgmConceptCategories);
