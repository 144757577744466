import React, { PureComponent } from "react";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import Conf from "../../Conf";
import Logo from "../../globalComponents/Logo";
import {
  Wrapper,
  CustomNavbarHeader,
  MenuMobileDesktop,
  MenuItem,
} from "./HeaderStyles";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";
import "./hamburger.css";
import Search from "./Search";
import { getBearer } from "../../utils/localStorage";
import { withLanguage } from "../../context/LanguageContext/withLanguage";

const SubWrapper = styled("div")`
  padding: 0 15px !important;
  @media screen and (min-width: 1200px) {
    padding: 0 !important;
  }
`;

const Button = styled("div")`
  padding: 4px 0 0 0 !important;
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background-color: ${(props) => props.theme.btnBg};
  }
`;

const ContainerForMenu = styled("div")`
  padding: 0 !important;
  position: relative;
  margin: auto !important;
  width: 100% !important;

  @media screen and (min-width: 1200px) {
    width: 1200px !important;
  }
`;

/**
 * Header Component
 * Logo with Hamburger-Menu
 * Menu links are defined in Conf.js
 */

class Header extends PureComponent {
  render() {
    let authLayout = getBearer();
    if (this.props.resetPassword || this.props.renewPassword) {
      authLayout = false;
    }

    return (
      <Wrapper className="header">
        <CustomNavbarHeader>
          <SubWrapper>
            <PaddingLessContainer>
              <Logo />
              <Button
                className={[
                  "hamburger",
                  "hamburger--spin",
                  this.props.isToggleOn ? "is-active" : "",
                ].join(" ")}
                type="button"
                onClick={this.props.func}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </Button>
              {authLayout && this.props.modules.includes("SEARCH") ? (
                <Search history={this.props.history} />
              ) : (
                ""
              )}
            </PaddingLessContainer>
          </SubWrapper>
        </CustomNavbarHeader>
        <ContainerForMenu className="container hamburger-menu">
          <MenuMobileDesktop className={this.props.isToggleOn ? "active" : ""}>
            {Conf.basicLinks.map((link) => (
              <MenuItem key={link.key} url={link.route}>
                <FormattedMessage id={link.name} />
              </MenuItem>
            ))}
            {this.props.languageContext.selectionAvailable && (
              <MenuItem key="1" url="/language">
                <FormattedMessage id="app.global.language" />
              </MenuItem>
            )}
            {this.props.resetPassword ? (
              <MenuItem key="0" url="/login">
                <FormattedMessage id="app.global.backToLogin" />
              </MenuItem>
            ) : (
              <MenuItem key="0" url="/logout">
                <FormattedMessage id="app.global.logout" />
              </MenuItem>
            )}
          </MenuMobileDesktop>
        </ContainerForMenu>
      </Wrapper>
    );
  }
}

export default withLanguage(injectIntl(Header));
