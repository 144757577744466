import { gql } from "@apollo/client";

export const OFFER_FILTER_QUERY = gql`
  query {
    bgmConcept {
      availableTherapyOfferAttributes {
        id
        title
        icon
        availableTherapyOfferAttributeValues {
          id
          title
          icon
        }
      }
      categories {
        id
        title
        icon
      }
    }
  }
`;
