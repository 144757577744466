import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { Collapse } from "react-bootstrap";
import { Title, CustomFaAngleRight, Header } from "../../globalComponents/List";
import Icon from "../../globalComponents/Icon";
import Conf from "../../Conf";
import Button from "../CustomBootstrap/Button";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import { GUEST_CARDS_QUERY } from "./GuestCardList.graphql";
import { DateFormatter } from "../../globalComponents/DateFormatter";

const CustomHeader = styled(Header)`
  padding-left: 15px;

  &.open {
    padding: 0 15px 0 15px;
  }
`;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 0 15px;
  border-bottom: 1px solid #d4d4d4;
`;

const Content = styled("div")`
  padding: 15px;
  text-align: left;
`;

const IconStyled = styled(Icon)`
  margin-left: 15px;
`;

class GuestCardList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentGuestCard: null,
      loadingMore: false,
    };

    this.showDetails = this.showDetails.bind(this);
    this.canGetMore = this.canGetMore.bind(this);
    this.fetchMore = this.fetchMore.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (this.state.editAppointment) {
      this.setState({ editAppointment: false });
      window.customBackAction = this.goBack;
      return;
    }

    this.setState({ currentGuestCard: null });
  }

  showDetails(guestCard) {
    window.customBackAction = this.goBack;
    this.setState({
      currentGuestCard: guestCard,
      editAppointment: false,
    });
  }

  canGetMore() {
    return this.props.data.guest.guestCardsConnection.pageInfo.hasNextPage;
  }

  fetchMore() {
    this.setState({ loadingMore: true });
    return this.props.data.fetchMore();
  }

  render() {
    const isDataReady = !this.props.data.loading;

    return (
      <div>
        {isDataReady || this.state.loadingMore ? (
          this.props.data.guest.guestCardsConnection.edges.length === 0 ? (
            <VerticallyAlignedMiddle height={window.innerHeight / 2}>
              <span className="large">
                <FormattedMessage id="app.guestcards.empty" />
              </span>
            </VerticallyAlignedMiddle>
          ) : (
            this.props.data.guest.guestCardsConnection.edges.map(
              (guestCard) => (
                <div key={guestCard.node.id}>
                  <CustomHeader
                    className={
                      this.state.currentGuestCard &&
                      this.state.currentGuestCard.node.id === guestCard.node.id
                        ? "open"
                        : ""
                    }
                    onClick={() =>
                      this.state.currentGuestCard &&
                      this.state.currentGuestCard.node.id === guestCard.node.id
                        ? this.showDetails(null)
                        : this.showDetails(guestCard)
                    }
                  >
                    {guestCard?.node?.therapyOffer?.icon && (
                      <IconStyled
                        src={JSON.parse(guestCard.node.therapyOffer.icon)}
                        className="icon inactive"
                      />
                    )}
                    <Title>
                      {guestCard.node.credit}x{" "}
                      {guestCard.node.therapyOffer.title}
                      <br />
                      <small className="text-muted">
                        <FormattedMessage id="app.guestscards.label.salesDate" />
                        {"  "}
                        <DateFormatter
                          date={new Date(guestCard.node.salesDate)}
                          type="DATE"
                        />{" "}
                      </small>
                    </Title>
                    <CustomFaAngleRight className="arrow" />
                  </CustomHeader>
                  <Wrapper>
                    <Collapse
                      in={
                        this.state.currentGuestCard &&
                        this.state.currentGuestCard.node.id ===
                          guestCard.node.id
                      }
                    >
                      <Content>
                        <b>
                          <FormattedMessage id="app.guestscards.label.used" />
                        </b>{" "}
                        {guestCard.node.used}/{guestCard.node.credit}
                        <br />
                        <b>
                          <FormattedMessage id="app.guestscards.label.blockDate" />
                        </b>{" "}
                        <DateFormatter
                          date={new Date(guestCard.node.blockDate)}
                          type="DATE"
                        />{" "}
                        <br />
                      </Content>
                    </Collapse>
                  </Wrapper>
                </div>
              )
            )
          )
        ) : (
          <LoadingCol />
        )}
        {(isDataReady || this.state.loadingMore) && this.canGetMore() ? (
          <div>
            <br />
            <div className="d-grid">
              <Button
                onClick={this.fetchMore}
                processing={!isDataReady && this.state.loadingMore}
                variant="primary"
                size="lg"
              >
                <FormattedMessage id="app.global.loadMore" />
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const ITEMS_PER_PAGE = Conf.general.itemsPerPage;
const withData = graphql(GUEST_CARDS_QUERY, {
  options: () => ({
    variables: {
      cursor: null,
      limit: ITEMS_PER_PAGE,
    },
    fetchPolicy: "cache-and-network",
  }),
  props: ({ data: { loading, guest, fetchMore } }) => ({
    data: {
      loading,
      guest,
      fetchMore: () =>
        fetchMore({
          variables: {
            cursor:
              guest.guestCardsConnection.edges[
                guest.guestCardsConnection.edges.length - 1
              ].cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            const fetchResult = fetchMoreResult;
            if (!fetchResult) {
              return prev;
            }
            fetchResult.guest.guestCardsConnection.edges =
              prev.guest.guestCardsConnection.edges.concat(
                fetchResult.guest.guestCardsConnection.edges
              );
            return fetchMoreResult;
          },
        }),
    },
  }),
});

export default withData(injectIntl(GuestCardList));
