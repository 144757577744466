import React, { PureComponent } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import isExternal from "is-url-external";

/**
 * General Header Component Styles
 */
export const Wrapper = styled("div")`
  background-color: #ffffff !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

/**
 * Navbar Styles
 */
export const CustomNavbar = styled("div")`
  background-color: #ffffff !important;
  border-bottom: 1px solid #d4d4d4 !important;
  border-radius: 0 !important;
  margin: 0 !important;
`;

/**
 * Navbar-Header Styles
 */
export const CustomNavbarHeader = styled("div")`
  border-bottom: 1px solid #d4d4d4 !important;
  float: none !important;
  text-align: left;
  padding: 10px 0;

  .hamburger {
    float: right;
    padding: 0px;
    margin-top: 5px;
  }
`;

/**
 * Menu Element Styles
 */
export const MenuMobileDesktop = styled("ul")`
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  list-style-type: none;
  margin: 0;
  background-color: #ffffff !important;
  border-top: 0 !important;
  display: block !important;
  height: auto;
  max-height: 0;
  overflow: hidden;
  padding: 0 !important;
  transition: max-height 0.4s ease-in-out, height 0.4s ease-in-out;

  &.active {
    border-bottom: 1px solid #d4d4d4;
    max-height: 500px;
  }

  @media screen and (min-width: 0px) {
    &.navbar-collapse.collapse {
      overflow: hidden !important;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    display: none !important;
  }

  @media screen and (min-width: 992px) {
    position: absolute;
    right: 0;
    width: 25%;
  }
`;

/**
 * MenuItem Component
 * builds list-element, a-element, styles it
 */
export class MenuItem extends PureComponent {
  render() {
    const Li = styled("li")`
      border-bottom: 1px solid #e9e9e9;

      &:last-child {
        border-bottom: none;
      }

      @media screen and (min-width: 768px) and (max-width: 992px) {
        &:last-child {
          border-bottom: 1px solid #e9e9e9;
        }
      }
    `;

    const LinkA = styled(NavLink)`
      color: #8a8a8a;
      display: block;
      padding: 15px;

      &:hover,
      &.active {
        color: ${(props) => props.theme.btnText};
        background-color: ${(props) => props.theme.btnBg};
        text-decoration: none;
      }

      @media screen and (min-width: 768px) {
        text-align: left;
      }
    `;

    const LinkE = styled("a")`
      color: #8a8a8a;
      display: block;
      padding: 15px;

      &:hover,
      &.active {
        color: ${(props) => props.theme.btnText};
        background-color: ${(props) => props.theme.btnBg};
        text-decoration: none;
      }

      @media screen and (min-width: 768px) {
        text-align: left;
      }
    `;

    return (
      <Li>
        {isExternal(this.props.url) ? (
          <LinkE href={this.props.url} target="_blank">
            {this.props.children}
          </LinkE>
        ) : (
          <LinkA to={this.props.url}>{this.props.children}</LinkA>
        )}
      </Li>
    );
  }
}
