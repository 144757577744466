import React, { PureComponent } from "react";
import { injectIntl } from "react-intl";
import { Wrapper } from "../../globalComponents/Panel";
import CategoryBar from "./CategoryBar";

class CategoryBarsWidget extends PureComponent {
  render() {
    return (
      <Wrapper className="widget widget-category-bars">
        {this.props.data.enabledCategories.map((category) => (
          <CategoryBar
            key={category.id}
            category={category}
            history={this.props.history}
            activePanelId={this.props.activePanelId}
            setActivePanelId={this.props.setActivePanelId}
          />
        ))}
      </Wrapper>
    );
  }
}

export default injectIntl(CategoryBarsWidget);
