import { gql } from "@apollo/client";

export const ACCESS_DATA_QUERY = gql`
  query {
    acl {
      whoami {
        username
        loginsConnection(first: 5) {
          edges {
            node {
              remoteAddress
              loginDate
            }
          }
        }
      }
    }
  }
`;
