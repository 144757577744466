import { gql } from "@apollo/client";

export const BASIC_DATA_QUERY = gql`
  query {
    guest {
      id
      lastname
      firstname
      street
      emailJob
      phonePrivate
      town
      zip
      emailPrivate
      enableEmailPrivate
      mobileProviderJob
      mobileJob
    }
  }
`;
