import React, { PureComponent } from "react";
import { ThemeProvider } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import Container from "../globalComponents/Container";
import { Wrapper } from "../globalComponents/Panel";
import CustomPanel from "../globalComponents/CustomPanel";
import Button from "../components/CustomBootstrap/Button";
import { theme } from "../globalComponents/Theme";
import StaticContent from "../components/StaticContent";
import { LoadingCol } from "../globalComponents/LoadingCol";
import { IMPRINT_QUERY } from "./Imprint.graphql";

class Imprint extends PureComponent {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container className="imprint">
          <Wrapper>
            <CustomPanel>
              {!this.props.data.loading ? (
                <StaticContent
                  staticContent={this.props.data.frontend.staticContent}
                />
              ) : (
                <LoadingCol />
              )}
            </CustomPanel>
            <div className="d-grid">
              <Button
                variant="primary"
                size="lg"
                onClick={() => {
                  window.history.go(-1);
                }}
              >
                <FormattedMessage id="app.staticcontent.close" />
              </Button>
            </div>
          </Wrapper>
        </Container>
      </ThemeProvider>
    );
  }
}

export default graphql(IMPRINT_QUERY)(injectIntl(Imprint));
