export default {
  "app.account.accessData.password.error.400x06":
    "The password must not contain the username. Please create a stronger password.",
  "app.account.accessData.password.error.400x07":
    "The password must not contain the same character three times in a row. Please create a stronger password.",
  "app.account.accessData.password.error.400x08":
    "The password is either too weak or already compromised or publicly known. Please create a stronger password.",
  "app.global.error": "Error",
  "app.modal.confirm": "confirm",
  "app.modal.cancel": "quit",
  "app.global.technicalError":
    "Sorry, an error occurred. \\nPlease try again later.",
  "app.error.lowbudget": "Sorry, your current budget is not sufficient.",
  "app.global.language": "Language",
  "app.global.title": "Title",
  "app.global.from": "from",
  "app.global.at": "at",
  "app.global.with": "at",
  "app.global.clock": "o’clock",
  "app.global.priceUnit": "€",
  "app.global.loadMore": "show more",
  "app.global.noInternet.default":
    "Log-in failed. Please check your internet connection.",
  "app.global.delete": "delete",
  "app.login.title": "Log-in",
  "app.login.login": "Sign in",
  "app.login.username": "User name",
  "app.login.password": "Password",
  "app.login.loginError.default": "An error occurred during log-in.",
  "app.login.loginError.401x01":
    "Too many log-in attempts. Your access has been blocked for ten minutes.",
  "app.login.loginError.401x02":
    "Your access has been blocked. Please contact email@bgm.services for help..",
  "app.login.loginError.401x03": "Your user name or password is invalid.",
  "app.login.loginError.401x04": "Access from your network is blocked.",
  "app.login.loginErrorEmptyUsername": "Please enter your user name.",
  "app.login.loginErrorEmptyPassword": "Please enter your password.",
  "app.account.title": "My details",
  "app.account.accessMessage": "On: {datetime} with IP-address: {ip}",
  "app.account.basicData": "Master data",
  "app.account.basicData.error.default": "An error occurred.",
  "app.account.basicData.error.403": "Sorry, an error occurred (403).",
  "app.account.basicData.error.404": "Sorry, an error occurred (404).",
  "app.account.basicData.error.400x00": "The master data is invalid.",
  "app.account.basicData.error.400x01": "The account details are invalid.",
  "app.account.accessData": "Access data",
  "app.account.accessData.username.error.default": "An error occurred.",
  "app.account.accessData.username.error.400x03":
    "The user name may only consist of numbers, letters and the characters -_.",
  "app.account.accessData.username.error.400x04":
    "This user name is already taken.",
  "app.account.accessData.password.error.default": "An error occurred.",
  "app.account.accessData.password.error.400x01": "Please enter a password.",
  "app.account.accessData.password.error.400x02":
    "The password doesn’t meet the requirements.",
  "app.account.accessData.password.error.400x12":
    "This password has been previously used. Please create a new password.",
  "app.account.emailSettings": "Notifications",
  "app.account.emailSettings.error.default": "An error occurred.",
  "app.account.emailSettings.error.400": "Invalid employee account.",
  "app.account.emailSettings.error.403": "Access denied.",
  "app.appointments.appointmentCanceled": "Your booking has been cancelled.",
  "app.appointments.courseCanceled":
    "Your course participation will be cancelled.",
  "app.account.firstName": "First name",
  "app.account.lastName": "Last name",
  "app.account.emailJob": "Company e-mail",
  "app.account.phone": "Telephone",
  "app.account.street": "Street",
  "app.account.town": "Town/city",
  "app.account.zip": "Postcode",
  "app.account.accountOwner": "Account holder",
  "app.account.iban": "IBAN",
  "app.account.bic": "BIC",
  "app.account.bankAccount": "Account details",
  "app.calendar.title": "Calendar",
  "app.calendar.timeSlotTooltip":
    "In this time slot, you already have a {duration}-minute appointment",
  "app.news.error.default": "An error occurred.",
  "app.news.error.400": "Sorry, an error occurred (400).",
  "app.news.error.403": "Sorry, an error occurred (403).",
  "app.news.title": "News",
  "app.news.markAsReadWarningTitle": "Note",
  "app.news.markAsReadWarningMessage":
    "Do you really want to hide this permanently?",
  "app.news.markAsReadWarningShow": "Do not show warning again.",
  "app.news.noNews": "There is currently no news",
  "app.privacyPolicy.continue": "More",
  "app.privacyPolicy.ok": "close",
  "app.privacyPolicy.accept":
    "I have read the Privacy Policy and agree with it.",
  "app.privacyPolicy.error.pleaseAccept.title":
    "Please accept the Privacy Policy to continue.",
  "app.privacyPolicy.error.pleaseAccept":
    "Please accept the Privacy Policy to continue.",
  "app.privacyPolicy.error.default": "An error occurred.",
  "app.privacyPolicy.error.403": "Sorry, an error occurred (403).",
  "app.offers.title": "Offers",
  "app.offers.book": "Select appointment",
  "app.offers.bookAnother": "book another appointment",
  "app.offers.book.error.bookLimitExceeded":
    "You can’t book this appointment/time any more. Please choose another one.",
  "app.offers.bookCourse": "Select course",
  "app.offers.bookNotAvailable": "no appointments available",
  "app.offers.uploadReceipt": "more information & document upload",
  "app.offers.upload.amountHelpText":
    "Please enter a valid amount with max. two decimal places.",
  "app.offers.description": "Description",
  "app.offers.images": "Images",
  "app.offers.price": "Points",
  "app.offers.price.price": "Lack of points",
  "app.offers.price.subvention": "Points",
  "app.offers.price.subventionSingular": "Point",
  "app.offers.price.course.price": "Price",
  "app.offers.price.course.subvention": "Points",
  "app.offers.price.course.fromTo": "Course from {from} to {to}",
  "app.offers.price.date.at": "Appointment on",
  "app.offers.price.receipt.fromOver": "Document dated {from} for {over} EUR",
  "app.offers.price.visited.at": "Visit on",
  "app.offers.price.refund.state": "Status",
  "app.offers.price.refund.submittedAt": "Submitted on",
  "app.offers.price.refund.amount": "Amount",
  "app.offers.price.refund.referenceNumber": "Document number",
  "app.offers.price.refund.price": "Points",
  "app.offers.price.refund.text": "1 point = 1 euro",
  "app.offers.price.availableBudget": "My current budget",
  "app.offers.criteria": "Eligibility criteria",
  "app.offers.upload": "Submit document",
  "app.offers.upload.addMore": "add more",
  "app.offers.upload.error.default":
    "Sorry, an unknown error occurred. Please try again later.",
  "app.offers.upload.error.400x02":
    "Sorry, it wasn’t possible to process your upload: the refund form is invalid.",
  "app.offers.upload.error.400x03":
    "Sorry, it wasn’t possible to process your upload: please upload a PDF or JPG file only.",
  "app.offers.upload.error.500":
    "Sorry, a processing error occurred. Please contact the Service Team.",
  "app.offers.receiptTitle": "Title",
  "app.offers.upload.file.help":
    "Max. of three files up to 5 MB, PDF or JPG/JPEG only, no bank statements.",
  "app.offers.upload.receiptNumber.help":
    "If you don't have a document number, enter",
  "app.offers.uploadAccept":
    "I have read and accepted the eligibility criteria.",
  "app.offers.uploadSuccessful": "The document was successfully submitted.",
  "app.offers.uploadValidationError":
    "Your receipt was not submitted. Please check your entries.",
  "app.offers.uploadError": "Sorry, an error occurred. Please try again later.",
  "app.offers.criteriaNotAcceptedError":
    "Please accept the eligibility criteria.",
  "app.offers.provideBankAccount":
    "Please enter your account details under “My details” first.",
  "app.offers.offer": "Offer",
  "app.offers.receiptDate": "Document date",
  "app.offers.receiptNumber": "Document no.",
  "app.offers.totalAmount": "Refund amount",
  "app.offers.videos": "Videos",
  "app.offers.downloads": "Further info for download",
  "app.offers.date.book": "Book",
  "app.offers.date.edit": "Edit appointment",
  "app.offers.date.renew": "Extend",
  "app.offers.date.reserve": "Pre-book",
  "app.offers.date.reserve.uncommitted": "Make a non-binding pre-booking",
  "app.offers.date.reserve.committed": "Make a binding pre-booking",
  "app.offers.date.reserve.ONE_DAY": "Up to one day before the start",
  "app.offers.date.reserve.RESERVATION_FOUR_HOURS":
    "Up to four hours before start",
  "app.offers.date.reserve.RESERVATION_ONE_HOUR": "Up to our hour before start",
  "app.offers.date.book.info":
    "You can book appointments a maximum of eight weeks in advance.",
  "app.offers.date.date": "Date",
  "app.offers.date.time": "Time",
  "app.offers.date.time.available": "available",
  "app.offers.date.time.not_available": "not available",
  "app.offers.date.time.booked": "booked",
  "app.offers.date.time.info":
    "There are several appointments available for the same time.",
  "app.offers.bookError": "Error when trying to book.",
  "app.offers.booking.error.default": "Error when trying to book.",
  "app.offers.booking.error.400x00": "This booking already exists.",
  "app.offers.booking.error.400x02": "You can’t make a booking in the past.",
  "app.offers.booking.error.400x03":
    "Sorry, the appointment is no longer available.",
  "app.offers.bookSuccess": "Successfully booked.",
  "app.offers.course.eventDates": "The appointments in detail",
  "app.offers.course.notAvailable":
    "Sorry, the selected course period is already fully booked.",
  "app.offers.course.booked": "You have already booked this course.",
  "app.offers.course.date": "Course period",
  "app.offers.noNote": "No Notes",
  "app.offers.info": "Your information for the trainer/company physician",
  "app.offers.noinLimit":
    "You don’t meet the restrictions that apply to this offer",
  "app.home.nextDatePhrase": "from {from} – {to} o’clock",
  "app.home.welcome": "Welcome",
  "app.home.allAppointments": "All appointments",
  "app.home.manageCredit": "My budget",
  "app.home.widget.nextDate.button": "My appointments",
  "app.home.noAppointmentsToday": "No appointments today",
  "app.home.noNextDate": "no upcoming appointment",
  "app.password.reset": "Forgotten your password?",
  "app.password.set.error.default": "Not possible to set password",
  "app.password.set.error.400x01": "Please enter a password.",
  "app.password.set.error.400x02":
    "Sorry, the new password doesn’t meet the requirements.",
  "app.password.set.error.400x03":
    "The link to set the new password has expired.",
  "app.password.set.emptyUsername": "User name must not be blank.",
  "app.account.passwordHint":
    "The password must consist of at least 10 characters with one upper and lower case letter, a number and a special character ($@!%*#?&_.-).",
  "app.account.usernameHint":
    "Your user name must consist of at least six characters and may only contain letters, numbers and the following special characters -._",
  "app.password.reset.error.default": "Could not reset password.",
  "app.password.reset.error.400x01":
    "Please contact Support to reset your password.",
  "app.password.reset.error.emptyUsername": "User name must not be blank.",
  "app.password.reset.error.emptyEmail": "E-mail must not be blank.",
  "app.password.reset.success":
    "A link to reset your password has been sent to your registered e-mail address.",
  "app.password.set.fail": "Sorry, an error occurred. Please try again.",
  "app.password.set.success": "The new password has been set.",
  "app.password.set": "Set password",
  "app.password.reset.username": "User name",
  "app.password.reset.email": "E-mail address",
  "app.password.reset.password": "Password",
  "app.appointments.title": "My appointments",
  "app.appointments.selectTimeSlot": "Please choose another time",
  "app.appointments.save": "Save",
  "app.appointments.updated": "The appointment was changed.",
  "app.appointments.update.error.default": "Unable to update appointment.",
  "app.appointments.update.error.403": "Access denied.",
  "app.appointments.update.error.404": "The appointment booking was not found.",
  "app.appointments.update.error.400x00": "Sorry, an error occurred.",
  "app.appointments.update.error.400x02":
    "An appointment in the past can’t be changed.",
  "app.appointments.update.error.400x03":
    "Sorry, the appointment is no longer available.",
  "app.appointments.update.error.400x05":
    "Your current credit balance is not sufficient.",
  "app.appointments.update.error.400x06":
    "Your current budget is not sufficient.",
  "app.appointments.update.error.400x07":
    "Please enter your IBAN and BIC first under",
  "app.appointments.update.error.400x09":
    "You have reached the maximum number of appointments for the day.",
  "app.appointments.appointment": "Appointment",
  "app.appointments.therapist": "With:",
  "app.appointments.note": "Note",
  "app.appointments.noAppointments":
    "You currently have no internal appointments booked",
  "app.appointments.paymentMethod": "Payment method",
  "app.appointments.paymentMethod.INVOICE": "Invoice",
  "app.appointments.paymentMethod.AGENT": "Agent",
  "app.appointments.paymentMethod.DIRECT_DEBIT": "Direct debit",
  "app.appointments.paymentMethod.CASH": "Cash",
  "app.appointments.paymentMethod.SUBVENTION": "Budget",
  "app.appointments.paymentMethod.CREDIT": "Credit",
  "app.appointments.roomInfo": "Room/meeting place:",
  "app.appointments.roomInfo.COURSE": "Course",
  "app.appointments.roomInfo.EVENT": "Event",
  "app.appointments.roomInfo.DEFAULT": "Appointment",
  "app.appointments.guestInfo": "Comment",
  "app.appointments.binding": "Reservation binding",
  "app.appointments.binding.NO_BINDING": "non-binding",
  "app.appointments.binding.ONE_DAY": "Reservation up to one day before",
  "app.appointments.binding.RESERVATION_FOUR_HOURS":
    "Reservation up to four hours before",
  "app.appointments.binding.RESERVATION_ONE_HOUR":
    "Reservation up to one hour before",
  "app.appointments.editAppointment": "Edit appointment",
  "app.appointments.editAppointment.noTimeSlots": "No slots available",
  "app.appointments.editAppointment.selectTimeSlot": "Please select a timeslot",
  "app.appointments.cancelAppointment": "Cancel appointment",
  "app.appointments.cancelReservation": "Cancel reservation",
  "app.appointments.cancelAppointment.error.default":
    "Sorry, it wasn’t possible to cancel the appointment.",
  "app.appointments.cancelAppointment.error.403": "Access denied.",
  "app.appointments.cancelAppointment.error.404":
    "The appointment booking is unknown.",
  "app.appointments.cancelAppointment.error.400x00":
    "Sorry, an error occurred.",
  "app.appointments.cancelAppointment.error.400x01":
    "Appointments in the past can’t be changed.",
  "app.appointments.confirmCancelAppointment":
    "Do you really want to cancel the appointment?",
  "app.appointments.confirmCancelReservation":
    "Do you really want to cancel the reservation?",
  "app.appointments.editCourse": "Edit course booking",
  "app.appointments.cancelCourse": "Cancel course booking",
  "app.appointments.cancelCourse.error.default":
    "Sorry, it wasn’t possible to cancel the series.",
  "app.appointments.cancelCourse.error.403": "Access denied.",
  "app.appointments.cancelCourse.error.404": "The series booking is unknown.",
  "app.appointments.cancelCourse.error.404x01":
    "No bookings were found for this series.",
  "app.appointments.cancelCourse.error.400x00": "Sorry, an error occurred.",
  "app.appointments.cancelCourse.error.400x01":
    "Appointments in the past can’t be changed.",
  "app.appointments.paymentMethod.default": "Select payment method",
  "app.appointments.monday": "Monday",
  "app.appointments.tuesday": "Tuesday",
  "app.appointments.wednesday": "Wednesday",
  "app.appointments.thursday": "Thursday",
  "app.appointments.friday": "Friday",
  "app.appointments.saturday": "Saturday",
  "app.appointments.sunday": "Sunday",
  "app.appointments.course.bookedUp":
    "Sorry, the course is already fully booked",
  "app.appointments.course.bookedByYou": "You have already booked this course",
  "app.budget.title": "My budget",
  "app.budget.currentBudget": "Current budget",
  "app.budget.overview": "Budget overview",
  "app.budget.submittedReceipts": "Receipts submitted",
  "app.budget.of": "from",
  "app.budget.points": "Points",
  "app.budget.noBudget": "You haven’t spent any of your budget yet.",
  "app.budget.noRefund": "You haven’t submitted a receipt yet.",
  "app.budget.state.inProgress.header": "being processed",
  "app.budget.state.rejectedRequirements.header": "rejected",
  "app.budget.state.rejectedSubventionLimit.header": "rejected",
  "app.budget.state.rejectedUnknownOffer.header": "rejected",
  "app.budget.state.refund.header": "paid out",
  "app.budget.state.approved.header": "approved",
  "app.budget.state.inProgress.content": "The document is being processed",
  "app.budget.state.rejectedRequirements.content":
    "Sorry, your document doesn’t meet the eligibility criteria.",
  "app.budget.state.rejectedSubventionLimit.content":
    "Sorry, your budget for this period has been used up.",
  "app.budget.state.rejectedUnknownOffer.content":
    "Sorry, your document could not be assigned to an offer from the sponsorship catalogue.",
  "app.budget.state.refund.content": "The amount was paid out.",
  "app.budget.state.approved.content":
    "Your refund has been approved and will be paid.",
  "app.icon.title.home": "Your current budget is not sufficient.",
  "app.test.test.test": "this is a test",
  "app.modal.close": "close",
  "app.logo.alt": "Health Budget",
  "app.login.legal.title": "All things legal",
  "app.account.username": "User name",
  "app.account.password": "New password",
  "app.account.save": "Save",
  "app.account.saved": "The data has been saved.",
  "app.account.bank.help":
    "You’re wondering why we need your account details?\r\nOnce you have chosen an external health partner and initiated a refund on the portal, we will need a bank account to which we can credit your expense.",
  "app.calendar.help": "Only the next six weeks are displayed in the calendar.",
  "app.calendar.help.booked": "You have already booked this date.",
  "app.staticcontent.close": "Ok",
  "app.offers.extern.price": "Price",
  "app.offers.extern.note": "Note for the provider",
  "app.offers.extern.privacy": "Declaration of consent",
  "app.offers.extern.error.default":
    "Sorry, an error occurred. Please try again later.",
  "app.offers.extern.error.403": "The current authorisation is not sufficient.",
  "app.offers.upload.error.400x01":
    "Sorry, it wasn’t possible to process your upload: please attach a PDF or JPG file.",
  "app.offers.upload.error.400x04":
    "Sorry, it wasn’t possible to process your upload: please only upload files with max. 5MB",
  "app.offers.upload.error.400x05":
    "Sorry, it wasn’t possible to process your upload: the image format is not supported.",
  "app.offers.upload.error.400x06":
    "Sorry, it wasn’t possible to process your upload: encrypted PDFs are not currently supported.",
  "app.offers.upload.error.500x01":
    "Sorry, an error occurred when processing your document. Please contact the Service Team.",
  "app.offers.upload.error.500x02":
    "Sorry, an error occurred when processing your document. Please contact the Service Team.",
  "app.offers.price.subvention.tooltip":
    "The points will be debited directly to your budget.",
  "app.offers.price.course.subvention.tooltip":
    "As you are booking a series, the total number of points for all appointments will be debited directly to your budget.",
  "app.offers.price.refund.price.tooltip":
    "You will be debited exactly as many points as your training programme/course costs externally in euros.",
  "app.offers.date.reserve.text":
    "Sorry, this appointment is already booked! Would you like to be put on the waiting list?",
  "app.offers.booking.error.400x09":
    "You have reached the maximum number of appointments for the day.",
  "app.offers.booking.error.400x05":
    "Your current credit balance is not sufficient.",
  "app.offers.booking.error.400x06": "Your current budget is not sufficient.",
  "app.offers.booking.error.400x07": "Please enter your bank details.",
  "app.offers.booking.error.400x10": "The conditions of the offer are not met.",
  "app.offers.booking.error.403":
    "The session has expired. Please log in again.",
  "app.offers.booking.error.500":
    "Sorry, an error occurred during the booking process. Please try again later.",
  "app.offers.times": "Time",
  "app.faq.title": "FAQ",
  "app.faq.search.placeholder": "Search...",
  "app.survey.questionnaire.question.bodyzones.label.empty": "please click",
  "app.survey.questionnaire.question.bodyzones.label.green": "no complaints",
  "app.survey.questionnaire.question.bodyzones.label.yellow": "rare complaints",
  "app.survey.questionnaire.question.bodyzones.label.red":
    "frequent complaints",
  "app.survey.questionnaire.question.bodyzones.label.back": "Back",
  "app.survey.questionnaire.question.bodyzones.label.front": "Front",
  "app.survey.title": "Survey",
  "app.survey.start": "more",
  "app.survey.code.error.auto":
    "Sorry, an error occurred during loading.<br />Please try again later.",
  "app.survey.error.null":
    "Sorry, an error occurred during loading.<br />Please try again later.",
  "app.survey.questionnaire.error": "Error!",
  "app.survey.questionnaire.error.403x01":
    "Sorry, an error occurred. Please reload the page and try again.",
  "app.survey.questionnaire.error.403x02":
    "Sorry, an error occurred. Please reload the page and try again.",
  "app.survey.questionnaire.error.403x03": "Sorry, your code has expired.",
  "app.survey.questionnaire.error.403x04":
    "Sorry, an error occurred. Please reload the page and try again.",
  "app.survey.questionnaire.error.400":
    "Please check the details you have entered.",
  "app.survey.questionnaire.error.default":
    "Sorry, an error occurred.<br />Please try again.",
  "app.survey.questionnaire.question.yes": "yes",
  "app.survey.questionnaire.question.no": "no",
  "app.survey.questionnaire.error.required": "Please complete your entry.",
  "app.survey.vote.error.null":
    "Sorry, an error occurred during loading.<br />Please try again later.",
  "app.survey.vote.error.key":
    "Sorry the link used is invalid. <br />Please check the link and try again.",
  "app.survey.vote.error.403":
    "Sorry, the link used is invalid or has expired.",
  "app.survey.vote.error.400": "You have already voted on this survey.",
  "app.survey.questionnaire.next": "more",
  "app.survey.questionnaire.question.text.placeholder": "...",
  "app.survey.questionnaire.question.longtext.placeholder": "...",
  "app.survey.questionnaire.question.number.placeholder": "0",
  "app.survey.questionnaire.question.dropdown.placeholder": "please select",
  "app.survey.questionnaire.tooltip.incomplete":
    "Please complete the questionnaire first.",
  "app.survey.questionnaire.progress": "approx. {minutes} minutes remaining",
  "app.survey.questionnaire.progress.finished": "completed",
  "app.account.basicData.headline": "Contact details",
  "app.account.accessData.error": "Access data",
  "app.account.privateEmail": "Private e-mail",
  "app.account.enablePrivateEmail": "Send e-mails to private address",
  "app.account.accessData.headline": "Access data",
  "app.account.passwordRepeat": "Password (repetition)",
  "app.account.lastLogins": "Your last five log-ins",
  "app.account.changeUsername": "Change user name",
  "app.account.changePassword": "Change password",
  "app.account.invalidUsername": "The user name contains invalid characters.",
  "app.account.invalidPassword": "The password doesn’t meet the requirements.",
  "app.account.invalidPasswordRepeat": "The passwords don’t match.",
  "app.account.invalidIban": "The IBAN is invalid.",
  "app.account.invalidBic": "The BIC is invalid.",
  "app.account.blockMessage":
    "The data has not yet been saved. Are you sure you wish to continue?",
  "app.account.usernameChanged": "Your user name has been changed.",
  "app.account.passwordChanged": "Your password has been changed.",
  "app.account.emailSettingsSaved": "Settings saved.",
  "app.offers.useExternal": "To the booking",
  "app.offers.extern.serviceProvider": "Provider",
  "app.appointments.confirmCancelCourse":
    "Do you really want to unsubscribe from the whole series?",
  "app.budget.state.rejectedDuplicate.header": "rejected",
  "app.budget.state.rejectedDuplicate.content":
    "The document has already been submitted.",
  "app.home.widget.companyEvent.button": "to the event",
  "app.home.widget.companyEvent.headline": "A selection:",
  "app.survey.questionnaire.question.unknownType":
    "Sorry, an error occurred. Please try again later.",
  "app.navigation.imprint.title": "Legal notice",
  "app.navigation.privacy.title": "Privacy Policy",
  "app.navigation.faq.title": "FAQ",
  "app.navigation.contact.title": "Contact",
  "app.navigation.about.title": "About the Health Budget",
  "app.navigation.pro54.title": "About pro54",
  "app.navigation.home.title": "Home",
  "app.navigation.offers.title": "Offers",
  "app.navigation.calendar.title": "Calendar",
  "app.navigation.appointments.title": "My appointments",
  "app.navigation.subventions.title": "My budget",
  "app.navigation.news.title": "News",
  "app.navigation.account.title": "My account",
  "app.global.logout": "Log-out",
  "app.global.backToLogin": "Back to login",
  "app.home.widget.nextDate.title": "My next appointment",
  "app.home.widget.categories.title": "Offers:",
  "app.home.widget.highlightEvents.title": "Currently available",
  "app.home.widget.highlightEvents.button": "My offers",
  "app.home.widget.budget.title": "My budget",
  "app.home.widget.budget.button": "Manage budget",
  "app.home.widget.todayEvents.title": "Current appointments",
  "app.home.widget.todayEvents.button": "To the calendar",
  "app.home.widget.nextDate.button.offers": "to the offers",
  "app.home.widget.highlightEvents.button.book": "book",
  "app.home.widget.credit.title": "My credit",
  "app.home.widget.credit.minutes": "Min.",
  "app.bonus.collect.collegues.title": "Recruit colleagues",
  "app.bonus.collect.collegues.hint":
    "Get bonus points if a colleague registers and then again after their first appointment!",
  "app.bonus.collect.birthday.title": "Birthday bonus",
  "app.bonus.collect.birthday.hint": "Get 125 bonus points for your birthday!",
  "app.navigation.bonus.title": "My rewards",
  "app.bonus.title": "My rewards",
  "app.bonus.nav.shop": "Shop for rewards",
  "app.bonus.nav.collections": "Track history",
  "app.bonus.nav.conversions": "View orders",
  "app.bonus.nav.collect": "Collect points",
  "app.bonus.availablePoints": "Points available",
  "app.bonus.points": "Points",
  "app.bonus.noCollections": "You have not collected any bonus points yet.",
  "app.bonus.noConversions": "You have not redeemed any points yet.",
  "app.bonus.shop.buy": "redeem",
  "app.bonus.shop.category1000": "up to 1,000 points",
  "app.bonus.shop.category2000": "up to 2000 points",
  "app.bonus.shop.category3000": "up to 3000 points",
  "app.bonus.shop.category4000": "up to 4000 points",
  "app.bonus.shop.category5000": "up to 5000 points",
  "app.bonus.shop.category6000": "up to 6000 points",
  "app.bonus.shop.category7000": "up to 7000 points",
  "app.bonus.shop.category8000": "up to 8000 points",
  "app.bonus.shop.category9000": "up to 9000 points",
  "app.bonus.shop.category10000": "from 9,000 points",
  "app.bonus.shop.confirm":
    "Would you like to redeem your bonus points for the chosen reward?",
  "app.bonus.shop.convert": "redeem",
  "app.global.abort": "quit",
  "app.bonus.shop.success": "The reward has been successfully ordered!",
  "app.bonus.shop.notAvailable":
    "Sorry, you don’t currently have enough points for the reward.",
  "app.bonus.widget.label.points": "Current score: ",
  "app.bonus.widget.label.shop": "Shop for rewards",
  "app.bonus.widget.label.collect": "Collect points",
  "app.bonus.widget.label.collegues": "Recommend colleagues",
  "app.home.widget.bonus.title": "My bonus points",
  "app.home.widget.bonus.button": "redeem now",
  "app.navigation.more.title": "More",
  "app.offers.price.total": "Points",
  "app.offers.price.price.tooltip": "Sorry, you lack this many points",
  "app.offers.price.total.tooltip": "This is what your offer costs",
  "app.login.register": "No access yet?",
  "app.register.title": "Registration",
  "app.register.registerKey.label":
    "Step 1 of 3 – Enter your registration password:",
  "app.register.registerKey.hint":
    "You can find the password on the intranet or obtain it from your BGM contact person",
  "app.register.registerKey.submit": "check",
  "app.register.registerKey.placeholder": "Password",
  "app.register.registerKey.error":
    "Sorry, your password is invalid. Please check the link or your entry!",
  "app.register.identifier.label": "Step 2 of 3 – Enter your e-mail address:",
  "app.register.identifier.hint":
    "Only one company address is valid to check your eligibility. You can enter a private e-mail address later.",
  "app.register.identifier.submit": "check",
  "app.register.identifier.placeholder": "ihr.name@firma.com",
  "app.register.identifier.error":
    "Sorry, your e-mail address is not approved. Please check your entry or get in touch with your BGM contact person",
  "app.register.registerForm.label":
    "Step 3 of 3 – Enter your personal details:",
  "app.register.registerForm.submit": "register",
  "app.register.registerKey.error.404":
    "Sorry, your password is invalid. Please check the link or your entry!",
  "app.register.registerForm.group.company": "To the company",
  "app.register.registerForm.group.personal": "Your personal details",
  "app.register.registerForm.group.contact": "To contact",
  "app.register.registerForm.group.account": "To log-in",
  "app.register.registerForm.group.privacyPolicy": "Protecting your data",
  "app.register.registerForm.emailJob.label": "Company e-mail address",
  "app.register.registerForm.firstname.label": "First name",
  "app.register.registerForm.firstname.placeholder": "First name",
  "app.register.registerForm.lastname.label": "Last name",
  "app.register.registerForm.lastname.placeholder": "Last name",
  "app.register.registerForm.gender.label": "Title",
  "app.register.registerForm.gender.male": "Mr",
  "app.register.registerForm.gender.female": "Ms",
  "app.register.registerForm.companyId.label": "Company",
  "app.register.registerForm.locationId.label": "Site",
  "app.register.registerForm.username.hint":
    "Please choose a user name. Letters, numbers and the characters .-_ are permitted.",
  "app.register.registerForm.username.label": "User name",
  "app.register.registerForm.username.placeholder": "User name",
  "app.register.registerForm.password.hint":
    "The password must contain 10 characters, an upper case letter, a lower case letter, a number and a special character.",
  "app.register.registerForm.password.label": "Password",
  "app.register.registerForm.password.placeholder": "Password",
  "app.register.registerForm.passwordRepeat.hint":
    "Please repeat your password.",
  "app.register.registerForm.passwordRepeat.label": "Password repetition",
  "app.register.registerForm.passwordRepeat.placeholder": "Password",
  "app.register.registerForm.phoneJob.hint":
    "Please enter your extension number only.",
  "app.register.registerForm.phoneJob.label": "Extension",
  "app.register.registerForm.phoneJob.placeholder": "Extension",
  "app.register.registerForm.mobile.hint":
    "Would you like to receive notifications by text message at short notice? If so, enter a German mobile phone number here.",
  "app.register.registerForm.mobile.label": "Mobile",
  "app.register.registerForm.mobile.placeholder": "Mobile phone number",
  "app.register.registerForm.privacyPolicy.label":
    "I have read and accepted the Privacy Policy.",
  "app.register.registerForm.privacyPolicy.hint":
    "Click on the link to go to the consent form.",
  "app.register.registerForm.error.passwordRepeat":
    "The passwords you have entered don’t match.",
  "app.register.registerForm.success.title": "Registration successful!",
  "app.register.registerForm.success.content":
    "You have successfully registered.<br />In the next few minutes you will receive an e-mail enabling you to activate your account.",
  "app.register.registerForm.error.400x04": "This user name is not available.",
  "app.register.registerForm.error.500":
    "Please try again later or contact our Service Team.",
  "app.register.registerForm.error.400x00":
    "Please check your entries are valid",
  "app.register.registerForm.error.500x01":
    "Sorry, there are problems with your account. Please contact our Service Team.",
  "app.password.set.title": "Set new password",
  "app.password.reset.title": "Forgotten your access data?",
  "app.bonus.shop.error.400x00": "Please check the details you have entered.",
  "app.bonus.shop.error.400x01":
    "Sorry, the selected reward is no longer available.",
  "app.bonus.shop.error.400x02": "Please enter your postal address first.",
  "app.bonus.shop.error.400x03": "Sorry, you don’t have enough points.",
  "app.bonus.shop.deliverAddress": "Delivery address:",
  "app.bonus.shop.deliver.town": "Town/city",
  "app.bonus.shop.deliver.zip": "Postcode",
  "app.bonus.shop.deliver.street": "Street",
  "app.bonus.shop.imediately": "The reward is available right away.",
  "app.bonus.collect.birthday.existsHint":
    "You have already entered your date of birth and can look forward to the extra points!",
  "app.bonus.collect.birthday.save": "enter",
  "app.bonus.collect.recommand.contact": "Contact details of your colleague:",
  "app.bonus.collect.recommand.emailTo.label": "Company e-mail address:",
  "app.bonus.collect.recommand.nameTo.label": "Name:",
  "app.bonus.collect.recommand.message": "Your personal message:",
  "app.bonus.collect.recommand.preview": "Preview:",
  "app.bonus.collect.recommand.preview.legal": "*DISCLAIMER*",
  "app.bonus.collect.recommand.preview.link": "*LINK*",
  "app.bonus.collect.recommand.preview.salutation": "Hello",
  "app.bonus.collect.recommand.preview.greeting": "Kind regards,",
  "app.bonus.collect.recommand.success": "Your recommendation has been sent.",
  "app.bonus.collect.recommand.error":
    "Sorry, an error occurred. Please check the details you have entered.",
  "app.bonus.collect.recommend.send": "send",
  "app.bonus.collect.recommand.message.template":
    "are you familiar with our company’s great corporate health management scheme? Register now!",
  "app.account.accounting": "Account details",
  "app.account.accounting.sepaMandate": "SEPA direct debit mandate",
  "app.account.accounting.sepaMandate.identifier":
    "Creditor identification number:",
  "app.account.accounting.sepaMandate.reference": "Mandate reference:",
  "app.account.accounting.sepaMandate.referenceTodo":
    "will be communicated on issue",
  "app.account.accounting.sepaMandate.text":
    "I authorise wellfeelin GmbH to collect payments from my account by direct debit. At the same time, I instruct my bank to honour the direct debits drawn on my account by wellfeelin GmbH. The period for pre-notification is reduced to five days.",
  "app.account.accounting.sepaMandate.create": "Issue direct debit mandate",
  "app.account.accounting.sepaMandate.cancel": "Revoke direct debit mandate",
  "app.account.accounting.sepaMandate.canceled":
    "The direct debit mandate has been revoked as of today.",
  "app.account.sepaMandate.error.400x00":
    "The mandate has already been revoked.",
  "app.account.sepaMandate.error.account":
    "Please fill in the account details first.",
  "app.account.accounting.sepaMandate.doCancellations":
    "Fees for short-term appointment cancellations are to be debited to my account. ",
  "app.account.accounting.cancellationFees": "Cancellation fees",
  "app.appointments.reservation": "Reservation",
  "app.offers.price.count": "Limitation",
  "app.offers.price.count.tooltip": "Limitation for the current subsidy",
  "app.offers.price.count.fac": "x",
  "app.offers.price.count.once": "one-off",
  "app.global.yes": "Yes",
  "app.global.no": "No",
  "app.account.mobileJob": "(phone number)",
  "app.account.mobileProviderJob": "Mobile (area code)",
  "app.offers.price.valid": "Valid",
  "app.offers.price.valid.from": "from",
  "app.offers.price.valid.to": "to",
  "app.appointments.futureAppointments": "My future appointments",
  "app.appointments.pastAppointments": "My past appointments",
  "app.appointments.prescriptions": "My prescriptions",
  "app.navigation.guestcards.title": "My credit",
  "app.guestcards.title": "My credit",
  "app.guestcards.nav.list": "My purchases",
  "app.guestcards.empty": "You have not yet acquired any credit.",
  "app.guestscards.label.salesDate": "Sold on:",
  "app.guestscards.label.used": "redeemed:",
  "app.guestscards.label.blockDate": "valid until:",
  "app.prescriptions.noPrescriptions":
    "You haven’t yet submitted a private prescription.",
  "app.prescriptions.upload.button": "Submit private prescription",
  "app.prescriptions.upload.title": "Submit private prescription",
  "app.prescriptions.upload.prescriptionText.label": "Service:",
  "app.prescriptions.upload.prescriptionText.help":
    "Please indicate the form of treatment as noted on the prescription. For several types of treatment, please submit the prescription again separately.",
  "app.prescriptions.upload.prescriptionDate.label": "Prescription date:",
  "app.prescriptions.upload.prescriptionCredit.label": "Quantity:",
  "app.prescriptions.upload.prescriptionCredit.help":
    "Please enter the number of treatment sessions prescribed for this type of treatment.",
  "app.prescriptions.upload.file.help":
    "Max. files up to 5 MB, PDF or JPG/JPEG only.",
  "app.prescriptions.upload.submit": "submit",
  "app.prescriptions.upload.error.transmission":
    "Sorry, an error occurred during transmission. Please try again later.",
  "app.prescriptions.upload.successful":
    "Your prescription has been successfully transferred.",
  "app.prescriptions.checked.label": "Checked:",
  "app.prescriptions.dates.label": "Dates:",
  "app.prescriptions.rejected": "(rejected)",
  "app.prescriptions.rejectedInfo":
    "Sorry, we had to reject your prescription. Please contact our customer service for details.",
  "app.prescriptions.downloadReceipt": "Download receipt",
  "app.coupon.invoke.button": "Redeem voucher",
  "app.coupon.invoke.title": "Redeem voucher",
  "app.coupon.invoke.code.label": "Code:",
  "app.coupon.invoke.code.help":
    "You will find the code at the bottom of your voucher in the middle.",
  "app.coupon.invoke.code.placeholder": "ABCD-EFGH-IJKL-MNOP",
  "app.coupon.invoke.submit": "redeem",
  "app.coupon.invoke.error.403":
    "Sorry, we don't have a record of this voucher code.",
  "app.coupon.invoke.error.400":
    "Sorry, we don't have a record of this voucher code.",
  "app.coupon.invoke.error.transmission":
    "Sorry, an error occurred during transmission. Please try again later.",
  "app.coupon.invoke.error.processing":
    "Sorry, an error occurred during processing. Please try again later.",
  "app.coupon.invoke.successful":
    "Your voucher has been successfully activated.",
  "app.title": "BGMSuite employee portal",
  "app.appointments.dateTimePhrase": "{day} {date}, at {from} o'clock",
  "app.icon.title.date-edit": "Edit existing appointment.",
  "app.icon.title.reservation-edit": "Edit existing reservation.",
  "app.icon.title.prescription-new":
    "The prescription is available for review.",
  "app.icon.title.prescription-download":
    "The receipt is available for download.",
  "app.icon.title.prescription-checked": "The prescription has been approved.",
  "app.icon.title.prescription-rejected":
    "Sorry, the prescription had to be rejected.",
  "app.offers.price.course.budget": "Points",
  "app.offers.price.course.budget.tooltip":
    "As you are booking a series, the total number of points for all appointments will be debited directly to your budget.",
  "app.offers.price.budget": "Points",
  "app.offers.price.budget.tooltip":
    "This amount of points will be debited directly to your budget.",
  "app.icon.title.64x64_rgb_extern": "external offer",
  "app.icon.title.64x64_rgb_inhouse": "internal offer",
  "app.icon.date-edit": "You already have an appointment in this time slot.",
  "app.home.widget.nextDate.joinOnlineEventButton": "join now",
  "app.home.widget.nextDate.joinOnlineEventButton.hintCanJoin":
    "Join the online training programme now!",
  "app.home.widget.nextDate.joinOnlineEventButton.hintCantJoin":
    "It is not yet possible to join the online training programme ...",
  "app.onlineMeeting.title": "Online event",
  "app.onlineMeeting.error.403":
    "You don’t have permission to join this meeting.",
  "app.onlineMeeting.error.404": "The requested meeting was not found.",
  "app.onlineMeeting.error.default":
    "An error occurred while entering the meeting.<br />Please try again later.",
  "app.icon.title.more": "Show more",
  "app.offer.search.empty":
    "Sorry, no offers were found to match your search “{search}”.",
  "app.offers.filter.title": "Filter",
  "app.offers.filter.button": "Show results",
  "app.offers.filter.reset": "reset",
  "app.offers.filter.category": "Category",
  "app.offers.filter.price": "Price ",
  "app.offers.filter.price.to": " to",
  "app.offers.filter.price.onlyBudget": "only within my budget",
  "app.offers.more": "load more offers...",
  "app.home.widget.search.title": "Search",
  "app.home.widget.search.placeholder": "Searching ...",
  "app.home.widget.search.button": "go!",
  "app.home.widget.offerBanners.title": "{offer}",
  "app.home.widget.offerBanners.text": "{offer} – find out more now!",
  "app.budget.state.waiting.header": "waiting for feedback",
  "app.budget.state.waiting.content":
    "Your feedback is required for further processing. Please check your e-mails or contact us directly.",
  "app.icon.title.filter": "Filter offers",
  "app.offer.filter.empty":
    "Sorry, no offers were found to match your filter criteria.",
  "app.offer.searchFilter.empty":
    "Sorry, no offers were found to match your search “{search}” and your filter criteria.",
  "app.offers.upload.duration": "Learning time",
  "app.offers.upload.duration.help":
    "Please state your learning time in minutes.",
  "app.offers.upload.serviceProvider": "Provider",
  "app.offers.upload.serviceProvider.empty": "Other",
  "app.offers.upload.serviceProvider.pleaseChoose": "please select",
  "app.offers.upload.serviceProvider.required":
    "Please select a provider or set the selection to “Other”",
  "app.offers.upload.attributes.required":
    "Please select one or more of the attributes.",
  "app.offers.upload.error.400x07":
    "Sorry, it wasn’t possible to process your upload: Please check the details you have entered.",
  "app.home.title": "Home",
  "app.offers.notAvailableNotice.button": "more information",
  "app.offers.notAvailableNotice.title": "Currently not available",
  "app.offers.notAvailableNotice.text":
    "This offer is currently not available..",
  "app.offers.upload.receiptTitle.default": "{offer}",
  "app.offers.upload.receiptTitle.placeholder": "please enter",
  "app.offers.upload.attributes.help": "Click here for the relevant options.",
  "app.offers.upload.file": "Document",
  "app.offers.upload.error.transmission":
    "Sorry, an error occurred during transmission. Please check your network connection.",
  "app.offers.upload.error.processing":
    "Sorry, an error occurred while processing your document. Please contact the Service Team.",
  "app.global.note": "Note",
  "app.offers.extern.privacyPolicyAccepted":
    "I have read and accepted the Privacy Policy.",
  "app.offers.extern.error.privacyPolicyAccepted":
    "Please give your consent to the Privacy Policy.",
  "app.offers.extern.error.selectPayment":
    "Your current budget is not sufficient.",
  "app.offers.extern.error.budgetLeft":
    "Unfortunately your budget is not sufficient for this offer",
  "app.offers.extern.error.400x01": "Please select a valid payment method.",
  "app.offers.extern.bookingDate": "Booked on",
  "app.offers.extern.state": "Status",
  "app.offers.extern.state.BOOKED": "transmitted",
  "app.offers.extern.state.RUNNING": "confirmed",
  "app.offers.extern.state.CANCELED": "cancelled",
  "app.offers.extern.state.FINISHED": "ends",
  "app.offers.extern.info.BOOKED": "Booked until: ",
  "app.offers.extern.variants.BgmConceptTherapyOfferExternVariantEventOnline":
    "Online events",
  "app.offers.extern.variants.BgmConceptTherapyOfferExternVariantEventLocation":
    "Face-to-face events",
  "app.offers.extern.variants.begin": "Start",
  "app.offers.extern.variants.end": "Finish",
  "app.offers.extern.variants.platform": "Platform",
  "app.offers.extern.variants.place": "Town/city",
  "app.offers.extern.variants.name": "Name",
  "app.offers.upload.attributes.pleaseChoose": "please select",
  "app.offers.upload.error.422":
    "Sorry, it wasn’t possible to process your upload: please attach a PDF or JPG file.",
  "app.offers.global.error.402":
    "You don’t currently have a valid booking for this offer. Please book the offer to continue.",
  "app.offers.comment": "Message",
  "app.offers.upload.comment.placeholder":
    "Here you can add a message to your upload...",
  "app.appointments.paymentMethod.error.sepaMandateRequired":
    'A SEPA direct debit mandate is required for booking. Please set this up first under "My account".',
  "app.password.request": "Request new password",
  "app.password.forgotUsername": "Forgotten your user name?",
  "app.password.reset.email.help":
    "Please note: If you have entered a private e-mail address in your account, you must use the same address here.",
  "app.password.or": "OR",
  "app.password.reset.support":
    "mailto:email@gebu.bgm.services?subject=Benutzername%20vergessen&body=Hallo%20GeBu%20Team%2C%0D%0Aich%20habe%20leider%20meine%20Zugangsdaten%20vergessen.%0D%0ABitte%20sendet%20mir%20eine%20E-Mail%20mit%20meinem%20Benutzernamen%20und%20einem%20Link%20zum%20Zur%C3%BCcksetzen%20des%20Passwortes.%0D%0A%0D%0ADanke%20und%20viele%20Gr%C3%BC%C3%9Fe%0D%0A%0D%0A%0D%0A",
  "app.login.loginErrorTooManyAttempts": "",
  "app.offers.extern.variants.BgmConceptTherapyOfferExternVariantCustom":
    "Options",
  "app.offers.extern.variants.custom": "Option",
  "app.offers.extern.variants.date": "Date",
  "app.offers.extern.variants.dateBegin": "Start:",
  "app.offers.extern.variants.dateEnd": "Finish:",
  "app.offers.extern.variants.allPlaces": "all towns/cities",
  "app.offers.extern.variants.location": "location",
  "app.offers.extern.variants.maps": "Show in Google Maps",
  "app.offers.extern.variants.details": "Details",
  "app.offers.extern.variants.online": "online",
  "app.offers.extern.variants.sortBy": "Sort by",
  "app.offers.price.course.price.tooltip": "",
  "app.password.reset.username.help":
    "If you’ve forgotten your user name, just click on the button at the bottom of the page.",
  "app.password.set.error.403":
    "Please contact Support to reset your password.",
  "app.password.reset.error.403":
    "Please contact Support to reset your password.",
  "app.password.reset.error.403x01":
    "A new password can be requested in 2 hours.",
  "app.register.registerForm.firstname.hint": "",
  "app.register.registerForm.lastname.hint": "",
  "app.register.registerForm.gender.hint": "",
  "app.register.registerForm.companyId.hint": "",
  "app.register.registerForm.locationId.hint": "",
  "app.password.renew.title": "Set new password",
  "app.password.renew.toLogin": "Back to login",
  "app.password.renew.text":
    "For security reasons, it is necessary to update your password regularly. Please create a new password.",
  "app.password.renew.expire.title": "Your password will expire soon",
  "app.password.renew.expire.text":
    "For security reasons, it is necessary to update your password regularly. ",
  "app.password.renew.expire.changePassword": "Change now",
  "app.password.renew.expire.ignore": "Later",
};
