import React from "react";
import { Dropdown as BSDropdown } from "react-bootstrap";
import styled from "styled-components";
import { withLanguage } from "../../context/LanguageContext/withLanguage";
import Conf from "../../Conf";

const Dropdown = styled(BSDropdown)`
  button {
    background-color: transparent;
    border-color: #ccc;
    color: #8a8a8a;
    font-size: 14px;
    padding: 6px;
    padding-left: 30px;
    background-image: url(${Conf.navigation.language.src});
    background-repeat: no-repeat;
    background-position: -20px center;
    background-size: 15px 15px;
    background-origin: content-box;
  }
  button::after {
    vertical-align: 0.2em !important;
  }
  button:hover,
  button:active,
  button.show {
    background-color: #d4d4d4 !important;
    border-color: #8c8c8c !important;
    color: #8a8a8a !important;
  }
`;

const DropdownMenu = styled(BSDropdown.Menu)`
  padding: 0;
`;

const DropdownItem = styled(BSDropdown.Item)`
  padding: 2px 0 2px 0;
  font-size: 14px;
`;

class LanguageSelect extends React.PureComponent {
  render() {
    return (
      <div className="dropup">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdownLanguage">
            {
              this.props.languageContext.availableLanguages.find(
                (availableLanguage) =>
                  availableLanguage.value ===
                  this.props.languageContext.language
              ).caption
            }
          </Dropdown.Toggle>
          <DropdownMenu>
            {this.props.languageContext.availableLanguages.map((language) => (
              <DropdownItem
                onClick={() => {
                  if (this.props.languageContext.language === language.value)
                    return;
                  this.props.languageContext.setLanguage(language.value);
                }}
                key={`option-footer-language-${language.value}`}
              >
                <span
                  style={{
                    width: "15px",
                    marginLeft: "6px",
                    display: "inline-block",
                  }}
                >
                  {this.props.languageContext.language === language.value &&
                    "✓"}
                </span>

                {language.caption}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

export default withLanguage(LanguageSelect);
