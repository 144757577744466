import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";
import {
  CustomRow,
  CustomLeftCol,
  CustomRightCol,
  CustomListGroup,
  SecondLevelListGroupItem,
  Title,
  CustomFaAngleRight,
} from "../../globalComponents/List";
import Layout from "../../components/Layout";
import GuestCardList from "../../components/GuestCards/GuestCardList";
import { Loading } from "../../globalComponents/Loading";
import { GUEST_CARDS_QUERY } from "./GuestCards.graphql";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 0px;
  width: calc(100%);
`;

const CustomListGroupItem = styled(SecondLevelListGroupItem)`
  font-weight: bold;

  &.open {
    background-color: #ffffff !important;
    color: #000000;
  }

  &.open svg {
    transform: rotate(0);
  }
`;

const BudgetOverview = styled("div")`
  border-bottom: 1px solid #b1b1b1;
  font-size: 18px;
  padding: 30px;
`;

export const PAGE_SHOP = "shop";
export const PAGE_COLLECT = "collect";
export const PAGE_COLLECTIONS = "collections";
export const PAGE_CONVERSIONS = "conversions";

class GuestCards extends Component {
  constructor(props) {
    super(props);

    const isMobile = window.innerWidth < 992;

    this.state = {
      contentIsOpen: !isMobile,
    };

    window.addEventListener("resize", this.updateIsMobile.bind(this));
  }

  showList() {
    this.setState({ contentIsOpen: true });
  }

  updateIsMobile() {
    const isMobile = window.innerWidth < 992;
    this.setState((prevState) => ({
      contentIsOpen: prevState.contentIsOpen || !isMobile,
    }));
  }

  render() {
    return (
      <FormattedMessage id="app.guestcards.title">
        {(guestCardsTitle) => (
          <Layout {...this.props} title={guestCardsTitle}>
            <PaddingLessContainer className="guestcards">
              <Helmet title={guestCardsTitle} />
              <CustomRow>
                <CustomLeftCol xs={12} md={12} lg={4}>
                  <CustomListGroup className="list-group">
                    {this.props.data.loading === false &&
                    this.props.data.guest ? (
                      this.props.data.guest.availableCredit.map((credit) => (
                        <BudgetOverview key={credit.therapyOffer.id}>
                          <p>
                            <b>{credit.therapyOffer.title}:</b> {credit.value}x{" "}
                            {credit.therapyOffer.duration}
                            <FormattedMessage id="app.home.widget.credit.minutes" />
                          </p>
                        </BudgetOverview>
                      ))
                    ) : (
                      <Loading />
                    )}
                    <CustomListGroupItem
                      onClick={() => this.showList()}
                      className={this.state.contentIsOpen ? "open" : ""}
                    >
                      <Title>
                        <FormattedMessage id="app.guestcards.nav.list" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomListGroupItem>
                  </CustomListGroup>
                </CustomLeftCol>

                <CustomRightCol
                  xs={12}
                  md={12}
                  lg={8}
                  className={this.state.contentIsOpen ? "open" : ""}
                >
                  <ScrollableDiv className="text-left">
                    <GuestCardList />
                  </ScrollableDiv>
                </CustomRightCol>
              </CustomRow>
            </PaddingLessContainer>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default graphql(GUEST_CARDS_QUERY, {
  options: { fetchPolicy: "network" },
})(injectIntl(GuestCards));
