import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Alert,
  FormControl,
  FormGroup,
  FormLabel,
  Card,
  FormCheck,
  Modal,
} from "react-bootstrap";
import styled from "styled-components";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../CustomBootstrap/Button";
import StaticContent from "../StaticContent";
import Question, { TYPES } from "./Question";

import {
  PRIVACY_POLICY_QUERY,
  REGISTER_GUEST_MUTATION,
} from "./RegisterForm.graphql";

const RegisterFormPanel = styled((props) => <Card {...props} />)`
  border-radius: 8px !important;
  margin: auto;
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
  margin-top: 50px;
  margin-bottom: 50px;

  button {
    margin-top: 25px;
  }
  .privacy-policy {
    cursor: pointer;
    .checkbox span {
      text-decoration: underline !important;
    }
  }
`;

const FieldGroup = styled("div")`
  margin-top: 50px;
`;

class RequestRegisterForm extends Component {
  constructor(props) {
    super(props);
    const loading = false;

    this.updateField = this.updateField.bind(this);
    this.checkRegisterForm = this.checkRegisterForm.bind(this);

    const companies = [
      {
        id: props.registerKey.company.id,
        value: props.registerKey.company.id,
        title: props.registerKey.company.name,
      },
    ];
    const locations = [];
    let selLocation = null;
    if (props.registerKey.location !== null) {
      selLocation = props.registerKey.location.id;
      locations.push({
        id: props.registerKey.location.id,
        value: props.registerKey.location.id,
        title: props.registerKey.location.name,
      });
    } else {
      props.registerKey.company.locations.map((location) => {
        locations.push({
          id: location.id,
          value: location.id,
          title: location.name,
        });
        return location;
      });
      if (locations.length === 1) {
        selLocation = locations[0].id;
      }
    }

    this.state = {
      valid: false,
      errorField: null,
      success: false,
      privacyPolicyAcceptedId: null,
      showPrivacyPolicy: false,
      fieldGroups: {
        company: {
          companyId: {
            id: 1,
            title: "",
            display: {
              type: TYPES.DROPDOWN,
              options: { multi_select: false },
              answers: companies,
            },
            validate: /^[0-9]+$/,
            value: props.registerKey.company.id,
            valid: true,
            requiredAnswers: 1,
            singleRow: true,
            text:
              props.intl.messages[
                "app.register.registerForm.companyId.hint"
              ] !== ""
                ? props.intl.formatMessage({
                    id: "app.register.registerForm.companyId.hint",
                  })
                : null,
          },
          locationId: {
            id: 2,
            title: "",
            display: {
              type: TYPES.DROPDOWN,
              options: { multi_select: false },
              answers: locations,
            },
            validate: /^[0-9]+$/,
            values: { locations },
            value: selLocation,
            valid: selLocation !== null,
            requiredAnswers: 1,
            singleRow: true,
            text:
              props.intl.messages[
                "app.register.registerForm.locationId.hint"
              ] !== ""
                ? props.intl.formatMessage({
                    id: "app.register.registerForm.locationId.hint",
                  })
                : null,
          },
        },
        personal: {
          gender: {
            id: 3,
            title: "",
            display: {
              type: TYPES.DROPDOWN,
              options: { multi_select: false },
              answers: [
                {
                  id: "MALE",
                  value: "MALE",
                  title: props.intl.formatMessage({
                    id: "app.register.registerForm.gender.male",
                  }),
                },
                {
                  id: "FEMALE",
                  value: "FEMALE",
                  title: props.intl.formatMessage({
                    id: "app.register.registerForm.gender.female",
                  }),
                },
              ],
            },
            validate: /^.+$/,
            value: null,
            valid: false,
            requiredAnswers: 1,
            singleRow: true,
            text:
              props.intl.messages["app.register.registerForm.gender.hint"] !==
              ""
                ? props.intl.formatMessage({
                    id: "app.register.registerForm.gender.hint",
                  })
                : null,
          },
          firstname: {
            id: 4,
            title: "",
            display: {
              type: TYPES.TEXT,
              options: {},
            },
            validate: /^[^0-9]{2,}$/,
            value: null,
            valid: false,
            requiredAnswers: 1,
            singleRow: true,
            placeholder: this.props.intl.formatMessage({
              id: "app.register.registerForm.firstname.placeholder",
            }),
            text:
              props.intl.messages[
                "app.register.registerForm.firstname.hint"
              ] !== ""
                ? props.intl.formatMessage({
                    id: "app.register.registerForm.firstname.hint",
                  })
                : null,
          },
          lastname: {
            id: 5,
            title: "",
            display: {
              type: TYPES.TEXT,
              options: {},
            },
            validate: /^[^0-9]{2,}$/,
            value: null,
            valid: false,
            requiredAnswers: 1,
            singleRow: true,
            placeholder: this.props.intl.formatMessage({
              id: "app.register.registerForm.lastname.placeholder",
            }),
            text:
              props.intl.messages["app.register.registerForm.lastname.hint"] !==
              ""
                ? props.intl.formatMessage({
                    id: "app.register.registerForm.lastname.hint",
                  })
                : null,
          },
        },

        contact: {
          phoneJob: {
            id: 6,
            title: "",
            display: {
              type: TYPES.TEXT,
              options: {},
            },
            validate: /^[0-9]+$/,
            value: null,
            valid: true,
            requiredAnswers: 0,
            singleRow: true,
            placeholder: props.intl.formatMessage({
              id: "app.register.registerForm.phoneJob.placeholder",
            }),
            text:
              props.intl.messages["app.register.registerForm.phoneJob.hint"] !==
              ""
                ? props.intl.formatMessage({
                    id: "app.register.registerForm.phoneJob.hint",
                  })
                : null,
          },
          mobile: {
            id: 10,
            title: "",
            display: {
              type: TYPES.TEXT,
              options: {},
            },
            validate: /^[0-9 ]{6,}$/,
            value: null,
            valid: true,
            requiredAnswers: 0,
            singleRow: true,
            placeholder: props.intl.formatMessage({
              id: "app.register.registerForm.mobile.placeholder",
            }),
            text:
              props.intl.messages["app.register.registerForm.mobile.hint"] !==
              ""
                ? props.intl.formatMessage({
                    id: "app.register.registerForm.mobile.hint",
                  })
                : null,
          },
        },
        account: {
          username: {
            id: 7,
            title: "",
            display: {
              type: TYPES.TEXT,
              options: {},
            },
            validate: /^[A-Za-z0-9\-_.]{3,}$/,
            value: null,
            valid: false,
            requiredAnswers: 1,
            singleRow: true,
            placeholder: props.intl.formatMessage({
              id: "app.register.registerForm.username.placeholder",
            }),
            text:
              props.intl.messages["app.register.registerForm.username.hint"] !==
              ""
                ? props.intl.formatMessage({
                    id: "app.register.registerForm.username.hint",
                  })
                : null,
          },
        },
      },
      loading,
      error: null,
    };
  }

  updateField(id, value, valid) {
    this.setState((prevState) => {
      const fields = { ...prevState.fieldGroups };
      let globalValid = true;

      Object.keys(prevState.fieldGroups).map((group) => {
        Object.keys(prevState.fieldGroups[group]).map((key) => {
          if (fields[group][key].id === id) {
            fields[group][key].value = value;
            fields[group][key].valid = valid;
          }
          globalValid = fields[group][key].valid && globalValid;
          return key;
        });
        return group;
      });
      return {
        fieldGroups: fields,
        valid: globalValid,
      };
    });
  }

  checkRegisterForm() {
    if (this.state.valid && this.state.privacyPolicyAcceptedId !== null) {
      this.setState({ loading: true });

      this.props
        .mutate({
          variables: {
            registerKey: this.props.registerKey.keyValue,
            privacyPolicyAcceptedId: this.state.privacyPolicyAcceptedId,
            username: this.state.fieldGroups.account.username.value,
            guest: {
              companyId: this.state.fieldGroups.company.companyId.value,
              locationId: this.state.fieldGroups.company.locationId.value,
              emailJob: this.props.identifier,
              gender: this.state.fieldGroups.personal.gender.value[0],
              firstname: this.state.fieldGroups.personal.firstname.value,
              lastname: this.state.fieldGroups.personal.lastname.value,
              mobileProviderJob:
                this.state.fieldGroups.contact.mobile.value == null
                  ? null
                  : this.state.fieldGroups.contact.mobile.value.substr(0, 4),
              mobileJob:
                this.state.fieldGroups.contact.mobile.value == null
                  ? null
                  : this.state.fieldGroups.contact.mobile.value.substr(4),
              phoneJob: this.state.fieldGroups.contact.phoneJob.value,
            },
          },
        })
        .then((data) => {
          // eslint-disable-next-line no-unused-expressions
          data.data.registerGuest
            ? this.setState({
                success: true,
                loading: false,
              })
            : this.setState({
                loading: false,
                error: "500",
              });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: error.message.match(/[0-9]{3}(x[0-9]+)?/g),
          });
        });
    }
  }

  render() {
    if (this.state.success === true) {
      return (
        <Alert variant="success" style={{ marginTop: "30px" }}>
          <h4>
            <FormattedMessage id="app.register.registerForm.success.title" />
          </h4>
          {React.createElement("p", {
            dangerouslySetInnerHTML: {
              __html: this.props.intl.formatMessage({
                id: "app.register.registerForm.success.content",
              }),
            },
          })}
        </Alert>
      );
    }

    let errorMessage = null;

    if (
      this.state.loading === true ||
      (this.props.data !== undefined && this.props.data.loading === true)
    ) {
      return <LoadingCol />;
    }

    if (this.props.data !== undefined && this.props.data.error) {
      errorMessage = `app.register.registerForm.error.${this.props.data.error.message.match(
        /[0-9]{3}(x[0-9]+)?/g
      )}`;
    } else if (this.state.error !== null) {
      errorMessage = `app.register.registerForm.error.${this.state.error}`;
    }

    return (
      <div className="register-form">
        {errorMessage !== null ? (
          <Alert variant="danger" onDismiss={this.handleDismiss}>
            <h4>
              <FormattedMessage id="app.global.error" />
            </h4>
            {React.createElement("p", {
              dangerouslySetInnerHTML: {
                __html: this.props.intl.formatMessage({ id: errorMessage }),
              },
            })}
          </Alert>
        ) : (
          ""
        )}

        <Modal
          show={this.state.showPrivacyPolicy === true}
          onHide={() => {
            this.setState({ showPrivacyPolicy: false });
          }}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <StaticContent
              noParsing
              staticContent={this.props.data.frontend.employee.privacyPolicy}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid">
              <Button
                onClick={() => {
                  this.setState({ showPrivacyPolicy: false });
                }}
                variant="primary"
                size="lg"
              >
                <FormattedMessage id="app.modal.close" />
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <RegisterFormPanel>
          <Card.Body>
            <form
              onSubmit={(event) => {
                this.checkRegisterForm();
                event.preventDefault();
                return false;
              }}
            >
              <FormLabel>
                <FormattedMessage id="app.register.registerForm.label" />
              </FormLabel>
              <FormGroup
                className="email-job"
                controlId="formBasicText"
                validationState="success"
              >
                <FormLabel>
                  <FormattedMessage id="app.register.registerForm.emailJob.label" />
                </FormLabel>
                <FormControl
                  type="text"
                  value={this.props.identifier}
                  disabled
                />
              </FormGroup>
              {Object.keys(this.state.fieldGroups).map((group) => (
                <FieldGroup key={group} className="register-field-group">
                  <FormLabel>
                    <FormattedMessage
                      id={`app.register.registerForm.group.${group}`}
                    />
                  </FormLabel>
                  {Object.keys(this.state.fieldGroups[group]).map((key) => {
                    const field = this.state.fieldGroups[group][key];
                    return (
                      <Question
                        key={key}
                        question={field}
                        onUpdate={this.updateField}
                        errorMessage={
                          this.state.errorField === key
                            ? `app.register.registerForm.error.${key}`
                            : null
                        }
                      />
                    );
                  })}
                </FieldGroup>
              ))}

              <FieldGroup className="register-field-group">
                <FormLabel>
                  <FormattedMessage id="app.register.registerForm.group.privacyPolicy" />
                </FormLabel>
                <FormGroup
                  className="privacy-policy"
                  controlId="formBasicText"
                  validationState={
                    this.state.privacyPolicyAcceptedId !== null
                      ? "success"
                      : "error"
                  }
                >
                  <FormCheck
                    onChange={() => {
                      // eslint-disable-next-line no-unused-expressions
                      this.state.privacyPolicyAcceptedId !== null
                        ? this.setState({ privacyPolicyAcceptedId: null })
                        : this.setState({
                            privacyPolicyAcceptedId:
                              this.props.data.frontend.employee.privacyPolicy
                                .id,
                            showPrivacyPolicy: true,
                          });
                    }}
                  >
                    <FormattedMessage id="app.register.registerForm.privacyPolicy.label" />
                  </FormCheck>
                  <FormControl.Feedback />
                </FormGroup>
              </FieldGroup>
              <div className="d-grid">
                <Button
                  disabled={
                    !this.state.valid ||
                    this.state.privacyPolicyAcceptedId === null
                  }
                  onClick={this.checkRegisterForm}
                  variant="primary"
                  size="lg"
                >
                  <FormattedMessage id="app.register.registerForm.submit" />
                </Button>
              </div>
            </form>
          </Card.Body>
        </RegisterFormPanel>
      </div>
    );
  }
}

export default compose(
  graphql(PRIVACY_POLICY_QUERY),
  graphql(REGISTER_GUEST_MUTATION)
)(injectIntl(RequestRegisterForm));
