import { gql } from "@apollo/client";

export const PRIORITY_NEWS_QUERY = gql`
  query PriorityNewsQuery {
    guest {
      id
      news {
        id
        priority
        title
        content
      }
    }
  }
`;
export const MARK_GUEST_NEWS_MUTATION = gql`
  mutation markGuestNewsRead($id: ID!) {
    markGuestNewsRead(newsId: $id)
  }
`;
