import React, { PureComponent } from "react";
import styled from "styled-components";

const Cell = styled("div")`
  height: ${(props) => (props.height ? props.height : 400)}px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
`;

const Row = styled("div")`
  height: ${(props) => (props.height ? props.height : 400)}px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  display: table-row;
`;

const Table = styled("div")`
  height: ${(props) => (props.height ? props.height : 400)}px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  display: table;
`;

class VerticallyAlignedMiddle extends PureComponent {
  render() {
    return (
      <Table>
        <Row>
          <Cell>{this.props.children}</Cell>
        </Row>
      </Table>
    );
  }
}

export default VerticallyAlignedMiddle;
