import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class ModalBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      title: "",
      text: "",
    };

    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);
    this.confirmCallback = null;
  }

  // do not remove. called by ref
  // eslint-disable-next-line react/no-unused-class-component-methods
  open(title, text, confirmCallback) {
    this.setState({ showModal: true, title, text });
    this.confirmCallback = confirmCallback;
  }

  confirm() {
    if (this.confirmCallback !== null) this.confirmCallback();

    this.close();
  }

  close() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{this.state.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirm}>
            <FormattedMessage id="app.modal.confirm" />
          </Button>
          <Button onClick={this.close}>
            <FormattedMessage id="app.modal.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalBox;
