import { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import Conf from "../../Conf";

import { MARK_GUEST_NEWS_MUTATION } from "./PriorityNews.graphql";

class PriorityNewsMutation extends Component {
  constructor(props) {
    super(props);

    this.makeNewsRead = this.makeNewsRead.bind(this);
    this.parseError = parseError.bind(this);

    this.makeNewsRead();
  }

  makeNewsRead() {
    this.props
      .mutate({ variables: { id: this.props.id } })
      .then(() => {
        this.props.nextCallback();
      })
      .catch((error) => {
        const errorTextTranslationKey = this.parseError(
          error,
          Conf.errorContext.newsRead
        );

        this.props.onError(error, errorTextTranslationKey);
      });
  }

  componentDidUpdate() {
    this.makeNewsRead();
  }

  render() {
    return null;
  }
}

export default graphql(MARK_GUEST_NEWS_MUTATION)(PriorityNewsMutation);
