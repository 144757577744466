import React, { Component } from "react";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import { AppointmentBookingForm } from "../Booking/AppointmentBookingForm";
import { CourseBookingForm } from "../Booking/CourseBookingForm";

import { BOOK_COURSE_QUERY, BOOK_DATE_QUERY } from "./Book.graphql";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 0px;
  width: calc(100%);
`;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 30px;
`;

class Book extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookType: null,
      eventDayList: null,
      courseList: null,
      showLoader: false,
      employeeBudgetLeft: null,
    };

    this.reload = this.reload.bind(this);
  }

  /** set default information of the view */
  setupView(data) {
    let _data = data;
    if (_data === null || _data === undefined) {
      _data = this.props.data;
    }

    if (_data.bgmConcept.offer.eventDays) {
      this.setState({
        bookType: "bookDate",
        eventDayList: _data.bgmConcept.offer.eventDays,
      });
    }

    if (_data.bgmConcept.offer.courses) {
      this.setState({
        bookType: "bookCourse",
        courseList: _data.bgmConcept.offer.courses,
      });
    }

    if (_data.guest.guestSubventionLimit !== null) {
      this.setState({
        employeeBudgetLeft:
          _data.guest.guestSubventionLimit.value -
          _data.guest.guestSubventionLimit.currentValue,
      });
    }
  }

  componentDidUpdate(prev) {
    if (!this.props.data.loading && prev.data.loading === true) {
      this.setupView();
    }
  }

  reload() {
    this.setState({ showLoader: true });
    this.props.client
      .query({
        query: BOOK_DATE_QUERY,
        variables: { id: this.props.offerId },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        this.setupView(data);
        this.setState({ showLoader: false });
      })
      .catch(() => {
        this.setState({ showLoader: false });
      });
  }

  render() {
    if (this.props.data.loading || this.state.showLoader) {
      return <LoadingCol />;
    }

    let dateEditableUntil = null;
    let courseCancelableUntil = null;
    let reservationEnabled = null;

    /** extract courseCancelableUntil AND dateEditableUntil */
    this.props.data.config.modules.forEach((module) => {
      if (module.dateEditableUntil) {
        dateEditableUntil = module.dateEditableUntil;
      }

      if (module.courseCancelableUntil) {
        courseCancelableUntil = module.courseCancelableUntil;
      }

      if (module.reservationEnabled !== undefined) {
        reservationEnabled = module.reservationEnabled;
      }
    });

    const config = this.props.data.config.modules.filter((w) => w.employee)[0]
      .employee;

    return (
      <ScrollableDiv>
        <Wrapper>
          {this.state.bookType === "bookDate" ? (
            <AppointmentBookingForm
              history={this.props.history}
              couponsEnabled={this.props.enableCoupons}
              config={config}
              eventDayList={this.state.eventDayList}
              therapyOfferId={this.props.therapyOfferId}
              offerId={this.props.offerId}
              employeeBudgetLeft={this.state.employeeBudgetLeft}
              dateEditableUntil={dateEditableUntil}
              reservationEnabled={reservationEnabled}
              reset={this.reload}
            />
          ) : this.state.bookType === "bookCourse" ? (
            <CourseBookingForm
              history={this.props.history}
              config={config}
              courses={this.state.courseList}
              therapyOfferId={this.props.therapyOfferId}
              offerId={this.props.offerId}
              employeeBudgetLeft={this.state.employeeBudgetLeft}
              courseCancelableUntil={courseCancelableUntil}
            />
          ) : (
            ""
          )}
        </Wrapper>
      </ScrollableDiv>
    );
  }
}

export const BookDateComponent = compose(
  withApollo,
  graphql(BOOK_DATE_QUERY, {
    options: (props) => ({
      variables: { id: props.offerId },
      fetchPolicy: "network-only",
    }),
  })
)(injectIntl(Book));

export const BookCourseComponent = compose(
  withApollo,
  graphql(BOOK_COURSE_QUERY, {
    options: (props) => ({
      variables: { id: props.offerId },
      fetchPolicy: "cache-and-network",
    }),
  })
)(injectIntl(Book));
