import React from "react";
import styled from "styled-components";
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Table } from "react-bootstrap";
import Icon from "react-icon-base";

const FaAngleLeft = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m26.5 12.1q0 0.3-0.2 0.6l-8.8 8.7 8.8 8.8q0.2 0.2 0.2 0.5t-0.2 0.5l-1.1 1.1q-0.3 0.3-0.6 0.3t-0.5-0.3l-10.4-10.4q-0.2-0.2-0.2-0.5t0.2-0.5l10.4-10.4q0.3-0.2 0.5-0.2t0.6 0.2l1.1 1.1q0.2 0.2 0.2 0.5z" />
    </g>
  </Icon>
);

const FaAngleRight = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m26.3 21.4q0 0.3-0.2 0.5l-10.4 10.4q-0.3 0.3-0.6 0.3t-0.5-0.3l-1.1-1.1q-0.2-0.2-0.2-0.5t0.2-0.5l8.8-8.8-8.8-8.7q-0.2-0.3-0.2-0.6t0.2-0.5l1.1-1.1q0.3-0.2 0.5-0.2t0.6 0.2l10.4 10.4q0.2 0.2 0.2 0.5z" />
    </g>
  </Icon>
);

const CalendarDay = styled("div")`
  text-align: left;
  font-size: 1.1em;
  color: ${(props) => props.theme.btnText};
  margin-right: auto;
  margin-left: auto;
  display: inline-block;
  line-height: 0.9em;
`;

const CalendarDayContainer = styled("div")`
  cursor: pointer;
  text-align: center;
  &.active {
    border-bottom: 2px solid ${(props) => props.theme.btnText};
    margin-bottom: -2px;
  }
`;

const DisabledCalendarDay = styled(CalendarDay)`
  color: ${(props) => props.theme.btnText};
  color: rgba(255, 255, 255, 0.66);
`;

const EventName = styled("div")`
  font-weight: bold;
  max-height: 60px;
  overflow: hidden;

  @media screen and (max-width: 992px) {
    word-break: break-all;
  }
`;

const EventTime = styled("div")`
  color: #8a8a8a;
`;

const ArrowHeadRight = styled("div")`
  float: right;
  cursor: pointer;
`;
const ArrowHeadLeft = styled("div")`
  float: left;
  cursor: pointer;
`;

const TimeSlot = styled("div")`
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
`;

const EventPanel = styled("a")`
  align-items: center;
  background-color: ${(props) => props.theme.calendar.eventBackground};
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 1em;
  min-height: 80px;
  margin-bottom: 1px;
  padding: 2px;
  text-align: left;
  cursor: pointer;
  &:hover {
    color: #000000;
  }
`;

const CalendarHead = styled("thead")`
  background-color: ${(props) => props.theme.btnBg};
  table > & {
    table-layout: fixed;
  }
  .th {
    padding: 0.3em !important;
  }
`;

const CalendarTable = styled(Table)`
  table-layout: fixed;
  .cell {
    padding: 1px;
    height: 4em;
  }
  .cell:first-child {
    vertical-align: middle;
  }
`;

const AngleLeft = styled(FaAngleLeft)`
  color: ${(props) => props.theme.calendar.leftArrowIconColor};
  font-size: 40px;
`;

const AngleRight = styled(FaAngleRight)`
  color: ${(props) => props.theme.calendar.rightArrowIconColor};
  font-size: 40px;
`;

const IconContainer = styled("div")`
  float: left;
`;

const DisabledEvent = styled("div")`
  .event-panel {
    opacity: 0.4;
  }
  cursor: default;
`;

const RightIconContainer = styled("div")`
  width: 2.2em;
  float: left;
  line-height: 5vh;
  padding-left: 0.2em;
  padding-right: 0.5em;
`;

export {
  CalendarDay,
  DisabledCalendarDay,
  EventName,
  EventPanel,
  CalendarHead,
  AngleLeft,
  AngleRight,
  IconContainer,
  DisabledEvent,
  ArrowHeadRight,
  ArrowHeadLeft,
  TimeSlot,
  EventTime,
  CalendarDayContainer,
  CalendarTable,
  RightIconContainer,
};
