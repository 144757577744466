import { gql } from "@apollo/client";

export const SUBVENTION_QUERY = gql`
  query subvention($cursor: String, $limit: Int!) {
    guest {
      id
      bonusCollectionsConnection(first: $limit, after: $cursor) {
        totalCount
        edges {
          cursor
          node {
            id
            points
            converted
            available
            issueDate
            bonusCollectionType {
              points
              title
            }
          }
        }
      }
    }
  }
`;
