import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import {
  Col,
  FormLabel,
  Form,
  FormGroup,
  Row,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import PropTypes from "prop-types";
import Button from "../CustomBootstrap/Button";
import { PaymentSelect } from "./PaymentSelect";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import { EXTERNAL_VARIANTS } from "../../config/offer/external-variants";
import Conf from "../../Conf";
import { BOOK_EXTERN_MUTATION } from "./ExternBookingForm.graqhql";
import { DateFormatter } from "../../globalComponents/DateFormatter";
import budgetLeftError from "../../utils/budgetLeftError";

const CustomTextarea = styled("textarea")`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

export const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
  .row {
    margin-bottom: 15px;
  }
`;

export const ImgWrapper = styled("div")`
  float: left;
  padding-right: 15px;
  width: 90px;
  height: 100px;

  img {
    width: 100%;
    height: auto;
    max-height: 100px;
  }
`;

export const Title = styled("div")`
  display: block;
  font-size: 16px;
  font-weight: bold;
`;

export const ButtonContent = styled("div")`
  padding: 30px 0;
`;

const PrivacyPolicy = styled((props) => <Card {...props} />)``;

class ExternBooking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: "",
      textAreaValue: "",
      bookSuccess: null,
      showLoader: false,
      privacyPolicyAccepted: false,
      sepaMandateMissing: false,
      paymentEnabled: true,
    };

    this.parseError = parseError.bind(this);
    this.updatePaymentMethodBy = this.updatePaymentMethodBy.bind(this);
    this.updateTextAreaValue = this.updateTextAreaValue.bind(this);
    this.checkSepaMandate = this.checkSepaMandate.bind(this);

    this._alertModal = null;
  }

  checkSepaMandate(methods, available) {
    if (
      available.length > 0 &&
      methods.length === 0 &&
      available.includes("DIRECT_DEBIT")
    ) {
      this.setState({ sepaMandateMissing: true });
    } else if (available.length === 0) {
      this.setState({ paymentEnabled: false });
    }
  }

  updatePaymentMethodBy(sPaymentMethod) {
    this.setState({ paymentMethod: sPaymentMethod });
  }

  updateTextAreaValue(event) {
    this.setState({ textAreaValue: event.target.value });
  }

  submitExtern(action) {
    if (this.state.showLoader) {
      return;
    }
    if (
      this.state.paymentEnabled &&
      this.props.offer.therapyOffer.price.total > 0 &&
      this.state.sepaMandateMissing
    ) {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.note" }),
        this.props.intl.formatMessage({
          id: "app.appointments.paymentMethod.error.sepaMandateRequired",
        })
      );
      return;
    }

    if (
      !this.state.paymentEnabled &&
      this.props.offer.therapyOffer.price.total > 0
    ) {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: "app.error.lowbudget" })
      );
      return;
    }

    if (
      this.props.offer.therapyOffer.price.total > 0 &&
      this.state.paymentMethod === ""
    ) {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({
          id: "app.offers.extern.error.selectPayment",
        })
      );
      return;
    }

    if (
      this.props.offer.extern.privacyPolicy &&
      this.props.offer.extern.privacyPolicy !== "" &&
      !this.state.privacyPolicyAccepted
    ) {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({
          id: "app.offers.extern.error.privacyPolicyAccepted",
        })
      );
      return;
    }

    this.setState({ showLoader: true }, () => {
      this.processExtern(action);
    });
  }

  processExtern(action) {
    let variantType = null;
    if (this.props.variant) {
      switch (this.props.variant.__typename) {
        case EXTERNAL_VARIANTS.LOCATION:
          variantType = "EVENT_LOCATION";
          break;
        case EXTERNAL_VARIANTS.ONLINE:
          variantType = "EVENT_ONLINE";
          break;
        case EXTERNAL_VARIANTS.CUSTOM:
          variantType = "CUSTOM";
          break;
        default:
          this.BookingError("500");
          return;
      }
    }

    this.props
      .bookExtern({
        variables: {
          bgmConceptTherapyOfferExternId: this.props.offer.extern.id,
          serviceProviderLocationId:
            this.props.offer.extern.serviceProviderLocation !== null
              ? this.props.offer.extern.serviceProviderLocation.id
              : null,
          paymentType:
            this.props.offer.therapyOffer.price.total > 0
              ? this.state.paymentMethod
              : "SUBVENTION",
          info: this.state.textAreaValue,
          variantId: this.props.variant ? this.props.variant.id : null,
          variantType,
          action,
        },
      })
      .then(() => {
        this.BookingSuccess();
      })
      .catch((error) => {
        this.BookingError(error);
      });
  }

  componentDidMount() {
    budgetLeftError({
      paymentMethod: this.state.paymentMethod,
      employeeBudgetLeft: this.props.budgetLeft,
      eventOfferPrice: this.props.offer.therapyOffer.price.total,
      alertModal: this._alertModal,
      intl: this.props.intl,
      closeModal: this.props.closeModal,
      history: this.props.history,
      offerId: this.props.offer.id,
    });
  }

  BookingSuccess() {
    this.setState({
      bookSuccess: true,
      showLoader: false,
    });
  }

  BookingError(error) {
    const errorTextTranslationKey = this.parseError(
      error,
      Conf.errorContext.offerExternError
    );
    let errorMessage = null;

    if (errorTextTranslationKey.substr(-6) === "400x00") {
      // eslint-disable-next-line prefer-regex-literals
      const regex = new RegExp("[0-9]{3}x[0-9]{2} (.+)");
      errorMessage = error.message.match(regex)[1];
    } else {
      errorMessage = this.props.intl.formatMessage({
        id: errorTextTranslationKey,
      });
    }

    this.setState({
      showLoader: false,
    });

    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      errorMessage
    );
  }

  formatPrice(number) {
    return this.props.intl.formatNumber(number, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  renderVariant() {
    const isLocation =
      this.props.variant.__typename === EXTERNAL_VARIANTS.LOCATION &&
      "app.offers.extern.variants.place";
    const isPlatform =
      this.props.variant.__typename === EXTERNAL_VARIANTS.ONLINE &&
      "app.offers.extern.variants.platform";
    const isCustom =
      this.props.variant.__typename === EXTERNAL_VARIANTS.CUSTOM &&
      "app.offers.extern.variants.custom";
    const label = isLocation || isPlatform || isCustom;
    const {
      locationName,
      city,
      street,
      streetNumber,
      zipCode,
      plattformName,
      dateBegin,
      dateEnd,
      country,
      title,
    } = this.props.variant;
    const userCountry = this.props.country;

    const renderColumn = (colTitle, children) => (
      <Col xs={12} md={3}>
        <Title>
          <FormattedMessage id={colTitle} />{" "}
        </Title>
        <div className="text-muted">
          {children
            .filter((child) => !!child)
            .map((child) => (
              <div key={child}>{child}</div>
            ))}
        </div>
      </Col>
    );

    return (
      <Row>
        {renderColumn(label, [
          locationName || plattformName || title,
          city ? `${zipCode} ${city}` : "",
          street ? `${street} ${streetNumber}` : "",
          country && userCountry.toLowerCase() !== country.toLowerCase()
            ? country
            : "",
        ])}
        {dateBegin &&
          renderColumn("app.offers.extern.variants.begin", [
            <DateFormatter
              date={new Date(dateBegin)}
              type="DAY_WITH_DATE_AND_TIME_BR"
            />,
          ])}
        {dateEnd &&
          renderColumn("app.offers.extern.variants.end", [
            <DateFormatter
              date={new Date(dateEnd)}
              type="DAY_WITH_DATE_AND_TIME_BR"
            />,
          ])}
      </Row>
    );
  }

  renderExternBookingButtons() {
    return this.props.offer.extern.availableActions.map((action) => (
      <ButtonContent key={`${action}-button`}>
        <div className="d-grid">
          <Button
            processing={this.state.showLoader}
            onClick={() => this.submitExtern(action)}
            variant="primary"
            size="lg"
          >
            <FormattedMessage id={`app.offers.date.${action.toLowerCase()}`} />
          </Button>
        </div>
      </ButtonContent>
    ));
  }

  render() {
    if (this.state.bookSuccess) {
      return (
        <Content>
          <Row>
            <Col xs={12}>
              <VerticallyAlignedMiddle height={window.innerHeight / 2}>
                {React.createElement("div", {
                  dangerouslySetInnerHTML: {
                    __html: JSON.parse(
                      this.props.offer.extern.confirmationMessage
                    ),
                  },
                })}
              </VerticallyAlignedMiddle>
            </Col>
          </Row>
        </Content>
      );
    }

    const eventOffer = {
      offer: this.props.offer.therapyOffer,
      ...this.props.offer.therapyOffer,
    };
    const priceMatrix = this.props.offer.therapyOffer;
    const subventionValue =
      priceMatrix.price.net * (1 + priceMatrix.price.tax / 100) -
      priceMatrix.price.total;
    const config = this.props.config.modules.filter(
      (w) => w.__typename === "ModuleConfigFrontend"
    )[0].employee;

    return (
      <Content>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        {this.props.offer.extern.serviceProviderLocation && (
          <Row>
            <Col xs={12}>
              {this.props.offer.extern.serviceProviderLocation.logo && (
                <ImgWrapper>
                  <img
                    src={JSON.parse(
                      this.props.offer.extern.serviceProviderLocation.logo
                    )}
                    alt={this.props.offer.extern.serviceProviderLocation.name}
                  />
                </ImgWrapper>
              )}
              <Title>
                <FormattedMessage id="app.offers.extern.serviceProvider" />:
              </Title>
              <span className="text-muted">
                {this.props.offer.extern.serviceProviderLocation.name}
              </span>
            </Col>
          </Row>
        )}

        <Form>
          {this.props.variant && this.renderVariant()}

          {this.props.offer.therapyOffer.hidePrices !== true && (
            <div>
              <Row>
                {config.showPrices && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {this.props.intl.formatMessage({
                          id: "app.offers.price.total.tooltip",
                        })}
                      </Tooltip>
                    }
                  >
                    <Col xs={12} md={3} style={{ cursor: "help" }}>
                      <Title>
                        <FormattedMessage id="app.offers.price.total" />
                        :&nbsp;
                      </Title>
                      <span className="text-muted">
                        {this.formatPrice(
                          priceMatrix.price.net *
                            (1 + priceMatrix.price.tax / 100)
                        )}
                        <FormattedMessage id="app.global.priceUnit" />
                      </span>
                      <br />
                    </Col>
                  </OverlayTrigger>
                )}
                {config.showSubventionValue && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {this.props.intl.formatMessage({
                          id: "app.offers.price.subvention.tooltip",
                        })}
                      </Tooltip>
                    }
                  >
                    <Col xs={12} md={3} style={{ cursor: "help" }}>
                      <Title>
                        <FormattedMessage id="app.offers.price.subvention" />
                        :&nbsp;
                      </Title>
                      <span className="text-muted">
                        {this.formatPrice(subventionValue)}
                        <FormattedMessage id="app.global.priceUnit" />
                      </span>
                      <br />
                    </Col>
                  </OverlayTrigger>
                )}
                {this.props.budgetTotal && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {this.props.intl.formatMessage({
                          id: "app.offers.price.budget.tooltip",
                        })}
                      </Tooltip>
                    }
                  >
                    <Col xs={12} md={3} style={{ cursor: "help" }}>
                      <Title>
                        <FormattedMessage id="app.offers.price.budget" />
                        :&nbsp;
                      </Title>
                      <span className="text-muted">
                        {this.formatPrice(
                          this.props.budgetGross
                            ? priceMatrix.price.companySubventionAmount *
                                (1 + priceMatrix.price.tax / 100)
                            : priceMatrix.price.companySubventionAmount
                        )}
                        <FormattedMessage id="app.global.priceUnit" />
                      </span>
                      <br />
                    </Col>
                  </OverlayTrigger>
                )}
                {priceMatrix.price.total > 0 && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {this.props.intl.formatMessage({
                          id: "app.offers.price.price.tooltip",
                        })}
                      </Tooltip>
                    }
                  >
                    <Col xs={12} md={2} style={{ cursor: "help" }}>
                      <Title>
                        <FormattedMessage id="app.offers.extern.price" />:
                      </Title>
                      <strong>
                        {this.formatPrice(priceMatrix.price.total)}
                        <FormattedMessage id="app.global.priceUnit" />
                      </strong>
                      <br />
                    </Col>
                  </OverlayTrigger>
                )}
              </Row>
              {(priceMatrix.price.total > 0 ||
                (subventionValue > 0 && config.showSubventionValue) ||
                (this.props.budgetTotal &&
                  priceMatrix.price.companySubventionAmount > 0)) && (
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <FormLabel>
                        <Title>
                          <FormattedMessage id="app.appointments.paymentMethod" />
                          :
                        </Title>
                      </FormLabel>
                      <PaymentSelect
                        value={this.state.paymentMethod}
                        onReady={this.checkSepaMandate}
                        eventOffer={eventOffer}
                        onChange={(event) =>
                          this.updatePaymentMethodBy(event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </div>
          )}

          {this.props.offer.extern.enableNote === true && (
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <FormLabel>
                    <Title>
                      <FormattedMessage id="app.offers.extern.note" />:
                    </Title>
                  </FormLabel>
                  <CustomTextarea
                    className="form-control"
                    value={this.state.textAreaValue}
                    onChange={(event) => this.updateTextAreaValue(event)}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}

          {this.props.offer.extern.privacyPolicy &&
            this.props.offer.extern.privacyPolicy !== "" && (
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <FormLabel>
                      <Title>
                        <FormattedMessage id="app.offers.extern.privacy" />:
                      </Title>
                    </FormLabel>
                    <PrivacyPolicy>
                      <Card.Body>
                        {React.createElement("div", {
                          dangerouslySetInnerHTML: {
                            __html: JSON.parse(
                              this.props.offer.extern.privacyPolicy
                            ),
                          },
                        })}
                      </Card.Body>
                    </PrivacyPolicy>
                    <input
                      type="checkbox"
                      name="privacyPolicyAccepted"
                      value="1"
                      checked={this.state.privacyPolicyAccepted}
                      onChange={() => {
                        this.setState((prevState) => ({
                          privacyPolicyAccepted:
                            !prevState.privacyPolicyAccepted,
                        }));
                      }}
                    />{" "}
                    <FormattedMessage id="app.offers.extern.privacyPolicyAccepted" />
                  </FormGroup>
                </Col>
              </Row>
            )}
          {this.props.offer.extern.lastBooking && (
            <Row>
              <Col xs={12}>
                <p>
                  <FormattedMessage
                    id={`app.offers.extern.info.${this.props.offer.extern.lastBooking.state}`}
                  />
                  <DateFormatter
                    date={new Date(this.props.offer.extern.lastBooking.endDate)}
                    type="DATE"
                  />
                </p>
              </Col>
            </Row>
          )}
          {this.renderExternBookingButtons()}
        </Form>
      </Content>
    );
  }
}

export const variantShape = {
  id: PropTypes.string,
  __typename: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  dateBegin: PropTypes.string,
  dateEnd: PropTypes.string,
  locationName: PropTypes.string,
  street: PropTypes.string,
  streetNumber: PropTypes.string,
  zipCode: PropTypes.number,
  city: PropTypes.string,
  country: PropTypes.string,
  plattformName: PropTypes.string,
};

ExternBooking.propTypes = {
  bookExtern: PropTypes.func,
  budgetTotal: PropTypes.number,
  budgetGross: PropTypes.bool,
  config: PropTypes.shape({
    __typename: PropTypes.string,
    modules: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.shape({
            offerPaging: PropTypes.number,
            showPrices: PropTypes.bool,
            showSubventionValue: PropTypes.bool,
          }),
        ])
      )
    ),
  }),
  country: PropTypes.string,
  intl: PropTypes.shape(Intl.shape),
  offerExtern: PropTypes.shape({
    __typename: PropTypes.string,
    confirmationMessage: PropTypes.string,
    enableNote: PropTypes.bool,
    enablePackages: PropTypes.bool,
    id: PropTypes.string,
    privacyPolicy: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    serviceProviderLocation: PropTypes.objectOf(PropTypes.string),
    variants: PropTypes.arrayOf(PropTypes.shape(variantShape)),
  }),
  therapyOffer: PropTypes.shape({
    __typename: PropTypes.string,
    hidePrices: PropTypes.bool,
    id: PropTypes.string,
    price: PropTypes.shape({
      __typename: PropTypes.string,
      companySubventionAmount: PropTypes.number,
      net: PropTypes.number,
      tax: PropTypes.number,
      total: PropTypes.number,
    }),
  }),
  variant: PropTypes.shape(variantShape),
};
// pragma mark - compose mutations

const ExternBookingMutations = compose(
  graphql(BOOK_EXTERN_MUTATION, { name: "bookExtern" })
)(ExternBooking);

// pragma mark - export component

export const ExternBookingForm = injectIntl(ExternBookingMutations);
