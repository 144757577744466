import React from "react";
import * as ReactDOMClient from "react-dom/client";
import App from "./containers/App";
import "./css/normalize.css";
import "bootstrap";
// import "bootstrap/scss/bootstrap.scss";
import "./css/bootstrap.scss";
import "./css/index.css";
import "./css/vars.scss";
import { initLanguage } from "./utils/language";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

initLanguage();
root.render(<App />);
