import React, { PureComponent } from "react";
import styled from "styled-components";

/**
 * Container Element
 *
 * @param children
 */
const PaddingLessDiv = styled("div")`
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;

  @media screen and (min-width: 1200px) {
    width: 1190px !important;
    padding: 0 5px !important;
  }
`;

export class PaddingLessContainer extends PureComponent {
  render() {
    const { children, className, ...other } = this.props;

    return (
      <PaddingLessDiv {...other} className={`container ${className}`}>
        {children}
      </PaddingLessDiv>
    );
  }
}
