import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import Icon from "../../globalComponents/Icon";
import { theme } from "../../globalComponents/Theme";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import Conf from "../../Conf";
import { MARK_GUEST_NEWS_MUTATION } from "./NewsList.graphql";

const Wrapper = styled("div")`
  border-bottom: 1px solid #d4d4d4;
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px;

  &:first-child {
    margin-top: -15px;
  }

  &:last-child {
    margin-bottom: -15px;
  }
`;

const Title = styled("h2")`
  float: left;
  font-size: 20px;
  margin: 0;
`;

const Delete = styled("div")`
  float: right;
  cursor: pointer;
`;

const CustomButton = styled(Button)`
  background-color: ${theme.btnBg} !important;
  color: ${theme.btnText} !important;
`;

const H2 = styled("h2")`
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
`;

class NewsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      useModal: true,
      showModal: false,
      list: [],
    };

    this.newsIdToBeDeleted = null;
    this.toggleUseModal = this.toggleUseModal.bind(this);
    this.confirmDeleteNews = this.confirmDeleteNews.bind(this);
    this.cancelDeleteNews = this.cancelDeleteNews.bind(this);
    this.parseError = parseError.bind(this);
  }

  toggleUseModal() {
    this.setState((prevState) => ({ useModal: !prevState.useModal }));
  }

  deleteNews(newsId) {
    this.newsIdToBeDeleted = newsId;
    this.state.useModal
      ? this.setState({ showModal: true })
      : this.confirmDeleteNews();
  }

  confirmDeleteNews() {
    this.setState({ showModal: false });

    this.props
      .mutate({ variables: { id: this.newsIdToBeDeleted } })
      .then(() => {
        this.setState((prevState) => {
          const newList = prevState.list.filter(
            (news) => news.id !== this.newsIdToBeDeleted
          );
          this.newsIdToBeDeleted = null;
          return { list: newList };
        });
      })
      .catch((error) => {
        const errorTextTranslationKey = this.parseError(
          error,
          Conf.errorContext.newsRead
        );
        this.props.onError(error, errorTextTranslationKey);
      });
  }

  cancelDeleteNews() {
    this.newsIdToBeDeleted = null;
    this.setState({
      showModal: false,
      useModal: true,
    });
  }

  componentDidMount() {
    this.setState({ list: this.props.list });
  }

  render() {
    return this.state.list.length === 0 ? (
      <H2>
        <FormattedMessage id="app.news.noNews" />
      </H2>
    ) : (
      <div>
        <Modal show={this.state.showModal} onHide={this.cancelDeleteNews}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="app.news.markAsReadWarningTitle" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="app.news.markAsReadWarningMessage" />
            </p>
            <label>
              <input type="checkbox" onClick={this.toggleUseModal} />
              <FormattedMessage id="app.news.markAsReadWarningShow" />
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.cancelDeleteNews}>
              <FormattedMessage id="app.modal.cancel" />
            </Button>
            <CustomButton onClick={this.confirmDeleteNews}>
              <FormattedMessage id="app.modal.confirm" />
            </CustomButton>
          </Modal.Footer>
        </Modal>

        {this.state.list.map((news) => (
          <Wrapper className="news-item" key={news.id}>
            <div className="row">
              <div className="col-xs-12">
                <Title>{news.title}</Title>
                <Delete onClick={() => this.deleteNews(news.id)}>
                  <Icon
                    className="icon-themed"
                    src="/static/frontend/employee/images/event-delete.svg"
                  />
                </Delete>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                {React.createElement("p", {
                  dangerouslySetInnerHTML: { __html: JSON.parse(news.content) },
                })}
              </div>
            </div>
          </Wrapper>
        ))}
      </div>
    );
  }
}

export default graphql(MARK_GUEST_NEWS_MUTATION)(injectIntl(NewsList));
