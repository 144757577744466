import React, { Component } from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { Swipeable } from "react-swipeable";
import { CustomFaAngleRight } from "../../globalComponents/List";
import { CustomPanel, Wrapper, H4 } from "../../globalComponents/Panel";
import CategoryBarItem from "./CategoryBarItem";

import "./CategoryBar.scss";

const CategoryPanel = styled(CustomPanel)`
  .row > div {
    padding-left: 5px;
    padding-right: 5px;
  }
  .row {
    padding-left: 10px;
    padding-right: 10px;
  }
  position: relative;
  background: none;
  padding-top: 0;
  padding-bottom: 0;
`;

const CustomWrapper = styled(Wrapper)`
  margin-bottom: 0;
  position: relative;
  margin-bottom: 20px;
  > H4 {
    color: #fff;
    padding-top: 15px;
    font-weight: bold;
    font-size: 16px;
  }
`;

const ColorBar = styled(CustomPanel)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: -1;
  margin: 0;
  background-color: rgb(255, 98, 0);
`;

const SliderPanel = styled("div")`
  height: 40px;
  width: 100%;
  text-align: center;
`;
const SliderButton = styled("button")`
  line-height: 1.6875rem;
  text-align: center;
  font-size: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 1.125rem 0.5625rem;
  transition-delay: 0.1s;
  transition-property: opacity;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  opacity: 1;
  span {
    width: 0.5625rem;
    height: 0.5625rem;
    background-color: #a8a8a8;
    position: relative;
    display: block;
    border-radius: 50%;
    pointer-events: none;
    transition: background-color 0.25 cubic-bezier(0.4, 0, 0.2, 1);
  }
  span.active {
    background-color: #ff6200;
  }
`;
const CustomFaAngleL = styled(CustomFaAngleRight)`
  transform: rotate(90deg);
  cursor: pointer;
  &.disabled path {
    fill: transparent;
  }
`;
const CustomFaAngleR = styled(CustomFaAngleRight)`
  transform: rotate(-90deg);
  cursor: pointer;
  &.disabled path {
    fill: transparent;
  }
`;

class CategoryBar extends Component {
  constructor(props) {
    super(props);

    const numEvents = CategoryBar.calcEvents();

    this.state = {
      page: 1,
      numEvents,
      maxPages: Math.ceil(props.category.bgmOffers.length / numEvents),
    };

    this.switchPage = this.switchPage.bind(this);
    this.updateEvents = this.updateEvents.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.hideCategoryBarDetails = this.hideCategoryBarDetails.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  switchPage(page) {
    this.setState({ page });
  }

  updateEvents() {
    const numEvents = CategoryBar.calcEvents();
    this.setState({
      numEvents,
      maxPages: Math.ceil(this.props.category.bgmOffers.length / numEvents),
    });
  }

  static calcEvents() {
    if (window.innerWidth >= 992) {
      return 6;
    }
    if (window.innerWidth >= 768) {
      return 4;
    }
    return 2;
  }

  hideCategoryBarDetails() {
    this.props.setActivePanelId(null);
  }

  showDetails(eventId, event) {
    event.stopPropagation();

    this.props.setActivePanelId(`${this.props.category.id}-${eventId}`);
  }

  next() {
    if (this.state.page < this.state.maxPages) {
      this.switchPage(this.state.page + 1);
    }
  }

  prev() {
    if (this.state.page > 1) {
      this.switchPage(this.state.page - 1);
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateEvents);
  }

  render() {
    const { numEvents } = this.state;
    const { category } = this.props;
    const { maxPages } = this.state;

    const startIndex = (this.state.page - 1) * numEvents;
    const selEvents = category.bgmOffers.slice(
      startIndex,
      startIndex + numEvents
    );
    return (
      <CustomWrapper onClick={this.hideDetails}>
        <ColorBar className="color-bar" />
        <H4>{category.title}</H4>
        <Swipeable onSwipedRight={this.prev} onSwipedLeft={this.next}>
          <CategoryPanel>
            <Row>
              {selEvents.map((eventOffer, index) => {
                const num = index + 1;
                const priceMatrix =
                  eventOffer.therapyOffer.priceMatrix.length > 0
                    ? eventOffer.therapyOffer.priceMatrix[0]
                    : null;
                return (
                  <Col xs={6} sm={3} md={2} lg={2} key={eventOffer.id}>
                    <CategoryBarItem
                      eventOffer={eventOffer}
                      num={num}
                      priceMatrix={priceMatrix}
                      numEvents={numEvents}
                      category={category}
                      activePanelId={this.props.activePanelId}
                      history={this.props.history}
                      intl={this.props.intl}
                      showDetails={this.showDetails}
                      hideCategoryBarDetails={this.hideCategoryBarDetails}
                    />
                  </Col>
                );
              })}
            </Row>
          </CategoryPanel>
        </Swipeable>
        <SliderPanel className="slider-panel">
          <CustomFaAngleL
            onClick={this.prev}
            className={`${this.state.page === 1 ? "disabled" : ""} arrow`}
          />
          <SliderButton
            onClick={() => {
              this.switchPage(1);
            }}
          >
            <span
              className={maxPages > 1 && this.state.page === 1 ? "active" : ""}
            />
          </SliderButton>
          <SliderButton
            onClick={() => {
              if (maxPages > 1) {
                this.switchPage(Math.floor(maxPages / 2));
              }
            }}
          >
            <span
              className={
                maxPages > 1 &&
                this.state.page > 1 &&
                this.state.page < maxPages
                  ? "active"
                  : ""
              }
            />
          </SliderButton>
          <SliderButton
            onClick={() => {
              this.switchPage(maxPages);
            }}
          >
            <span
              className={
                maxPages > 1 && this.state.page === maxPages ? "active" : ""
              }
            />
          </SliderButton>
          <CustomFaAngleR
            onClick={this.next}
            className={`${
              this.state.page === maxPages ? "disabled" : ""
            } arrow`}
          />
        </SliderPanel>
      </CustomWrapper>
    );
  }
}

export default injectIntl(CategoryBar);
