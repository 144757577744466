import { gql } from "@apollo/client";

export const NEWS_BADGE_QUERY = gql`
  query {
    guest {
      id
      news {
        id
      }
    }
  }
`;
