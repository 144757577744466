import React, { Component, createRef } from "react";
import {
  Alert,
  FormCheck,
  Col,
  Collapse,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  FormText,
  Row,
} from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import { format, compareAsc } from "date-fns";
import { CustomFaAngleRight, Header } from "../../globalComponents/List";
import Button from "../CustomBootstrap/Button";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import Icon from "../../globalComponents/Icon";
import Conf from "../../Conf";
import { RECEIPT_UPLOAD_MUTATION } from "./Upload.graphql";
import { DayPickerInput } from "../../globalComponents/DayPickerInput";
import AccountingCheck from "../Account/AccountingCheck";

const CustomFormControl = styled(FormControl)`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

const FileFormControl = styled(FormControl)`
  max-width: 100%;
`;

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 0px;
  width: calc(100%);
`;

const CollapseHelper = styled("div")``;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 1px solid #d4d4d4;
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

const SubHeader = styled(Header)`
  min-height: 40px;
`;

const OfferTitle = styled("div")``;

const ButtonContent = styled("div")`
  padding: 15px 0;
`;

const AttributeIcon = styled(Icon)`
  svg {
    height: 40px;
    width: 40px;
  }
  float: left;
  height: 40px;
  width: 40px;

  cursor: pointer;
  svg path,
  svg circle {
    fill: #8a8a8a !important;
  }
  &.active svg path,
  &.active svg circle {
    fill: #ff6200 !important;
  }
  margin-right: 10px;
`;

class Upload extends Component {
  static ALLOWED_FILES = ["pdf", "jpeg", "jpg"];

  constructor(props) {
    super(props);

    this.state = {
      bankDetailsExist: false,
      desc: true,
      criteriaAccepted: false,
      receiptTitle: "",
      receiptDate: null,
      receiptNumber: "",
      duration: null,
      serviceProviderId: null,
      serviceProviderLocationId: null,
      attributes: [],
      totalAmount: null,
      comment: "",
      files: {
        1: false,
        2: false,
        3: false,
      },
      fileKeys: {
        1: 1,
        2: 2,
        3: 3,
      },
      uploadIsDone: false,
      uploadError: false,
      uploadInProgress: false,
      validationActive: false,
      validationState: false,
      validation: {
        criteriaAccepted: false,
        receiptTitle: false,
        receiptNumber: false,
        receiptDate: false,
        totalAmount: false,
        files: false,
        attributes: new Map(),
        duration: false,
        serviceProvider: false,
        comment: false,
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.resetFile = this.resetFile.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.parseError = parseError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.scrollRef = createRef();
  }

  componentDidMount() {
    if (!this.state.receiptTitle) {
      let defaultTitle = "";
      if (this.props.intl.messages["app.offers.upload.receiptTitle.default"]) {
        defaultTitle = this.props.intl.formatMessage(
          { id: "app.offers.upload.receiptTitle.default" },
          { offer: this.props.offer.therapyOffer.title }
        );
      }
      this.setState({ receiptTitle: defaultTitle });
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    let value = "";

    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      default:
        value = target.value;
    }

    switch (name) {
      case "duration":
        value = value.replace(/[^0-9]/g, "") * 1;
        break;
      case "serviceProviderId":
        value = value === "" ? null : value * 1;
        break;
      default:
        break;
    }

    this.setState({ [name]: value }, () => this.validate());
  }

  handleFileInputChange(event, num) {
    const { files } = this.state;
    files[num] = event.target.files.length > 0 ? event.target.files[0] : false;
    this.setState({ files }, () => this.validate());
  }

  static handleFileInputOnDrop(event) {
    if (
      Array.from(event.dataTransfer.files).find(
        (file) =>
          !Upload.ALLOWED_FILES.find((allowedFile) =>
            file.name.endsWith(`.${allowedFile}`)
          )
      )
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  static getAllowedFiles() {
    return Upload.ALLOWED_FILES.map((allowedFile) => `.${allowedFile}`).join(
      ","
    );
  }

  resetFile(num) {
    const { files } = this.state;
    const { fileKeys } = this.state;
    files[num] = false;
    fileKeys[num]++;
    this.setState(
      {
        files,
        fileKeys,
      },
      () => this.validate()
    );
  }

  validate() {
    const validation = {};

    validation.criteriaAccepted = this.state.criteriaAccepted;

    if (!this.props.config.bgmConceptRefundsFieldReceiptTitleEnabled) {
      validation.receiptTitle = true;
    } else {
      validation.receiptTitle =
        !this.props.config.bgmConceptRefundsFieldReceiptTitleRequired ||
        (this.state.receiptTitle !== null && this.state.receiptTitle !== "");
    }

    if (!this.props.config.bgmConceptRefundsFieldReceiptNumberRequired) {
      validation.receiptNumber = true;
    } else {
      validation.receiptNumber =
        this.state.receiptNumber !== null && this.state.receiptNumber !== "";
    }

    validation.receiptDate =
      this.state.receiptDate !== null && this.state.receiptDate !== "";

    // regex to match gross (e.g. 123,17 or 94100,99 or 29,1)
    const regex = /^\d+(,\d{1,2})?$/;
    const result = this.state.totalAmount
      ? this.state.totalAmount.match(regex)
      : null;
    validation.totalAmount = result !== null;

    validation.files = !!Object.keys(this.state.files).find(
      (key) => this.state.files[key] !== false
    );

    validation.attributes = new Map();
    this.props.config.bgmConceptRefundsFieldTherapyOfferAttributesEnabled.forEach(
      (attr) => {
        const required =
          this.props.config.bgmConceptRefundsFieldTherapyOfferAttributesRequired.find(
            (requiredAttr) => requiredAttr.id === attr.id
          );

        if (!required) {
          validation.attributes.set(attr.id, true);
          return;
        }

        const value = this.state.attributes.find(
          (valueAttr) => valueAttr.therapyOfferAttributeId === attr.id
        );

        if (value) {
          validation.attributes.set(attr.id, true);
          return;
        }

        validation.attributes.set(attr.id, false);
      }
    );

    if (!this.props.config.bgmConceptRefundsFieldDurationEnabled) {
      validation.duration = true;
    } else {
      validation.duration =
        !this.props.config.bgmConceptRefundsFieldDurationRequired ||
        (this.state.duration !== null && this.state.duration !== "");
    }

    if (!this.props.config.bgmConceptRefundsFieldServiceProvidersEnabled) {
      validation.serviceProvider = true;
    } else {
      validation.serviceProvider =
        !this.props.config.bgmConceptRefundsFieldServiceProviderRequired ||
        (this.state.serviceProviderId !== null &&
          this.state.serviceProviderId !== "");
    }

    if (!this.props.config.bgmConceptRefundsFieldCommentEnabled) {
      validation.comment = true;
    } else {
      validation.comment =
        !this.props.config.bgmConceptRefundsFieldCommentRequired ||
        (this.state.comment !== null && this.state.comment !== "");
    }

    // ---

    const validationState = !Object.keys(validation).find((key) => {
      if (Array.isArray(validation[key])) {
        return !!validation[key].find((val) => val.state === false);
      }
      return validation[key] === false;
    });

    this.setState({
      validation,
      validationState,
    });

    return validationState;
  }

  submitForm(event) {
    event.preventDefault();

    this.setState({ validationActive: true });
    if (!this.validate()) {
      if (this.scrollRef && this.scrollRef.current) {
        this.scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
      return false;
    }

    if (
      this.props.config.bgmConceptRefundsFieldTherapyOfferAttributesRequired
        .length > 0
    ) {
      let attributesSet = true;
      this.props.config.bgmConceptRefundsFieldTherapyOfferAttributesRequired.forEach(
        (reqAttribute) => {
          attributesSet =
            attributesSet &&
            this.state.attributes.filter(
              (attribute) =>
                attribute.therapyOfferAttributeId === reqAttribute.id
            ).length > 0;
        }
      );
      if (!attributesSet) {
        this._alertModal.open(
          this.props.intl.formatMessage({ id: "app.global.error" }),
          this.props.intl.formatMessage({
            id: "app.offers.upload.attributes.required",
          })
        );
        return false;
      }
    }

    if (event.target.checkValidity()) {
      this.setState({ uploadInProgress: true });

      let errorTextTranslationKey = null;

      const file = [];
      if (this.state.files[1] !== false) {
        file.push(this.state.files[1]);
      }
      if (this.state.files[2] !== false) {
        file.push(this.state.files[2]);
      }
      if (this.state.files[3] !== false) {
        file.push(this.state.files[3]);
      }

      this.props
        .mutate({
          variables: {
            bgmConceptTherapyOfferRefundId: this.props.offer.refund.id,
            conditionsAccepted: true,
            receiptTitle: this.state.receiptTitle
              ? this.state.receiptTitle
              : this.props.offer.therapyOffer.title,
            receiptNumber: this.state.receiptNumber,
            receiptDate: this.state.receiptDate,
            comment: this.state.comment,
            duration: this.state.duration,
            serviceProviderId: this.state.serviceProviderId,
            serviceProviderLocationId: this.state.serviceProviderLocationId,
            attributes: this.state.attributes,
            gross: this.state.totalAmount.replace(",", "."),
            file,
          },
        })
        .then(({ data }) => {
          if (data.uploadRefundReceipt && data.uploadRefundReceipt.id) {
            this.setState({
              uploadIsDone: true,
              uploadInProgress: false,
            });
          } else {
            errorTextTranslationKey = this.parseError(
              data.errors,
              Conf.errorContext.offerUploadError,
              "processing"
            );
            this._alertModal.open(
              this.props.intl.formatMessage({ id: "app.global.error" }),
              this.props.intl.formatMessage({ id: errorTextTranslationKey })
            );
            this.setState({
              uploadError: true,
              uploadInProgress: false,
            });
          }
        })
        .catch((errors) => {
          errorTextTranslationKey = this.parseError(
            errors,
            Conf.errorContext.offerUploadError,
            "transmission"
          );

          this.setState({ uploadInProgress: false });

          this._alertModal.open(
            this.props.intl.formatMessage({ id: "app.global.error" }),
            this.props.intl.formatMessage({ id: errorTextTranslationKey })
          );
        });
    }
  }

  handleDateChange(date) {
    this.setState(
      {
        receiptDate: format(new Date(date), "yyyy-MM-dd"),
        date,
      },
      () => this.validate()
    );
  }

  /**
   * get range of dates from where
   * within you are allowed to select days
   * */
  static getEnabledDays = (d) => {
    const today = new Date();
    const currentYear = today.getFullYear();

    return !(
      compareAsc(d, new Date(currentYear - 10, 0, 0)) === 1 &&
      compareAsc(d, today) === -1
    );
  };

  static getDropdownYears = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    return { min: currentYear - 10, max: currentYear };
  };

  getAttributeValue(attributeId) {
    const attributeState = this.state.attributes.filter(
      (attribute) => attribute.therapyOfferAttributeId === attributeId
    );
    if (attributeState.length > 0) {
      return attributeState[0].therapyOfferAttributeValueId;
    }
    return "";
  }

  isAttributeValueSelected(attributeId, valueId) {
    return (
      this.state.attributes.filter(
        (attribute) => attribute.therapyOfferAttributeValueId === valueId
      ).length > 0
    );
  }

  toggleAttributeValue(attributeId, valueId) {
    let { attributes } = this.state;
    const { multiple } =
      this.props.config.bgmConceptRefundsFieldTherapyOfferAttributesEnabled.filter(
        (attribute) => attribute.id === attributeId
      )[0];

    const originLength = attributes.length;
    attributes = attributes.filter(
      (attribute) => attribute.therapyOfferAttributeValueId !== valueId
    );
    if (valueId === "0") {
      attributes = attributes.filter(
        (attribute) => attribute.therapyOfferAttributeId !== attributeId
      );
    }
    if (attributes.length === originLength) {
      let set = false;
      if (!multiple) {
        attributes.map((attribute_) => {
          const attribute = attribute_;
          if (attribute.therapyOfferAttributeId === attributeId) {
            set = true;
            attribute.therapyOfferAttributeValueId = valueId;
          }
          return attribute;
        });
      }
      if (!set) {
        attributes.push({
          therapyOfferAttributeId: attributeId,
          therapyOfferAttributeValueId: valueId,
        });
      }
    }

    this.setState({ attributes }, () => this.validate());
  }

  render() {
    const { validationActive, validationState } = this.state;

    if (this.state.uploadIsDone) {
      return (
        <ScrollableDiv>
          <Content>
            <Row>
              <Col className="pt-4">
                <Alert variant="success">
                  <p>
                    <FormattedMessage id="app.offers.uploadSuccessful" />
                  </p>
                </Alert>
              </Col>
            </Row>
          </Content>
        </ScrollableDiv>
      );
    }

    const providers =
      this.props.config.bgmConceptRefundsFieldServiceProvidersEnabled.slice(0);
    if (providers.length > 0) {
      providers.push({
        id: 0,
        name: this.props.intl.formatMessage({
          id: "app.offers.upload.serviceProvider.empty",
        }),
      });
      providers.unshift({
        id: "",
        name: this.props.intl.formatMessage({
          id: "app.offers.upload.serviceProvider.pleaseChoose",
        }),
      });
    }

    const attributesChooseText = this.props.intl.formatMessage({
      id: "app.offers.upload.attributes.pleaseChoose",
    });

    return (
      <ScrollableDiv>
        <Row>
          <Col>
            <Header
              className={this.state.desc ? "open" : ""}
              onClick={() =>
                this.setState((prevState) => ({ desc: !prevState.desc }))
              }
            >
              <FormattedMessage id="app.offers.criteria" />
              <CustomFaAngleRight className="arrow" />
            </Header>
            <Wrapper>
              <Collapse in={this.state.desc}>
                <CollapseHelper>
                  <Content>
                    {React.createElement("p", {
                      dangerouslySetInnerHTML: {
                        __html: JSON.parse(this.props.offer.refund.description),
                      },
                    })}
                  </Content>
                </CollapseHelper>
              </Collapse>
            </Wrapper>
          </Col>
        </Row>
        <Content>
          <div ref={this.scrollRef} />
          {validationActive && !validationState && (
            <Row>
              <Col className="pt-4">
                <Alert variant="danger" className="mb-0">
                  <p>
                    <FormattedMessage id="app.account.failed" />
                  </p>
                </Alert>
              </Col>
            </Row>
          )}
          <Form onSubmit={this.submitForm}>
            <Wrapper>
              <FormGroup className="mb-5">
                <Row>
                  <Col sm={12} className="pt-4">
                    <FormCheck id="uploadAccept">
                      <FormCheck.Input
                        className="border-2"
                        style={{ borderWidth: "2px" }}
                        name="criteriaAccepted"
                        onChange={this.handleInputChange}
                        isInvalid={
                          validationActive &&
                          !this.state.validation.criteriaAccepted
                        }
                      />
                      <FormCheck.Label className="fw-bold">
                        <FormattedMessage id="app.offers.uploadAccept" />
                      </FormCheck.Label>
                    </FormCheck>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="mb-3">
                <Row>
                  <Col as={FormLabel} sm={3}>
                    <FormattedMessage id="app.offers.offer" />
                  </Col>
                  <Col sm={9} style={{ alignSelf: "center" }}>
                    <OfferTitle>
                      {this.props.offer.therapyOffer.title}
                    </OfferTitle>
                  </Col>
                </Row>
              </FormGroup>

              {this.props.config.bgmConceptRefundsFieldReceiptTitleEnabled && (
                <FormGroup className="mb-3">
                  <Row>
                    <Col as={FormLabel} sm={3}>
                      <FormattedMessage id="app.offers.receiptTitle" />
                    </Col>
                    <Col sm={9}>
                      <CustomFormControl
                        placeholder={this.props.intl.formatMessage({
                          id: "app.offers.upload.receiptTitle.placeholder",
                        })}
                        value={this.state.receiptTitle}
                        type="text"
                        name="receiptTitle"
                        onChange={this.handleInputChange}
                        maxLength={200}
                        isInvalid={
                          validationActive &&
                          !this.state.validation.receiptTitle
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
              )}

              {this.props.config.bgmConceptRefundsFieldReceiptNumberEnabled && (
                <FormGroup className="mb-3">
                  <Row>
                    <Col as={FormLabel} sm={3}>
                      <FormattedMessage id="app.offers.receiptNumber" />
                    </Col>
                    <Col sm={9}>
                      <CustomFormControl
                        type="text"
                        name="receiptNumber"
                        onChange={this.handleInputChange}
                        maxLength={200}
                        isInvalid={
                          validationActive &&
                          !this.state.validation.receiptNumber
                        }
                      />
                      <p className="form-text mb-0">
                        <FormattedMessage id="app.offers.upload.receiptNumber.help" />
                      </p>
                    </Col>
                  </Row>
                </FormGroup>
              )}

              <FormGroup className="mb-3">
                <Row>
                  <Col as={FormLabel} sm={3}>
                    <FormattedMessage id="app.offers.receiptDate" />
                  </Col>
                  <Col sm={9}>
                    <DayPickerInput
                      value={this.state.date}
                      onDayChange={this.handleDateChange}
                      locale={this.props.intl.locale}
                      disabledDays={Upload.getEnabledDays}
                      dropdownEnabled
                      dropdownYears={Upload.getDropdownYears()}
                      isInvalid={
                        validationActive && !this.state.validation.receiptDate
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>

              {this.props.config.bgmConceptRefundsFieldDurationEnabled && (
                <FormGroup className="mb-3">
                  <Row>
                    <Col as={FormLabel} sm={3}>
                      <FormattedMessage id="app.offers.upload.duration" />
                    </Col>
                    <Col sm={9}>
                      <CustomFormControl
                        value={this.state.duration ? this.state.duration : ""}
                        type="text"
                        name="duration"
                        onChange={this.handleInputChange}
                        isInvalid={
                          validationActive && !this.state.validation.duration
                        }
                      />
                      <p className="form-text mb-0">
                        <FormattedMessage id="app.offers.upload.duration.help" />
                      </p>
                    </Col>
                  </Row>
                </FormGroup>
              )}

              {this.props.config.bgmConceptRefundsFieldServiceProvidersEnabled
                .length > 0 && (
                <FormGroup className="mb-3">
                  <Row>
                    <Col as={FormLabel} sm={3}>
                      <FormattedMessage id="app.offers.upload.serviceProvider" />
                    </Col>
                    <Col sm={9}>
                      <Form.Select
                        className="form-control"
                        value={this.state.serviceProviderId ?? ""}
                        name="serviceProviderId"
                        onChange={this.handleInputChange}
                        isInvalid={
                          validationActive &&
                          !this.state.validation.serviceProvider
                        }
                      >
                        {providers.map((provider) => (
                          <option key={provider.id} value={provider.id}>
                            {provider.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </FormGroup>
              )}

              <FormGroup className="mb-3">
                <Row>
                  <Col as={FormLabel} sm={3}>
                    <FormattedMessage id="app.offers.totalAmount" />
                  </Col>
                  <Col sm={9}>
                    <CustomFormControl
                      type="text"
                      placeholder="0,00"
                      name="totalAmount"
                      onChange={this.handleInputChange}
                      isInvalid={
                        validationActive && !this.state.validation.totalAmount
                      }
                    />
                    {validationActive && !this.state.validation.totalAmount && (
                      <p className="form-text mb-0">
                        <FormattedMessage id="app.offers.upload.amountHelpText" />
                      </p>
                    )}
                  </Col>
                </Row>
              </FormGroup>

              {this.props.config.bgmConceptRefundsFieldTherapyOfferAttributesEnabled.map(
                (attribute, index) => (
                  <FormGroup key={attribute.id} className="mb-3">
                    <Row>
                      <Col as={FormLabel} sm={3}>
                        {attribute.title}
                      </Col>
                      <Col sm={9}>
                        {attribute.multiple ? (
                          <div>
                            {attribute.therapyOfferAttributeValues.map(
                              (value) => (
                                <AttributeIcon
                                  className={
                                    this.isAttributeValueSelected(
                                      attribute.id,
                                      value.id
                                    )
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => {
                                    this.toggleAttributeValue(
                                      attribute.id,
                                      value.id
                                    );
                                    return false;
                                  }}
                                  key={value.id}
                                  src={JSON.parse(value.icon)}
                                  title={value.title}
                                />
                              )
                            )}
                            {index ===
                              this.props.config
                                .bgmConceptRefundsFieldTherapyOfferAttributesEnabled
                                .length -
                                1 && (
                              <FormText style={{ clear: "both" }}>
                                <FormattedMessage id="app.offers.upload.attributes.help" />
                              </FormText>
                            )}
                          </div>
                        ) : (
                          <div>
                            <Form.Select
                              isInvalid={
                                validationActive &&
                                this.state.validation.attributes.get(
                                  attribute.id
                                ) === false
                              }
                              className="form-control"
                              name={`attribute_${attribute.id}`}
                              value={this.getAttributeValue(attribute.id)}
                              onChange={(event) => {
                                this.toggleAttributeValue(
                                  attribute.id,
                                  event.target.value
                                );
                                return false;
                              }}
                            >
                              <option value={0}>{attributesChooseText}</option>
                              {attribute.therapyOfferAttributeValues.map(
                                (value) => (
                                  <option value={value.id} key={value.id}>
                                    {value.title}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                )
              )}
              <FormGroup className={this.state.files[1] ? "mb-2" : ""}>
                <Row>
                  <Col as={FormLabel} sm={3} xs={12}>
                    <FormattedMessage id="app.offers.upload.file" />
                  </Col>
                  <Col sm={6} xs={9}>
                    <FileFormControl
                      type="file"
                      name="file"
                      key={`file_${this.state.fileKeys[1]}`}
                      accept={Upload.getAllowedFiles()}
                      onDrop={Upload.handleFileInputOnDrop}
                      onChange={(event) => {
                        this.handleFileInputChange(event, 1);
                      }}
                      isInvalid={
                        validationActive && !this.state.validation.files
                      }
                      // required="required"
                    />
                  </Col>
                  <Col sm={3} xs={3}>
                    {this.state.files[1] && (
                      <Button
                        variant="primary"
                        className="pull-right"
                        onClick={() => {
                          this.resetFile(1);
                        }}
                      >
                        <FormattedMessage id="app.global.delete" />
                      </Button>
                    )}
                  </Col>
                </Row>
              </FormGroup>
              {(this.state.files[1] || this.state.files[2]) && (
                <FormGroup className={this.state.files[2] ? "mb-2" : ""}>
                  <Row>
                    <Col sm={{ span: 6, offset: 3 }} xs={9}>
                      <FileFormControl
                        type="file"
                        key={`file_${this.state.fileKeys[2]}`}
                        name="file_1"
                        accept={Upload.getAllowedFiles()}
                        onDrop={Upload.handleFileInputOnDrop}
                        onChange={(event) => {
                          this.handleFileInputChange(event, 2);
                        }}
                      />
                    </Col>
                    <Col sm={3} xs={3}>
                      {this.state.files[2] && (
                        <Button
                          variant="primary"
                          className="pull-right"
                          onClick={() => {
                            this.resetFile(2);
                          }}
                        >
                          <FormattedMessage id="app.global.delete" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
              )}
              {(this.state.files[2] || this.state.files[3]) && (
                <FormGroup className={this.state.files[3] ? "mb-2" : ""}>
                  <Row>
                    <Col sm={{ span: 6, offset: 3 }} xs={9}>
                      <FileFormControl
                        type="file"
                        name="file_2"
                        key={`file_${this.state.fileKeys[3]}`}
                        accept={Upload.getAllowedFiles()}
                        onDrop={Upload.handleFileInputOnDrop}
                        onChange={(event) => {
                          this.handleFileInputChange(event, 3);
                        }}
                      />
                    </Col>
                    <Col sm={3} xs={3}>
                      {this.state.files[3] && (
                        <Button
                          variant="primary"
                          className="pull-right"
                          onClick={() => {
                            this.resetFile(3);
                          }}
                        >
                          <FormattedMessage id="app.global.delete" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
              )}

              <FormGroup className="mb-1">
                <Row>
                  <Col sm={{ span: 9, offset: 3 }} xs={12}>
                    <p className="form-text">
                      <FormattedMessage id="app.offers.upload.file.help" />
                    </p>
                  </Col>
                </Row>
              </FormGroup>

              {this.props.config.bgmConceptRefundsFieldCommentEnabled && (
                <FormGroup className="mb-3">
                  <Row>
                    <Col as={FormLabel} sm={3}>
                      <FormattedMessage id="app.offers.comment" />
                    </Col>
                    <Col sm={9}>
                      <CustomFormControl
                        className="form-control"
                        placeholder={this.props.intl.formatMessage({
                          id: "app.offers.upload.comment.placeholder",
                        })}
                        value={this.state.comment}
                        name="comment"
                        onChange={this.handleInputChange}
                        rows={6}
                        type="text"
                        as="textarea"
                        isInvalid={
                          validationActive && !this.state.validation.comment
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
              )}

              <Row className="mb-5 mt-2">
                <Col>
                  <SubHeader>
                    <FormattedMessage id="app.account.bankAccount" />
                  </SubHeader>
                  <hr className="mt-0" />
                  <AccountingCheck
                    setBankDetailsExist={(bankDetailsExist) =>
                      this.setState({ bankDetailsExist })
                    }
                  />
                </Col>
              </Row>
            </Wrapper>

            <Row>
              <Col className="pt-4">
                <ButtonContent>
                  <div className="d-grid">
                    <Button
                      disabled={!this.state.bankDetailsExist}
                      processing={this.state.uploadInProgress}
                      type="submit"
                      variant="primary"
                      size="lg"
                    >
                      <FormattedMessage id="app.offers.upload" />
                    </Button>
                  </div>
                </ButtonContent>
                {this.state.uploadError && (
                  <Alert variant="danger">
                    <p>
                      <FormattedMessage id="app.offers.uploadError" />
                    </p>
                  </Alert>
                )}
              </Col>
            </Row>
          </Form>
        </Content>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
      </ScrollableDiv>
    );
  }
}

export default graphql(RECEIPT_UPLOAD_MUTATION)(injectIntl(Upload));
