import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { Col, FormLabel, FormGroup, Row, Modal, Button } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Accounting from "./Accounting";
import { ACCOUNTING_QUERY } from "./Accounting.graphql";

class AccountingCheck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moduleConfig: null,
      dataFilled: false,
      accountOwner: "",
      iban: "",
      bic: "",
      showEditModal: false,
    };
  }

  componentDidUpdate() {
    if (!this.props.data.loading && !this.state.dataFilled) {
      let moduleConfig = null;

      this.props.data.config.modules.forEach((module) => {
        if (module.__typename === "ModuleConfigPayment") {
          moduleConfig = module;
        }
      });

      this.setState({
        moduleConfig,
        dataFilled: true,
        accountOwner: this.props.data.guest.debitor.accountOwner,
        iban: this.props.data.guest.debitor.iban,
        bic: this.props.data.guest.debitor.bic,
      });

      const bankDetailsExist = !!(
        this.props.data.guest.debitor.accountOwner &&
        this.props.data.guest.debitor.iban &&
        this.props.data.guest.debitor.bic
      );
      this.props.setBankDetailsExist(bankDetailsExist);
    }
  }

  update = () => {
    this.setState({ dataFilled: false }, () => {
      this.props.data.refetch();
    });
  };

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    if (!this.state.moduleConfig) {
      return null;
    }

    const bankDetailsExist = !!(
      this.state.accountOwner &&
      this.state.iban &&
      this.state.bic
    );

    return (
      <>
        {bankDetailsExist && (
          <>
            <Row>
              <Col xs={12} sm={4}>
                <FormGroup className="mb-3">
                  <FormLabel style={{ marginBottom: "0.2rem" }}>
                    <FormattedMessage id="app.account.accountOwner" />
                  </FormLabel>
                  <p style={{ marginBottom: "0.5rem" }}>
                    {this.state.accountOwner}
                  </p>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4}>
                <FormGroup className="mb-3">
                  <FormLabel style={{ marginBottom: "0.2rem" }}>
                    <FormattedMessage id="app.account.bic" />
                  </FormLabel>
                  <p style={{ marginBottom: "0.5rem" }}>{this.state.bic}</p>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4}>
                <FormGroup className="mb-3">
                  <FormLabel style={{ marginBottom: "0.2rem" }}>
                    <FormattedMessage id="app.account.iban" />
                  </FormLabel>
                  <p style={{ marginBottom: "0.5rem" }}>
                    {this.state.iban && `${this.state.iban.slice(0, -5)}XXXXX`}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={() => this.setState({ showEditModal: true })}
                >
                  <FormattedMessage id="app.account.edit" />
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Modal
          show={this.state.showEditModal || !bankDetailsExist}
          onHide={() => this.setState({ showEditModal: false })}
        >
          <Modal.Header closeButton={bankDetailsExist}>
            <Modal.Title>
              <span>
                <FormattedMessage id="app.account.bankAccount" />
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Accounting
              onUpdate={this.update}
              isModal
              disableSepa
              enableDefaultEdit
              onClose={() => this.setState({ showEditModal: false })}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default compose(
  graphql(ACCOUNTING_QUERY, { options: { fetchPolicy: "network-only" } })
)(injectIntl(AccountingCheck));
