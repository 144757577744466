import React, { useState, useEffect } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { Redirect } from "react-router-dom";

import { LOGOUT_MUTATION } from "./Logout.graphql";
import { removeBearer, setIsPasswordExpired } from "../utils/localStorage";

function Logout({ mutate }) {
  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    mutate({}).then(() => {
      removeBearer();
      setLoggedIn(false);
      setIsPasswordExpired(false);
    });
  }, []);

  if (loggedIn) return null;

  return <Redirect to={{ pathname: "/login" }} />;
}

export default graphql(LOGOUT_MUTATION)(Logout);
