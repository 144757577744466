import * as React from "react";

import { getLanguage } from "../../utils/localStorage";

import { getAvailableLanguages } from "../../utils/language";

export const LanguageContext = React.createContext({
  languageContext: {
    messages: [],
    language: getLanguage() ?? "de",
    availableLanguages: getAvailableLanguages(["de"]),
    selectionAvailable: getAvailableLanguages(["de"]).length > 1,
    setLanguage: () => {},
  },
});
