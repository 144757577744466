import React, { Component } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import Layout from "../../components/Layout";
import Container from "../../globalComponents/Container";
import CustomPanel from "../../globalComponents/CustomPanel";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import NewsList from "./NewsList";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { NEWS_QUERY } from "./News.graphql";

const NewsContainer = styled(Container)`
  padding-bottom: 52px !important;
`;

class News extends Component {
  render() {
    return (
      <FormattedMessage id="app.news.title">
        {(newsTitle) => (
          <Layout {...this.props} title={newsTitle} style={{ height: "auto" }}>
            <Helmet title={newsTitle} />
            <NewsContainer className="news">
              <AlertModalBox
                title="error"
                text="error"
                ref={(modal) => {
                  // eslint-disable-next-line react/no-unused-class-component-methods
                  this._alertModal = modal;
                }}
              />
              <br />
              <CustomPanel>
                {this.props.data.loading ? (
                  <LoadingCol />
                ) : (
                  <NewsList list={this.props.data.guest.news} />
                )}
              </CustomPanel>
            </NewsContainer>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default graphql(NEWS_QUERY)(injectIntl(News));
