import { gql } from "@apollo/client";

export const PRIVACY_POLICY_QUERY = gql`
  query privacyPolicyQuery {
    frontend {
      employee {
        privacyPolicy {
          title
          content
          id
        }
      }
    }
  }
`;
export const REGISTER_GUEST_MUTATION = gql`
  mutation registerGuest(
    $registerKey: ID!
    $guest: GuestRegisterInput!
    $privacyPolicyAcceptedId: ID!
    $username: String!
  ) {
    registerGuest(
      registerKey: $registerKey
      guest: $guest
      privacyPolicyAcceptedId: $privacyPolicyAcceptedId
      username: $username
    )
  }
`;
