import React from "react";
import Helmet from "react-helmet";
import { Form, FormGroup, Col, Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import styled, { ThemeProvider } from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import * as queryString from "query-string";
import FormControl from "../../components/CustomBootstrap/FormControl";
import Button from "../../components/CustomBootstrap/Button";
import { handleSubmit, handleChange } from "./actions";
import AlertModalBox from "../../globalComponents/AlertModalBox";

import { theme } from "../../globalComponents/Theme";
import Conf from "../../Conf";
import {
  FooterMenu,
  FooterItem,
  SelectLi,
} from "../../components/Footer/FooterStyles";

import { LOGIN_QUERY } from "./Login.graphql";
import { getBearer, setIsPasswordExpired } from "../../utils/localStorage";

import LanguageSelect from "../Language/LanguageSelect";
import { withLanguage } from "../../context/LanguageContext/withLanguage";

const Wrapper = styled("div")`
  height: 100%;
`;

const LoginFooter = styled(FooterMenu)`
  display: flex;
  flex-direction: column;
  position: relative !important;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Logo = styled("img")`
  width: 100%;
`;

const Container = styled("div")`
  min-height: calc(100% - 201px);
  padding-bottom: 52px;

  @media screen and (min-width: 768px) {
    min-height: calc(100% - 52px);
  }
  @media screen and (min-width: 1310px) {
    width: 1280px;
  }
  .login-links a {
    display: block;
    line-height: 20px;
  }
`;

const CustomFormControl = styled(FormControl)`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

class Login extends React.Component {
  constructor(props) {
    super(props);

    let samlUrl = false;
    const samlAuthState =
      queryString.parse(props.history.location.search).AuthState !== undefined
        ? queryString.parse(props.history.location.search).AuthState
        : false;
    if (samlAuthState !== false) {
      samlUrl = new URL(samlAuthState.substr(samlAuthState.indexOf(":") + 1));
      const apiUrl = new URL(Conf.api.url);
      const localUrl = new URL(window.location.href);
      if (
        apiUrl.hostname !== samlUrl.hostname &&
        localUrl.hostname !== samlUrl.hostname
      ) {
        // eslint-disable-next-line no-console
        console.warn(`Invalid cross origin saml request: ${samlUrl.origin}`);
        this.props.history.push("/login");
        samlUrl = false;
      }
    }

    this.state = {
      loading: false,
      redirectHome: false,
      samlUrl,
      open: true,
      // eslint-disable-next-line react/no-unused-state
      username: "",
      // eslint-disable-next-line react/no-unused-state
      password: "",
      // eslint-disable-next-line react/no-unused-state
      _alertModal: null,
    };

    this.handleChange = handleChange.bind(this);
    this.handleSubmit = handleSubmit.bind(this);
    this.checkSaml = this.checkSaml.bind(this);
  }

  checkSaml() {
    const bearer = getBearer();
    if (this.state.samlUrl !== false && bearer) {
      window.location.href = `${this.state.samlUrl.href}&token=${bearer}`;
      return true;
    }
    return false;
  }

  componentDidMount() {
    setIsPasswordExpired(false);
    this.checkSaml();
  }

  render() {
    if (this.state.redirectHome) {
      return <Redirect push to={{ pathname: "/privacy-policy-accept" }} />;
    }

    const closeModal = () => this.setState({ open: false });

    return (
      <ThemeProvider theme={theme}>
        <Wrapper className="wrapper">
          <Helmet
            title={`${this.props.intl.formatMessage({
              id: "app.login.title",
            })} - ${this.props.intl.formatMessage({ id: "app.title" })}`}
          >
            <meta
              name="application-name"
              content={this.props.intl.formatMessage({ id: "app.title" })}
            />
            <meta
              name="msapplication-tooltip"
              content={this.props.intl.formatMessage({ id: "app.title" })}
            />
            <meta
              name="apple-mobile-web-app-title"
              content={this.props.intl.formatMessage({ id: "app.title" })}
            />
          </Helmet>

          {window.location.host.search("test") !== -1 ? (
            <Modal show={this.state.open} onHide={closeModal}>
              <Modal.Header closeButton="closeButton">
                <Modal.Title>
                  <span>Achtung!</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Du befindest dich im Testsystem zum Gesundheitsbudget.
                  <br />
                  Zu deinem persönlichen Login gelangst du
                  <a
                    href="https://gebu.bgm.services"
                    title="zum Gesundheitsbudget"
                  >
                    hier
                  </a>
                  .
                </p>
              </Modal.Body>
              <Modal.Footer />
            </Modal>
          ) : (
            ""
          )}

          {this.props.data.loading === false &&
          JSON.parse(this.props.data.frontend.staticContent.content) !== "" ? (
            <Modal show={this.state.open} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>
                    <FormattedMessage id="app.login.legal.title" />
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {React.createElement("p", {
                  dangerouslySetInnerHTML: {
                    __html: JSON.parse(
                      this.props.data.frontend.staticContent.content
                    ),
                  },
                })}
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={closeModal}>
                  {this.props.intl.formatMessage({ id: "app.modal.close" })}
                </Button>
              </Modal.Footer>
            </Modal>
          ) : (
            ""
          )}
          <Container className="container login">
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={5}
              xl={4}
              xxl={3}
              className="login-inner"
            >
              <Logo
                className="logo"
                src={Conf.theme.logo.src}
                alt={this.props.intl.formatMessage({ id: "app.logo.alt" })}
              />
              <Form method="POST" onSubmit={this.handleSubmit}>
                <FormGroup controlId="formHorizontalEmail" className="mb-3">
                  <FormattedMessage id="app.login.username">
                    {(message) => (
                      <CustomFormControl
                        size="lg"
                        type="text"
                        placeholder={message}
                        name="username"
                        onChange={this.handleChange}
                      />
                    )}
                  </FormattedMessage>
                </FormGroup>
                <FormGroup controlId="formHorizontalPassword" className="mb-3">
                  <FormattedMessage id="app.login.password">
                    {(message) => (
                      <CustomFormControl
                        size="lg"
                        type="password"
                        placeholder={message}
                        name="password"
                        onChange={this.handleChange}
                      />
                    )}
                  </FormattedMessage>
                </FormGroup>
                <FormGroup className="d-grid mb-3">
                  <Button
                    processing={this.state.loading}
                    type="submit"
                    variant="primary"
                    size="lg"
                  >
                    <FormattedMessage id="app.login.login" />
                  </Button>
                </FormGroup>
                <FormGroup>
                  <div className="login-links">
                    <a href="/password/reset">
                      <FormattedMessage id="app.password.reset" />
                    </a>
                    {this.props.modules.includes("REGISTER") ? (
                      <a href="/register" className="mt-3">
                        <FormattedMessage id="app.login.register" />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </FormGroup>
              </Form>
              <AlertModalBox
                title="error"
                text="error"
                ref={(modal) => {
                  // eslint-disable-next-line react/no-unused-class-component-methods
                  this._alertModal = modal;
                }}
              />
            </Col>
          </Container>
          <LoginFooter className="footer-navigation">
            {Conf.basicLinks.map((link) =>
              link.name === "help" ? (
                ""
              ) : (
                <FooterItem
                  key={link.key}
                  url={link.publicRoute ? link.publicRoute : link.route}
                >
                  <FormattedMessage id={link.name} />
                </FooterItem>
              )
            )}
            {this.props.languageContext.selectionAvailable && (
              <SelectLi>
                <LanguageSelect />
              </SelectLi>
            )}
          </LoginFooter>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

export default withLanguage(graphql(LOGIN_QUERY)(injectIntl(Login)));
