import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import Conf from "../../Conf";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../../components/CustomBootstrap/Button";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import { UPDATE_USERNAME_MUTATION } from "./SaveUsername.graphql";

class SaveUsername extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress: false,
    };

    this.parseError = parseError.bind(this);
  }

  onClick() {
    if (this.props.onValidate()) {
      this.setState({ inProgress: true });

      this.props
        .mutate({ variables: { username: this.props.data.username } })
        .then(() => {
          this.props.onFinish();
          this.setState({
            inProgress: false,
          });
        })
        .catch((error) => {
          this.setState({ inProgress: false });

          const errorTextTranslationKey = this.parseError(
            error,
            Conf.errorContext.accountSaveUsername
          );

          this.props.onError(error, errorTextTranslationKey);
        });
    }
  }

  render() {
    return this.state.inProgress ? (
      <LoadingCol height="50" />
    ) : (
      <div className="d-grid">
        <Button onClick={this.onClick.bind(this)} variant="danger" size="lg">
          <FormattedMessage id="app.account.changeUsername" />
        </Button>
      </div>
    );
  }
}

export default graphql(UPDATE_USERNAME_MUTATION)(injectIntl(SaveUsername));
