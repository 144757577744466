import React, { Component } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import Conf from "../Conf";
import Icon from "./Icon";
import NewsBadge from "../containers/News/NewsBadge";

const Wrapper = styled("div")`
  background-color: #ffffff;
  border-top: 1px solid #d4d4d4;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 90;

  @media screen and (min-width: 992px) {
    bottom: initial;
    border-top: 0;
    border-bottom: 1px solid #d4d4d4;
    height: 51px;
    top: 62px;
    z-index: 90;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    border-top: 1px solid #d4d4d4 !important;
    bottom: 0 !important;
    height: auto;
    top: initial !important;
    position: fixed !important;
    z-index: 100 !important;
  }
`;

const Container = styled("div")`
  padding: 0 15px !important;
  width: 100% !important;

  @media screen and (min-width: 1200px) {
    width: 1190px !important;
    padding: 0 5px !important;
  }

  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: 0 !important;
  }
`;

const NavigationContainer = styled("ul")`
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0;

  .more-menu-wrapper {
    padding: 0 10px;
  }

  @media screen and (min-width: 992px) {
    justify-content: space-between;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    justify-content: space-around !important;
  }
`;

const MoreMenuContainer = styled("ul")`
  background-color: #ffffff;
  border-bottom: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
  border-top: 1px solid #d4d4d4;
  bottom: 100%;
  display: flex;
  flex-direction: column;
  height: 0;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out;
  width: 100%;

  &.active {
    height: auto;
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    border-right: 1px solid #d4d4d4;
  }
`;

const Li = styled("li")`
  flex-grow: 1;

  @media screen and (min-width: 992px) {
    flex-grow: 0;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-grow: 1 !important;
  }

  &.more-menu-wrapper {
    position: relative;
  }
`;

const A = styled("a")`
  color: #8a8a8a;
  display: block;
  padding: 10px 0;
  text-align: center;

  .icon {
    margin: auto;
  }

  svg path {
    fill: #8a8a8a;
  }

  &:hover {
    color: ${(props) => props.theme.link};
    cursor: pointer;
    text-decoration: none;

    svg * {
      fill: ${(props) => props.theme.link} !important;
    }
  }

  @media screen and (min-width: 992px) {
    align-items: center;
    display: flex;
    .item {
      margin: 0;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    display: block !important;
    .item {
      margin: auto !important;
    }
  }
`;

const LinkA = styled(NavLink)`
  color: #8a8a8a;
  display: block;
  padding: 10px 0;
  position: relative;
  text-align: center;

  .icon {
    margin: auto;
  }

  &:hover,
  &.active {
    color: ${(props) => props.theme.link};
    cursor: pointer;
    text-decoration: none;

    svg * {
      fill: ${(props) => props.theme.link} !important;
    }
  }

  @media screen and (min-width: 992px) {
    align-items: center;
    display: flex;
    .item {
      margin: 0;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    display: block !important;
    .item {
      margin: auto !important;
    }
  }
`;

const NavTitle = styled("div")`
  display: block;

  @media screen and (min-width: 992px) {
    display: inline-block;
    padding-left: 5px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    display: block !important;
    padding-left: 0 !important;
  }
`;

const BatchContainer = styled("div")`
  display: inline-block;
  position: relative;
`;

class Navigation extends Component {
  /**
   * function
   * - remove navigationItems that doesn't fit
   */
  checkIconsFitNavigation() {
    const navigationResource = document.getElementById(
      "icon-navigation-resource"
    );
    const currentNavigation = document.getElementById("icon-navigation");
    const currentNavigationSpace = currentNavigation?.offsetWidth;
    const iconAmount = Conf.navigation.items.filter((icon) =>
      this.props.modules.includes(icon.module)
    ).length;
    const iconSpace = 72; // defining space that an icon deserves
    let navigationSpaceNeeded = iconAmount * iconSpace;
    const moreMenuWrapper =
      document.getElementsByClassName("more-menu-wrapper");
    const moreMenu = document.getElementsByClassName("more-menu");

    if (navigationSpaceNeeded > currentNavigationSpace) {
      this.setState({ hasMoreMenu: true });

      const newNavigation = navigationResource.cloneNode(true);

      /**
       * change it's ID so it's recognizable as the current real navigation
       */
      newNavigation.classList.remove("d-none");
      newNavigation.id = "icon-navigation";

      const navigationItems = newNavigation.getElementsByTagName("li");
      let navigationItemsArray = [];
      const moreItem = currentNavigation.lastChild;

      /**
       * function
       * - iterate through navigationItems and add value of (key, value) pair to navigationItemArray
       */
      for (let i = 0; i < navigationItems.length; i++) {
        navigationItemsArray.push(navigationItems[i]);
      }

      /**
       * removing the more-item
       * to calculate space for real navItems
       * and append more item afterwards
       * (it was just in there so we can work with it)
       */
      newNavigation.removeChild(newNavigation.lastChild);
      navigationItemsArray.pop();

      /** function
       *  - navigation: remove all items that do not fit
       */
      while (
        newNavigation.lastChild &&
        currentNavigationSpace < navigationSpaceNeeded
      ) {
        newNavigation.removeChild(newNavigation.lastChild);
        navigationSpaceNeeded -= iconSpace;
      }

      /**
       * remove just one more item from navigation
       * in order to make place for the more-item
       */
      newNavigation.removeChild(newNavigation.lastChild);

      /**
       * AMOUNT of items for more-menu
       * - subtract number of items that stayed in navigation
       *   from total number of navigation items
       */
      const moreItemAmount =
        navigationItemsArray.length -
        newNavigation.getElementsByTagName("li").length;

      /**
       * keep only the amount of items that should go to more-menu
       * (amount of items started from end of array by using minus "-")
       */
      navigationItemsArray = navigationItemsArray.slice(-moreItemAmount);

      /**
       * reverse order of left items for better UX
       */
      navigationItemsArray.reverse();

      /**
       * un-hide the more menu
       */
      moreMenuWrapper[0].classList.remove("d-none");

      /**
       * clear current more-menu items
       * to make space for the new calculated ones
       */
      while (moreMenu[0].lastChild) {
        moreMenu[0].removeChild(moreMenu[0].lastChild);
      }

      /**
       * append all items that didn't make into the navigation
       */
      navigationItemsArray.forEach((item) => {
        moreMenu[0].appendChild(item);
      });

      /**
       * add more-menu item to navigation
       */
      newNavigation.appendChild(moreItem);

      /**
       * function
       * - replace current navigation by calculated one
       */
      currentNavigation.parentNode.replaceChild(
        newNavigation,
        currentNavigation
      );
    }

    /**
     * if
     * - more-menu was applied
     * - and we have more space now than the nav needs
     */
    if (
      this.state.hasMoreMenu &&
      iconAmount * iconSpace < currentNavigationSpace
    ) {
      /**
       * hide more-menu
       */
      moreMenuWrapper[0].classList.add("d-none");

      /**
       * get all more-menu items to append them back to navigation
       */
      const moreMenuItems = moreMenu[0].getElementsByTagName("li");

      /**
       * only append to navigation if there is something to append ;)
       */
      if (moreMenuItems.length > 0) {
        /**
         * create an array with all items form more-menu
         */
        let moreMenuItemsArray = [];
        for (let i = 0; i < moreMenuItems.length; i++) {
          moreMenuItemsArray.push(moreMenuItems[i]);
        }

        /**
         * (re)reverse order because they were reversed when added to more-menu
         */
        moreMenuItemsArray = moreMenuItemsArray.reverse();

        /**
         * append all items from more-menu to navigation
         */
        moreMenuItemsArray.forEach((item) => {
          currentNavigation.appendChild(item);
        });

        /**
         * move more-menu to the end of navigation
         * since the logic to calculate navigation icons need it that way
         */
        currentNavigation.appendChild(moreMenuWrapper[0]);

        /**
         * clear current more-menu items
         * to make space for the new calculated ones
         */
        while (moreMenu[0].lastChild) {
          moreMenu[0].removeChild(moreMenu[0].lastChild);
        }
      }
    }
  }

  constructor() {
    super();

    this.state = {
      isToggleOn: false,
      hasMoreMenu: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.checkIconsFitNavigation = this.checkIconsFitNavigation.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({ isToggleOn: !prevState.isToggleOn }));
  }

  componentDidMount() {
    this.checkIconsFitNavigation();
    window.addEventListener("resize", this.checkIconsFitNavigation);
  }

  render() {
    const { modules } = this.props;
    return (
      <Wrapper>
        <Container className="container">
          <NavigationContainer id="icon-navigation">
            {Conf.navigation.items.map((icon) =>
              modules.includes(icon.module) ? (
                <Li
                  className={`nav-item-${icon.module.toLowerCase()}`}
                  key={icon.id}
                >
                  <LinkA
                    to={icon.route}
                    activeClassName="active"
                    exact={icon.route.length <= 1}
                  >
                    <BatchContainer>
                      <Icon
                        src={icon.src}
                        noTitle
                        className="icon inactive"
                        onLoad={this.checkIconsFitNavigation}
                      />{" "}
                      {icon.id === "news" ? <NewsBadge /> : ""}
                    </BatchContainer>
                    <NavTitle>
                      {this.props.intl.formatMessage({ id: icon.name })}
                    </NavTitle>
                  </LinkA>
                </Li>
              ) : (
                ""
              )
            )}
            <Li onClick={this.handleClick} className="more-menu-wrapper d-none">
              <A>
                <Icon
                  src={Conf.navigation.more.src}
                  className="icon inactive"
                />
                <NavTitle>
                  <FormattedMessage id={Conf.navigation.more.name} />
                </NavTitle>
              </A>
              <MoreMenuContainer
                className={
                  this.state.isToggleOn ? "active more-menu" : "more-menu"
                }
              />
            </Li>
          </NavigationContainer>
        </Container>

        <NavigationContainer id="icon-navigation-resource" className="d-none">
          {Conf.navigation.items.map((icon) =>
            modules.includes(icon.module) ? (
              <Li
                className={`nav-item-${icon.module.toLowerCase()}`}
                key={icon.key}
              >
                <LinkA to={icon.route} exact>
                  <BatchContainer>
                    <Icon src={icon.src} className="icon inactive" />{" "}
                    {icon.id === "news" ? <NewsBadge /> : ""}
                  </BatchContainer>
                  <NavTitle>
                    <FormattedMessage id={icon.name} />
                  </NavTitle>
                </LinkA>
              </Li>
            ) : (
              ""
            )
          )}
          <Li>
            <A>
              <Icon
                src={Conf.navigation.more.src}
                className="inactive"
                onLoad={this.checkIconsFitNavigation}
              />
              <NavTitle>
                <FormattedMessage id={Conf.navigation.more.name} />
              </NavTitle>
            </A>
            <MoreMenuContainer />
          </Li>
        </NavigationContainer>
      </Wrapper>
    );
  }
}

export default injectIntl(Navigation);
