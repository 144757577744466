import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { Collapse, Button } from "react-bootstrap";
import { Title, CustomFaAngleRight, Header } from "../../globalComponents/List";
import Icon from "../../globalComponents/Icon";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import Upload from "./Upload";
import { PRESCRIPTIONS_QUERY } from "./PrescriptionList.graphql";
import { DateFormatter } from "../../globalComponents/DateFormatter";

const CustomHeader = styled(Header)`
  padding-left: 15px;

  &.open {
    padding: 0 15px 0 15px;
  }
`;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 1px solid #d4d4d4;
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

const UploadWrapper = styled("div")`
  margin-top: 30px;
  margin-bottom: 30px;
`;

class PrescriptionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPrescription: null,
    };

    this.showPrescription = this.showPrescription.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.setState({ currentPrescription: null });
  }

  showPrescription(prescription) {
    window.customBackAction = this.goBack;
    this.setState({
      currentPrescription: prescription,
    });
  }

  static getIcon(prescription) {
    if (prescription.checked !== true) {
      return (
        <Icon
          className="icon-themed"
          src="/static/frontend/employee/images/prescription-new.svg"
        />
      );
    }
    if (prescription.credit === 0) {
      return (
        <Icon
          className="icon-themed"
          src="/static/frontend/employee/images/prescription-rejected.svg"
        />
      );
    }
    if (
      prescription.used < prescription.credit ||
      prescription.receiptUrl === null
    ) {
      return (
        <Icon
          className="icon-themed"
          src="/static/frontend/employee/images/prescription-checked.svg"
        />
      );
    }
    return (
      <Icon
        className="icon-themed"
        src="/static/frontend/employee/images/prescription-download.svg"
      />
    );
  }

  render() {
    const isDataReady = !this.props.data.loading;

    return (
      <div>
        <UploadWrapper>
          <Upload />
        </UploadWrapper>
        {isDataReady || this.state.loadingMore ? (
          this.props.data.guest.prescriptions.length === 0 ? (
            <VerticallyAlignedMiddle height={window.innerHeight / 2}>
              <span className="large">
                <FormattedMessage id="app.prescriptions.noPrescriptions" />
              </span>
            </VerticallyAlignedMiddle>
          ) : (
            this.props.data.guest.prescriptions.map((prescription) => (
              <div key={prescription.id}>
                <CustomHeader
                  className={
                    this.state.currentPrescription &&
                    this.state.currentPrescription.id === prescription.id
                      ? "open"
                      : ""
                  }
                  onClick={() =>
                    this.state.currentPrescription &&
                    this.state.currentPrescription.id === prescription.id
                      ? this.showPrescription(null)
                      : this.showPrescription(prescription)
                  }
                >
                  {PrescriptionList.getIcon(prescription)}
                  <Title>
                    {prescription.credit > 0 ? (
                      <span>{prescription.credit}x&nbsp;</span>
                    ) : (
                      ""
                    )}
                    {prescription.text}&nbsp;{" "}
                    {prescription.credit === 0 ? (
                      <FormattedMessage id="app.prescriptions.rejected" />
                    ) : (
                      ""
                    )}
                    <br />
                    <span />
                  </Title>
                  <CustomFaAngleRight className="arrow" />
                </CustomHeader>
                <Wrapper>
                  <Collapse
                    in={
                      this.state.currentPrescription &&
                      this.state.currentPrescription.id === prescription.id
                    }
                  >
                    <div>
                      <Content>
                        <strong>
                          <FormattedMessage id="app.prescriptions.upload.prescriptionDate.label" />
                        </strong>
                        <DateFormatter
                          date={new Date(prescription.date)}
                          type="DATE"
                        />
                        <br />
                        <strong>
                          <FormattedMessage id="app.prescriptions.checked.label" />
                        </strong>
                        {prescription.checked === true ? (
                          <FormattedMessage id="app.global.yes" />
                        ) : (
                          <FormattedMessage id="app.global.no" />
                        )}
                        <br />
                        <br />{" "}
                        {prescription.events.length > 0 ? (
                          <strong>
                            <FormattedMessage id="app.prescriptions.dates.label" />
                          </strong>
                        ) : (
                          ""
                        )}
                        {prescription.events.map((event) => (
                          <p key={event.id}>
                            <DateFormatter
                              date={new Date(event.eventGuest.date)}
                              type="DATE"
                            />
                          </p>
                        ))}
                        {prescription.credit === 0 ? (
                          <FormattedMessage id="app.prescriptions.rejectedInfo" />
                        ) : (
                          ""
                        )}
                        {prescription.receiptUrl !== null ? (
                          <UploadWrapper>
                            <div className="d-grid">
                              <Button
                                variant="primary"
                                size="lg"
                                onClick={() =>
                                  window.open(
                                    JSON.parse(prescription.receiptUrl)
                                  )
                                }
                              >
                                <FormattedMessage id="app.prescriptions.downloadReceipt" />
                              </Button>
                            </div>
                          </UploadWrapper>
                        ) : (
                          ""
                        )}
                      </Content>
                    </div>
                  </Collapse>
                </Wrapper>
              </div>
            ))
          )
        ) : (
          <LoadingCol />
        )}
      </div>
    );
  }
}

const withData = graphql(PRESCRIPTIONS_QUERY, {
  options: () => ({ fetchPolicy: "cache-and-network" }),
});

export default withData(injectIntl(PrescriptionList));
