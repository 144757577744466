import { gql } from "@apollo/client";

export const UPDATE_DATE_MUTATION = gql`
  mutation updateDate(
    $info: String
    $timeStart: Time!
    $timeEnd: Time!
    $id: ID!
  ) {
    updateDate(
      date: { info: $info, timeStart: $timeStart, timeEnd: $timeEnd }
      id: $id
    ) {
      timeStart
      timeEnd
      id
      info
    }
  }
`;
