import { gql } from "@apollo/client";

export const IMPRINT_QUERY = gql`
  query {
    frontend {
      staticContent(type: EMPLOYEE_IMPRESS) {
        title
        content
      }
    }
  }
`;
