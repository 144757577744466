import * as React from "react";
import { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { AppointmentBookingForm } from "../Booking/AppointmentBookingForm";
import { CourseBookingForm } from "../Booking/CourseBookingForm";

export const CalendarModal = (props) => {
  const dateEditableUntil = useMemo(
    () =>
      props.config.modules.find((module) => module.dateEditableUntil)
        ?.dateEditableUntil,
    [props.config.modules]
  );
  const courseCancelableUntil = useMemo(
    () =>
      props.config.modules.find((module) => module.courseCancelableUntil)
        ?.courseCancelableUntil,
    [props.config.modules]
  );
  const reservationEnabled = useMemo(
    () =>
      props.config.modules.find((module) => module.reservationEnabled)
        ?.reservationEnabled,
    [props.config.modules]
  );

  const config = props.config.modules.filter((w) => w.employee)[0].employee;

  const renderTitle = () => {
    switch (props.data.event.type) {
      case "COURSE":
        return <FormattedMessage id="app.offers.bookCourse" />;
      case "DEFAULT":
        return <FormattedMessage id="app.offers.book" />;
      default:
        return null;
    }
  };

  const renderBody = () => {
    switch (props.data.event.type) {
      case "COURSE":
        return (
          <CourseBookingForm
            title={props.data.event?.offers?.[0]?.offer?.title}
            config={config}
            courses={
              // The data must be put in the correct structure
              [
                {
                  ...props.data.event.roomEvent,
                  events: [props.data.event],
                  room: props.data.event.room,
                  timeBegin: props.data.event.timeStart,
                  timeEnd: props.data.event.timeEnd,
                },
              ]
            }
            therapyOfferId={props.therapyOfferId}
            employeeBudgetLeft={props.employeeBudgetLeft}
            courseCancelableUntil={courseCancelableUntil}
            week={props.week}
            closeModal={props.close}
          />
        );
      case "DEFAULT":
        return (
          <AppointmentBookingForm
            title={props.data.event?.offers?.[0]?.offer?.title}
            config={config}
            couponsEnabled={props.enableCoupons}
            eventDayList={
              // The data must be put in the correct structure
              [{ events: [props.data.event], date: props.data.event.date }]
            }
            therapyOfferId={props.data.offerId}
            employeeBudgetLeft={props.employeeBudgetLeft}
            dateEditableUntil={dateEditableUntil}
            reservationEnabled={reservationEnabled}
            week={props.week}
            closeModal={props.close}
            history={props.history}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal show onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>{renderTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderBody()}</Modal.Body>
    </Modal>
  );
};
