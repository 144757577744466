import React, { Component } from "react";
import {
  Alert,
  Row,
  Col,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  FormText,
  Modal,
} from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import Button from "../CustomBootstrap/Button";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import Conf from "../../Conf";
import { INVOKE_COUPON_MUTATION } from "./Invoke.graphql";

const CustomFormControl = styled(FormControl)`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

const ButtonContent = styled("div")`
  padding: 15px 0;
`;

class Invoke extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      showForm: false,
      uploadIsDone: false,
      uploadError: false,
      errorMessage: null,
      uploadInProgress: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.parseError = parseError.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = Invoke.formatCode(target.value);

    this.setState({ [name]: value });
  }

  static formatCode(code) {
    const cleanCode = code
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, "")
      .substr(0, 16);
    let formatCode = cleanCode;
    if (formatCode.length > 12) {
      formatCode = `${cleanCode.substr(0, 4)}-${cleanCode.substr(
        4,
        4
      )}-${cleanCode.substr(8, 4)}-${cleanCode.substr(12, 4)}`;
    } else if (formatCode.length > 8) {
      formatCode = `${cleanCode.substr(0, 4)}-${cleanCode.substr(
        4,
        4
      )}-${cleanCode.substr(8, 4)}`;
    } else if (formatCode.length > 4) {
      formatCode = `${cleanCode.substr(0, 4)}-${cleanCode.substr(4, 4)}`;
    }
    if (
      code.substr(-1) === "-" &&
      (cleanCode.length === 4 ||
        cleanCode.length === 8 ||
        cleanCode.length === 12)
    ) {
      formatCode += "-";
    }
    return formatCode;
  }

  submitForm() {
    this.setState({ uploadInProgress: true });

    let errorTextTranslationKey = null;

    this.props
      .mutate({ variables: { code: this.state.code } })
      .then(({ data }) => {
        if (data.invokeCoupon > 0) {
          this.setState({
            uploadIsDone: true,
            uploadInProgress: false,
          });
        } else {
          errorTextTranslationKey = this.parseError(
            data.errors,
            Conf.errorContext.invokeCoupon,
            "processing"
          );
          this._alertModal.open(
            this.props.intl.formatMessage({ id: "app.global.error" })
          );
          this.setState({
            uploadError: true,
            uploadInProgress: false,
            errorMessage: this.props.intl.formatMessage({
              id: errorTextTranslationKey,
            }),
          });
        }
      })
      .catch((errors) => {
        errorTextTranslationKey = this.parseError(
          errors,
          Conf.errorContext.invokeCoupon,
          "transmission"
        );
        this.setState({
          uploadError: true,
          uploadInProgress: false,
          errorMessage: this.props.intl.formatMessage({
            id: errorTextTranslationKey,
          }),
        });
      });
  }

  handleClose() {
    if (this.state.uploadIsDone === true && this.props.onDone !== undefined) {
      this.props.onDone();
    }
    this.setState({ showForm: false });
  }

  render() {
    if (!this.state.showForm) {
      return (
        <div className="d-grid">
          <Button
            variant="primary"
            size="lg"
            onClick={() => this.setState({ showForm: true })}
          >
            <FormattedMessage id="app.coupon.invoke.button" />
          </Button>
        </div>
      );
    }

    return (
      <Modal show={this.state.showForm} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="app.coupon.invoke.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.uploadError ? (
            <Alert variant="danger">
              <p>{this.state.errorMessage}</p>
            </Alert>
          ) : (
            ""
          )}
          {this.state.uploadIsDone ? (
            <Alert variant="success">
              <p>
                <FormattedMessage id="app.coupon.invoke.successful" />
              </p>
            </Alert>
          ) : (
            ""
          )}

          <Form horizontal>
            <FormGroup>
              <Row>
                <Col sm={3}>
                  <FormLabel>
                    <FormattedMessage id="app.coupon.invoke.code.label" />
                  </FormLabel>
                </Col>
                <Col sm={9}>
                  <CustomFormControl
                    disabled={
                      this.state.uploadInProgress || this.state.uploadIsDone
                    }
                    value={this.state.code}
                    placeholder={this.props.intl.formatMessage({
                      id: "app.coupon.invoke.code.placeholder",
                    })}
                    type="text"
                    name="code"
                    pattern="[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}"
                    onChange={this.handleInputChange}
                    required="required"
                  />
                  <FormText>
                    <FormattedMessage id="app.coupon.invoke.code.help" />
                  </FormText>
                </Col>
              </Row>
            </FormGroup>

            <ButtonContent>
              {this.state.uploadIsDone ? (
                <div className="d-grid">
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={this.handleClose}
                  >
                    <FormattedMessage id="app.modal.close" />
                  </Button>
                </div>
              ) : (
                <div className="d-grid">
                  <Button
                    processing={this.state.uploadInProgress}
                    type="button"
                    variant="primary"
                    size="lg"
                    onClick={this.submitForm}
                  >
                    <FormattedMessage id="app.coupon.invoke.submit" />
                  </Button>
                </div>
              )}
            </ButtonContent>
          </Form>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  }
}

export default graphql(INVOKE_COUPON_MUTATION)(injectIntl(Invoke));
