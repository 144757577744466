import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { CustomFaAngleRight, Title } from "../../globalComponents/List";
import { Content, CustomHeader } from "./Styles";
import QuestionAnswer from "./QuestionAnswer";

class Category extends Component {
  constructor(props) {
    super(props);

    this.state = { open: !!this.props.open };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  render() {
    return (
      <div className="faq-category">
        <CustomHeader
          className={this.state.open ? "open" : ""}
          onClick={this.toggle}
        >
          <Title> {this.props.category.title} </Title>
          <CustomFaAngleRight className="arrow" />
        </CustomHeader>
        <Collapse in={this.state.open}>
          <Content>
            {this.props.category.faqs.map((faq, index) => (
              <QuestionAnswer faq={faq} key={index} />
            ))}
          </Content>
        </Collapse>
      </div>
    );
  }
}

export default Category;
