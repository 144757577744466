import { gql } from "@apollo/client";

export const HOME_QUERY = gql`
  query {
    frontend {
      employee {
        widgets {
          type
          col
          order
          title
          ... on FrontendEmployeeWidgetNextDate {
            nextDate {
              date
              timeStart
              timeEnd
              therapyOffer {
                title
              }
              eventTherapist {
                id
                isOnline
                eventTherapistOnline {
                  id
                  canGuestJoin
                }
              }
            }
          }
          ... on FrontendEmployeeWidgetTodaysEvents {
            events {
              timeStart
              timeEnd
              offers {
                offer {
                  title
                  icon
                }
                freeSlots
                totalSlots
              }
              id
              guestState
            }
          }
          ... on FrontendEmployeeWidgetSubventionBudget {
            budgetLeft
            budgetTotal
          }
          ... on FrontendEmployeeWidgetBgmConceptCategories {
            categories {
              title
              id
              icon
            }
          }
          ... on FrontendEmployeeWidgetCurrentCredit {
            availableCredit {
              value
              therapyOffer {
                id
                duration
                title
                icon
              }
            }
          }
          ... on FrontendEmployeeWidgetOfferBanners {
            selectedOffers {
              id
              therapyOffer {
                id
                title
                images {
                  url
                  name
                }
                videos {
                  url
                  name
                }
              }
            }
          }
          ... on FrontendEmployeeWidgetCategoryBars {
            enabledCategories {
              id
              title
              icon
              bgmOffers {
                id
                icon
                extern {
                  serviceProviderLocation {
                    name
                    shortName
                  }
                }
                bgmConceptTherapyOfferTags {
                  id
                  therapyOfferTag {
                    id
                    lang
                    icon
                    color
                    label
                  }
                }
                bgmConceptTherapyOfferAttribute {
                  id
                  therapyOfferAttribute {
                    id
                    icon
                    title
                  }
                  therapyOfferAttributeValue {
                    id
                    title
                    icon
                  }
                }
                therapyOffer {
                  id
                  title
                  icon
                  images {
                    url
                    name
                  }
                  videos {
                    url
                    name
                  }
                  hidePrices
                  priceMatrix {
                    price {
                      total
                      companySubventionAmount
                      net
                      tax
                      count
                    }
                    validFrom
                    validTo
                  }
                }
              }
            }
          }
          ... on FrontendEmployeeWidgetHighlightEvents {
            offers {
              id
              icon
              events {
                id
              }
              bgmConceptCategory {
                id
              }
              therapyOffer {
                id
                icon
                title
                images {
                  url
                  name
                }
                videos {
                  url
                  name
                }
              }
            }
          }
        }
      }
    }
    config {
      modules {
        ... on ModuleConfigFrontend {
          employee {
            enabledWidgets
          }
        }
      }
    }
    acl {
      whoami {
        id
        firstname
      }
    }
    guest {
      id
      privacyPolicyAccepted {
        acceptedDate
        id
      }
      news {
        id
        priority
      }
      guestSubventionLimit {
        id
        currentValue
        value
      }
    }
    calendar {
      companyEvent {
        id
        logo
        title
        description
        offers {
          offer {
            id
            title
            images {
              url
            }
            desc
          }
          eventDays {
            date
            events {
              id
              date
              guestState
            }
          }
        }
      }
    }
  }
`;
