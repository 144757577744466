/**
 * Fn takes a string and returns it with newlines turned to <br/>
 * @param {string} str - the string to replace input of
 */
const nl2br = (str) => {
  if (typeof str !== "string") {
    return str;
  }

  return str.replace(/\n/g, "<br/>");
};

export { nl2br };
