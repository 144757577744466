import { gql } from "@apollo/client";

export const REFUND_QUERY = gql`
  query refund($cursor: String, $limit: Int!) {
    guest {
      id
      refundRecordsConnection(first: $limit, after: $cursor) {
        totalCount
        edges {
          cursor
          node {
            id
            creationDate
            receiptTitle
            receiptNumber
            receiptDate
            state
            gross
            bgmConceptTherapyOfferRefund {
              bgmConceptTherapyOffer {
                therapyOffer {
                  title
                }
              }
            }
            subvention {
              value
            }
          }
        }
      }
    }
  }
`;
