import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import Button from "../CustomBootstrap/Button";
import Conf from "../../Conf";
import AlertModalBox from "../../globalComponents/AlertModalBox";

import { JOIN_ONLINE_MEETING_MUTATION } from "./JoinOnlineEventButton.graphql";

class JoinOnlineEventButton extends Component {
  constructor(props) {
    super(props);

    this.state = { showLoader: false };

    this.joinMeeting = this.joinMeeting.bind(this);
    this._alertModal = null;
  }

  joinMeeting() {
    this.setState({ showLoader: true });
    this.props
      .mutate({
        variables: {
          eventTherapistOnlineId: this.props.eventTherapistOnline.id,
        },
      })
      .then(({ data }) => {
        window.open(data.joinOnlineMeeting);
        this.setState({ showLoader: false });
      })
      .catch((error) => {
        const errorTextTranslationKey = this.parseError(
          error,
          Conf.errorContext.joinMeetingError
        );

        this.setState({
          showLoader: false,
        });

        this._alertModal.open(
          this.props.intl.formatMessage({ id: "app.global.error" }),
          this.props.intl.formatMessage({ id: errorTextTranslationKey })
        );
      });
  }

  render() {
    const event = this.props.eventTherapistOnline;

    if (!event) {
      return "";
    }

    const buttonProps = { ...this.props };
    delete buttonProps.eventTherapistOnline;
    delete buttonProps.mutate;
    delete buttonProps.intl;
    delete buttonProps.canJoin;

    /** default: render simple button to download from link */
    return (
      <div>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <Button
          processing={this.state.showLoader}
          onClick={this.joinMeeting}
          {...buttonProps}
          disabled={!this.props.canJoin}
        >
          {this.props.children}
        </Button>
      </div>
    );
  }
}

export default graphql(JOIN_ONLINE_MEETING_MUTATION)(
  injectIntl(JoinOnlineEventButton)
);
