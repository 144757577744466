import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";
import {
  CustomRow,
  CustomLeftCol,
  CustomRightCol,
  CustomListGroup,
  SecondLevelListGroupItem,
  Title,
  CustomFaAngleRight,
} from "../../globalComponents/List";
import Layout from "../../components/Layout";
import Shop from "../../components/Bonus/Shop";
import Collections from "../../components/Bonus/Collections";
import Conversions from "../../components/Bonus/Conversions";
import Collect from "../../components/Bonus/Collect";
import { Loading } from "../../globalComponents/Loading";
import { BONUS_QUERY } from "./Bonus.graphql";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 0px;
  width: calc(100%);
`;

const CustomListGroupItem = styled(SecondLevelListGroupItem)`
  font-weight: bold;

  &.open {
    background-color: #ffffff !important;
    color: #000000;
  }

  &.open svg {
    transform: rotate(0);
  }
`;

const BudgetOverview = styled("div")`
  border-bottom: 1px solid #b1b1b1;
  font-size: 18px;
  padding: 30px;
`;

export const PAGE_SHOP = "shop";
export const PAGE_COLLECT = "collect";
export const PAGE_COLLECTIONS = "collections";
export const PAGE_CONVERSIONS = "conversions";

class Bonus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page !== undefined ? props.page : null,
      isMobile: window.innerWidth < 992,
    };

    window.addEventListener("resize", this.updateIsMobile.bind(this));
  }

  showShop() {
    this.props.history.push("/bonus/shop");
  }

  showCollections() {
    this.props.history.push("/bonus/collections");
  }

  showConversions() {
    this.props.history.push("/bonus/conversions");
  }

  showCollect() {
    this.props.history.push("/bonus/collect");
  }

  getContent() {
    // this.props.data.guest.bonusPoints.available
    switch (this.state.page) {
      case PAGE_SHOP:
        return (
          <Shop
            availablePoints={
              this.props.data.loading
                ? 0
                : this.props.data.guest.bonusPoints.available
            }
          />
        );
      case PAGE_COLLECTIONS:
        return <Collections />;
      case PAGE_CONVERSIONS:
        return <Conversions />;
      case PAGE_COLLECT:
        return <Collect />;
      default:
        return "";
    }
  }

  updateIsMobile() {
    const isMobile = window.innerWidth < 992;
    this.setState({
      isMobile,
      page: this.props.page === null && !isMobile ? PAGE_SHOP : null,
    });
  }

  componentDidUpdate() {
    if (this.state.page !== this.props.page && this.props.page !== undefined) {
      this.setState({ page: this.props.page });
    } else if (this.state.isMobile !== true && this.state.page === null) {
      this.setState({ page: PAGE_SHOP });
    }
  }

  getLayoutTitle(defaultTitle) {
    if (!this.state.isMobile) {
      return defaultTitle;
    }

    switch (this.state.page) {
      case PAGE_SHOP:
        return this.props.intl.formatMessage({ id: "app.bonus.nav.shop" });
      case PAGE_COLLECTIONS:
        return this.props.intl.formatMessage({
          id: "app.bonus.nav.collections",
        });
      case PAGE_CONVERSIONS:
        return this.props.intl.formatMessage({
          id: "app.bonus.nav.conversions",
        });
      case PAGE_COLLECT:
        return this.props.intl.formatMessage({ id: "app.bonus.nav.collect" });
      default:
        return defaultTitle;
    }
  }

  render() {
    return (
      <FormattedMessage id="app.bonus.title">
        {(budgetTitle) => (
          <Layout {...this.props} title={this.getLayoutTitle(budgetTitle)}>
            <PaddingLessContainer className="bonus">
              <Helmet title={budgetTitle} />
              <CustomRow>
                <CustomLeftCol xs={12} md={12} lg={4}>
                  <CustomListGroup className="list-group">
                    {this.props.data.loading !== true &&
                    !this.props.data.error ? (
                      <BudgetOverview>
                        <p>
                          <b>
                            <FormattedMessage id="app.bonus.availablePoints" />:
                          </b>
                        </p>
                        <p>
                          {this.props.data.guest.bonusPoints.available}
                          {this.props.intl.formatMessage({
                            id: "app.bonus.points",
                          })}
                        </p>
                      </BudgetOverview>
                    ) : (
                      <BudgetOverview>
                        <p>
                          <b>
                            <FormattedMessage id="app.bonus.availablePoints" />:
                          </b>
                        </p>
                        <Loading style={{ marginTop: "10px" }} />
                      </BudgetOverview>
                    )}
                    <CustomListGroupItem
                      onClick={() => this.showShop()}
                      className={this.state.page === PAGE_SHOP ? "open" : ""}
                    >
                      <Title>
                        <FormattedMessage id="app.bonus.nav.shop" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomListGroupItem>
                    <CustomListGroupItem
                      onClick={() => this.showCollect()}
                      className={this.state.page === PAGE_COLLECT ? "open" : ""}
                    >
                      <Title>
                        <FormattedMessage id="app.bonus.nav.collect" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomListGroupItem>
                    <CustomListGroupItem
                      onClick={() => this.showConversions()}
                      className={
                        this.state.page === PAGE_CONVERSIONS ? "open" : ""
                      }
                    >
                      <Title>
                        <FormattedMessage id="app.bonus.nav.conversions" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomListGroupItem>
                    <CustomListGroupItem
                      onClick={() => this.showCollections()}
                      className={
                        this.state.page === PAGE_COLLECTIONS ? "open" : ""
                      }
                    >
                      <Title>
                        <FormattedMessage id="app.bonus.nav.collections" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </CustomListGroupItem>
                  </CustomListGroup>
                </CustomLeftCol>

                <CustomRightCol
                  xs={12}
                  md={12}
                  lg={8}
                  className={this.state.page !== null ? "open" : ""}
                >
                  <ScrollableDiv className="text-left">
                    {this.getContent()}
                  </ScrollableDiv>
                </CustomRightCol>
              </CustomRow>
            </PaddingLessContainer>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default graphql(BONUS_QUERY, { options: { fetchPolicy: "network" } })(
  injectIntl(Bonus)
);
