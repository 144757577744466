import { gql } from "@apollo/client";

export const COMPANY_REGISTER_KEY_QUERY = gql`
  query companyRegisterKeyQuery($registerKey: ID!) {
    companyRegisterKey(key: $registerKey) {
      id
      keyValue
      company {
        id
        name
        emailDomains {
          id
          domain
        }
        locations {
          id
          name
          town
        }
      }
      location {
        id
        name
        town
      }
    }
  }
`;
