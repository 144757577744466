import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Helmet from "react-helmet";
import { Alert } from "react-bootstrap";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Layout from "../../components/Layout";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";
import RequestKey from "../../components/Register/RequestKey";

import RequestIdentifier, {
  validateIdentifier,
} from "../../components/Register/RequestIdentifier";

import RegisterForm from "../../components/Register/RegisterForm";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registerKey: null,
      identifier: null,
      error: null,
    };

    this.updateKey = this.updateKey.bind(this);
    this.updateIdentifier = this.updateIdentifier.bind(this);
  }

  updateKey(key) {
    this.setState({
      registerKey: key,
    });
  }

  updateIdentifier(id) {
    this.setState({ identifier: id });
  }

  render() {
    let errorMessage = null;

    if (this.props.data !== undefined && this.props.data.error) {
      errorMessage = `app.register.error.${this.props.data.error.message.match(
        /[0-9]{3}(x[0-9]+)?/g
      )}`;
    } else if (
      this.props.match.params.token !== undefined &&
      this.state.token === null
    ) {
      errorMessage = "app.register.error";
    } else if (this.state.error !== null) {
      errorMessage = `app.register.error.${this.state.error.message.match(
        /[0-9]{3}(x[0-9]+)?/g
      )}`;
    }

    return (
      <FormattedMessage id="app.register.title">
        {(registerTitle) => (
          <Layout
            {...this.props}
            history={this.props.history}
            hideBacklink
            title={registerTitle}
          >
            <PaddingLessContainer className="register">
              <Helmet
                title={`${registerTitle} - ${this.props.intl.formatMessage({
                  id: "app.title",
                })}`}
              />{" "}
              {this.state.loading === true ||
              (this.props.data !== undefined &&
                this.props.data.loading === true) ? (
                <LoadingCol />
              ) : (
                <div style={{ paddingBottom: "50px" }}>
                  {errorMessage !== null ? (
                    <Alert variant="danger" onDismiss={this.handleDismiss}>
                      <h4>
                        <FormattedMessage id="app.global.error" />
                      </h4>
                      {React.createElement("p", {
                        dangerouslySetInnerHTML: {
                          __html: this.props.intl.formatMessage({
                            id: errorMessage,
                          }),
                        },
                      })}
                    </Alert>
                  ) : (
                    <div>
                      {this.state.registerKey === null ? (
                        <RequestKey
                          registerKey={
                            this.props.match.params.key !== undefined
                              ? this.props.match.params.key
                              : null
                          }
                          onUpdate={this.updateKey}
                        />
                      ) : (
                        <span>
                          {this.state.identifier === null ? (
                            <RequestIdentifier
                              registerKey={this.state.registerKey}
                              identifier={
                                this.props.match.params.identifier !==
                                  undefined &&
                                validateIdentifier(
                                  this.props.match.params.identifier
                                )
                                  ? this.props.match.params.identifier
                                  : null
                              }
                              onUpdate={this.updateIdentifier}
                            />
                          ) : (
                            <RegisterForm
                              registerKey={this.state.registerKey}
                              identifier={this.state.identifier}
                            />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </PaddingLessContainer>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default injectIntl(Register);
