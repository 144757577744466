import React, { Component } from "react";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Form,
  Alert,
  Col,
  FormLabel,
  FormGroup,
  FormText,
  Row,
} from "react-bootstrap";
import SavePassword from "../../containers/Account/SavePassword";
import FormControl from "../CustomBootstrap/FormControl";
import Button from "../CustomBootstrap/Button";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { RENEW_PASSWORD_MUTATION } from "./Renew.graphql";

const Container = styled("div")`
  min-height: calc(100% - 201px);
  padding-bottom: 52px;
  text-align: left;

  @media screen and (min-width: 768px) {
    min-height: calc(100% - 52px);
  }
`;

const CustomInput = styled(FormControl)`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

class Renew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordChanged: false,
      password: "",
      passwordRepeat: "",
      passwordValidationState: null,
      passwordRepeatValidationState: null,
    };
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const { value } = target;

    this.setState({ [name]: value });
  }

  validatePassword() {
    let error = false;

    /**
     * password must contain at least
     * - min. 10 characters
     * - one capital letter
     * - one lowercase letter
     * - one number (digit)
     * - one special char of these @$!%*#?&_.-
     *
     * password is only allowed to contain letter/chars/digits
     * that are within the above listed restrictions
     */
    if (
      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{10,}$/.test(
        this.state.password
      )
    ) {
      this.setState({ passwordValidationState: "error" });
      error = true;
    } else {
      this.setState({ passwordValidationState: "success" });
    }

    if (this.state.password !== this.state.passwordRepeat) {
      this.setState({ passwordRepeatValidationState: "error" });
      error = true;
    } else {
      this.setState({ passwordRepeatValidationState: "success" });
    }

    return !error;
  }

  onError(error, errorTextTranslationKey) {
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  }

  render() {
    return (
      <Container className="renew container">
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        {this.state.passwordChanged ? (
          <Row>
            <Col
              xs={12}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 4 }}
              style={{ paddingTop: "35px" }}
            >
              <Alert variant="success">
                <p>
                  <FormattedMessage id="app.account.passwordChanged" />
                </p>
              </Alert>
              <div className="d-grid">
                <Button
                  className="save-password"
                  href="/logout"
                  variant="danger"
                  size="lg"
                >
                  <FormattedMessage id="app.password.renew.toLogin" />
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              xs={12}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 4 }}
              style={{ paddingTop: "35px" }}
            >
              <Col className="pb-3">
                <FormattedMessage id="app.password.renew.text" />
              </Col>

              <FormGroup className="mb-3">
                <FormLabel>
                  <FormattedMessage id="app.account.password" />
                </FormLabel>
                <CustomInput
                  name="password"
                  onChange={(e) => this.handleInputChange(e)}
                  type="password"
                  size="lg"
                  isInvalid={this.state.passwordValidationState === "error"}
                />
                <Form.Control.Feedback type="invalid">
                  <FormattedMessage id="app.account.invalidPassword" />
                </Form.Control.Feedback>
              </FormGroup>

              <FormGroup className="mb-3">
                <FormLabel>
                  <FormattedMessage id="app.account.passwordRepeat" />
                </FormLabel>
                <CustomInput
                  name="passwordRepeat"
                  onChange={(e) => this.handleInputChange(e)}
                  type="password"
                  size="lg"
                  isInvalid={
                    this.state.passwordRepeatValidationState === "error"
                  }
                />
                <Form.Control.Feedback type="invalid">
                  <FormattedMessage id="app.account.invalidPasswordRepeat" />
                </Form.Control.Feedback>
              </FormGroup>

              <Col className="pb-3">
                <FormText>
                  <FormattedMessage id="app.account.passwordHint" />
                </FormText>
              </Col>

              <SavePassword
                data={this.state}
                onFinish={() => {
                  this.setState({ passwordChanged: true });
                }}
                onValidate={() => this.validatePassword()}
                onError={(error, errorTextTranslationKey) =>
                  this.onError(error, errorTextTranslationKey)
                }
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default graphql(RENEW_PASSWORD_MUTATION)(injectIntl(Renew));
