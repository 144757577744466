import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import base64 from "base-64";
import { format, addDays, differenceInMilliseconds, getDay } from "date-fns";
import { isBookable } from "../Booking/BookingHandler";
import { DateFormatter } from "../../globalComponents/DateFormatter";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Event from "./Event";
import {
  CalendarDay,
  DisabledCalendarDay,
  CalendarHead,
  AngleLeft,
  AngleRight,
  DisabledEvent,
  ArrowHeadLeft,
  ArrowHeadRight,
  TimeSlot,
  CalendarDayContainer,
  CalendarTable,
} from "./StyledComponents";

import { CALENDAR_COMPONENT_QUERY } from "./CalendarComponent.graphql";

class CalendarBody extends React.Component {
  constructor(props) {
    super(props);
    this.moveNext = this.moveNext.bind(this);
    this.movePrev = this.movePrev.bind(this);
    this.changeDay = this.changeDay.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);

    this.state = {
      isSmallScreen: window.innerWidth < 768,
      currentDay: getDay(new Date()),
    };

    this.timeSlotsArr = [];

    for (let i = 7; i <= 22; i++) {
      this.timeSlotsArr.push(`${`00${i}`.slice(-2)}:00`);
    }
  }

  updateDimensions() {
    this.setState({ isSmallScreen: window.innerWidth < 768 });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  moveNext() {
    this.props.moveNext();
    this.setState({ currentDay: 1 });
    this.props.data.refetch();
  }

  movePrev() {
    this.props.movePrev();
    this.setState({ currentDay: 1 });
    this.props.data.refetch();
  }

  changeDay(weekDay) {
    this.setState({ currentDay: weekDay });
  }

  renderEvent(event, day, timeSlot) {
    const dateEditableUntil = this.props.data.config.modules.find(
      (module) => module.dateEditableUntil
    ).dateEditableUntil;

    if (!(event.event.type === "DEFAULT" || event.event.type === "COURSE")) {
      return null;
    }

    if (
      differenceInMilliseconds(
        new Date(
          day.substr(0, 4),
          day.substr(5, 2) - 1,
          day.substr(8, 2),
          timeSlot.substr(0, 2)
        ),
        new Date()
      ) > 0 &&
      isBookable(dateEditableUntil, day, timeSlot)
    ) {
      const guestSubventionLimit = this.props.data.guest.guestSubventionLimit;
      return (
        <Event
          key={event.id.toString()}
          data={event}
          history={this.props.history}
          employeeBudgetLeft={
            guestSubventionLimit
              ? guestSubventionLimit.value - guestSubventionLimit.currentValue
              : 0
          }
          modules={this.props.modules}
          config={this.props.data.config}
          week={this.props.week}
          isBookable
        />
      );
    }

    return (
      <DisabledEvent key={event.id.toString()}>
        <Event data={event} isBookable={false} />
      </DisabledEvent>
    );
  }

  getEvents(day, timeSlot) {
    const index = timeSlot.substr(0, 2);

    if (
      this.eventsCalendar[format(new Date(day), "yyyy-MM-dd")] &&
      this.eventsCalendar[format(new Date(day), "yyyy-MM-dd")][index]
    ) {
      return (
        <div>
          {this.eventsCalendar[format(new Date(day), "yyyy-MM-dd")][index].map(
            (event) => this.renderEvent(event, day, timeSlot)
          )}
        </div>
      );
    }

    return <div />;
  }

  render() {
    if (!this.props.data.loading) {
      const eventsCalendar = {};

      const weekDays = {};

      this.props.data.calendar.weeksConnection.edges.forEach((edge) => {
        edge.node.days.forEach((day) => {
          if (day.day === "SATURDAY" || day.day === "SUNDAY") {
            return;
          }

          if (!weekDays[day.date]) {
            weekDays[day.date] = "";
          }

          day.events.forEach((event) => {
            const index = event.timeStart.substr(0, 2);
            const startTime = event.timeStart.substr(0, 5);
            const endTime = event.timeEnd.substr(0, 5);

            if (!eventsCalendar[day.date]) {
              eventsCalendar[day.date] = {};
            }

            if (!eventsCalendar[day.date][index]) {
              eventsCalendar[day.date][index] = [];
            }

            eventsCalendar[day.date][index].push({
              title: event.offers[0].offer.title,
              start: startTime,
              end: endTime,
              desc: event.info,
              id: event.id,
              guestState: event.guestState,
              offerId: event.offers[0].offer.id,
              icon: event.offers[0].offer.icon,
              freeSlots: event.offers[0].freeSlots,
              /* - Math.floor(Math.random() * 5), */
              totalSlots: event.offers[0].totalSlots,
              duration: event.roomEvent.therapyOffer.duration,
              date: day.date,
              event,
            });
          });
        });
      });

      this.eventsCalendar = eventsCalendar;

      return (
        <CalendarTable>
          <CalendarHead>
            <tr>
              <th className="th" width="5%">
                <ArrowHeadLeft>
                  <span onClick={this.movePrev} role="button" tabIndex={-1}>
                    <AngleLeft />
                  </span>
                </ArrowHeadLeft>
              </th>
              {Object.keys(weekDays).map((day) => (
                <th className="th" key={day.toString()} width="18%">
                  {differenceInMilliseconds(
                    addDays(new Date(day.replaceAll("-", "/")), 1),
                    new Date()
                  ) > 0 ? (
                    <CalendarDayContainer
                      onClick={() => {
                        this.changeDay(getDay(new Date(day)));
                      }}
                      className={
                        this.state.isSmallScreen &&
                        this.state.currentDay === getDay(new Date(day))
                          ? "active"
                          : ""
                      }
                    >
                      <CalendarDay>
                        <DateFormatter
                          date={new Date(day)}
                          type="DAY_WITH_SHORT_DATE_BR"
                        />
                      </CalendarDay>
                    </CalendarDayContainer>
                  ) : (
                    <CalendarDayContainer
                      onClick={() => {
                        this.changeDay(getDay(new Date(day)));
                      }}
                    >
                      <DisabledCalendarDay
                        className={
                          this.state.isSmallScreen &&
                          this.state.currentDay === getDay(new Date(day))
                            ? "active"
                            : ""
                        }
                      >
                        <DateFormatter
                          date={new Date(day)}
                          type="DAY_WITH_SHORT_DATE_BR"
                        />
                      </DisabledCalendarDay>
                    </CalendarDayContainer>
                  )}
                </th>
              ))}
              <th className="th" width="5%">
                <ArrowHeadRight>
                  <span onClick={this.moveNext} role="button" tabIndex={-1}>
                    <AngleRight />
                  </span>
                </ArrowHeadRight>
              </th>
            </tr>
          </CalendarHead>
          <tbody>
            {this.timeSlotsArr.map((timeSlot) => (
              <tr key={timeSlot.toString()}>
                <td className="cell">
                  <TimeSlot>{timeSlot.substr(0, 2)}</TimeSlot>
                </td>
                {Object.keys(weekDays).map((day) => {
                  if (
                    this.state.isSmallScreen &&
                    getDay(new Date(day)) !== this.state.currentDay
                  ) {
                    return false;
                  }
                  return (
                    <td
                      className="cell"
                      key={day.toString()}
                      colSpan={this.state.isSmallScreen ? 5 : 0}
                    >
                      {this.getEvents(day, timeSlot)}
                    </td>
                  );
                })}
                <td />
              </tr>
            ))}
          </tbody>
        </CalendarTable>
      );
    }

    return (
      <div>
        <CalendarTable>
          <CalendarHead>
            <tr>
              <th className="th" width="5%">
                <ArrowHeadLeft>
                  <span>
                    <AngleLeft />
                  </span>
                </ArrowHeadLeft>
              </th>
              <th className="th">
                <CalendarDayContainer>
                  <CalendarDay>&nbsp;</CalendarDay>
                </CalendarDayContainer>
              </th>
              <th className="th">
                <CalendarDayContainer>
                  <CalendarDay>&nbsp;</CalendarDay>
                </CalendarDayContainer>
              </th>
              <th className="th">
                <CalendarDayContainer>
                  <CalendarDay>&nbsp;</CalendarDay>
                </CalendarDayContainer>
              </th>
              <th className="th">
                <CalendarDayContainer>
                  <CalendarDay>&nbsp;</CalendarDay>
                </CalendarDayContainer>
              </th>
              <th className="th">
                <CalendarDayContainer>
                  <CalendarDay>&nbsp;</CalendarDay>
                </CalendarDayContainer>
              </th>
              <th className="th" width="5%">
                <ArrowHeadLeft>
                  <span>
                    <AngleRight />
                  </span>
                </ArrowHeadLeft>
              </th>
            </tr>
          </CalendarHead>
          <tbody>
            {this.timeSlotsArr.map((timeSlot) => (
              <tr key={timeSlot.toString()}>
                <td className="cell">
                  <TimeSlot>{timeSlot.substr(0, 2)}</TimeSlot>
                </td>
                <td className="cell" colSpan="5">
                  <LoadingCol
                    height="50"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  />
                </td>
                <td>&nbsp;</td>
              </tr>
            ))}
          </tbody>
        </CalendarTable>
      </div>
    );
  }
}

const CalendarComponent = graphql(CALENDAR_COMPONENT_QUERY, {
  options: (ownProps) => ({
    variables: { week: base64.encode((ownProps.week - 1).toString()) },
  }),
})(CalendarBody);

export default CalendarComponent;
