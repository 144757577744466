import React, { Component } from "react";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import {
  Col,
  Row,
  Modal,
  FormControl,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import FittedImage from "react-fitted-image";
import Button from "../CustomBootstrap/Button";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import { Loading } from "../../globalComponents/Loading";
import {
  CATALOG_QUERY,
  CONVERT_BPOINTS_MUTATION,
  UPDATE_GUEST_MUTATION,
} from "./Shop.graphql";

const ImageWrapper = styled("div")`
  text-align: center;
  margin-bottom: 5px;
  height: 125px;
  overflow: hidden;
`;

const BigImageWrapper = styled("div")`
  text-align: center;
  margin-bottom: 5px;
  height: 250px;
  overflow: hidden;
`;

const Description = styled(Modal)`
  p {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const EventBox = styled(CustomPanel)`
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  border: 1px solid #fff;
  .booked {
    border: 1px solid #f00;
  }
  :hover {
    border: 1px solid #d4d700;
  }
  cursor: pointer;
  Button {
  }
  Button.n-av {
    color: #fff;
    border-color: #aaa;
    background-color: #aaa;
  }
  H4 {
    font-size: 14px;
    height: 75px;
    overflow: hidden;
  }
  position: relative;
`;

class Shop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBonus: null,
      showDetails: false,
      showConvert: false,
      converted: false,
      error: null,
      deliver: {
        town: "",
        zip: "",
        street: "",
        ready: false,
      },
      loading: false,
    };

    this.closeDetails = this.closeDetails.bind(this);
    this.showConvert = this.showConvert.bind(this);
    this.closeConvert = this.closeConvert.bind(this);
    this.convert = this.convert.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.isAddressValid = this.isAddressValid.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.showDetails = this.showDetails.bind(this);
  }

  isAddressValid(field, val) {
    let value = val;
    if (value === null || value === undefined) {
      value = this.state.deliver[field];
    }
    if (field === "zip") {
      return /^[0-9]{5}$/.test(value);
    }
    if (field === "town") {
      return value.length > 3;
    }
    if (field === "street") {
      return value.length > 3;
    }
  }

  updateAddress(field, value) {
    let ready = this.isAddressValid(field, value);
    if (ready) {
      if (field !== "zip") {
        ready = ready && this.isAddressValid("zip");
      }
      if (field !== "town") {
        ready = ready && this.isAddressValid("town");
      }
      if (field !== "street") {
        ready = ready && this.isAddressValid("street");
      }
    }
    this.setState((prevState) => {
      const deliver = { ...prevState.deliver };
      deliver[field] = value;
      deliver.ready = ready;
      return { deliver };
    });
  }

  closeDetails() {
    this.setState({
      showDetails: false,
      selectedBonus: null,
    });
  }

  showDetails(bonus) {
    this.setState({
      showDetails: true,
      selectedBonus: bonus,
    });
  }

  closeConvert() {
    this.setState({
      showConvert: false,
      selectedBonus: null,
      converted: false,
      error: null,
    });
  }

  showConvert(bonus, rest) {
    this.setState({
      showConvert: true,
      selectedBonus: bonus,
      error: rest <= 0 ? null : "app.bonus.shop.notAvailable",
    });
  }

  submitOrder() {
    this.props
      .convertBonusPoints({
        variables: { bonusId: this.state.selectedBonus.id },
      })
      .then(() => {
        this.setState({
          loading: false,
          converted: true,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: `app.bonus.shop.error.${error.message.match(
            /[0-9]{3}(x[0-9]+)?/g
          )}`,
        });
      });
  }

  convert() {
    this.setState({ loading: true });

    if (this.state.selectedBonus.deliver === true) {
      this.props
        .updateGuest({
          variables: {
            guest: {
              zip: this.state.deliver.zip,
              town: this.state.deliver.town,
              street: this.state.deliver.street,
            },
          },
        })
        .then(() => {
          this.submitOrder();
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: `app.bonus.shop.error.${error.message.match(
              /[0-9]{3}(x[0-9]+)?/g
            )}`,
          });
        });
    } else {
      this.submitOrder();
    }
  }

  componentDidUpdate(prev) {
    if (prev.data.loading === true && this.props.data.loading !== true) {
      const { deliver } = this.state;
      deliver.town =
        this.props.data.guest.town !== null ? this.props.data.guest.town : "";
      deliver.zip =
        this.props.data.guest.zip !== null ? this.props.data.guest.zip : "";
      deliver.street =
        this.props.data.guest.street !== null
          ? this.props.data.guest.street
          : "";
      deliver.ready =
        deliver.town.length > 0 &&
        deliver.street.length > 0 &&
        deliver.zip.length > 0;
      this.setState({ deliver });
    }
  }

  render() {
    if (this.props.data.loading === true || this.state.loading === true) {
      return <LoadingCol />;
    }

    const categories = [
      {
        min: 0,
        max: 1000,
        title: "app.bonus.shop.category1000",
      },
      {
        min: 1000,
        max: 2000,
        title: "app.bonus.shop.category2000",
      },
      {
        min: 2000,
        max: 3000,
        title: "app.bonus.shop.category3000",
      },
      {
        min: 3000,
        max: 4000,
        title: "app.bonus.shop.category4000",
      },
      {
        min: 4000,
        max: 5000,
        title: "app.bonus.shop.category5000",
      },
      {
        min: 5000,
        max: 6000,
        title: "app.bonus.shop.category6000",
      },
      {
        min: 6000,
        max: 7000,
        title: "app.bonus.shop.category7000",
      },
      {
        min: 7000,
        max: 8000,
        title: "app.bonus.shop.category8000",
      },
      {
        min: 8000,
        max: 9000,
        title: "app.bonus.shop.category9000",
      },
      {
        min: 9000,
        max: 100000,
        title: "app.bonus.shop.category10000",
      },
    ];

    return (
      <div className="bonus-shop">
        {this.state.selectedBonus !== null ? (
          <Description
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered="true"
            show={this.state.showDetails}
            onHide={this.closeDetails}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.state.selectedBonus.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BigImageWrapper>
                <FittedImage
                  style={{
                    backgroundPosition: "center center",
                    backgroundSize: "contain",
                  }}
                  loader={<Loading />}
                  fit="contain"
                  src={JSON.parse(this.state.selectedBonus.image)}
                  alt={this.state.selectedBonus.title}
                />
              </BigImageWrapper>
              {React.createElement("p", {
                className: "margin-top",
                dangerouslySetInnerHTML: {
                  __html: this.state.selectedBonus.description,
                },
              })}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeDetails}>
                <FormattedMessage id="app.modal.close" />
              </Button>
            </Modal.Footer>
          </Description>
        ) : (
          ""
        )}
        {this.state.selectedBonus !== null ? (
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered="true"
            show={this.state.showConvert}
            onHide={this.closeConvert}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.state.selectedBonus.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.converted === true || this.state.error !== null ? (
                <p>
                  {this.state.error !== null ? (
                    <FormattedMessage id={this.state.error} />
                  ) : (
                    <FormattedMessage id="app.bonus.shop.success" />
                  )}
                </p>
              ) : (
                <div>
                  <p>
                    <FormattedMessage id="app.bonus.shop.confirm" />
                  </p>
                  {this.state.selectedBonus.deliver === true ? (
                    <form>
                      <FormLabel>
                        <FormattedMessage id="app.bonus.shop.deliverAddress" />
                      </FormLabel>
                      <FormGroup
                        controlId="formBasicText"
                        validationState={
                          this.isAddressValid("street") ? "success" : "error"
                        }
                      >
                        <FormLabel>
                          <FormattedMessage id="app.bonus.shop.deliver.street" />
                        </FormLabel>
                        <FormControl
                          autoComplete="street"
                          type="text"
                          value={this.state.deliver.street}
                          onChange={(e) => {
                            this.updateAddress("street", e.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup
                        controlId="formBasicText"
                        validationState={
                          this.isAddressValid("zip") ? "success" : "error"
                        }
                      >
                        <FormLabel>
                          <FormattedMessage id="app.bonus.shop.deliver.zip" />
                        </FormLabel>
                        <FormControl
                          autoComplete="zip"
                          type="text"
                          value={this.state.deliver.zip}
                          onChange={(e) => {
                            this.updateAddress("zip", e.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup
                        controlId="formBasicText"
                        validationState={
                          this.isAddressValid("town") ? "success" : "error"
                        }
                      >
                        <FormLabel>
                          <FormattedMessage id="app.bonus.shop.deliver.town" />
                        </FormLabel>
                        <FormControl
                          autoComplete="town"
                          type="text"
                          value={this.state.deliver.town}
                          onChange={(e) => {
                            this.updateAddress("town", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </form>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Modal.Body>

            {this.state.converted !== true && this.state.error === null ? (
              <Modal.Footer>
                <Button
                  disabled={
                    this.state.selectedBonus.deliver === true &&
                    this.state.deliver.ready === false
                  }
                  onClick={this.convert}
                >
                  <FormattedMessage id="app.bonus.shop.convert" />
                </Button>
                <Button onClick={this.closeConvert}>
                  <FormattedMessage id="app.global.abort" />
                </Button>
              </Modal.Footer>
            ) : (
              <Modal.Footer>
                <Button onClick={this.closeConvert}>
                  <FormattedMessage id="app.modal.close" />
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        ) : (
          ""
        )}
        {categories.map((cat) => (
          <Wrapper className="widget widget-highlight" key={cat.max}>
            <H4>
              <FormattedMessage id={cat.title} />
            </H4>
            <CustomPanel>
              <Row>
                {this.props.data.bonusProgram.catalog.map((bonus) =>
                  bonus.points >= cat.min && bonus.points < cat.max ? (
                    <Col xs={12} sm={4} key={bonus.id}>
                      <EventBox className="event-box ">
                        <ImageWrapper
                          onClick={() => {
                            this.showDetails(bonus);
                          }}
                        >
                          <FittedImage
                            style={{
                              backgroundPosition: "center center",
                              backgroundSize: "contain",
                            }}
                            loader={<Loading />}
                            fit="contain"
                            src={JSON.parse(bonus.image)}
                            alt={bonus.title}
                          />
                        </ImageWrapper>
                        <H4
                          onClick={() => {
                            this.showDetails(bonus);
                          }}
                        >
                          {bonus.points}
                          <FormattedMessage id="app.bonus.points" />
                          <br />
                          <small className="text-muted">{bonus.title}</small>
                        </H4>

                        <div className="d-grid">
                          <Button
                            onClick={() => {
                              this.showConvert(
                                bonus,
                                bonus.points - this.props.availablePoints
                              );
                            }}
                            variant="primary"
                            size="lg"
                            className={
                              this.props.availablePoints < bonus.points
                                ? "n-av"
                                : ""
                            }
                          >
                            <FormattedMessage id="app.bonus.shop.buy" />
                          </Button>
                        </div>
                      </EventBox>
                    </Col>
                  ) : (
                    ""
                  )
                )}
              </Row>
            </CustomPanel>
          </Wrapper>
        ))}
      </div>
    );
  }
}

export default compose(
  graphql(UPDATE_GUEST_MUTATION, { name: "updateGuest" }),
  graphql(CONVERT_BPOINTS_MUTATION, { name: "convertBonusPoints" }),
  graphql(CATALOG_QUERY, {
    options: () => ({ fetchPolicy: "cache-and-network" }),
  })
)(injectIntl(Shop));
