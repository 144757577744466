import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { Collapse } from "react-bootstrap";
import { differenceInMilliseconds, getDay } from "date-fns";
import { Title, CustomFaAngleRight, Header } from "../../globalComponents/List";
import Icon from "../../globalComponents/Icon";
import Appointment from "./Appointment";
import Conf from "../../Conf";
import Button from "../CustomBootstrap/Button";
import UpdateAppointment from "./UpdateAppointment";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import { APPOINTMENTS_QUERY } from "./AppointmentList.graphql";
import { withLanguage } from "../../context/LanguageContext/withLanguage";
import { DateFormatter } from "../../globalComponents/DateFormatter";

const CustomHeader = styled(Header)`
  padding-left: 15px;

  &.open {
    padding: 0 15px 0 15px;
  }
`;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 1px solid #d4d4d4;
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

class AppointmentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAppointment: null,
      editAppointment: false,
      loadingMore: false,
      removedAppointments: [],
      removedCourses: [],
    };

    this.showAppointment = this.showAppointment.bind(this);
    this.canGetMore = this.canGetMore.bind(this);
    this.fetchMore = this.fetchMore.bind(this);
    this.editAppointment = this.editAppointment.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (this.state.editAppointment) {
      this.setState({ editAppointment: false });
      window.customBackAction = this.goBack;
      return;
    }

    this.setState({ currentAppointment: null });
  }

  showAppointment(appointment) {
    window.customBackAction = this.goBack;
    this.setState({
      currentAppointment: appointment,
      editAppointment: false,
    });
  }

  static getIcon(type, date, time) {
    if (
      differenceInMilliseconds(
        new Date(
          date.substr(0, 4),
          date.substr(5, 2) - 1,
          date.substr(8, 2),
          time.substr(0, 2)
        ),
        new Date()
      ) > 0
    ) {
      if (type === "EventGuest") {
        return (
          <Icon
            className="icon-themed"
            src="/static/frontend/employee/images/date-edit.svg"
          />
        );
      }

      if (type === "EventGuestReservation") {
        return (
          <Icon
            className="icon-themed"
            src="/static/frontend/employee/images/reservation-edit.svg"
          />
        );
      }
    }
  }

  canGetMore() {
    return this.props.data.guest.datesConnection.pageInfo.hasNextPage;
  }

  fetchMore() {
    this.setState({ loadingMore: true });
    return this.props.data.fetchMore();
  }

  editAppointment() {
    window.customBackAction = this.goBack;
    this.setState({ editAppointment: true });
  }

  removeFromList(eventGuest) {
    if (eventGuest.eventTherapist.roomEvent.type === "COURSE") {
      const newlyRemovedCourses = [eventGuest.eventTherapist.roomEvent.id];
      this.setState((prevState) => {
        const removedCourses =
          prevState.removedCourses.concat(newlyRemovedCourses);
        return { removedCourses };
      });
    } else {
      const newlyRemovedAppointments = [eventGuest.id];
      this.setState((prevState) => {
        const removedAppointments = prevState.removedAppointments.concat(
          newlyRemovedAppointments
        );
        return { removedAppointments };
      });
    }
  }

  isRemoved(eventGuest) {
    if (eventGuest.eventTherapist.roomEvent.type === "COURSE") {
      if (this.state.removedCourses.length === 0) {
        return false;
      }
      return (
        this.state.removedCourses.indexOf(
          eventGuest.eventTherapist.roomEvent.id
        ) !== -1
      );
    }
    if (this.state.removedAppointments.length === 0) {
      return false;
    }
    return this.state.removedAppointments.indexOf(eventGuest.id) !== -1;
  }

  static getWeekDayByDate(sDate) {
    const iDay = getDay(new Date(sDate));
    let weekdayTranslationKey = "";

    switch (iDay) {
      case 0:
        weekdayTranslationKey = "app.appointments.sunday";
        break;
      case 1:
        weekdayTranslationKey = "app.appointments.monday";
        break;
      case 2:
        weekdayTranslationKey = "app.appointments.tuesday";
        break;
      case 3:
        weekdayTranslationKey = "app.appointments.wednesday";
        break;
      case 4:
        weekdayTranslationKey = "app.appointments.thursday";
        break;
      case 5:
        weekdayTranslationKey = "app.appointments.friday";
        break;
      case 6:
        weekdayTranslationKey = "app.appointments.saturday";
        break;
      default:
        weekdayTranslationKey = "";
    }

    return weekdayTranslationKey;
  }

  render() {
    const isDataReady = !this.props.data.loading;
    let dateEditableUntil = null;
    let dateCancelableUntil = null;

    if (isDataReady) {
      this.props.data.config.modules.forEach((module) => {
        if (module.dateEditableUntil) {
          dateEditableUntil = module.dateEditableUntil;
        }
        if (module.dateCancelableUntil) {
          dateCancelableUntil = module.dateCancelableUntil;
        }
      });
    }

    return (
      <div>
        {isDataReady || this.state.loadingMore ? (
          this.props.data.guest.datesConnection.edges.length === 0 ? (
            <VerticallyAlignedMiddle height={window.innerHeight / 2}>
              <span className="large">
                <FormattedMessage id="app.appointments.noAppointments" />
              </span>
            </VerticallyAlignedMiddle>
          ) : (
            this.props.data.guest.datesConnection.edges.map((appointment) =>
              this.isRemoved(appointment.node) ? null : (
                <div key={appointment.node.id}>
                  <CustomHeader
                    className={
                      this.state.currentAppointment &&
                      this.state.currentAppointment.node.id ===
                        appointment.node.id
                        ? "open"
                        : ""
                    }
                    onClick={() =>
                      this.state.currentAppointment &&
                      this.state.currentAppointment.node.id ===
                        appointment.node.id
                        ? this.showAppointment(null)
                        : this.showAppointment(appointment)
                    }
                  >
                    {AppointmentList.getIcon(
                      appointment.node.__typename,
                      appointment.node.date,
                      appointment.node.timeStart
                    )}
                    <Title>
                      <DateFormatter
                        date={
                          new Date(
                            `${appointment.node.date} ${appointment.node.timeStart}`
                          )
                        }
                        type="DAY_WITH_DATE_AND_TIME"
                      />
                      {}
                      <br />
                      <small>{appointment.node.therapyOffer.title}</small>
                    </Title>
                    <CustomFaAngleRight className="arrow" />
                  </CustomHeader>
                  <Wrapper>
                    <Collapse
                      in={
                        this.state.currentAppointment &&
                        this.state.currentAppointment.node.id ===
                          appointment.node.id
                      }
                    >
                      <div>
                        <Content>
                          {this.state.editAppointment ? (
                            <UpdateAppointment
                              data={appointment}
                              appointmentId={appointment.node.id}
                              info={appointment.node.info}
                              timeSlots={
                                appointment.node.eventTherapist.offers[0].slots
                              }
                            />
                          ) : (
                            <Appointment
                              data={appointment}
                              dateEditableUntil={dateEditableUntil}
                              dateCancelableUntil={dateCancelableUntil}
                              onDelete={() =>
                                this.removeFromList(appointment.node)
                              }
                              onEditAppointment={this.editAppointment}
                            />
                          )}
                        </Content>
                      </div>
                    </Collapse>
                  </Wrapper>
                </div>
              )
            )
          )
        ) : (
          <LoadingCol />
        )}
        {(isDataReady || this.state.loadingMore) && this.canGetMore() ? (
          <div>
            <br />
            <div className="d-grid">
              <Button
                onClick={this.fetchMore}
                processing={!isDataReady && this.state.loadingMore}
                variant="primary"
                size="lg"
              >
                <FormattedMessage id="app.global.loadMore" />
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const ITEMS_PER_PAGE = Conf.general.itemsPerPage;
const withData = graphql(APPOINTMENTS_QUERY, {
  options: (props) => ({
    variables: {
      cursor: null,
      limit: ITEMS_PER_PAGE,
      past: !!props.past,
    },
    notifyOnNetworkStatusChange: true,
  }),
  props: ({ data: { loading, guest, config, fetchMore } }) => ({
    data: {
      loading,
      guest,
      config,
      fetchMore: () =>
        fetchMore({
          variables: {
            cursor:
              guest.datesConnection.edges[
                guest.datesConnection.edges.length - 1
              ].cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            const _fetchMoreResult = fetchMoreResult;
            if (!_fetchMoreResult) {
              return prev;
            }
            _fetchMoreResult.guest.datesConnection.edges =
              prev.guest.datesConnection.edges.concat(
                _fetchMoreResult.guest.datesConnection.edges
              );
            return _fetchMoreResult;
          },
        }),
    },
  }),
});

export default withLanguage(withData(injectIntl(AppointmentList)));
