import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../CustomBootstrap/Button";
import { CANCEL_APPOINTMENT_MUTATION } from "./CancelCourse.graphql";

class CancelCourse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelling: false,
    };
  }

  onClick() {
    if (
      window.confirm(
        this.props.intl.formatMessage({
          id: "app.appointments.confirmCancelCourse",
        })
      )
    ) {
      this.setState({ cancelling: true });

      this.props.mutate({ variables: { id: this.props.id } });
      window.setTimeout(() => {
        this.setState({
          cancelling: false,
        });
        this.props.onDelete();
      }, 3000);

      /**
       * Modal Error Handling when Timeout-Workaround isn't needed anymore
       */
      /*
      this.props.mutate({ variables: { id: this.props.id } })
        .then((data) => {
          if (data.cancelCourse) {

            this.setState({canceled: true, cancelling: false});
            this.props.onDelete();
          }
        })
        .catch((error) => {

          this.setState({cancelling: false});
          const errorTextTranslationkey = this.parseError(error, Conf.errorContext.appointmentCancelCourse);

          this.props.onError(error, errorTextTranslationkey);
        })
      ;
      */
    }
  }

  render() {
    return this.state.cancelling ? (
      <LoadingCol height="50" />
    ) : (
      <div className="d-grid">
        <Button onClick={this.onClick.bind(this)} variant="danger" size="lg">
          {" "}
          <FormattedMessage id="app.appointments.cancelCourse" />{" "}
        </Button>
      </div>
    );
  }
}

export default graphql(CANCEL_APPOINTMENT_MUTATION)(injectIntl(CancelCourse));
