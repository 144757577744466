import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FittedImage from "react-fitted-image";
import { CustomPanel, ImageWrapper, H4 } from "../../globalComponents/Panel";
import { Loading } from "../../globalComponents/Loading";
import Icon from "../../globalComponents/Icon";

const StyledDetailPanel = styled(CustomPanel)`
  position: absolute;
  top: -50px;
  left: -50px;
  width: 300px;
  height: auto;
  z-index: 99;
  &.first {
    left: -15px;
  }
  &.last {
    left: auto;
    right: -15px;
  }
  /* border: 1px solid #ccc; */
  box-shadow: 1px 1px 2px #888;
  .image {
    height: 175px;
    margin-bottom: 20px;
  }
  h4 {
    font-size: 14px;
    font-weight: bold;
    word-wrap: break-word;
  }
  min-height: 250px;
  @media only screen and (max-width: 768px) {
  }
`;

export const AttributesWrapper = styled("div")`
  float: left;
  padding: 10px;
  position: relative;
`;

export const IconsWrapper = styled("div")`
  margin-top: 10px;
  span {
    float: left;
    margin-right: 5px;
  }
`;

function DetailPanel({
  panelClass,
  hidden = true,
  history,
  priceMatrix,
  eventOffer,
  intl,
}) {
  // TODO: implement useIntl after react-intl upgrade

  function formatPrice(number) {
    return intl.formatNumber(number, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const videoRef = useRef();

  useEffect(() => {
    if (videoRef.current && !hidden) videoRef.current.play();
  }, [videoRef, hidden]);

  if (hidden) return null;

  return (
    <StyledDetailPanel className={panelClass}>
      {" "}
      <ImageWrapper
        className="image"
        onClick={() => {
          history.push(`/offers/${eventOffer.id}`);
        }}
      >
        {eventOffer.therapyOffer.videos.length > 0 ? (
          <video
            ref={videoRef}
            poster={
              eventOffer.therapyOffer.images.length > 0
                ? JSON.parse(eventOffer.therapyOffer.images[0].url)
                : ""
            }
            className="b-lazy b-loaded"
            controls={false}
          >
            <source
              src={JSON.parse(eventOffer.therapyOffer.videos[0].url)}
              type="video/mp4"
            />
          </video>
        ) : (
          ""
        )}
        {eventOffer.therapyOffer.videos.length === 0 &&
        eventOffer.therapyOffer.images.length > 0 ? (
          <FittedImage
            style={{
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
            loader={<Loading />}
            fit="cover"
            src={JSON.parse(eventOffer.therapyOffer.images[0].url)}
            alt={eventOffer.therapyOffer.title}
          />
        ) : (
          ""
        )}
      </ImageWrapper>
      <H4
        onClick={() => {
          history.push(`/offers/${eventOffer.id}`);
        }}
      >
        {eventOffer.therapyOffer.title}
      </H4>
      <AttributesWrapper
        onClick={() => {
          history.push(`/offers/${eventOffer.id}`);
        }}
      >
        {eventOffer.extern &&
        eventOffer.extern.serviceProviderLocation &&
        eventOffer.extern.serviceProviderLocation.name ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top">
                {intl.formatMessage({
                  id: "app.offers.extern.serviceProvider",
                })}
              </Tooltip>
            }
          >
            <span style={{ cursor: "help" }}>
              <strong>
                <FormattedMessage id="app.offers.extern.serviceProvider" />
                :&nbsp;
              </strong>
              {eventOffer.extern.serviceProviderLocation.name}
            </span>
          </OverlayTrigger>
        ) : (
          ""
        )}
        {priceMatrix === null || eventOffer.therapyOffer.hidePrices ? (
          ""
        ) : (
          <div>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {intl.formatMessage({
                    id: "app.offers.price.total.tooltip",
                  })}
                </Tooltip>
              }
            >
              <span style={{ cursor: "help" }}>
                <strong>
                  <FormattedMessage id="app.offers.price.total" />
                  :&nbsp;
                </strong>
                {formatPrice(
                  priceMatrix.price.net * (1 + priceMatrix.price.tax / 100)
                )}
                <FormattedMessage id="app.global.priceUnit" />
                <br />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {intl.formatMessage({
                    id: "app.offers.price.budget.tooltip",
                  })}
                </Tooltip>
              }
            >
              <span style={{ cursor: "help" }}>
                <strong>
                  <FormattedMessage id="app.offers.price.budget" />
                  :&nbsp;
                </strong>
                {formatPrice(
                  priceMatrix.price.net * (1 + priceMatrix.price.tax / 100) -
                    priceMatrix.price.total
                )}
                <FormattedMessage id="app.global.priceUnit" />
                <br />
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {intl.formatMessage({
                    id: "app.offers.price.price.tooltip",
                  })}
                </Tooltip>
              }
            >
              <span style={{ cursor: "help" }}>
                <strong>
                  <FormattedMessage id="app.offers.price.price" />
                  :&nbsp;
                </strong>
                {formatPrice(priceMatrix.price.total)}
                <FormattedMessage id="app.global.priceUnit" />
                <br />
              </span>
            </OverlayTrigger>
          </div>
        )}
        <IconsWrapper>
          {eventOffer.bgmConceptTherapyOfferAttribute.map((attribute) => (
            <Icon
              key={attribute.therapyOfferAttributeValue.id}
              src={JSON.parse(attribute.therapyOfferAttributeValue.icon)}
              title={attribute.therapyOfferAttributeValue.title}
            />
          ))}
        </IconsWrapper>
      </AttributesWrapper>
    </StyledDetailPanel>
  );
}

export default DetailPanel;
