import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import Helmet from "react-helmet";
import * as queryString from "query-string";
import styled from "styled-components";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Layout from "../../components/Layout";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";
import Offer from "../../components/Offers/Offer";
import OffersCategories from "../../components/Offers/OffersCategories";
import {
  CustomRow,
  CustomLeftCol,
  CustomRightCol,
} from "../../globalComponents/List";
import Upload from "../../components/Offers/Upload";
import {
  BookDateComponent,
  BookCourseComponent,
} from "../../components/Offers/Book";
import { BookExternComponent } from "../../components/Offers/Extern";
import OfferFilter from "../../components/Offers/OfferFilter";
import VerticallyAlignedMiddle from "../../components/VerticallyAlignedMiddle";
import Conf from "../../Conf";
import Icon from "../../globalComponents/Icon";

import { OFFERS_QUERY } from "./Offers.graphql";

function escapeStringRegexp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const FilterIcon = styled(Icon)`
  cursor: pointer;
  svg {
    margin-top: 0;
  }
  svg path,
  svg circle {
    fill: #8a8a8a !important;
  }
  &.active svg path,
  &.active svg circle {
    fill: #ff6200 !important;
  }
`;

class Offers extends Component {
  constructor(props) {
    super(props);

    const initCategoryId =
      this.props.history.location.search &&
      queryString.parse(this.props.history.location.search).openCategory
        ? queryString.parse(this.props.history.location.search).openCategory
        : null;
    const initOfferId = this.props.match.params.id
      ? this.props.match.params.id
      : null;

    this.state = {
      currentOffer: null,
      currentOfferId: initOfferId,
      action: null,
      offerIsOpen: false,
      currentCategoryId: initCategoryId,
      employeeBudgetLeft: null,
      bankDetailsExist: false,
      isMobile: window.innerWidth < 992,
      bgmConcept: null,
      offers: null,
      config: null,
      filter: Offers.getFilterValue(props),
      search: Offers.getSearchValue(props),
      paging: null,
      filteredOffers: null,
      emptySearch: false,
      showFilter: false,
    };

    this.showOffer = this.showOffer.bind(this);
    this.showOfferUpload = this.showOfferUpload.bind(this);
    this.showOfferExtern = this.showOfferExtern.bind(this);
    this.showOfferBookDate = this.showOfferBookDate.bind(this);
    this.showOfferBookCourse = this.showOfferBookCourse.bind(this);
    this.openCategory = this.openCategory.bind(this);
    this.showList = this.showList.bind(this);

    window.addEventListener("resize", this.updateIsMobile.bind(this));
  }

  setShowFilter = (showFilter) => {
    this.setState({ showFilter });
  };

  static getSearchValue(props) {
    return queryString.parse(props.history.location.search).search !== undefined
      ? queryString.parse(props.history.location.search).search
      : "";
  }

  static getFilterValue(props) {
    return props.history.location.search &&
      queryString.parse(props.history.location.search).filter
      ? queryString.parse(props.history.location.search).filter
      : null;
  }

  getQueryString() {
    let queryString2 = "?";
    if (this.state.search) {
      queryString2 += `&search=${this.state.search}`;
    }
    if (this.state.filter) {
      queryString2 += `&filter=${this.state.filter}`;
    }
    return queryString2;
  }

  updateIsMobile() {
    const isMobile = window.innerWidth < 992;
    this.setState({ isMobile });
  }

  showList() {
    if (this.state.isMobile && this.state.offerIsOpen)
      this.setState({ offerIsOpen: false });
    else {
      this.setState({ offerIsOpen: true });
      window.history.back();
    }
  }

  getCategoryByOffer(offer) {
    if (!this.props.data.bgmConcept.categories) {
      return null;
    }

    return this.props.data.bgmConcept.categories.find((category) => {
      if (category.bgmOffers.some((_offer) => _offer.id === offer.id)) {
        return true;
      }
      return false;
    });
  }

  showOffer(offer) {
    const category = this.getCategoryByOffer(offer);
    const currentCategoryId = this.state.currentCategoryId;

    this.setState({
      currentOfferId: offer.id,
      currentOffer: offer,
      offerIsOpen: true,
      action: null,
      currentCategoryId: category ? category.id : currentCategoryId,
    });
    this.props.history.push(`/offers/${offer.id}${this.getQueryString()}`);
  }

  openCategory(categoryId) {
    this.setState({
      currentOfferId: null,
      currentCategoryId: categoryId,
      currentOffer: null,
      offerIsOpen: null,
    });
  }

  showOfferUpload(offer) {
    this.setState({
      currentOffer: offer,
      offerIsOpen: true,
      action: "upload",
    });
    this.props.history.push(
      `/offers/${offer.id}/upload${this.getQueryString()}`
    );
  }

  showOfferExtern(offer, variant) {
    const variantQuery = variant ? `/${variant}` : "";
    this.setState({
      currentOffer: offer,
      offerIsOpen: true,
      action: "book-extern",
    });
    this.props.history.push(
      `/offers/${offer.id}${variantQuery}/book-extern${this.getQueryString()}`
    );
  }

  showOfferBookDate(offer) {
    this.setState({
      currentOffer: offer,
      offerIsOpen: true,
      action: "book-date",
    });
    this.props.history.push(
      `/offers/${offer.id}/book-date${this.getQueryString()}`
    );
  }

  showOfferBookCourse(offer) {
    this.setState({
      currentOffer: offer,
      offerIsOpen: true,
      action: "book-date",
    });
    this.props.history.push(
      `/offers/${offer.id}/book-course${this.getQueryString()}`
    );
  }

  static filterOffer(offer, search, filterObj, attributes) {
    if (offer.refund !== null) {
      return true;
    }
    let filtered = true;
    if (search) {
      const searchExp = new RegExp(escapeStringRegexp(search), "i");
      if (
        offer.therapyOffer.title.search(searchExp) >= 0 ||
        offer.therapyOffer.desc.search(searchExp) >= 0
      ) {
        filtered = true;
      } else {
        return false;
      }
    }
    if (filterObj && filtered) {
      if (filterObj.price) {
        if (offer.therapyOffer.priceMatrix.length > 0) {
          const priceMatrix = offer.therapyOffer.priceMatrix[0];
          if (filterObj.price.free) {
            filtered = !(priceMatrix.price.total > 0);
          }
          const price =
            priceMatrix.price.net * (1 + priceMatrix.price.tax / 100);
          if (filtered && filterObj.price.min && filterObj.price.min > 0) {
            filtered = price > filterObj.price.min;
          }
          if (filtered && filterObj.price.max && filterObj.price.max > 0) {
            filtered = price < filterObj.price.max;
          }
        } else {
          filtered = !(filterObj.price.min && filterObj.price.min > 0);
        }
        if (filtered === false) {
          return false;
        }
      }

      if (filterObj.options && Object.keys(filterObj.options).length > 0) {
        filtered = true;
        Object.keys(filterObj.options).forEach((attributeId) => {
          if (!attributes[attributeId]) {
            filtered = false;
            return;
          }
          let hasOne = false;
          Object.keys(filterObj.options[attributeId]).forEach((valueId) => {
            if (attributes[attributeId][valueId]) {
              hasOne = true;
            }
          });
          filtered = filtered && hasOne;
        });
      }
    }
    return filtered;
  }

  componentDidUpdate(prev) {
    const updateSearch = Offers.getSearchValue(prev) !== this.state.search;
    const updateFilter = Offers.getFilterValue(prev) !== this.state.filter;

    if (
      this.props.data.loading === false &&
      (prev.data.loading === true || updateSearch || updateFilter)
    ) {
      this.props.data.config.modules.forEach((module) => {
        if (module.__typename === "ModuleConfigFrontend") {
          this.setState({ paging: module.employee.offerPaging });
        }
      });

      if (
        !this.state.bankDetailsExist &&
        this.props.data.guest !== undefined &&
        this.props.data.guest.debitor.iban &&
        this.props.data.guest.debitor.bic
      ) {
        this.setState({ bankDetailsExist: true });
      }

      if (
        this.state.employeeBudgetLeft === null &&
        this.props.data.guest.guestSubventionLimit !== null
      ) {
        this.setState({
          employeeBudgetLeft:
            this.props.data.guest.guestSubventionLimit.value -
            this.props.data.guest.guestSubventionLimit.currentValue,
        });
      }

      if (this.state.config === null && this.props.data.config !== undefined) {
        this.setState({ config: this.props.data.config });
      }

      if (
        this.props.data.bgmConcept !== undefined &&
        (this.state.bgmConcept === null || updateSearch || updateFilter)
      ) {
        const offers = {};
        const filteredOffers = {};
        let emptySearch = !(
          Offers.getSearchValue(this.props) || Offers.getFilterValue(this.props)
        );
        const filterObj = JSON.parse(Offers.getFilterValue(this.props));

        let currentCategoryId =
          updateSearch || updateFilter ? null : this.state.currentCategoryId;
        let currentOfferId =
          updateSearch || updateFilter ? null : this.state.currentOfferId;

        this.props.data.bgmConcept.categories
          .filter((cat) => {
            filteredOffers[cat.id] = [];
            const showCat = !(
              filterObj &&
              filterObj.categories &&
              !filterObj.categories[cat.id]
            );
            return showCat;
          })
          .forEach((cat) => {
            cat.bgmOffers.forEach((offer) => {
              const attributes = {};

              if (
                filterObj &&
                filterObj.options &&
                Object.keys(filterObj.options).length > 0
              ) {
                offer.bgmConceptTherapyOfferAttribute.forEach((attribute) => {
                  attributes[attribute.therapyOfferAttribute.id] = {};
                  attributes[attribute.therapyOfferAttribute.id][
                    attribute.therapyOfferAttributeValue.id
                  ] = true;
                });
              }

              if (
                Offers.filterOffer(
                  offer,
                  Offers.getSearchValue(this.props),
                  filterObj,
                  attributes
                )
              ) {
                filteredOffers[cat.id].push(offer);
                if (offer.refund === null) {
                  emptySearch = false;
                }

                offers[offer.id] = { ...offer };
                offers[offer.id].categoryId = cat.id;
                offers[offer.id].config = this.state.config;

                if (
                  !this.state.isMobile &&
                  (currentCategoryId === null ||
                    currentCategoryId === cat.id) &&
                  currentOfferId === null
                ) {
                  currentOfferId = offer.id;
                  this.setState({ currentOfferId: offer.id });
                }
              }
            });

            if (
              currentCategoryId === null &&
              filteredOffers[cat.id].length > 0
            ) {
              currentCategoryId = cat.id;
            }
          });

        this.setState({
          currentOfferId: emptySearch ? null : currentOfferId,
          bgmConcept: this.props.data.bgmConcept,
          offers,
          filteredOffers,
          emptySearch,
          search: Offers.getSearchValue(this.props),
          filter: Offers.getFilterValue(this.props),
        });
      }
    }

    if (
      this.state.offers !== null &&
      this.state.currentOfferId !== null &&
      (this.state.currentOffer === null ||
        this.state.currentOfferId !== this.state.currentOffer.id)
    ) {
      this.setState((prevState) => ({
        currentOffer: prevState.offers[prevState.currentOfferId],
        offerIsOpen: prevState.currentOfferId !== null,
        currentCategoryId:
          prevState.offers[prevState.currentOfferId].categoryId,
      }));
    }
  }

  getRightSide() {
    switch (
      this.state.action ? this.state.action : this.props.match.params.action
    ) {
      case "upload":
        // eslint-disable-next-line no-case-declarations
        const config = this.props.data.config.modules.filter(
          (w) => w.__typename === "ModuleConfigCompany"
        )[0];
        return (
          <Upload
            history={this.props.history}
            config={config}
            offer={this.state.currentOffer}
            bankDetailsExist={this.state.bankDetailsExist}
            employeeBudgetLeft={this.state.employeeBudgetLeft}
          />
        );
      case "book-date":
        return (
          <BookDateComponent
            history={this.props.history}
            enableCoupons={this.props.modules.includes("COUPONS")}
            offerId={this.state.currentOffer.id}
            therapyOfferId={this.state.currentOffer.therapyOffer.id}
          />
        );
      case "book-course":
        return (
          <BookCourseComponent
            history={this.props.history}
            offerId={this.state.currentOffer.id}
            therapyOfferId={this.state.currentOffer.therapyOffer.id}
          />
        );
      case "book-extern":
        return (
          <BookExternComponent
            history={this.props.history}
            config={this.props.data.config}
            guestSubventionLimit={this.props.data.guest.guestSubventionLimit}
            offerId={this.state.currentOffer.id}
            variant={this.props.match.params.variant}
          />
        );
      default:
        return (
          <Offer
            history={this.props.history}
            offerId={this.state.currentOffer.id}
            showOfferUpload={this.showOfferUpload}
            showOfferBookDate={this.showOfferBookDate}
            showOfferBookCourse={this.showOfferBookCourse}
            showOfferExtern={this.showOfferExtern}
          />
        );
    }
  }

  render() {
    return (
      <FormattedMessage id="app.offers.title">
        {(offersTitle) => (
          <Layout
            {...this.props}
            title={
              this.state.currentOffer
                ? this.state.currentOffer.therapyOffer.title
                : offersTitle
            }
            controls={
              this.props.modules.includes("FILTER") ? (
                <FilterIcon
                  onClick={() => this.setShowFilter(!this.state.showFilter)}
                  src={Conf.offerDetailIcons.filter}
                  className={this.state.filter ? "active" : ""}
                />
              ) : null
            }
            goBack={this.showList}
          >
            <PaddingLessContainer
              className="offers"
              style={{ position: "relative" }}
            >
              <Helmet
                title={
                  (this.state.currentOffer
                    ? `${this.state.currentOffer.therapyOffer.title} - `
                    : "") + offersTitle
                }
              />
              <OfferFilter
                history={this.props.history}
                showFilter={this.state.showFilter}
                setShowFilter={this.setShowFilter}
                isMobile={this.state.isMobile}
              />
              <CustomRow>
                <CustomLeftCol
                  xs={12}
                  md={12}
                  lg={4}
                  className={
                    this.state.offerIsOpen || this.state.emptySearch
                      ? ""
                      : "open"
                  }
                >
                  {this.state.bgmConcept ? (
                    <OffersCategories
                      filteredOffers={this.state.filteredOffers}
                      paging={this.state.paging}
                      categories={this.state.bgmConcept.categories}
                      onOfferClick={this.showOffer}
                      openOffer={
                        this.state.currentOffer
                          ? this.state.currentOffer.id
                          : null
                      }
                      currentCategoryId={this.state.currentCategoryId}
                      openCategory={this.openCategory}
                    />
                  ) : (
                    <LoadingCol />
                  )}
                </CustomLeftCol>
                <CustomRightCol
                  xs={12}
                  md={12}
                  lg={8}
                  className={
                    this.state.offerIsOpen || this.state.emptySearch
                      ? "open"
                      : ""
                  }
                >
                  {this.state.emptySearch && (
                    <VerticallyAlignedMiddle height={window.innerHeight / 2}>
                      <FormattedMessage
                        id={
                          this.state.search && !this.state.filter
                            ? "app.offer.search.empty"
                            : !this.state.search && this.state.filter
                            ? "app.offer.filter.empty"
                            : "app.offer.searchFilter.empty"
                        }
                        values={{ search: this.state.search }}
                      />
                    </VerticallyAlignedMiddle>
                  )}
                  {!this.state.emptySearch &&
                    this.state.currentOffer &&
                    (this.state.bgmConcept ? (
                      this.getRightSide()
                    ) : (
                      <LoadingCol />
                    ))}
                </CustomRightCol>
              </CustomRow>
            </PaddingLessContainer>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default graphql(OFFERS_QUERY, {
  options: { fetchPolicy: "cache-and-network" },
})(injectIntl(Offers));
