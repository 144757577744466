import { gql } from "@apollo/client";

export const OFFER_QUERY = gql`
  query offer($id: ID!) {
    guest {
      id
      location {
        country
      }
    }
    bgmConcept {
      offer(id: $id) {
        id
        therapyOffer {
          id
          hidePrices
          price {
            net
            total
            tax
            companySubventionAmount
          }
        }
        extern {
          id
          lastBooking {
            state
            endDate
          }
          availableActions
          enablePackages
          enableNote
          confirmationMessage
          privacyPolicy
          serviceProviderLocation {
            id
            logo
            name
          }
          variants {
            id
            __typename
            description
            ... on BgmConceptTherapyOfferExternVariantCustom {
              title
            }
            ... on BgmConceptTherapyOfferExternVariantEventOnline {
              dateBegin
              dateEnd
              plattformName
            }
            lang
            ... on BgmConceptTherapyOfferExternVariantEventLocation {
              dateBegin
              dateEnd
              locationName
              street
              streetNumber
              zipCode
              city
              country
            }
          }
        }
      }
    }
  }
`;
