import React, { Component } from "react";
import Helmet from "react-helmet";
import { Form, FormGroup, Row, Col, Alert } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import FormControl from "../CustomBootstrap/FormControl";
import Button from "../CustomBootstrap/Button";
import Conf from "../../Conf";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import { RESET_MUTATION } from "./Reset.graphql";
import "./Reset.scss";

const Container = styled("div")`
  min-height: calc(100% - 201px);
  padding-bottom: 52px;
  text-align: left;

  @media screen and (min-width: 768px) {
    min-height: calc(100% - 52px);
  }
`;

const CustomFormControl = styled(FormControl)`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

class Reset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      showMessage: false,
      resetting: false,
      usernameValidationState: null,
    };

    this.parseError = parseError.bind(this);
  }

  async submitForm(event) {
    event.preventDefault();
    await this.setState({ resetting: true });
    /** error FormText on empty user * */
    if (this.isUsernameEmpty()) {
      this.setState({
        usernameValidationState: "error",
        resetting: false,
      });
      return;
    }
    this.setState({ usernameValidationState: "success" });

    this.props
      .mutate({
        variables: {
          username: !isValidEmail(this.state.username)
            ? this.state.username
            : null,
          email: isValidEmail(this.state.username) ? this.state.username : null,
        },
      })
      .then(({ data }) => {
        if (data.requestPasswordReset) {
          this.setState({
            showMessage: true,
            username: "",
          });
        } else {
          const errorTextTranslationKey = `${Conf.errorContext.passwordReset}.default`;

          this._alertModal.open(
            this.props.intl.formatMessage({ id: "app.global.error" }),
            this.props.intl.formatMessage({ id: errorTextTranslationKey })
          );
        }
      })
      .catch((error) => {
        const errorTextTranslationKey = this.parseError(
          error,
          Conf.errorContext.passwordReset
        );
        this._alertModal.open(
          this.props.intl.formatMessage({ id: "app.global.error" }),
          this.props.intl.formatMessage({ id: errorTextTranslationKey })
        );
      })
      .finally(() => {
        this.setState({ resetting: false });
      });
  }

  updateUsername(event) {
    this.setState({ username: event.target.value });
  }

  isUsernameEmpty() {
    return this.state.username === "";
  }

  render() {
    const { intl } = this.props;
    const translationKeys = {
      help: {
        email: "app.password.reset.email.help",
        username: "app.password.reset.username.help",
      },
    };
    const translations = {
      help: {
        email: intl.formatMessage({ id: "app.password.reset.email.help" }),
        username: intl.formatMessage({
          id: "app.password.reset.username.help",
        }),
      },
    };

    return (
      <Container className="reset container">
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <Row>
          <Col
            xs={12}
            md={{ span: 6, offset: 3 }}
            lg={{ span: 4, offset: 4 }}
            style={{ paddingTop: "35px" }}
          >
            {this.state.showMessage ? (
              <Alert variant="success">
                <p className="text-left">
                  <FormattedMessage id="app.password.reset.success" />
                </p>
              </Alert>
            ) : (
              ""
            )}
            <FormattedMessage id="app.password.reset">
              {(message) => <Helmet title={message} />}
            </FormattedMessage>
            <Form onSubmit={(event) => this.submitForm(event)}>
              <FormGroup autoComplete="current-username">
                <FormattedMessage id="app.password.reset.username">
                  {(message) => (
                    <CustomFormControl
                      size="lg"
                      type="text"
                      placeholder={message}
                      name="username"
                      onChange={(event) => this.updateUsername(event)}
                      isInvalid={this.state.usernameValidationState === "error"}
                    />
                  )}
                </FormattedMessage>
                <Form.Control.Feedback type="invalid">
                  <FormattedMessage id="app.password.reset.error.emptyUsername" />
                </Form.Control.Feedback>
                {translations.help.username !==
                  translationKeys.help.username && (
                  <p className="form-text">
                    <FormattedMessage id={translationKeys.help.username} />
                  </p>
                )}
              </FormGroup>
              <FormGroup>
                <div>
                  <div className="d-grid">
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      processing={this.state.resetting}
                    >
                      <FormattedMessage id="app.password.request" />
                    </Button>
                  </div>
                </div>
              </FormGroup>
              <div className="reset__support-btn">
                <FormGroup className="password-separator pt-3 pb-3">
                  <FormattedMessage id="app.password.or" />
                </FormGroup>
                <FormGroup>
                  <div className="d-grid">
                    <a
                      className="btn btn-lg btn-secondary btn-block"
                      href={intl.formatMessage({
                        id: "app.password.reset.support",
                      })}
                    >
                      <FormattedMessage id="app.password.forgotUsername" />
                    </a>
                  </div>
                </FormGroup>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default graphql(RESET_MUTATION)(injectIntl(Reset));
