import { gql } from "@apollo/client";

export const ACCOUNTING_QUERY = gql`
  query queryAccounting {
    config {
      modules {
        __typename
        ... on ModuleConfigPayment {
          enabled
          sepaIdentificationNumber
          enabledEventPaymentTypes
        }
      }
    }
    guest {
      directDebitCancellations
      id
      debitor {
        currentSepaMandate {
          id
          mandateReference
          cancelDate
        }
        id
        accountOwner
        iban
        bic
      }
    }
  }
`;
export const CREATE_SEPA_MANDATE_MUTATION = gql`
  mutation createSepaMandate {
    createSepaMandate {
      id
      mandateReference
      cancelDate
      signDate
    }
  }
`;
export const CANCEL_SEPA_MANDATE_MUTATION = gql`
  mutation cancelSepaMandate($id: ID!) {
    cancelSepaMandate(sepaMandateId: $id) {
      id
      mandateReference
      cancelDate
      signDate
    }
  }
`;
export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($directDebitCancellations: Boolean) {
    updateGuest(
      guest: { directDebitCancellations: $directDebitCancellations }
    ) {
      id
      directDebitCancellations
    }
  }
`;
