import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import Conf from "../../Conf";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../../components/CustomBootstrap/Button";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import { UPDATE_USER_MUTATION } from "./SaveBasicData.graphql";

class SaveBasicData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress: false,
    };

    this.parseError = parseError.bind(this);
  }

  onClick() {
    if (this.props.onValidate()) {
      this.setState({ inProgress: true });

      /** api expects value of type 'null' when value is 'empty' */
      let { emailPrivate } = this.props.data;
      if (emailPrivate.length < 1) {
        emailPrivate = null;
      }

      this.props
        .mutate({
          variables: {
            street: this.props.data.street,
            zip: this.props.data.zip,
            town: this.props.data.town,
            phonePrivate: this.props.data.phonePrivate,
            enableEmailPrivate: this.props.data.enableEmailPrivate,
            emailPrivate,
            accountOwner: this.props.data.accountOwner,
            iban: this.props.data.iban,
            bic: this.props.data.bic,
            mobileJob: this.props.data.mobileJob,
            mobileProviderJob: this.props.data.mobileProviderJob,
          },
        })
        .then(() => {
          this.props.onFinish();
          this.setState({
            inProgress: false,
          });
        })
        .catch((error) => {
          this.setState({ inProgress: false });

          const errorTextTranslationKey = this.parseError(
            error,
            Conf.errorContext.accountSaveBasicData
          );

          this.props.onError(error, errorTextTranslationKey);
        });
    }
  }

  render() {
    return this.state.inProgress ? (
      <LoadingCol />
    ) : (
      <div className="d-grid">
        <Button onClick={this.onClick.bind(this)} variant="danger" size="lg">
          <FormattedMessage id="app.account.save" />
        </Button>
      </div>
    );
  }
}

export default graphql(UPDATE_USER_MUTATION)(injectIntl(SaveBasicData));
