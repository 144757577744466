const budgetLeftError = (props) => {
  if (props.paymentMethod && props.paymentMethod !== "SUBVENTION") return;
  if (props.employeeBudgetLeft >= props.eventOfferPrice) return;
  props.alertModal.open(
    props.intl.formatMessage({ id: "app.global.note" }),
    props.intl.formatMessage({
      id: "app.offers.extern.error.budgetLeft",
    }),
    () => {
      if (props.closeModal) props.closeModal();
      else props.history.push(`/offers/${props.offerId}`);
    }
  );
};

export default budgetLeftError;
