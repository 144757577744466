/**
 * Home Container
 */
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import NextDateWidget from "../components/Widgets/NextDateWidgets";
import HighlightEventsWidget from "../components/Widgets/HighlightEventsWidget";
import CategoryBarsWidget from "../components/Widgets/CategoryBarsWidget";
import BonusWidget from "../components/Widgets/BonusWidget";
import CreditWidget from "../components/Widgets/CreditWidget";
import TodaysEventsWidget from "../components/Widgets/TodaysEventsWidget";
import SubventionBudgetWidget from "../components/Widgets/SubventionBudgetWidget";
import BgmConceptCategories from "../components/Widgets/BgmConceptCategories";
import CompanyEventWidget from "../components/Widgets/CompanyEventWidget";
import { Div } from "../globalComponents/Container";
import { LoadingCol } from "../globalComponents/LoadingCol";
import { showMockup } from "../globalComponents/MockupController";
import OfferBannersWidget from "../components/Widgets/OfferBannersWidget";
import { HOME_QUERY } from "./Home.graphql";

const ThemedTitle = styled("span")`
  color: ${(props) => props.theme.link};
`;

const Container = styled(Div)`
  padding-bottom: 71px;
`;

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privacyPolicyAccepted: true,
      priorityNews: false,
      activePanelId: null,
    };
  }

  getCol(colNumber, widgets, modulesConfig) {
    let enabledWidgets = [];
    const colWidgets = [];

    modulesConfig.forEach((module) => {
      if (module.employee && module.employee.enabledWidgets) {
        enabledWidgets = module.employee.enabledWidgets;
      }
    });

    // widgets.slice(0) is used only as a work around to copy the array of the objects because it's readonly
    const widgetsSorted = widgets.slice(0).sort((a, b) => {
      if (a.order < b.order) {
        return 1;
      }
      if (a.order > b.order) {
        return -1;
      }
      return 0;
    });

    widgetsSorted.forEach((widget) => {
      if (
        enabledWidgets.indexOf(widget.type) !== -1 &&
        widget.col === colNumber
      ) {
        switch (widget.type) {
          case "NEXT_DATE":
            if (widget.nextDate !== null) {
              colWidgets.push(
                <NextDateWidget
                  className="widget"
                  key={widget.type}
                  data={widget}
                  history={this.props.history}
                />
              );
            }
            break;
          case "TODAYS_EVENTS":
            colWidgets.push(
              <TodaysEventsWidget
                className="widget"
                key={widget.type}
                data={widget}
                history={this.props.history}
              />
            );
            break;
          case "SUBVENTION_BUDGET":
            if (!showMockup()) {
              colWidgets.push(
                <SubventionBudgetWidget
                  className="widget"
                  key={widget.type}
                  data={widget}
                  history={this.props.history}
                />
              );
            }
            break;
          case "BGM_CONCEPT_CATEGORIES":
            if (!showMockup()) {
              colWidgets.push(
                <BgmConceptCategories
                  className="widget"
                  key={widget.type}
                  data={widget}
                  history={this.props.history}
                />
              );
            }
            break;
          case "HIGHLIGHT_EVENTS":
            if (!showMockup()) {
              colWidgets.push(
                <HighlightEventsWidget
                  className="widget"
                  key={widget.type}
                  data={widget}
                  history={this.props.history}
                />
              );
            }
            break;
          case "BONUS":
            colWidgets.push(
              <BonusWidget
                className="widget"
                key={widget.type}
                data={widget}
                history={this.props.history}
              />
            );
            break;
          case "CURRENT_CREDIT":
            if (widget.availableCredit.length > 0) {
              colWidgets.push(
                <CreditWidget
                  className="widget"
                  key={widget.type}
                  data={widget}
                  history={this.props.history}
                />
              );
            }
            break;
          case "OFFER_BANNERS":
            colWidgets.push(
              <OfferBannersWidget
                className="widget"
                key={widget.type}
                data={widget}
                history={this.props.history}
              />
            );
            break;
          case "CATEGORY_BARS":
            colWidgets.push(
              <CategoryBarsWidget
                className="widget"
                key={widget.type}
                data={widget}
                history={this.props.history}
                activePanelId={this.state.activePanelId}
                setActivePanelId={(activePanelId) =>
                  this.setState({ activePanelId })
                }
              />
            );
            break;
          default:
        }
      }
    });
    return colWidgets;
  }

  static getThemedTitle(text) {
    return <ThemedTitle className="home-title">{text}</ThemedTitle>;
  }

  componentDidUpdate() {
    if (this.props.data.loading === false) {
      const hash = this.props.history.location.hash.substr(1);

      if (hash !== "privacyPolicyAccepted") {
        if (!this.props.data.guest.privacyPolicyAccepted) {
          this.setState({ privacyPolicyAccepted: false });
        }
      }

      if (hash !== "priorityNewsDone") {
        if (this.props.data.guest.news.length > 0 && !this.state.priorityNews) {
          this.props.data.guest.news.forEach((n) => {
            if (n.priority) {
              this.setState({ priorityNews: true });
              return false;
            }
          });
        }
      }
    }
  }

  render() {
    /** needed to update data since it will not do it automagically * */
    // this.props.data.refetch();

    if (!this.props.data.loading && !this.state.privacyPolicyAccepted) {
      return (
        <Redirect
          to={{
            pathname: "/privacy-policy",
            state: { from: this.props.location },
          }}
        />
      );
    }

    if (!this.props.data.loading && this.state.priorityNews) {
      return (
        <Redirect
          to={{
            pathname: "/priority-news",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <FormattedMessage id="app.home.welcome">
        {(welcome) => (
          <Layout
            {...this.props}
            title={
              this.props.data.loading
                ? Home.getThemedTitle("...")
                : Home.getThemedTitle(
                    `${welcome} ${this.props.data.acl.whoami.firstname}`
                  )
            }
            hideBacklink
          >
            <Helmet
              title={this.props.intl.formatMessage({ id: "app.home.title" })}
            />
            <Container className="container home" onClick={this.hideDetails}>
              {!this.props.data.loading ? (
                <div style={{ marginBottom: "75px" }}>
                  <Row>
                    <Col xs={12} sm={6}>
                      {this.getCol(
                        1,
                        this.props.data.frontend.employee.widgets,
                        this.props.data.config.modules
                      )}
                    </Col>
                    <Col xs={12} sm={6} className="hidden-xs">
                      {this.getCol(
                        2,
                        this.props.data.frontend.employee.widgets,
                        this.props.data.config.modules
                      )}
                      {this.props.data.calendar.companyEvent !== null ? (
                        <CompanyEventWidget
                          className="widget"
                          key="company-event"
                          data={this.props.data.calendar.companyEvent}
                          history={this.props.history}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12}>
                      {this.getCol(
                        0,
                        this.props.data.frontend.employee.widgets,
                        this.props.data.config.modules
                      )}
                    </Col>
                  </Row>
                </div>
              ) : (
                <LoadingCol />
              )}
            </Container>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default graphql(HOME_QUERY, {
  options: { fetchPolicy: "cache-and-network" },
})(injectIntl(Home));
