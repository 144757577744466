import React from "react";
import { FormattedMessage } from "react-intl";
import { FooterMenu, FooterItem, SelectLi } from "./FooterStyles";
import Conf from "../../Conf";
import LanguageSelect from "../../containers/Language/LanguageSelect";
import { withLanguage } from "../../context/LanguageContext/withLanguage";

/**
 * Footer Component
 * Menu links are defined in Conf.js
 */
// eslint-disable-next-line react/prefer-stateless-function
class Footer extends React.Component {
  render() {
    return (
      <FooterMenu className="footer-navigation">
        {Conf.basicLinks.map((link) => (
          <FooterItem key={link.key} url={link.route}>
            <FormattedMessage id={link.name} />
          </FooterItem>
        ))}
        {this.props.languageContext.selectionAvailable && (
          <SelectLi>
            <LanguageSelect />
          </SelectLi>
        )}
      </FooterMenu>
    );
  }
}

export default withLanguage(Footer);
