import React, { Component } from "react";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import { EXTERNAL_VARIANTS } from "../../config/offer/external-variants";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import { ExternBookingForm } from "../Booking/ExternBookingForm";
import { OFFER_QUERY } from "./Extern.graphql";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 15px;
  width: calc(100% + 15px);
`;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 30px;
`;

class Book extends Component {
  constructor(props) {
    super(props);

    const budgetTotal =
      props.guestSubventionLimit !== null
        ? props.guestSubventionLimit.value
        : null;
    const budgetLeft =
      props.guestSubventionLimit !== null
        ? props.guestSubventionLimit.value -
          props.guestSubventionLimit.currentValue
        : null;
    const budgetGross =
      props.guestSubventionLimit !== null
        ? props.guestSubventionLimit.gross
        : null;

    this.state = {
      budgetTotal,
      budgetLeft,
      budgetGross,
      variant: null,
    };
  }

  /** set default information of the view */
  setupView() {
    if (this.props.variant) {
      const typename = this.props.variant.includes("location")
        ? EXTERNAL_VARIANTS.LOCATION
        : this.props.variant.includes("custom")
        ? EXTERNAL_VARIANTS.CUSTOM
        : EXTERNAL_VARIANTS.ONLINE;
      const variantId = this.props.variant.match(/\d+/)[0];

      const crossRefVariant =
        this.props.data.bgmConcept.offer.extern.variants.find(
          (variant) =>
            variant.id === variantId && variant.__typename === typename
        );

      if (crossRefVariant) {
        this.setState({ variant: crossRefVariant });
      }
    }
  }

  componentDidUpdate(prev) {
    if (!this.props.data.loading && prev.data.loading) {
      this.setupView();
    }
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    return (
      <ScrollableDiv>
        <Wrapper>
          <ExternBookingForm
            {...this.state}
            config={this.props.config}
            offer={this.props.data.bgmConcept.offer}
            variant={this.state.variant}
            country={this.props.data.guest.location.country}
            history={this.props.history}
          />
        </Wrapper>
      </ScrollableDiv>
    );
  }
}

export const BookExternComponent = graphql(OFFER_QUERY, {
  options: (props) => ({
    variables: { id: props.offerId },
    fetchPolicy: "cache-and-network",
  }),
})(injectIntl(Book));
