import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as PlayButtonIcon } from "./img/play_button_outline.svg";
import { ReactComponent as PlayIcon } from "./img/play_outline.svg";
import { ReactComponent as PauseIcon } from "./img/pause_outline.svg";
import { ReactComponent as VolumeMaximumIcon } from "./img/volume_maximum_outline.svg";
import { ReactComponent as VolumeMuteIcon } from "./img/volume_mute_outline.svg";
import { ReactComponent as MaximizeIcon } from "./img/maximize_outline.svg";

const VideoWrapper = styled("figure")`
  position: relative;
  display: flex;
  flex-direction: row;     /* make main axis horizontal (default setting) */
  justify-content: center; /* center items horizontally, in this case */
  align-items: center;     /* center items vertically, in this case */
  }
`;

const PlayButtonDiv = styled("div")`
   {
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
`;

const ControlsWrapper = styled("div")`
   {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    width: 100%;
    justify-content: space-around;
    background: #0006;
  }
`;

const ControlsElement = styled("div")`
   {
    ${(props) => props.flex && `flex: ${props.flex};`}
    background: transparent;
    color: #fff;
    cursor: pointer;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ControlsSlider = styled("progress")`
   {
    width: 100%;
    accent-color: #ff6200;
  }
`;

const VideoPlayer = ({ url, posterUrl, isStopped, setIsStopped }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);

  const localVideoRef = useRef();
  const positionSliderRef = useRef();
  const volumeSliderRef = useRef();

  useEffect(() => {
    localVideoRef.current.volume = volume;
    localVideoRef.current.muted = volume === 0;
  }, [volume]);

  React.useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement));
    };

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  useEffect(() => {
    if (isPlaying) {
      localVideoRef.current.play();
      setIsStopped(false);
    } else {
      localVideoRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isStopped) {
      setIsPlaying(false);
    }
  }, [isStopped]);

  return (
    <VideoWrapper className="video-player">
      <PlayButtonDiv
        style={{
          visibility: isPlaying ? "hidden" : "visible",
        }}
        onClick={() => {
          setIsPlaying(true);
        }}
      >
        <PlayButtonIcon width="100" height="100" className="control" />
      </PlayButtonDiv>
      <video
        ref={localVideoRef}
        poster={posterUrl}
        controls={isFullscreen}
        preload="metadata"
        onClick={(event) => {
          event.preventDefault();
        }}
        onVolumeChange={(event) => {
          setVolume(event.target.volume);
        }}
        onPlay={() => {
          setIsPlaying(true);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
        onTimeUpdate={(event) => {
          setProgress(
            event.currentTarget.currentTime / localVideoRef.current.duration
          );
        }}
      >
        <source src={url} type="video/mp4" />
      </video>

      <ControlsWrapper>
        <ControlsElement>
          {isPlaying ? (
            <PauseIcon
              width="20"
              height="20"
              className="control"
              onClick={() => {
                setIsPlaying(!isPlaying);
              }}
            />
          ) : (
            <PlayIcon
              width="20"
              height="20"
              className="control"
              onClick={() => {
                setIsPlaying(!isPlaying);
              }}
            />
          )}
        </ControlsElement>
        <ControlsElement flex={5}>
          <ControlsSlider
            ref={positionSliderRef}
            value={progress}
            max={1}
            onClick={(event) => {
              const position =
                positionSliderRef.current.getBoundingClientRect();
              const selectedProgress =
                (event.pageX - position.left) / position.width;
              setProgress(selectedProgress);
              localVideoRef.current.currentTime =
                selectedProgress * localVideoRef.current.duration;
            }}
          />
        </ControlsElement>
        <ControlsElement>
          {volume > 0 ? (
            <VolumeMaximumIcon
              width="20"
              height="20"
              className="control"
              onClick={() => {
                setVolume(volume === 0 ? 1 : 0);
              }}
            />
          ) : (
            <VolumeMuteIcon
              width="20"
              height="20"
              className="control"
              onClick={() => {
                setVolume(volume === 0 ? 1 : 0);
              }}
            />
          )}
        </ControlsElement>
        <ControlsElement flex={1}>
          <ControlsSlider
            ref={volumeSliderRef}
            value={volume}
            max={1}
            onClick={(event) => {
              const position = volumeSliderRef.current.getBoundingClientRect();
              const selectedVolume =
                (event.pageX - position.left) / position.width;
              setVolume(selectedVolume);
            }}
          />
        </ControlsElement>
        <ControlsElement>
          <MaximizeIcon
            width="20"
            height="20"
            className="control"
            onClick={() => {
              localVideoRef.current.requestFullscreen();
              setIsFullscreen(true);
            }}
          />
        </ControlsElement>
      </ControlsWrapper>
    </VideoWrapper>
  );
};

export default VideoPlayer;
