import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Alert,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Col,
  ToggleButton,
  ToggleButtonGroup,
  FormCheck,
  InputGroup,
  FormText,
} from "react-bootstrap";
import styled from "styled-components";
// TODO: import ReactBootstrapSlider from "react-bootstrap-slider";
// TODO: import "bootstrap-slider/dist/css/bootstrap-slider.css";
import Spinner from "../../globalComponents/Spinner";
import "./Question.scss";

const QuestionForm = styled(FormGroup)`
  FormControl {
    margin-right: 20px;
  }
  .slider {
    margin-top: 10px;
    width: 90% !important;
  }
  .slider-handle {
    background: 0;
    background-color: ${(props) => props.theme.btnBg};
  }
  .slider-selection.tick-slider-selection {
    background: ${(props) => props.theme.btnBg};
    opacity: 0.65;
  }
  .slider-tick.in-selection {
    background: #fc9656;
  }

  FormText {
    display: block;
    font-size: 0.75em;
  }
  .question-input {
    padding-right: 40px;
  }
  .question-input Button {
    margin-top: 0;
  }
  .question-input:first-child {
    margin: auto;
    width: 90% !important;
  }
  .question-input .spinner {
    width: 50%;
    input {
      text-align: center;
      padding-right: 12px;
    }
  }
  &.has-success .btn-default {
    border-color: #3c763d !important;
  }
  &.has-success .btn-default.active,
  &.has-success .spinner button {
    color: #3c763d !important;
    background-color: #dff0d8 !important;
    border-color: #3c763d !important;
  }
  &.has-error .btn-default {
    border-color: #a94442 !important;
  }
  &.has-error .btn-default.active,
  &.has-error .spinner button {
    color: #a94442 !important;
    background-color: #f9f2f4 !important;
    border-color: #a94442 !important;
  }

  .input-group .dropdown {
    display: block;
  }
`;
const AlignLeft = styled("div")`
  width: 100%;
  text-align: left;
`;

const CenterAlign = styled("div")`
  width: 100%;
  text-align: center;
`;

const RightAlign = styled("div")`
  width: 100%;
  text-align: right;
`;

const TYPES = {
  DROPDOWN: 1,
  TEXT: 2,
  LONGTEXT: 3,
  CHECKBOXES: 4,
  RADIOBUTTONS: 5,
  NUMBER: 6,
  SLIDER: 7,
  YES_NO: 8,
  SPINNER: 9,
  ZONES_UPPER: 10,
  ZONES_FULL: 11,
};

class Question extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.renderFormControl = this.renderFormControl.bind(this);

    this.state = {
      valid: props.question.requiredAnswers === 0,
      question: props.question,
      answer: null,
      changed: false,
      freeTextVal: "",
    };
  }

  getValidationState() {
    if (this.state.changed === false) {
      if (this.state.question.requiredAnswers > 0) {
        return null;
      }
    }

    if (this.state.valid) {
      return "success";
    }
    return "error";
  }

  handleChange(e) {
    let answer = null;
    let valid = false;
    const { question } = this.state;

    switch (question.display.type) {
      case TYPES.DROPDOWN: {
        answer = [];
        const { options } = e.target;
        for (let i = 0, iLen = options.length; i < iLen; i++) {
          if (options[i].selected && `${options[i].value}`.length > 0) {
            answer.push(options[i].value);
          }
        }
        valid =
          answer !== null && answer.length !== undefined
            ? answer.length >= this.state.question.requiredAnswers
            : false;
        break;
      }
      case TYPES.CHECKBOXES:
        answer = this.state.answer === null ? [] : this.state.answer;
        if (e.target.checked) {
          if (`${e.target.value}`.length > 0) {
            answer.push(e.target.value);
          }
          if (e.target.prevValue !== undefined) {
            answer = answer.filter((a) => a !== e.target.prevValue);
          }
        } else {
          answer = answer.filter((a) => a !== e.target.value);
        }

        valid = answer.length >= this.state.question.requiredAnswers;
        break;
      case TYPES.RADIOBUTTONS:
        answer = e.target.value;
        valid = true;
        break;
      case TYPES.NUMBER: {
        const val = e.target.value.replace(/[^0-9]/g, "");
        answer = val === "" ? null : parseInt(val, 10);
        valid =
          answer !== null &&
          `${answer}`.length >= this.state.question.requiredAnswers;
        if (
          answer !== null &&
          question.display.options.min !== undefined &&
          answer < question.display.options.min
        ) {
          valid = false;
        }
        if (
          answer !== null &&
          question.display.options.max !== undefined &&
          answer > question.display.options.max
        ) {
          answer = parseInt(question.display.options.max, 10);
          valid = false;
        }
        break;
      }
      case TYPES.SLIDER:
        answer = e.target.value;
        valid = true;
        break;
      case TYPES.YES_NO:
        answer = e;
        valid = true;
        break;
      case TYPES.SPINNER:
        answer = e;
        valid = `${answer}`.length >= this.state.question.requiredAnswers;
        if (
          question.display.options.min !== undefined &&
          answer < question.display.options.min
        ) {
          answer = parseInt(question.display.options.min, 10);
        }
        if (
          question.display.options.max !== undefined &&
          answer > question.display.options.max
        ) {
          answer = parseInt(question.display.options.max, 10);
        }
        break;
      case TYPES.ZONES_UPPER:
      case TYPES.ZONES_FULL:
        answer = e;
        if (this.state.question.requiredAnswers > 0) {
          let num = 0;
          Object.values(e).map((val) => {
            if (val > 0) {
              num++;
            }
            return val;
          });
          valid = num >= this.state.question.requiredAnswers;
        } else {
          valid = true;
        }
        break;
      case TYPES.TEXT:
      case TYPES.LONGTEXT:
        answer = e.target.value !== undefined ? e.target.value : "";
        valid = answer.length >= this.state.question.requiredAnswers;
        break;
      default:
    }
    this.setState((prevState) => ({
      answer,
      valid,
      changed: true,
      question: { valid, ...prevState.question },
    }));
    this.props.onUpdate(this.state.question.id, answer, valid);
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.question.id !== this.state.question.id) {
      this.setState({
        question: this.props.question,
        valid: this.props.question.requiredAnswers === 0,
        changed: false,
        answer: null,
      });
    }
  }

  renderFormControl(question) {
    switch (question.display.type) {
      case TYPES.DROPDOWN: {
        const selAnswer =
          question.display.options.multi_select !== undefined &&
          question.display.options.multi_select
            ? []
            : "";
        return (
          <InputGroup bsClass="input-group dropdown">
            <FormControl
              required={question.requiredAnswers > 0}
              multiple={
                question.display.options.multi_select !== undefined &&
                question.display.options.multi_select
              }
              bsClass="form-control input-small"
              as="select"
              value={this.state.answer === null ? selAnswer : this.state.answer}
              onChange={this.handleChange}
            >
              {selAnswer === "" ? (
                <option key={0} value="">
                  {this.props.intl.formatMessage({
                    id: "app.survey.questionnaire.question.dropdown.placeholder",
                  })}
                </option>
              ) : (
                ""
              )}
              {question.display.answers.map((answer) => (
                <option key={answer.id} value={answer.value}>
                  {answer.title}
                </option>
              ))}
            </FormControl>
            {question.display.options.unit !== undefined ? (
              <InputGroup.Addon>
                {question.display.options.unit}
              </InputGroup.Addon>
            ) : (
              ""
            )}
          </InputGroup>
        );
      }

      case TYPES.TEXT:
        return (
          <FormControl
            required={question.requiredAnswers > 0}
            type="text"
            value={this.state.answer === null ? "" : this.state.answer}
            placeholder={this.props.intl.formatMessage({
              id: "app.survey.questionnaire.question.text.placeholder",
            })}
            onChange={this.handleChange}
          />
        );
      case TYPES.LONGTEXT:
        return (
          <FormControl
            required={question.requiredAnswers > 0}
            size="lg"
            as="textarea"
            value={this.state.answer === null ? "" : this.state.answer}
            rows={
              question.display.options.rows !== undefined
                ? question.display.options.rows * 1
                : 2
            }
            placeholder={this.props.intl.formatMessage({
              id: "app.survey.questionnaire.question.longtext.placeholder",
            })}
            onChange={this.handleChange}
          />
        );
      case TYPES.CHECKBOXES:
        return (
          <FormGroup>
            {question.display.answers.map((answer) => (
              <FormCheck
                key={answer.id}
                value={answer.value}
                onChange={this.handleChange}
              >
                {answer.title}
              </FormCheck>
            ))}
            {question.display.options.add_free_answer ? (
              <FormCheck
                disabled
                checked={this.state.freeTextVal.length > 0}
                value={this.state.freeTextVal}
                onChange={this.handleChange}
              >
                <FormControl
                  size="sm"
                  type="text"
                  value={this.state.freeTextVal}
                  placeholder={question.display.options.free_answer_label}
                  onChange={(e) => {
                    this.setState({ freeTextVal: e.target.value });
                    const ev = { ...e };
                    ev.target.checked = true;
                    ev.target.prevValue = this.state.freeTextVal;
                    this.handleChange(ev);
                  }}
                />
              </FormCheck>
            ) : (
              ""
            )}
          </FormGroup>
        );
      case TYPES.RADIOBUTTONS:
        return (
          <FormGroup>
            {question.display.answers.map((answer) => (
              <FormCheck
                type="radio"
                key={answer.id}
                name={`quest-${this.state.question.id}`}
                value={answer.value}
                onClick={this.handleChange}
              >
                {answer.title}
              </FormCheck>
            ))}
          </FormGroup>
        );
      case TYPES.NUMBER:
        return (
          <InputGroup bsClass="input-group__numbers">
            <FormControl
              pattern="^[0-9]*$"
              required={question.requiredAnswers > 0}
              size="small"
              type="text"
              value={this.state.answer === null ? "" : this.state.answer}
              placeholder={this.props.intl.formatMessage({
                id: "app.survey.questionnaire.question.number.placeholder",
              })}
              onChange={this.handleChange}
            />{" "}
            {question.display.options.unit !== undefined ? (
              <InputGroup.Addon>
                {question.display.options.unit}
              </InputGroup.Addon>
            ) : (
              ""
            )}
          </InputGroup>
        );
      case TYPES.SLIDER: {
        /* TODO: const defaultVal =
          question.display.options.start_value !== undefined
            ? question.display.options.start_value * 1
            : 0; */
        const max =
          question.display.options.max !== undefined
            ? question.display.options.max * 1
            : 5;
        const min =
          question.display.options.min !== undefined
            ? question.display.options.min * 1
            : 1;
        const ticks = [];
        const ticksLabels = [];
        for (let i = min; i <= max; i++) {
          ticks.push(i);
          if (question.display.answers.length === 0) {
            ticksLabels.push(`${i}`);
          } else {
            question.display.answers.forEach((answer) => {
              if (parseInt(answer.value, 10) === i) {
                ticksLabels.push(answer.title);
              }
            });
          }
        }

        return (
          <CenterAlign
            className={
              this.state.question.singleRow
                ? "slider-wrapper slider-wrapper--singleRow"
                : "slider-wrapper"
            }
          >
            {/* TODO: <ReactBootstrapSlider
              value={
                this.state.answer !== null ? this.state.answer : defaultVal
              }
              change={this.handleChange}
              slideStop={this.handleChange}
              step={1}
              max={max}
              min={min}
              orientation="horizontal"
              ticks={ticks}
              ticks_labels={ticksLabels}
            /> */}
          </CenterAlign>
        );
      }
      case TYPES.YES_NO:
        return React.createElement(
          this.state.question.singleRow ? AlignLeft : RightAlign,
          null,
          <ToggleButtonGroup
            name={`yes-no-${this.state.question.id}`}
            type="radio"
            value={this.state.answer}
            onChange={this.handleChange}
          >
            <ToggleButton
              onClick={() => {
                this.handleChange(1);
              }}
              value={1}
            >
              {this.props.intl.formatMessage({
                id: "app.survey.questionnaire.question.yes",
              })}
            </ToggleButton>
            <ToggleButton
              onClick={() => {
                this.handleChange(0);
              }}
              value={0}
            >
              {this.props.intl.formatMessage({
                id: "app.survey.questionnaire.question.no",
              })}
            </ToggleButton>
          </ToggleButtonGroup>
        );
      case TYPES.SPINNER: {
        const defaultValue =
          question.display.options.start_value !== undefined
            ? question.display.options.start_value * 1
            : 0;

        const TagName = this.state.question.singleRow ? AlignLeft : RightAlign;
        return (
          <TagName>
            <Spinner
              className={[
                "spinner",
                this.state.question.singleRow ? "" : "spinner--right",
              ].join(" ")}
              readOnly
              value={
                this.state.answer !== null ? this.state.answer : defaultValue
              }
              unit={question.display.options.unit}
              onChange={this.handleChange}
              singleRow={this.state.question.singleRow}
              style={{
                float: this.state.question.singleRow ? "right" : "auto",
              }}
              placeholder={this.props.intl.formatMessage({
                id: "app.survey.questionnaire.question.number.placeholder",
              })}
            />
          </TagName>
        );
      }
      default:
        return (
          <p>
            <FormattedMessage id="app.survey.questionnaire.question.unknownType" />
          </p>
        );
    }
  }

  render() {
    if (this.state.question === undefined || this.state.question === null) {
      return null;
    }

    let errorMessage = null;

    if (this.state.valid === false && this.state.changed === true) {
      errorMessage = "app.survey.questionnaire.error.required";
    }

    return (
      <QuestionForm
        controlId={`form-control-${this.state.question.id}`}
        validationState={this.getValidationState()}
      >
        {errorMessage !== null ? (
          <Alert variant="danger">
            {React.createElement("p", {
              dangerouslySetInnerHTML: {
                __html: this.props.intl.formatMessage({ id: errorMessage }),
              },
            })}
          </Alert>
        ) : (
          ""
        )}
        <Row>
          <Col
            className="question-label"
            xs={12}
            md={this.state.question.singleRow === true ? 12 : 6}
          >
            <FormLabel>{this.state.question.title}</FormLabel>
            {React.createElement(FormText, {
              dangerouslySetInnerHTML: { __html: this.state.question.text },
            })}
          </Col>
          <Col
            className="question-input"
            xs={12}
            md={this.state.question.singleRow === true ? 12 : 6}
          >
            {this.renderFormControl(this.state.question)}
            <FormControl.Feedback />
          </Col>
        </Row>
      </QuestionForm>
    );
  }
}

export default injectIntl(Question);
