import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import * as queryString from "query-string";
import FormControl from "../CustomBootstrap/FormControl";
import Button from "../CustomBootstrap/Button";
import Icon from "../../globalComponents/Icon";
import Conf from "../../Conf";

const SearchForm = styled(Form)`
  float: right;
  margin-top: 5px !important;
  margin-right: 20px;
  .input-group {
    float: right;
    width: 250px;
    margin: 0;
  }
  @media screen and (max-width: 489px) {
    float: none;
    clear: both;
    margin-right: 0;
    .input-group {
      float: none;
      width: auto;
      margin: 0;
    }
  }
  input {
    border-color: #8a8a8a !important;
    border-radius: 4px !important;
    padding-right: 40px !important;
    height: 34px !important;
  }
  input:focus {
    box-shadow: none !important;
  }
  input::placeholder {
    font-size: 14px;
    color: #999;
  }
  button {
    background-color: transparent !important;
    border: none;
    position: absolute !important;
    right: 0;
    z-index: 10 !important;
    padding: 0;
    height: 100%;
  }
`;

export const MiniIcon = styled(Icon)`
  height: 34px;
  width: 20px;
  margin-right: 10px;
  svg {
    height: 34px;
    width: 20px;
  }
  svg path {
    fill: #ff6200;
  }
`;

class Search extends Component {
  constructor(props) {
    super(props);

    const searchValue = Search.getSearchValue(this.props);

    this.state = { search: searchValue };

    this.startSearch = this.startSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  static getSearchValue(props) {
    return queryString.parse(props.history.location.search).search !== undefined
      ? queryString.parse(props.history.location.search).search
      : "";
  }

  startSearch(event) {
    event.preventDefault();
    const filter =
      this.props.history.location.search &&
      queryString.parse(this.props.history.location.search).filter
        ? queryString.parse(this.props.history.location.search).filter
        : null;
    if (this.state.search !== "") {
      this.props.history.push(
        `/offers/?search=${this.state.search}${
          filter ? `&filter=${filter}` : ""
        }`
      );
    }
    return false;
  }

  componentDidUpdate(prev) {
    if (
      Search.getSearchValue(prev) !== Search.getSearchValue(this.props) &&
      Search.getSearchValue(this.props) !== this.state.search
    ) {
      this.setState({ search: Search.getSearchValue(this.props) });
    }
  }

  updateSearch(event) {
    this.setState({ search: event.target.value });
  }

  render() {
    return (
      <SearchForm onSubmit={this.startSearch} action="/offers/" method="get">
        <InputGroup>
          <FormattedMessage id="app.home.widget.search.placeholder">
            {(message) => (
              <>
                <FormControl
                  type="text"
                  name="search"
                  value={this.state.search}
                  placeholder={message}
                  onChange={this.updateSearch}
                />
                <Button type="submit">
                  <MiniIcon src={Conf.globalIcons.search} />
                </Button>
              </>
            )}
          </FormattedMessage>
        </InputGroup>
      </SearchForm>
    );
  }
}

export default injectIntl(Search);
