import { gql } from "@apollo/client";

export const RECEIPT_UPLOAD_MUTATION = gql`
  mutation receiptUpload(
    $bgmConceptTherapyOfferRefundId: ID!
    $conditionsAccepted: Boolean!
    $receiptTitle: String
    $receiptNumber: String
    $receiptDate: Date!
    $comment: String
    $gross: Float!
    $duration: Int
    $serviceProviderId: ID
    $serviceProviderLocationId: ID
    $attributes: [BgmConceptTherapyOfferRefundRecordAttributeInput]!
  ) {
    uploadRefundReceipt(
      receipt: {
        bgmConceptTherapyOfferRefundId: $bgmConceptTherapyOfferRefundId
        conditionsAccepted: $conditionsAccepted
        receiptTitle: $receiptTitle
        receiptNumber: $receiptNumber
        receiptDate: $receiptDate
        comment: $comment
        gross: $gross
        duration: $duration
        serviceProviderId: $serviceProviderId
        serviceProviderLocationId: $serviceProviderLocationId
        attributes: $attributes
      }
    ) {
      id
      state
    }
  }
`;
