import { gql } from "@apollo/client";

export const CALENDAR_COMPONENT_QUERY = gql`
  query ($week: String!) {
    calendar {
      weeksConnection(first: 1, after: $week) {
        edges {
          cursor
          node {
            days {
              day
              date
              events {
                id
                type
                timeStart
                timeEnd
                date
                guestState
                roomEvent {
                  id
                  day
                  dateBegin
                  dateEnd
                  therapyOffer {
                    id
                    duration
                    price {
                      total
                      companySubventionAmount
                    }
                  }
                }
                therapist {
                  displayName
                  photo
                }
                room {
                  name
                  emailNote
                }
                offers {
                  guestState
                  totalSlots
                  freeSlots
                  slots {
                    timeStart
                    timeEnd
                    guestState
                  }
                  offer {
                    id
                    icon
                    title
                    emailNote
                    price {
                      total
                      companySubventionAmount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    guest {
      id
      guestSubventionLimit {
        id
        currentValue
        value
      }
    }
    config {
      modules {
        ... on ModuleConfigFrontend {
          employee {
            showPrices
            showSubventionValue
          }
        }
        ... on ModuleConfigPayment {
          enabledEventPaymentTypes
        }
        ... on ModuleConfigCalendar {
          dateEditableUntil
          courseCancelableUntil
        }
        ... on ModuleConfigCalendar {
          reservationEnabled
        }
      }
    }
  }
`;
