import { gql } from "@apollo/client";

export const CONFIG_QUERY = gql`
  query {
    config {
      modules {
        ... on ModuleConfigFrontend {
          employee {
            enabledModules
          }
        }
      }
    }
  }
`;
