import React from "react";
import styled from "styled-components";
// import { FaAngleDown } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import Icon from "react-icon-base";

const FaAngleDown = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m31 16.4q0 0.3-0.2 0.5l-10.4 10.4q-0.3 0.3-0.5 0.3t-0.6-0.3l-10.4-10.4q-0.2-0.2-0.2-0.5t0.2-0.5l1.2-1.1q0.2-0.2 0.5-0.2t0.5 0.2l8.8 8.8 8.7-8.8q0.3-0.2 0.5-0.2t0.6 0.2l1.1 1.1q0.2 0.2 0.2 0.5z" />
    </g>
  </Icon>
);

export const CustomRow = styled(Row)`
  height: 100%;
  margin: 0 !important;
  position: relative;

  @media screen and (max-width: 992px) {
    overflow: hidden;
  }
`;

export const CustomLeftCol = styled(Col)`
  background-color: white;
  height: 100% !important;
  overflow: hidden;
  padding: 0 !important;

  @media screen and (max-width: 992px) {
    z-index: 20;

    position: absolute !important;
    transition: right 0.2s ease-in-out;

    &.open {
      right: 0;
    }
  }

  @media screen and (min-width: 992px) {
    border-right: 1px solid #d4d4d4;
    border-left: 1px solid #d4d4d4;
  }
`;

export const CustomRightCol = styled(Col)`
  background-color: white;
  height: 100% !important;
  overflow: hidden;
  padding: 0 !important;

  @media screen and (max-width: 992px) {
    background-color: #ffffff;
    left: 100%;

    position: absolute !important;
    transition: left 0.2s ease-in-out;
    z-index: 10;

    &.open {
      left: 0;
      z-index: 30;
    }
  }

  @media screen and (min-width: 992px) {
    border-right: 1px solid #d4d4d4;
  }
`;

export const CustomFaAngleRight = styled(FaAngleDown)`
  color: ${(props) => props.theme.btnBg};
  font-size: 40px;
  margin-left: auto;
  transition: all 0.2s ease-in-out;
`;

export const CustomListGroup = styled("div")`
  height: 100%;
  overflow-y: scroll;
  text-align: left;
  width: 100%;

  @media screen and (max-width: 992px) {
    background-color: #ffffff;
    border: 0;
    position: absolute;
  }
`;

export const ListGroupItem = styled("div")`
  align-items: center;
  background-color: #e3ea8f !important;
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #ffffff !important;
  color: #646567;
  cursor: pointer;
  display: flex !important;
  font-size: 16px;
  font-weight: bold;
  margin: 0 !important;
  padding: 2px 15px !important;

  .grey svg path {
    fill: #646567;
  }

  &.open svg.arrow {
    transform: rotate(180deg);
  }

  &.selected {
    background-color: #fff !important;
  }

  svg.arrow {
    fill: #646567;
  }
`;

export const SecondLevelListGroupItem = styled(ListGroupItem)`
  background-color: #ffffff !important;
  border-bottom: 1px solid #d4d4d4 !important;
  font-weight: 400;
  color: #000000;

  svg.arrow,
  &.open svg.arrow {
    transform: rotate(-90deg);
  }

  &.open {
    background-color: #ffffff !important;
    color: ${(props) => props.theme.btnBg} !important;
  }

  svg.arrow {
    fill: ${(props) => props.theme.btnBg} !important;
  }
`;

export const Title = styled("div")`
  padding: 0 15px;
  width: 90%;
`;

export const Header = styled("div")`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: ${window.innerWidth / 2};
  min-height: 56px;
  margin: 0 -30px;
  padding: 2px 15px 2px 30px;
  text-align: left;
  transition: padding 0.2s ease-in-out;
  font-weight: bold;

  &.open {
    padding: 0 15px 0 30px;
  }

  &.open svg.arrow {
    transform: rotate(180deg);
  }
`;
