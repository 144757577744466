import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import ExternalOfferVariant from "./ExternalOfferVariant";
import Option from "../Basic/Option";
import "./ExternalOfferVariants.scss";
import "./Sort.scss";
import { variantShape } from "../Booking/ExternBookingForm";

const locationStr = "location";
const onlineStr = "online";

class ExternalOfferVariants extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variants:
        this.props.variants.sort((a, b) =>
          ExternalOfferVariants.sortByKey(a, b, "dateBegin")
        ) || [],
    };
  }

  filterEvent(key, e) {
    const { value } = e.target;

    if (value === "choose") {
      this.setState({ variants: this.props.variants || [] });
    } else {
      this.setState({
        variants: this.props.variants.filter(
          (variant) => variant[key] === value
        ),
      });
    }
  }

  static sortByKey(a, b, key) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }

    return 0;
  }

  sortState(what, e) {
    const key = e.target.value.includes(
      this.props.intl.formatMessage({ id: "app.offers.extern.variants.date" })
    )
      ? "dateBegin"
      : e.target.value.includes(
          this.props.intl.formatMessage({
            id: "app.offers.extern.variants.allPlaces",
          })
        )
      ? "city"
      : "plattformName";

    this.setState((prevState) => {
      const sorted = prevState.variants.sort((a, b) =>
        ExternalOfferVariants.sortByKey(a, b, key)
      );
      return { sorted };
    });
  }

  handleBooking(id) {
    this.props.showOfferExtern(`${this.props.type}-${id}`);
  }

  render() {
    const variantSet =
      this.props.type === "online"
        ? new Set(this.props.variants.map((event) => event.plattformName))
        : new Set(this.props.variants.map((event) => event.city));

    const targetKey = this.props.type === "online" ? "plattformName" : "city";

    const { intl } = this.props;

    return (
      <div className="external-offer-variants">
        {this.props.variants.length > 0 && (
          <section className="external-offer-variants__variant-wrapper">
            <div className="external-offer-variants__filter">
              {this.props.type !== "custom" &&
                Array.from(this.props.variants).length > 1 &&
                Array.from(variantSet).filter((item) => !!item).length > 0 && (
                  <Option
                    defaultValue={
                      this.props.type === "online"
                        ? intl.formatMessage({
                            id: "app.offers.extern.variants.platform",
                          })
                        : intl.formatMessage({
                            id: "app.offers.extern.variants.allPlaces",
                          })
                    }
                    items={Array.from(variantSet).filter((item) => !!item)}
                    onChange={this.filterEvent.bind(this, targetKey)}
                    prepend="Filtern nach: "
                  />
                )}
              {this.props.type !== "custom" && (
                <Option
                  items={[
                    intl.formatMessage({
                      id: "app.offers.extern.variants.date",
                    }),
                    this.props.type === "online"
                      ? intl.formatMessage({
                          id: "app.offers.extern.variants.platform",
                        })
                      : intl.formatMessage({
                          id: "app.offers.extern.variants.allPlaces",
                        }),
                  ].map(
                    (sortBy) =>
                      `${intl.formatMessage({
                        id: "app.offers.extern.variants.sortBy",
                      })}: ${sortBy}`
                  )}
                  onChange={this.sortState.bind(
                    this,
                    this.props.type === onlineStr ? onlineStr : locationStr
                  )}
                  alignRight
                />
              )}
            </div>
            {this.state.variants.map((event) => (
              <ExternalOfferVariant
                clickHandler={this.handleBooking.bind(this)}
                fields={event}
                key={event.id}
                country={this.props.country}
              />
            ))}
          </section>
        )}
      </div>
    );
  }
}

ExternalOfferVariants.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.shape(variantShape)).isRequired,
  type: PropTypes.string.isRequired,
  showOfferExtern: PropTypes.func,
  country: PropTypes.string.isRequired,
  intl: PropTypes.shape(Intl.shape),
};

export default injectIntl(ExternalOfferVariants);
