import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import base64 from "base-64";
import { Col, Row, FormLabel, FormGroup, Form } from "react-bootstrap";
import styled from "styled-components";
import { differenceInMilliseconds, isSameDay } from "date-fns";
import Button from "../CustomBootstrap/Button";
import { DateFormatter } from "../../globalComponents/DateFormatter";
import {
  Content,
  ImgWrapper,
  Title,
  TitleOffer,
  Select,
  ButtonContent,
} from "./AppointmentBookingForm";
import { PaymentSelect } from "./PaymentSelect";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import Conf from "../../Conf";
import { isBookable } from "./BookingHandler";
import { isTimeSlotDisabled } from "./TimeSlotHandler";
import { BOOK_COURSE_MUTATION } from "./CourseBookingForm.graphql";
import { CALENDAR_COMPONENT_QUERY } from "../Calendar/CalendarComponent.graphql";
import budgetLeftError from "../../utils/budgetLeftError";

const CustomTextArea = styled("textarea")`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

class CourseBookingComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // book date/course vaiables
      therapistName: null,
      therapistPhoto: null,
      eventRoomName: null,
      eventOfferPrice: null,
      eventOfferSubvention: null,
      paymentMethod: "SUBVENTION",
      textAreaValue: "",
      bookSuccess: null,
      showLoader: false,
      // book course variables only
      course: null,
      courseList: null,
      courseEventList: null,
    };

    this.parseError = parseError.bind(this);
    this.isBookable = isBookable.bind(this);
    this.isTimeSlotDisabled = isTimeSlotDisabled.bind(this);
  }

  updatePaymentMethodBy(sPaymentMethod) {
    this.setState({ paymentMethod: sPaymentMethod });
  }

  updateTextAreaValue(event) {
    this.setState({ textAreaValue: event.target.value });
  }

  /**
   * updates therapist name and photo
   */
  updateTherapistBy(event) {
    const { therapist } = event;

    if (therapist === null) {
      this.setState({
        therapistName: null,
        therapistPhoto: null,
      });
    } else {
      this.setState({
        therapistName: therapist.displayName,
        therapistPhoto: therapist.photo,
      });
    }
  }

  submitForm(event) {
    event.preventDefault();

    if (this.isNotAvailable()) {
      return false;
    }

    if (this.state.courseEventList[0].guestState === "BOOKED") {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: "app.offers.course.booked" })
      );
      return;
    }

    if (!this.isEmployeeInBudget()) {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: "app.error.lowbudget" })
      );
      return;
    }

    if (
      !this.isBookable(
        this.props.courseCancelableUntil,
        this.state.course.dateEnd,
        this.state.course.timeBegin
      )
    ) {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({
          id: "app.offers.book.error.bookLimitExceeded",
        })
      );
      return;
    }

    this.setState({ showLoader: true });

    this.props
      .mutate({
        variables: {
          roomEventId: this.state.course.id,
          paymentType: this.state.paymentMethod,
          info: this.state.textAreaValue,
        },
      })
      .then(() => {
        if (this.props.week) this.props.data.refetch();
        this.setState({
          bookSuccess: true,
          showLoader: false,
        });
      })
      .catch((error) => {
        const errorTextTranslationKey = this.parseError(
          error,
          Conf.errorContext.offerBookingError
        );

        this.setState({
          bookSuccess: false,
          showLoader: false,
        });

        this._alertModal.open(
          this.props.intl.formatMessage({ id: "app.global.error" }),
          this.props.intl.formatMessage({ id: errorTextTranslationKey })
        );
      });
  }

  isEmployeeInBudget() {
    return (
      this.props.employeeBudgetLeft >=
      this.state.eventOfferSubvention * this.state.course.events.length
    );
  }

  isEveryCourseDisabled() {
    const aCourseList = this.state.courseList;
    let bIsEveryCourseDisabled = true;

    aCourseList.forEach((oCourse) => {
      const oTimeSlot = {
        guestState: oCourse.events[0].guestState,
        timeStart: oCourse.timeEnd,
      };

      if (!this.isTimeSlotDisabled(oCourse.dateEnd, oTimeSlot)) {
        bIsEveryCourseDisabled = false;
      }
    });

    return bIsEveryCourseDisabled;
  }

  isAtLeastOneCourseBooked() {
    const aCourseList = this.state.courseList;
    let bIsAtLeastOneCourseBooked = false;

    aCourseList.forEach((oCourse) => {
      if (oCourse.events[0].guestState === "BOOKED") {
        bIsAtLeastOneCourseBooked = true;
      }
    });

    return bIsAtLeastOneCourseBooked;
  }

  static isCourseLowAvailable(iFreeSlots, iTotalSlots) {
    return iFreeSlots / iTotalSlots <= 0.3;
  }

  static getWeekDayTranslationKeyByConstant(sWEEKDAY) {
    let weekdayTranslationKey = "";

    switch (sWEEKDAY) {
      case "MONTAG":
        weekdayTranslationKey = "app.appointments.monday";
        break;
      case "DIENSTAG":
        weekdayTranslationKey = "app.appointments.tuesday";
        break;
      case "MITTWOCH":
        weekdayTranslationKey = "app.appointments.wednesday";
        break;
      case "DONNERSTAG":
        weekdayTranslationKey = "app.appointments.thursday";
        break;
      case "FREITAG":
        weekdayTranslationKey = "app.appointments.friday";
        break;
      case "SAMSTAG":
        weekdayTranslationKey = "app.appointments.saturday";
        break;
      case "SONNTAG":
        weekdayTranslationKey = "app.appointments.sunday";
        break;
      default:
        weekdayTranslationKey = "";
        break;
    }

    return weekdayTranslationKey;
  }

  getDropdownWithCourseTimes() {
    const children = [];
    const { courseList } = this.state;
    const iPreSelectedCourse = this.state.course.id;

    courseList.forEach((course, index) => {
      const oTimeSlot = {
        guestState: course.events[0].guestState,
        timeStart: course.timeBegin,
      };

      const iTotalSlots = course.events[0].offers[0].totalSlots;
      let iFreeSlots = iTotalSlots;
      course.events.forEach((event) => {
        if (differenceInMilliseconds(new Date(), new Date(event.date)) < 0) {
          iFreeSlots = Math.min(iFreeSlots, event.offers[0].freeSlots);
        }
      });

      children.push(
        <FormattedMessage
          key={index}
          id={CourseBookingComponent.getWeekDayTranslationKeyByConstant(
            course.day
          )}
        >
          {(message) => (
            <option value={course.id}>
              {message}, <DateFormatter time={course.timeBegin} />
              {" - "}
              <DateFormatter time={course.timeEnd} />
              {" | "}
              <FormattedMessage
                id={`app.offers.date.time.${oTimeSlot.guestState.toLowerCase()}`}
              />
            </option>
          )}
        </FormattedMessage>
      );
    });

    return (
      <Select
        value={iPreSelectedCourse}
        className="form-control"
        onChange={(index) => this.updateCourseByCourseId(index.target.value)}
      >
        {children}
      </Select>
    );
  }

  isNotAvailable() {
    return this.state.course.events[0].guestState === "NOT_AVAILABLE";
  }

  isBooked() {
    return this.state.course.events[0].guestState === "BOOKED";
  }

  static getFirstAvailableCourse(courseList) {
    let course = null;

    courseList.forEach((oCourse) => {
      if (course === null && oCourse.events[0].guestState === "AVAILABLE") {
        course = oCourse;
      }
    });

    return course;
  }

  getDropdownWithCourseDates() {
    const children = [];
    const { courseList } = this.state;
    const iPreSelectedCourse = this.state.course.id;

    courseList.forEach((course, index) => {
      const { guestState } = course.events[0];
      const dateBegin = new Date(course.dateBegin);
      const dateEnd = new Date(course.dateEnd);

      children.push(
        <option key={index} value={course.id} className={guestState}>
          {isSameDay(dateBegin, dateEnd) ? (
            <DateFormatter date={dateBegin} type="DATE" />
          ) : (
            <>
              <DateFormatter date={dateBegin} type="DATE" />
              {" - "}
              <DateFormatter date={dateEnd} type="DATE" />
            </>
          )}
        </option>
      );
    });

    return (
      <Select
        value={iPreSelectedCourse}
        className="form-control"
        onChange={(index) => this.updateCourseByCourseId(index.target.value)}
      >
        {children}
      </Select>
    );
  }

  updateCourseByCourseId(iCourseId) {
    const { courseList } = this.state;
    let course = null;

    courseList.forEach((c) => {
      if (c.id === iCourseId) {
        course = c;
      }
    });

    this.setState({
      course,
      courseEventList: course.events,
      eventOfferPrice: course.therapyOffer.price.total,
      eventOfferSubvention: course.therapyOffer.price.companySubventionAmount,
    });

    this.updateTherapistBy(course.events[0]);
  }

  componentDidMount() {
    if (this.props.courses.length > 0) {
      const courseList =
        this.props.courses.length > 1
          ? this.props.courses.filter((course) => course.events.length > 1)
          : this.props.courses;
      let course = CourseBookingComponent.getFirstAvailableCourse(courseList);
      // TODO: use nullish coalescing operator
      if (course === null) course = courseList[0];

      const courseEventList = course.events;
      const { therapist } = course.events[0];
      const roomName = course.room.name;
      const price = course.therapyOffer.price.total;
      const subvention = course.therapyOffer.price.companySubventionAmount;

      this.setState({
        courseList,
        course,
        courseEventList,
        therapistName: therapist.displayName,
        therapistPhoto: therapist.photo,
        eventRoomName: roomName,
        eventOfferPrice: price,
        eventOfferSubvention: subvention,
      });
    }
  }

  componentDidUpdate() {
    budgetLeftError({
      paymentMethod: this.state.paymentMethod,
      employeeBudgetLeft: this.props.employeeBudgetLeft,
      eventOfferPrice: this.state.eventOfferPrice,
      alertModal: this._alertModal,
      intl: this.props.intl,
      closeModal: this.props.closeModal,
      history: this.props.history,
      offerId: this.props.offerId,
    });
  }

  render() {
    if (this.state.course === null) {
      return null;
    }

    if (this.state.bookSuccess) {
      return (
        <Content>
          <Row>
            <Col xs={12}>
              <VerticallyAlignedMiddle height={window.innerHeight / 2}>
                <strong>
                  <span className="large">
                    <FormattedMessage id="app.offers.bookSuccess" />
                  </span>
                </strong>
              </VerticallyAlignedMiddle>
            </Col>
          </Row>
        </Content>
      );
    }

    return (
      <Content>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        {this.props.title && (
          <Row>
            <Col xs={12}>
              <TitleOffer>{this.props.title}</TitleOffer>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={12}>
            <ImgWrapper>
              {this.state.therapistPhoto && (
                <img
                  src={JSON.parse(this.state.therapistPhoto)}
                  alt={this.state.therapistName}
                />
              )}
            </ImgWrapper>
            <Title>
              <FormattedMessage id="app.appointments.therapist" />
            </Title>
            <span className="text-muted">
              {this.state.therapistName ? this.state.therapistName : <br />}
            </span>
            <Title>
              <FormattedMessage id="app.appointments.roomInfo" />
            </Title>
            <span className="text-muted">
              {this.state.eventRoomName ? this.state.eventRoomName : <br />}
            </span>
          </Col>
        </Row>
        <br />
        <Form onSubmit={(event) => this.submitForm(event)}>
          <Row>
            <Col xs={12} sm={6}>
              <FormGroup>
                <FormLabel>
                  <Title>
                    <FormattedMessage id="app.offers.course.date" />:
                  </Title>
                </FormLabel>
                {this.getDropdownWithCourseDates()}
                {this.isNotAvailable() ? (
                  <p className="form-text">
                    <FormattedMessage id="app.offers.course.notAvailable" />
                  </p>
                ) : this.isBooked() ? (
                  <p className="form-text">
                    <FormattedMessage id="app.offers.course.booked" />
                  </p>
                ) : null}
              </FormGroup>
            </Col>
            <Col xs={12} sm={6}>
              <FormGroup>
                <FormLabel>
                  <Title>
                    <FormattedMessage id="app.offers.times" />:
                  </Title>
                </FormLabel>
                <p>{this.getDropdownWithCourseTimes()}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.state.eventOfferPrice > 0 && (
                <FormGroup>
                  <FormLabel>
                    <Title>
                      <FormattedMessage id="app.appointments.paymentMethod" />:
                    </Title>
                  </FormLabel>
                  <PaymentSelect
                    onChange={(event) =>
                      this.updatePaymentMethodBy(event.target.value)
                    }
                    eventOffer={this.state.course.therapyOffer}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
          {this.isEveryCourseDisabled() ? (
            this.isAtLeastOneCourseBooked() ? (
              <FormLabel>
                <Title>
                  <FormattedMessage id="app.appointments.course.bookedByYou" />
                </Title>
              </FormLabel>
            ) : (
              <FormLabel>
                <Title>
                  <FormattedMessage id="app.appointments.course.bookedUp" />
                </Title>
              </FormLabel>
            )
          ) : (
            <div>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <FormLabel>
                      <Title>
                        <FormattedMessage id="app.offers.info" />:
                      </Title>
                    </FormLabel>
                    <CustomTextArea
                      className="form-control"
                      value={this.state.textAreaValue}
                      onChange={(event) => this.updateTextAreaValue(event)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <ButtonContent>
                <div className="d-grid">
                  <Button
                    processing={this.state.showLoader}
                    type="submit"
                    variant="primary"
                    size="lg"
                    className={this.isNotAvailable() ? "disabled" : ""}
                  >
                    <FormattedMessage id="app.offers.date.book" />
                  </Button>
                </div>
              </ButtonContent>
            </div>
          )}
        </Form>
      </Content>
    );
  }
}

export const CourseBookingForm = compose(
  graphql(BOOK_COURSE_MUTATION),
  graphql(CALENDAR_COMPONENT_QUERY, {
    options: (ownProps) => ({
      variables: { week: base64.encode((ownProps.week - 1).toString()) },
    }),
    skip: (props) => !props.week,
  })
)(injectIntl(CourseBookingComponent));
