import { gql } from "@apollo/client";

export const GUEST_CARDS_QUERY = gql`
  query {
    guest {
      id
      availableCredit {
        therapyOffer {
          id
          title
          duration
        }
        value
      }
    }
  }
`;
