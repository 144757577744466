import React, { Component } from "react";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import Button from "../CustomBootstrap/Button";

const Container = styled("div")`
  min-height: calc(100% - 201px);
  padding-bottom: 52px;
  text-align: left;

  @media screen and (min-width: 768px) {
    min-height: calc(100% - 52px);
  }
`;

class ExpireWarning extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Container className="expireWarning container">
        <Modal show>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage id="app.password.renew.expire.title" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="app.password.renew.expire.text" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button href="/password/renew">
              <FormattedMessage id="app.password.renew.expire.changePassword" />
            </Button>
            <Button href="/">
              <FormattedMessage id="app.password.renew.expire.ignore" />
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default injectIntl(ExpireWarning);
