/**
 *  show mockup components?
 */
export function showMockup(v) {
  const isMockup = window.location.search.includes("mockup");
  if (isMockup && v !== undefined && v !== null) {
    return window.location.search.includes(v);
  }
  return isMockup;
}
