const Conf = {
  api: {
    url: "https://staging.bgm.software",
    clientId: "customer-web-central",
    clientPsw: "ycMHAuR6GkU4mKLdx8AE",
  },
  general: { itemsPerPage: 25 },
  theme: {
    logo: { src: "/static/frontend/employee/images/logo.svg" },
    logoNav: { src: "/static/frontend/employee/images/logo-nav.svg" },
    color: {
      links: "#ff6200",
      buttonBackground: "#ff6200",
      buttonText: "#ffffff",
      /* Calendar */
      calendar: {
        eventBackground: "#ffffff",
        addIconColor: "#3BA45B",
        yellowAddIconColor: "#FEE35F",
        redAddIconColor: "#d70000",
        greenAddIconColor: "#349651",
        clockIconColor: "#bc0000",
        editIconColor: "#417DC8",
        redEditIconColor: "#B7454D",
        rightArrowIconColor: "#ffffff",
        leftArrowIconColor: "#ffffff",
      },
      privacyPolicy: {
        titleColor: "#000",
        textColor: "#6d6d6d",
      },
      events: {
        available: {
          background: "#3BA45B",
          text: "#000000",
        },
        low_available: {
          background: "#FEE35F",
          text: "#000000",
        },
        not_available: {
          background: "#BC0000",
          text: "#000000",
        },
        booked: {
          background: "#417DC8",
          text: "#000000",
        },
        reserved: {
          background: "#ffa500",
          text: "#000000",
        },
      },
    },
    font: { family: "BGMS" },
  },
  navigation: {
    items: [
      {
        id: "home",
        module: "HOME",
        key: 1,
        name: "app.navigation.home.title",
        src: "/static/frontend/employee/images/home.svg",
        route: "/",
      },
      {
        id: "offers",
        module: "OFFERS",
        key: 2,
        name: "app.navigation.offers.title",
        src: "/static/frontend/employee/images/offer.svg",
        route: "/offers/",
      },
      {
        id: "calendar",
        module: "CALENDAR",
        key: 3,
        name: "app.navigation.calendar.title",
        src: "/static/frontend/employee/images/calendar.svg",
        route: "/calendar",
      },
      {
        id: "appointments",
        module: "DATES",
        key: 4,
        name: "app.navigation.appointments.title",
        src: "/static/frontend/employee/images/dates.svg",
        route: "/appointments",
      },
      {
        id: "budget",
        module: "SUBVENTIONS",
        key: 5,
        name: "app.navigation.subventions.title",
        src: "/static/frontend/employee/images/budget.svg",
        route: "/budget",
      },
      {
        id: "bonus",
        module: "BONUS",
        key: 6,
        name: "app.navigation.bonus.title",
        src: "/static/frontend/employee/images/bonus.svg",
        route: "/bonus",
      },
      {
        id: "guestcards",
        module: "GUESTCARDS",
        key: 9,
        name: "app.navigation.guestcards.title",
        src: "/static/frontend/employee/images/guestcards.svg",
        route: "/cards",
      },
      {
        id: "news",
        module: "NEWS",
        key: 7,
        name: "app.navigation.news.title",
        src: "/static/frontend/employee/images/news.svg",
        route: "/news",
      },
      {
        id: "account",
        module: "ACCOUNT",
        key: 8,
        name: "app.navigation.account.title",
        src: "/static/frontend/employee/images/account.svg",
        route: "/account",
      },
    ],
    more: {
      name: "app.navigation.more.title",
      src: "/static/frontend/employee/images/more.svg",
    },
    language: {
      src: "/static/frontend/employee/images/language.svg",
    },
    check: {
      src: "/static/frontend/employee/images/check.svg",
    },
  },
  basicLinks: [
    {
      key: 3,
      name: "app.navigation.faq.title",
      route: "/faq",
    },
    {
      key: 4,
      name: "app.navigation.contact.title",
      route: "/contact",
      publicRoute: "/imprint",
    },
    {
      key: 5,
      name: "app.navigation.about.title",
      route: "/about",
      publicRoute: "/about",
    },
    {
      key: 6,
      name: "app.navigation.pro54.title",
      route: "https://www.pro54.com",
    },
    {
      key: 7,
      name: "app.navigation.privacy.title",
      route: "/privacy-policy",
      publicRoute: "/public-privacy-policy",
    },
    {
      key: 8,
      name: "app.navigation.imprint.title",
      route: "/imprint",
    },
  ],
  offerDetailIcons: {
    desc: "/static/frontend/employee/images/description.svg",
    downloads: "/static/frontend/employee/images/downloads.svg",
    images: "/static/frontend/employee/images/images.svg",
    price: "/static/frontend/employee/images/prices.svg",
    videos: "/static/frontend/employee/images/videos.svg",
    videoPlayButton: "/static/frontend/employee/images/video-play.svg",
    filter: "/static/frontend/employee/images/filter.svg",
    externVariantOnline: "/static/frontend/employee/images/variants-online.svg",
    externVariantLocation:
      "/static/frontend/employee/images/variants-location.svg",
    externVariantCustom: "/static/frontend/employee/images/variants-custom.svg",
  },
  globalIcons: {
    search: "/static/frontend/employee/images/search.svg",
    category: "/static/frontend/employee/images/category.svg",
    empty: "/static/img/empty.svg",
  },
  errorContext: {
    offerGlobalError: "app.offers.global.error",
    offerBookingError: "app.offers.booking.error",
    offerUploadError: "app.offers.upload.error",
    offerExternError: "app.offers.extern.error",
    appointmentCancelDate: "app.appointments.cancelAppointment.error",
    appointmentUpdateDate: "app.appointments.update.error",
    appointmentCancelCourse: "app.appointments.cancelCourse.error",
    accountSaveBasicData: "app.account.basicData.error",
    accountSaveUsername: "app.account.accessData.username.error",
    accountSavePassword: "app.account.accessData.password.error",
    accountSaveEmailSettings: "app.account.emailSettings.error",
    newsRead: "app.news.error",
    privacyPolicy: "app.privacyPolicy.error",
    login: "app.login.loginError",
    passwordReset: "app.password.reset.error",
    passwordSet: "app.password.set.error",
    noInternet: "app.global.noInternet",
    prescriptionUpload: "app.prescriptions.upload.error",
    invokeCoupon: "app.coupon.invoke.error",
    joinMeetingError: "app.onlineMeeting.error",
  },
};

export default Conf;
