import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import styled from "styled-components";
import { Header, Title } from "../../globalComponents/List";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../CustomBootstrap/Button";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import { withLanguage } from "../../context/LanguageContext/withLanguage";
import { formatDate } from "../../utils/dateHelper";

import { SUBVENTION_QUERY } from "./Collections.graphql";

const CustomHeader = styled(Header)`
  padding-left: 15px;
  border-bottom: 1px solid #d4d4d4;
  cursor: default;
`;

class Collections extends Component {
  constructor(props) {
    super(props);

    this.fetchMore = this.fetchMore.bind(this);
  }

  canGetMore() {
    return (
      this.props.data.guest.bonusCollectionsConnection.edges.length !==
      this.props.data.guest.bonusCollectionsConnection.totalCount
    );
  }

  fetchMore() {
    return this.props.data.fetchMore();
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    return (
      <div>
        {this.props.data.guest.bonusCollectionsConnection.edges.length === 0 ? (
          <VerticallyAlignedMiddle height={window.innerHeight / 2}>
            <span className="large">
              <FormattedMessage id="app.bonus.noCollections" />
            </span>
          </VerticallyAlignedMiddle>
        ) : (
          this.props.data.guest.bonusCollectionsConnection.edges.map((edge) => (
            <div key={edge.cursor}>
              {edge.node.available === true ? (
                <div>
                  <CustomHeader>
                    <Title>
                      {edge.node.bonusCollectionType.title}
                      <br />
                      <small className="text-muted">
                        {edge.node.points}&nbsp;
                        <FormattedMessage id="app.bonus.points" />{" "}
                        {edge.node.issueDate !== null ? (
                          <span>
                            <FormattedMessage id="app.global.at" />{" "}
                            {formatDate(
                              edge.node.issueDate,
                              this.props.languageContext.language
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </small>
                    </Title>
                  </CustomHeader>
                </div>
              ) : null}
            </div>
          ))
        )}
        {!this.props.data.loading && this.canGetMore() ? (
          <div>
            <br />
            <div className="d-grid">
              <Button onClick={this.fetchMore} variant="primary" size="lg">
                <FormattedMessage id="app.global.loadMore" />
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const ITEMS_PER_PAGE = 15;
const withData = graphql(SUBVENTION_QUERY, {
  options: () => ({
    variables: {
      cursor: null,
      limit: ITEMS_PER_PAGE,
    },
    fetchPolicy: "cache-and-network",
  }),
  props: ({ data: { loading, guest, config, fetchMore } }) => ({
    data: {
      loading,
      guest,
      config,
      fetchMore: () =>
        fetchMore({
          variables: {
            cursor:
              guest.bonusCollectionsConnection.edges[
                guest.bonusCollectionsConnection.edges.length - 1
              ].cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            return {
              ...prev,
              guest: {
                bonusCollectionsConnection: {
                  edges: [
                    ...prev.guest.bonusCollectionsConnection.edges,
                    ...fetchMoreResult.guest.bonusCollectionsConnection.edges,
                  ],
                  totalCount:
                    fetchMoreResult.guest.bonusCollectionsConnection.totalCount,
                  __typename:
                    fetchMoreResult.guest.bonusCollectionsConnection.__typename,
                },
                __typename: fetchMoreResult.guest.__typename,
              },
            };
          },
        }),
    },
  }),
});

export default withLanguage(withData(injectIntl(Collections)));
