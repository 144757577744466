import React, { PureComponent } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import isExternal from "is-url-external";

export const FooterMenu = styled("ul")`
  align-items: center;
  background-color: #ffffff !important;
  border-top: 1px solid #d4d4d4;
  bottom: 0;
  display: none;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: fixed !important;
  width: 100%;
  z-index: 89;

  @media screen and (min-width: 992px) and (min-height: 768px) {
    display: flex;
  }

  @media screen and (max-width: 768px) {
    padding: 10px 0px;
  }
`;

export const SelectLi = styled("li")`
  padding: 0px 15px;

  @media screen and (max-width: 768px) {
    padding: 3px 15px;
  }
`;

/**
 * FooterItem Component
 * builds list-element, a-element, styles it
 */
export class FooterItem extends PureComponent {
  render() {
    const Li = styled("li")`
      padding: 10px;
      display: block;
      @media screen and (min-width: 768px) {
        padding: 15px;
      }
    `;

    const LinkE = styled("a")`
      color: #8a8a8a;
      display: block;

      &:hover {
        color: ${(props) => props.theme.link};
        cursor: pointer;
        text-decoration: none;
      }
    `;

    const LinkA = styled(Link)`
      color: #8a8a8a;
      display: block;

      &:hover {
        color: ${(props) => props.theme.link};
        cursor: pointer;
        text-decoration: none;
      }
    `;

    return (
      <Li>
        {isExternal(this.props.url) ? (
          <LinkE href={this.props.url} target="_blank">
            {this.props.children}
          </LinkE>
        ) : (
          <LinkA to={this.props.url}>{this.props.children}</LinkA>
        )}
      </Li>
    );
  }
}
