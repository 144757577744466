import React from "react";
import Helmet from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import { getISOWeek } from "date-fns";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../components/Layout";
import CalendarComponent from "../../components/Calendar/CalendarComponent";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";

const Wrapper = styled("div")``;

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.moveNext = this.moveNext.bind(this);
    this.movePrev = this.movePrev.bind(this);

    const initWeek = this.props.location.hash
      ? parseInt(this.props.location.hash.substr(7), 10)
      : getISOWeek(new Date());

    this.state = { week: initWeek };
  }

  changeWeek(week) {
    this.setState({ week });

    if (window.history.pushState) {
      window.history.pushState(null, null, `/calendar/#/week/${week}`);
    } else {
      window.location.hash = `/calendar/#/week/${week}`;
    }
  }

  moveNext() {
    if (this.state.week === getISOWeek(new Date()) + 6) {
      return false;
    }
    this.changeWeek(this.state.week + 1);
  }

  movePrev() {
    if (this.state.week === getISOWeek(new Date())) {
      return false;
    }
    this.changeWeek(this.state.week - 1);
  }

  render() {
    if (
      this.state.week > getISOWeek(new Date()) + 6 ||
      this.state.week < getISOWeek(new Date())
    ) {
      return <Redirect to={{ pathname: "/calendar" }} />;
    }

    return (
      <Layout
        {...this.props}
        title={`KW${this.state.week}`}
        tooltip={this.props.intl.formatMessage({ id: "app.calendar.help" })}
      >
        <Wrapper>
          <PaddingLessContainer className="calendar">
            <FormattedMessage id="app.calendar.title">
              {(messages) =>
                messages.map((message, i) => (
                  <Helmet key={`${message}-${i}`} title={message} />
                ))
              }
            </FormattedMessage>
            <CalendarComponent
              week={this.state.week}
              movePrev={this.movePrev}
              moveNext={this.moveNext}
              history={this.props.history}
              modules={this.props.modules}
            />
          </PaddingLessContainer>
        </Wrapper>
      </Layout>
    );
  }
}

export default injectIntl(Calendar);
