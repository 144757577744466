import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import {
  Alert,
  Col,
  FormLabel,
  FormGroup,
  FormText,
  Row,
  ListGroup,
} from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import FormControl from "../../components/CustomBootstrap/FormControl";
import { Wrapper } from "../../globalComponents/Panel";
import { DateFormatter } from "../../globalComponents/DateFormatter";

import SaveUsername from "./SaveUsername";
import SavePassword from "./SavePassword";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { ACCESS_DATA_QUERY } from "./AccessData.graphql";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 15px;
  width: calc(100% + 15px);

  // fixes iphone scroll problem
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

const CustomInput = styled(FormControl)`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

const Title = styled("div")`
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
`;

class AccessData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFilled: false,
      username: "",
      password: "",
      passwordRepeat: "",
      usernameValidationState: null,
      passwordValidationState: null,
      passwordRepeatValidationState: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateUsername = this.validateUsername.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidUpdate() {
    if (!this.props.data.loading && !this.state.dataFilled) {
      this.setState({
        dataFilled: true,
        username: this.props.data.acl.whoami.username,
      });
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const { value } = target;

    this.setState({ [name]: value });
  }

  validateUsername() {
    let error = false;

    if (!/[a-zA-Z0-9\-_.]{6,}/.test(this.state.username)) {
      this.setState({ usernameValidationState: "error" });
      error = true;
    } else {
      this.setState({ usernameValidationState: "success" });
    }

    return !error;
  }

  validatePassword() {
    let error = false;

    /**
     * password must contain at least
     * - min. 10 characters
     * - one capital letter
     * - one lowercase letter
     * - one number (digit)
     * - one special char of these @$!%*#?&_.-
     *
     * password is only allowed to contain letter/chars/digits
     * that are within the above listed restrictions
     */
    if (
      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{10,}$/.test(
        this.state.password
      )
    ) {
      this.setState({ passwordValidationState: "error" });
      error = true;
    } else {
      this.setState({ passwordValidationState: "success" });
    }

    if (this.state.password !== this.state.passwordRepeat) {
      this.setState({ passwordRepeatValidationState: "error" });
      error = true;
    } else {
      this.setState({ passwordRepeatValidationState: "success" });
    }

    return !error;
  }

  /**
   * opens modal box with error message
   *
   * @param error: returned from api
   * @param errorTextTranslationKey: returned form parseError
   */
  onError(error, errorTextTranslationKey) {
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    return (
      <ScrollableDiv>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <Wrapper>
          <Content>
            <Row>
              <Col xs={12}>
                <Title>
                  <FormattedMessage id="app.account.accessData.headline" />
                </Title>
              </Col>
            </Row>
            {this.state.usernameChanged ? (
              <Alert variant="success">
                <p>
                  <FormattedMessage id="app.account.usernameChanged" />
                </p>
              </Alert>
            ) : (
              ""
            )}
            <Row>
              <Col xs={12} sm={12}>
                <FormGroup
                  validationState={this.state.usernameValidationState}
                  className="mb-3"
                >
                  <FormLabel>
                    <FormattedMessage id="app.account.username" />
                  </FormLabel>
                  <CustomInput
                    name="username"
                    type="text"
                    onChange={this.handleInputChange}
                    value={this.state.username}
                  />
                  <FormText>
                    <FormattedMessage id="app.account.usernameHint" />
                  </FormText>
                  {this.state.usernameValidationState === "error" ? (
                    <FormText>
                      <FormattedMessage id="app.account.invalidUsername" />
                    </FormText>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <SaveUsername
                  data={this.state}
                  onFinish={() => {
                    this.setState({ usernameChanged: true });
                  }}
                  onValidate={this.validateUsername}
                  onError={this.onError}
                />
              </Col>
            </Row>
            <hr />{" "}
            {this.state.passwordChanged ? (
              <Alert variant="success">
                <p>
                  <FormattedMessage id="app.account.passwordChanged" />
                </p>
              </Alert>
            ) : (
              ""
            )}
            <Row>
              <Col xs={12} sm={12}>
                <FormGroup
                  validationState={this.state.passwordValidationState}
                  className="mb-3"
                >
                  <FormLabel>
                    <FormattedMessage id="app.account.password" />
                  </FormLabel>
                  <CustomInput
                    name="password"
                    onChange={this.handleInputChange}
                    type="password"
                  />{" "}
                  {this.state.passwordValidationState === "error" ? (
                    <FormText>
                      <FormattedMessage id="app.account.invalidPassword" />
                    </FormText>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <FormGroup
                  validationState={this.state.passwordRepeatValidationState}
                  className="mb-3"
                >
                  <FormLabel>
                    <FormattedMessage id="app.account.passwordRepeat" />
                  </FormLabel>
                  <CustomInput
                    name="passwordRepeat"
                    onChange={this.handleInputChange}
                    type="password"
                  />
                  <FormText>
                    <FormattedMessage id="app.account.passwordHint" />
                  </FormText>
                  {this.state.passwordRepeatValidationState === "error" ? (
                    <FormText>
                      <FormattedMessage id="app.account.invalidPasswordRepeat" />
                    </FormText>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <SavePassword
                  data={this.state}
                  onFinish={() => {
                    this.setState({ passwordChanged: true });
                  }}
                  onValidate={this.validatePassword}
                  onError={this.onError}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12} sm={12}>
                <FormLabel>
                  <FormattedMessage id="app.account.lastLogins" />
                </FormLabel>
                <ListGroup variant="flush">
                  {this.props.data.acl.whoami.loginsConnection.edges.map(
                    (edge) => (
                      <ListGroup.Item>
                        <div key={edge.node.loginDate}>
                          <FormattedMessage
                            id="app.account.accessMessage"
                            values={{
                              datetime: (
                                <DateFormatter
                                  date={new Date(edge.node.loginDate)}
                                  type="DAY_WITH_DATE_AND_TIME"
                                />
                              ),
                              ip: edge.node.remoteAddress,
                            }}
                          />
                        </div>
                      </ListGroup.Item>
                    )
                  )}
                </ListGroup>
              </Col>
            </Row>
          </Content>
        </Wrapper>
      </ScrollableDiv>
    );
  }
}

export default graphql(ACCESS_DATA_QUERY, {
  options: { fetchPolicy: "network-only" },
})(injectIntl(AccessData));
