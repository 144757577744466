import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import {
  Alert,
  FormCheck,
  Col,
  FormLabel,
  FormGroup,
  Row,
} from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import * as IBAN from "iban";
import * as BIC from "bic";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import FormControl from "../../components/CustomBootstrap/FormControl";
import { Wrapper } from "../../globalComponents/Panel";
import SaveBasicData from "./SaveBasicData";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { BASIC_DATA_QUERY } from "./BasicData.graphql";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 15px;
  width: calc(100% + 15px);

  // fixes iphone scroll problem
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

const Title = styled("div")`
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
`;

const CustomFormControl = styled(FormControl)`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

class BasicData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFilled: false,
      dataSaved: false,
      unBlock: null,
      phonePrivate: "",
      enableEmailPrivate: false,
      street: "",
      town: "",
      zip: "",
      emailPrivate: "",
      iban: "",
      bic: "",
      mobileJob: "",
      mobileProviderJob: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.dataSaved = this.dataSaved.bind(this);
    this.validateData = this.validateData.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidUpdate() {
    if (!this.props.data.loading && !this.state.dataFilled) {
      /** input field isn't allowed to be of type 'null' */
      let { emailPrivate } = this.props.data.guest;
      if (emailPrivate === null) {
        emailPrivate = "";
      }

      this.setState({
        dataFilled: true,
        phonePrivate: this.props.data.guest.phonePrivate,
        street: this.props.data.guest.street,
        town: this.props.data.guest.town,
        zip: this.props.data.guest.zip,
        emailPrivate,
        enableEmailPrivate: this.props.data.guest.enableEmailPrivate,
        mobileJob: this.props.data.guest.mobileJob,
        mobileProviderJob: this.props.data.guest.mobileProviderJob,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.unBlock) {
      this.state.unBlock();
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    let value = "";

    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      default:
        value = target.value;
    }

    if (!this.state.unBlock) {
      this.setState({ unBlock: this.props.history.block(this.blockMessage) });
    }

    if (!this.isEmailValid()) {
      this.setState({ enableEmailPrivate: false });
    }

    this.setState({ [name]: value });
  }

  validateData() {
    let error = false;

    if (this.state.iban && !IBAN.isValid(this.state.iban)) {
      error = true;
    }

    if (this.state.bic && !BIC.isValid(this.state.bic)) {
      error = true;
    }

    return !error;
  }

  isEmailValid() {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      this.state.emailPrivate
    );
  }

  dataSaved() {
    if (this.state.unBlock) {
      this.state.unBlock();
    }

    this.setState({
      dataSaved: true,
      unBlock: null,
    });
  }

  /**
   * opens modal box with error message
   *
   * @param error: returned from api
   * @param errorTextTranslationKey: returned form parseError
   */
  onError(error, errorTextTranslationKey) {
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    return (
      <ScrollableDiv>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <Wrapper>
          <Content>
            {this.state.dataSaved ? (
              <Alert variant="success">
                <p>
                  <FormattedMessage id="app.account.saved" />
                </p>
              </Alert>
            ) : (
              ""
            )}
            <Row>
              <Col xs={12}>
                <Title>
                  <FormattedMessage id="app.account.basicData.headline" />
                </Title>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormattedMessage id="app.account.blockMessage">
                  {(message) => {
                    this.blockMessage = message;
                    return null;
                  }}
                </FormattedMessage>

                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.firstName" />
                  </FormLabel>
                  <CustomFormControl
                    type="text"
                    value={this.props.data.guest.firstname}
                    readOnly="readOnly"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.lastName" />
                  </FormLabel>
                  <CustomFormControl
                    type="text"
                    value={this.props.data.guest.lastname}
                    readOnly="readOnly"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.emailJob" />
                  </FormLabel>
                  <CustomFormControl
                    type="text"
                    value={this.props.data.guest.emailJob}
                    readOnly="readOnly"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.phone" />
                  </FormLabel>
                  <CustomFormControl
                    name="phonePrivate"
                    className="form-control"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.phonePrivate}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.street" />
                  </FormLabel>
                  <CustomFormControl
                    name="street"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.street}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.town" />
                  </FormLabel>
                  <CustomFormControl
                    name="town"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.town}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.zip" />
                  </FormLabel>
                  <CustomFormControl
                    name="zip"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.zip}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.privateEmail" />
                  </FormLabel>
                  <CustomFormControl
                    name="emailPrivate"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.emailPrivate}
                  />
                </FormGroup>
              </Col>
            </Row>
            {this.isEmailValid() ? (
              <Row>
                <Col xs={12} sm={12}>
                  <FormGroup className="mb-3">
                    <FormCheck
                      id="checkbox_enableEmailPrivate"
                      name="enableEmailPrivate"
                      onChange={this.handleInputChange}
                      checked={this.state.enableEmailPrivate}
                      label={
                        <FormattedMessage id="app.account.enablePrivateEmail" />
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col xs={12} sm={3}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.mobileProviderJob" />
                  </FormLabel>
                  <CustomFormControl
                    name="mobileProviderJob"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.mobileProviderJob}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={9}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.mobileJob" />
                  </FormLabel>
                  <CustomFormControl
                    name="mobileJob"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.mobileJob}
                  />
                </FormGroup>
              </Col>
            </Row>
            <SaveBasicData
              data={this.state}
              onFinish={this.dataSaved}
              onValidate={this.validateData}
              onError={this.onError}
            />
          </Content>
        </Wrapper>
      </ScrollableDiv>
    );
  }
}

export default graphql(BASIC_DATA_QUERY, {
  options: { fetchPolicy: "network-only" },
})(injectIntl(BasicData));
