import React, { PureComponent } from "react";
import { BeatLoader } from "halogenium";
import Conf from "../Conf";

export class Loading extends PureComponent {
  render() {
    return (
      <BeatLoader
        className="loader"
        color={
          this.props.color
            ? this.props.color
            : Conf.theme.color.buttonBackground
        }
      />
    );
  }
}
