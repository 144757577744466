import React, { Component } from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import InlineSvg from "react-inlinesvg";

// Simple component to extract away the InlineSvg dependency
function SvgContainer(props) {
  return <InlineSvg {...props} src={props.src ?? "/static/img/empty.svg"} />;
}

const SvgWrapper = styled("span")`
  display: block;
  width: 30px;
  height: 30px;
  margin: 0;

  svg {
    width: ${(props) => props.width ?? "30px"};
    height: ${(props) => props.height ?? "30px"};
  }
  &.active svg * {
    fill: ${(props) => props.theme.btnBg} !important;
  }
  &.inactive svg * {
    fill: #8a8a8a !important;
  }
`;

/**
 * Icon Element
 *
 * @param src
 * @param title
 */
class Icon extends Component {
  static extractFileNameFromUrl(url) {
    if (!url) return null;
    const aUrlParts = url.split("/");
    const lastItem = aUrlParts.pop();
    return lastItem.split(".").shift();
  }

  /**
   * return translation if existing and undefined if not
   */
  getTranslation() {
    const id = `app.icon.title.${Icon.extractFileNameFromUrl(this.props.src)}`;
    const stringExists = !!this.props.intl.messages[id];
    return stringExists ? this.props.intl.formatMessage({ id }) : null;
  }

  wrapper({ dangerouslySetInnerHTML }) {
    const translation = this.props.noTitle
      ? null
      : this.props.title
      ? this.props.title
      : this.getTranslation();

    return translation ? (
      <SvgWrapper
        {...this.props}
        title={translation}
        style={{ cursor: "help" }}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      />
    ) : (
      <SvgWrapper
        {...this.props}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      />
    );
  }

  render() {
    return <SvgContainer {...this.props} wrapper={this.wrapper.bind(this)} />;
  }
}

export default injectIntl(Icon);
