import { gql } from "@apollo/client";

export const FAQ_QUERY = gql`
  query {
    frontend {
      employee {
        faqCategories {
          id
          title
          faqs {
            id
            question
            answer
          }
        }
      }
    }
  }
`;
