import { getLanguage, setLanguage } from "./localStorage";

import de from "../locales/de";
import en from "../locales/en";

export const LANGUAGES = [
  { value: "de", caption: "Deutsch" },
  { value: "en", caption: "English" },
];

export const getLanguageFallback = (language) => {
  if (language === "de") return de;
  if (language === "en") return en;
  return de; // fallback to german
};

export const getAvailableLanguages = (availableLanguages) =>
  LANGUAGES.filter((lang) =>
    (availableLanguages ?? ["de"]).includes(lang.value)
  );

export const initLanguage = () => {
  // only set language, if no language in storage
  if (getLanguage()) return;

  const browserLanguage = navigator?.language?.substring(0, 2)?.toLowerCase();

  if (!["de", "en"].includes(browserLanguage)) {
    // fallback to german when unable to detect browser language
    setLanguage("de");
    return;
  }

  setLanguage(browserLanguage);
};

export const getLocaleQueryLang = (language) => {
  if (language === "de") return "DE";
  if (language === "en") return "EN";
  return "DE"; // fallback to german
};
