import { gql } from "@apollo/client";

export const QUESTIONNAIRE_QUERY = gql`
  query QuestionnaireQuery($code: String!) {
    surveys {
      surveyState(code: $code) {
        id
        completed
        progress
        questionnaireGroup {
          id
          title
          text
          questions {
            id
            title
            text
            singleRow
            requiredAnswers
            questionType {
              id
              title
            }
            questionTypeOptions {
              id
              value
              questionnaireQuestionTypeOption {
                id
                param
                title
              }
            }
            answers {
              id
              title
              value
            }
          }
        }
      }
    }
  }
`;

export const ANSWER_QUESTIONNAIRE_GROUP_MUTATION = gql`
  mutation answerQuestionnaireGroup(
    $code: String!
    $answers: SurveyGroupAnswersInput!
  ) {
    answerQuestionnaireGroup(code: $code, groupAnswers: $answers)
  }
`;
