import React, { useState, useRef, useMemo, useEffect } from "react";
import styled from "styled-components";
import { DayPicker } from "react-day-picker";
import de from "date-fns/locale/de";
import enUs from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import "react-day-picker/dist/style.css";
import { format, getYear } from "date-fns";
import { FormControl } from "react-bootstrap";
import { useOutsideClickEventListener } from "../hooks/useOutsideClickEventListener";

const InputStyle = styled(FormControl)`
border: 1px solid #ccc:
border-radius: 4px;
box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
padding: 6px 12px;
transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
width: 100%;
background-color: white;
font-size: 14px;

@media screen and (max-width: 1024px) {
  font-size: 16px;
}
`;

const ModalDiv = styled("div")`
  position: absolute;
  border: 2px solid #ccc;
  z-index: 1000;
  background-color: white;
  min-height: 355px;
`;

const getLocale = (locale) => {
  if (locale === "en") return enUs;
  if (locale === "es") return es;

  // de and fallback
  return de;
};

const getDateFormat = (locale) => {
  if (locale === "en") return "MM/dd/yyyy";
  if (locale === "es") return "dd/MM/yyyy";

  // de and fallback
  return "dd.MM.yyyy";
};

export const DayPickerInput = ({
  onDayChange,
  value,
  modifiers,
  modifiersStyles,
  disabled = false,
  disabledDays,
  openByDefault = false,
  locale = "de",
  dropdownEnabled = false,
  defaultMonth,
  inputStyle = {},
  dropdownYears = {},
  required = false,
  isInvalid = false,
}) => {
  const formatString = useMemo(() => getDateFormat(locale), [locale]);

  const dateLocale = useMemo(() => getLocale(locale), [locale]);
  const dateText = useMemo(
    () => (value ? format(value, formatString) : ""),
    [value, formatString]
  );

  const [showModal, setShowModal] = useState(openByDefault && !disabled);
  const [month, setMonth] = useState(defaultMonth);

  const wrapperRef = useRef(null);
  useOutsideClickEventListener(wrapperRef, () => setShowModal(false));

  useEffect(() => {
    setMonth(defaultMonth);
  }, [defaultMonth]);

  const fromYear = useMemo(() => {
    if (!dropdownEnabled) return undefined;
    if (dropdownYears?.min) return dropdownYears.min;
    return getYear(new Date()) - 100;
  }, [dropdownEnabled]);
  const toYear = useMemo(() => {
    if (!dropdownEnabled) return undefined;
    if (dropdownYears?.max) return dropdownYears.max;
    return getYear(new Date());
  }, [dropdownEnabled]);

  return (
    <div ref={wrapperRef}>
      <InputStyle
        isInvalid={isInvalid}
        className="form-control"
        style={{
          ...{
            color: disabled ? "#ccc" : "#555",
            cursor: disabled ? "not-allowed" : "pointer",
            backgroundColor: "white",
            caretColor: "transparent",
          },
          ...inputStyle,
        }}
        type="text"
        value={dateText}
        required={required}
        onKeyPress={() => false}
        onClick={() => {
          if (!disabled) setShowModal(true);
        }}
        onChange={() => {}}
      />
      {showModal && (
        <ModalDiv>
          <DayPicker
            mode="single"
            selected={value}
            onSelect={(day) => {
              onDayChange(day);
              setShowModal(false);
            }}
            locale={dateLocale}
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            disabled={disabledDays}
            fromYear={fromYear}
            toYear={toYear}
            captionLayout="dropdown"
            month={month ?? new Date()}
            onMonthChange={setMonth}
            styles={{
              button: { margin: "1px" },
            }}
          />
        </ModalDiv>
      )}
    </div>
  );
};
