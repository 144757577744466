import React, { PureComponent } from "react";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import FittedImage from "react-fitted-image";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";
import { Loading } from "../../globalComponents/Loading";

const ImageWrapper = styled("div")`
  text-align: center;
  margin: 0;
  margin-bottom: 5px;
  height: 90px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const EventBox = styled(CustomPanel)`
  width: 100%;
  padding: 0;
  /* border: 1px solid #ccc; */
  box-shadow: 1px 1px 2px #888;
  float: left;
  position: relative;
  .booked {
    border: 1px solid #f00;
  }
  height: 135px;
  :hover {
    border: 1px solid #d4d700;
  }
  cursor: pointer;
  Button {
  }
  H4 {
    display: block;
    font-size: 14px;
    padding: 10px;
    padding-left: 15px;
  }
  position: relative;
  @media only screen and (min-width: 768px) {
    Button {
      position: absolute;
      bottom: 10px;
      width: 90%;
    }
    Description {
      p {
        font-size: 0.9em;
      }
    }
  }
`;

const CustomWrapper = styled(Wrapper)`
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
  > H4 {
    color: #696969;
    font-weight: bold;
    font-size: 16px;
  }
`;

class OfferBannersWidget extends PureComponent {
  render() {
    return (
      <Row className="widget widget-offer-banners">
        {" "}
        {this.props.data.selectedOffers.map((offer) => (
          <Col
            key={offer.id}
            xs={12}
            sm={Math.round(12 / this.props.data.selectedOffers.length)}
          >
            <CustomWrapper>
              <H4
                style={{ fontWeight: "bold" }}
                onClick={() => {
                  this.props.history.push(`/offers/${offer.id}`);
                }}
              >
                <FormattedMessage
                  id="app.home.widget.offerBanners.title"
                  values={{ offer: offer.therapyOffer.title }}
                />
              </H4>
              <EventBox className="event-box ">
                <ImageWrapper
                  onClick={() => {
                    this.props.history.push(`/offers/${offer.id}`);
                  }}
                >
                  {offer.therapyOffer.images.length > 0 ? (
                    <FittedImage
                      style={{
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                      loader={<Loading />}
                      fit="cover"
                      src={JSON.parse(offer.therapyOffer.images[0].url)}
                    />
                  ) : (
                    ""
                  )}
                </ImageWrapper>

                <H4
                  onClick={() => {
                    this.props.history.push(`/offers/${offer.id}`);
                  }}
                >
                  <FormattedMessage
                    id="app.home.widget.offerBanners.text"
                    values={{ offer: offer.therapyOffer.title }}
                  />
                </H4>
              </EventBox>
            </CustomWrapper>
          </Col>
        ))}
      </Row>
    );
  }
}

export default injectIntl(OfferBannersWidget);
