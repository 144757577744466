import React, { PureComponent } from "react";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Conf from "../Conf";

/**
 * Logo Styles
 */
const Img = styled("img")`
  width: auto;
  height: 42px;
  @media screen and (max-width: 320px) {
    max-width: 230px;
  }
`;

class Logo extends PureComponent {
  render() {
    return (
      <Link to="/">
        <Img
          src={Conf.theme.logoNav.src}
          alt={this.props.intl.formatMessage({ id: "app.logo.alt" })}
        />
      </Link>
    );
  }
}

export default injectIntl(Logo);
