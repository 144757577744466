import React, { PureComponent } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import styled from "styled-components";
import Icon from "../../globalComponents/Icon";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";

import { BONUS_WIDGET_QUERY } from "./BonusWidget.graphql";

const LinkA = styled(NavLink)`
  color: #8a8a8a;
  display: block;
  position: relative;
  text-align: center;
  float: left;
  width: 100%;
  margin-bottom: 20px;

  .icon {
    margin: 0;
    margin-right: 10px;
    float: left;
  }

  &:hover,
  &.active {
    color: ${(props) => props.theme.link};
    cursor: pointer;
    text-decoration: none;

    svg * {
      fill: ${(props) => props.theme.link} !important;
    }
  }
`;

const Clear = styled("div")`
  clear: both;
`;

const Label = styled("span")`
  display: block;
  float: left;
  line-height: 30px;
`;

class BonusWidget extends PureComponent {
  render() {
    return (
      <Wrapper className="widget widget-budget">
        <H4>
          <FormattedMessage id="app.home.widget.bonus.title" />
        </H4>
        <CustomPanel>
          <LinkA to="/bonus" exact>
            <Icon
              src="/static/frontend/employee/images/bonus.svg"
              className="icon inactive"
            />
            <Label>
              <FormattedMessage id="app.bonus.widget.label.points" />{" "}
              {this.props.data.loading ? (
                <span>...</span>
              ) : (
                <span>{this.props.data.guest.bonusPoints.available}</span>
              )}
              <FormattedMessage id="app.bonus.points" />
            </Label>
          </LinkA>
          <LinkA to="/bonus/collect" exact>
            <Icon
              src="/static/frontend/employee/images/bonus-collegues.svg"
              className="icon inactive"
            />
            <Label>
              <FormattedMessage id="app.bonus.widget.label.collegues" />
            </Label>
          </LinkA>
          <LinkA to="/bonus/shop" exact style={{ marginBottom: 0 }}>
            <Icon
              src="/static/frontend/employee/images/bonus-shop.svg"
              className="icon inactive"
            />
            <Label>
              <FormattedMessage id="app.bonus.widget.label.shop" />
            </Label>
          </LinkA>
          <Clear />
        </CustomPanel>
      </Wrapper>
    );
  }
}

export default graphql(BONUS_WIDGET_QUERY, {
  options: { fetchPolicy: "network" },
})(injectIntl(BonusWidget));
