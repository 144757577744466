import { gql } from "@apollo/client";

export const OFFERS_QUERY = gql`
  query {
    bgmConcept {
      categories {
        id
        title
        icon
        bgmOffers {
          id
          icon
          bgmConceptTherapyOfferAttribute {
            id
            therapyOfferAttribute {
              id
            }
            therapyOfferAttributeValue {
              id
            }
          }
          therapyOffer {
            id
            title
            desc
            icon
            priceMatrix {
              price {
                net
                total
                tax
              }
            }
          }
          refund {
            id
            description
          }
        }
      }
    }
    guest {
      id
      debitor {
        id
        iban
        bic
      }
      guestSubventionLimit {
        id
        currentValue
        value
        gross
      }
    }
    config {
      modules {
        __typename
        ... on ModuleConfigPayment {
          enabledEventPaymentTypes
        }
        ... on ModuleConfigFrontend {
          employee {
            offerPaging
            showPrices
            showSubventionValue
          }
        }
        ... on ModuleConfigCompany {
          bgmConceptRefundsFieldReceiptTitleEnabled
          bgmConceptRefundsFieldReceiptTitleRequired
          bgmConceptRefundsFieldReceiptNumberEnabled
          bgmConceptRefundsFieldReceiptNumberRequired
          bgmConceptRefundsFieldDurationEnabled
          bgmConceptRefundsFieldDurationRequired
          bgmConceptRefundsFieldServiceProvidersEnabled {
            id
            name
            serviceProviderLocations {
              id
              name
            }
          }
          bgmConceptRefundsFieldServiceProviderRequired
          bgmConceptRefundsFieldServiceProviderLocationEnabled
          bgmConceptRefundsFieldServiceProviderLocationRequired
          bgmConceptRefundsFieldTherapyOfferAttributesEnabled {
            id
            title
            multiple
            therapyOfferAttributeValues {
              id
              title
              icon
            }
          }
          bgmConceptRefundsFieldTherapyOfferAttributesRequired {
            id
          }
          bgmConceptRefundsFieldCommentEnabled
          bgmConceptRefundsFieldCommentRequired
        }
      }
    }
  }
`;
