import React from "react";
import { Redirect, Route } from "react-router-dom";
import { setBearer, getBearer, getIsPasswordExpired } from "./localStorage";

function removeHashFromUrl(hist) {
  const uri = window.location.toString();
  if (uri.indexOf("#") > 0) {
    const cleanUri = uri.substring(0, uri.indexOf("#"));
    hist.push({}, null, cleanUri);
    return true;
  }
}

/**
 * Protected zone to check wither the user is logged in or not and otherwise
 * it will redirect the user to the login page
 */
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        (props.location.hash !== null &&
          props.location.hash.substr(0, 2) === "#B" &&
          /^[a-z0-9]{40}$/.test(props.location.hash.substr(2)) &&
          (setBearer(props.location.hash.substr(2)) || true) &&
          removeHashFromUrl(props.history)) ||
        getBearer() ? (
          getIsPasswordExpired() && props.location.pathname !== "/logout" ? (
            <Redirect
              to={{
                pathname: "/password/renew",
              }}
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
