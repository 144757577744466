import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Alert,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
  Card,
} from "react-bootstrap";
import styled from "styled-components";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../CustomBootstrap/Button";

const IdentifierPanel = styled((props) => <Card {...props} />)`
  border-radius: 8px !important;
  @media only screen and (min-width: 768px) {
    width: 50%;
    margin: auto;
    margin-top: 10%;
  }
`;

export function validateIdentifier(identifier) {
  return /^[a-z0-9.\-_]{3,}@[a-z0-9.-]{3,}\.[a-z]{2,}$/.test(identifier);
}

class RequestIdentifier extends Component {
  constructor(props) {
    super(props);

    let identifier = null;
    let userIdentifier = "";
    let errorIdentifier = null;
    const loading = false;

    if (props.identifier !== null) {
      if (validateIdentifier(props.identifier)) {
        identifier = props.identifier;
        userIdentifier = props.identifier;
      } else {
        errorIdentifier = props.identifier;
        userIdentifier = props.identifier;
      }
    }

    this.setIdentifier = this.setIdentifier.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.parseDomains = this.parseDomains.bind(this);

    this.state = {
      identifier,
      errorIdentifier,
      userIdentifier,
      loading,
      error: null,
      allowedDomains: this.parseDomains(),
    };
  }

  parseDomains() {
    const domains = [];
    this.props.registerKey.company.emailDomains.map((emailDomain) => {
      domains.push(emailDomain.domain.toLowerCase());
      return emailDomain;
    });
    return domains;
  }

  getValidationState() {
    if (this.state.userIdentifier.length === 0) {
      return null;
    }
    if (
      this.state.userIdentifier !== this.state.errorIdentifier &&
      validateIdentifier(this.state.userIdentifier)
    )
      return "success";
    return "error";
  }

  setIdentifier() {
    if (this.getValidationState() === "success") {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const identifier = this.state.userIdentifier.replace("-", "");
      this.setState({
        identifier,
        errorIdentifier: null,
      });
      this.checkIdentifier(identifier);
    }
  }

  checkIdentifier(identifier) {
    this.setState({ loading: true });

    const domain = identifier
      .substring(identifier.lastIndexOf("@") + 1)
      .toLowerCase();

    if (
      this.state.allowedDomains.length === 0 ||
      this.state.allowedDomains.includes(domain)
    ) {
      this.props.onUpdate(identifier);
    } else {
      this.setState({
        loading: false,
        identifier: null,
        userIdentifier: identifier,
        errorIdentifier: identifier,
      });
    }
  }

  static formatIdentifier(identifier) {
    const formatIdentifier = identifier
      .toLowerCase()
      .replace(/[^a-z0-9\-._@]/g, "");
    return formatIdentifier;
  }

  handleChange(e) {
    this.setState({
      userIdentifier: RequestIdentifier.formatIdentifier(e.target.value),
    });
  }

  componentDidUpdate(prev) {
    if (prev.registerKey !== this.props.registerKey) {
      this.setState({ allowedDomains: this.updateDomains() });
    }
  }

  componentDidMount() {
    if (this.state.identifier !== null) {
      this.checkIdentifier(this.state.identifier);
    }
  }

  render() {
    let errorMessage = null;

    if (
      this.state.loading === true ||
      (this.props.data !== undefined && this.props.data.loading === true)
    ) {
      return <LoadingCol />;
    }

    if (this.state.errorIdentifier !== null) {
      errorMessage = "app.register.identifier.error";
    } else if (this.state.error !== null) {
      errorMessage = "app.register.identifier.error.auto";
    }

    return (
      <div>
        {errorMessage !== null ? (
          <Alert variant="danger" onDismiss={this.handleDismiss}>
            <h4>
              <FormattedMessage id="app.global.error" />
            </h4>
            {React.createElement("p", {
              dangerouslySetInnerHTML: {
                __html: this.props.intl.formatMessage({ id: errorMessage }),
              },
            })}
          </Alert>
        ) : (
          ""
        )}

        {this.state.identifier === null && this.state.error === null ? (
          <IdentifierPanel>
            <Card.Body>
              <form
                onSubmit={(event) => {
                  this.setIdentifier();
                  event.preventDefault();
                  return false;
                }}
              >
                <FormGroup
                  controlId="formBasicText"
                  validationState={this.getValidationState()}
                >
                  <FormLabel>
                    <FormattedMessage id="app.register.identifier.label" />
                  </FormLabel>
                  <FormControl
                    type="text"
                    value={this.state.userIdentifier}
                    placeholder={this.props.intl.formatMessage({
                      id: "app.register.identifier.placeholder",
                    })}
                    onChange={this.handleChange}
                  />
                  <FormControl.Feedback />
                  <FormText>
                    <FormattedMessage id="app.register.identifier.hint" />
                  </FormText>
                </FormGroup>
                <div className="d-grid">
                  <Button
                    disabled={this.getValidationState() !== "success"}
                    onClick={this.setIdentifier}
                    variant="primary"
                    size="lg"
                  >
                    <FormattedMessage id="app.register.identifier.submit" />
                  </Button>
                </div>
              </form>
            </Card.Body>
          </IdentifierPanel>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default injectIntl(RequestIdentifier);
