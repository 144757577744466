import React, { PureComponent } from "react";
import styled, { ThemeProvider } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import Container from "../../globalComponents/Container";
import { Wrapper } from "../../globalComponents/Panel";
import CustomPanel from "../../globalComponents/CustomPanel";
import Button from "../../components/CustomBootstrap/Button";
import { theme } from "../../globalComponents/Theme";
import { CONTACT_QUERY } from "../Contact.graphql";
import Conf from "../../Conf";
import { withLanguage } from "../../context/LanguageContext/withLanguage";
import { LANGUAGES } from "../../utils/language";
import Icon from "../../globalComponents/Icon";

const CustomNoIcon = styled("div")`
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px;
`;

const CustomSpan = styled("span")`
  width: 100%;
  padding: 10px 0px;
  margin: 0px;
  text-decoration: none;
  cursor: pointer;
`;

class Language extends PureComponent {
  render() {
    if (!this.props.languageContext.selectionAvailable) return null;

    return (
      <ThemeProvider theme={theme}>
        <Container className="contact">
          <Wrapper>
            <CustomPanel>
              <h3>
                <FormattedMessage id="app.global.language" />
              </h3>
              {LANGUAGES.map((language, index) => (
                <div
                  key={`language-select-${index}`}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {this.props.languageContext.language === language.value ? (
                    <Icon
                      src={Conf.navigation.check.src}
                      className="icon active"
                    />
                  ) : (
                    <CustomNoIcon />
                  )}
                  <CustomSpan
                    style={
                      index !== 0
                        ? {
                            borderTop: "1px solid #e9e9e9",
                          }
                        : {}
                    }
                    onClick={() => {
                      this.props.languageContext.setLanguage(language.value);
                    }}
                  >
                    {language.caption}
                  </CustomSpan>
                </div>
              ))}
            </CustomPanel>
            <div className="d-grid">
              <Button
                variant="primary"
                size="lg"
                onClick={() => {
                  window.history.go(-1);
                }}
              >
                <FormattedMessage id="app.staticcontent.close" />
              </Button>
            </div>
          </Wrapper>
        </Container>
      </ThemeProvider>
    );
  }
}

export default withLanguage(graphql(CONTACT_QUERY)(injectIntl(Language)));
