import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class AlertModalBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      title: "",
      text: "",
      button: () => null,
      backdrop: true,
    };

    this.close = this.close.bind(this);
  }

  // do not remove. called by ref
  // eslint-disable-next-line react/no-unused-class-component-methods
  open(title, text, button, backdrop) {
    this.setState({ showModal: true, title, text, button, backdrop });
  }

  close() {
    if (this.state.button) this.state.button();
    this.setState({ showModal: false });
  }

  render() {
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.close}
        backdrop={this.state.backdrop}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{this.state.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.close}>
            <FormattedMessage id="app.modal.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AlertModalBox;
