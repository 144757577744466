import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import { PaddingLessContainer } from "../../globalComponents/PaddingLessContainer";
import {
  CustomRow,
  CustomLeftCol,
  CustomRightCol,
  CustomListGroup,
  SecondLevelListGroupItem,
  Title,
  CustomFaAngleRight,
} from "../../globalComponents/List";
import BasicData from "./BasicData";
import AccessData from "./AccessData";
import EmailSettings from "./EmailSettings";
import Accounting from "./Accounting";

class Account extends Component {
  constructor(props) {
    super(props);

    this.views = {
      BASICDATA: 1,
      ACCESSDATA: 2,
      EMAILSETTINGS: 3,
      ACCOUNTING: 4,
    };

    this.state = {
      currentView: null,
      isMobile: window.innerWidth < 992,
    };

    this.getRightSide = this.getRightSide.bind(this);
    this.updateIsMobile = this.updateIsMobile.bind(this);
    this.goBack = this.goBack.bind(this);
    this.openAccessData = this.openAccessData.bind(this);
    this.openEmailSettings = this.openEmailSettings.bind(this);
    this.openBasicData = this.openBasicData.bind(this);
    this.openAccounting = this.openAccounting.bind(this);
  }

  goBack() {
    this.setState({ currentView: null });
  }

  getRightSide() {
    switch (this.state.currentView) {
      case this.views.ACCESSDATA:
        return <AccessData />;
      case this.views.EMAILSETTINGS:
        return <EmailSettings history={this.props.history} />;
      case this.views.ACCOUNTING:
        return <Accounting history={this.props.history} />;
      default:
        return <BasicData history={this.props.history} />;
    }
  }

  updateIsMobile() {
    this.setState({ isMobile: window.innerWidth < 992 });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateIsMobile.bind(this));

    if (!this.state.isMobile) {
      this.setState({ currentView: this.views.BASICDATA });
    }
  }

  openAccessData() {
    window.customBackAction = this.goBack;
    this.setState({ currentView: this.views.ACCESSDATA });
  }

  openAccounting() {
    window.customBackAction = this.goBack;
    this.setState({ currentView: this.views.ACCOUNTING });
  }

  openEmailSettings() {
    window.customBackAction = this.goBack;
    this.setState({ currentView: this.views.EMAILSETTINGS });
  }

  openBasicData() {
    window.customBackAction = this.goBack;
    this.setState({ currentView: this.views.BASICDATA });
  }

  render() {
    return (
      <FormattedMessage id="app.account.title">
        {(accountTitle) => (
          <Layout {...this.props} title={accountTitle}>
            <PaddingLessContainer className="account">
              <Helmet title={accountTitle} />
              <CustomRow>
                <CustomLeftCol xs={12} md={12} lg={4}>
                  <CustomListGroup className="list-group">
                    <SecondLevelListGroupItem
                      className={`
                                            account-standing-data
                                            ${
                                              this.state.currentView ===
                                              this.views.BASICDATA
                                                ? "open"
                                                : ""
                                            }
                                          `}
                      onClick={this.openBasicData}
                    >
                      <Title>
                        <FormattedMessage id="app.account.basicData" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </SecondLevelListGroupItem>

                    <SecondLevelListGroupItem
                      className={`
                                            account-bank-data
                                            ${
                                              this.state.currentView ===
                                              this.views.ACCOUNTING
                                                ? "open"
                                                : ""
                                            }
                                          `}
                      onClick={this.openAccounting}
                    >
                      <Title>
                        <FormattedMessage id="app.account.accounting" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </SecondLevelListGroupItem>

                    <SecondLevelListGroupItem
                      className={`
                                          account-login-data
                                            ${
                                              this.state.currentView ===
                                              this.views.ACCESSDATA
                                                ? "open"
                                                : ""
                                            }
                                          `}
                      onClick={this.openAccessData}
                    >
                      <Title>
                        <FormattedMessage id="app.account.accessData" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </SecondLevelListGroupItem>

                    <SecondLevelListGroupItem
                      className={`
                                            account-notification-data
                                            ${
                                              this.state.currentView ===
                                              this.views.EMAILSETTINGS
                                                ? "open"
                                                : ""
                                            }
                                          `}
                      onClick={this.openEmailSettings}
                    >
                      <Title>
                        <FormattedMessage id="app.account.emailSettings" />
                      </Title>
                      <CustomFaAngleRight className="arrow" />
                    </SecondLevelListGroupItem>
                  </CustomListGroup>
                </CustomLeftCol>
                <CustomRightCol
                  xs={12}
                  md={12}
                  lg={8}
                  className={`account-right-view ${
                    this.state.currentView ? "open" : ""
                  }`}
                >
                  {this.getRightSide()}
                </CustomRightCol>
              </CustomRow>
            </PaddingLessContainer>
          </Layout>
        )}
      </FormattedMessage>
    );
  }
}

export default injectIntl(Account);
