import { gql } from "@apollo/client";

export const BUDGET_QUERY = gql`
  query subvention($cursor: String, $limit: Int!) {
    guest {
      id
      bonusConversionsConnection(first: $limit, after: $cursor) {
        totalCount
        edges {
          cursor
          node {
            id
            points
            firstname
            lastname
            street
            zip
            town
            deliverDate
            state
            transmitted
            trackingNumber
            creationDate
            bonus {
              id
              number
              title
              deliver
            }
          }
        }
      }
    }
  }
`;
