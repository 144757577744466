import React from "react";

// eslint-disable-next-line
import Intl from "intl";
import { IntlProvider } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { Modal } from "react-bootstrap";

import Router from "./Router";
import { LoadingCol } from "./globalComponents/LoadingCol";
import { CONFIG_QUERY } from "./Frontend.graphql";
import { withLanguage } from "./context/LanguageContext/withLanguage";

class Frontend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      ready: false,
    };
  }

  componentDidUpdate() {
    if (!(this.props.data.loading !== true && this.state.ready === false))
      return;

    const modules = this.props.data.config.modules.find(
      (elem) => elem?.employee !== undefined
    ).employee.enabledModules;

    this.setState({
      modules,
      ready: true,
    });
  }

  render() {
    if (this.props.data.error !== undefined) {
      return (
        <Modal show>
          <Modal.Header closeButton={false}>
            <Modal.Title>
              <span>Wartungsarbeiten</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Auf Grund von Wartungsarbeiten ist das Portal aktuell nicht
              verfügbar.
            </p>
            <p>Bitte versuchen Sie es später noch einmal.</p>
          </Modal.Body>
          <Modal.Footer />
        </Modal>
      );
    }

    if (this.state.ready !== true) {
      return <LoadingCol />;
    }

    return (
      <IntlProvider
        defaultLocale="de"
        locale={this.props.languageContext.language}
        messages={this.props.languageContext.messages}
        key={this.props.languageContext.language}
      >
        <Router modules={this.state.modules} />
      </IntlProvider>
    );
  }
}

export default withLanguage(graphql(CONFIG_QUERY)(Frontend));
