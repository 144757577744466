import { gql } from "@apollo/client";

export const LANGUAGE_QUERY = gql`
  query Frontend($lang: Language!) {
    frontend {
      employee {
        locales(lang: $lang) {
          localeKey
          localeValue
        }
      }
    }
  }
`;

export const AVAILABLE_LANGUAGES_QUERY = gql`
  query {
    config {
      modules {
        ... on ModuleConfigFrontend {
          employee {
            enabledLanguages
          }
        }
      }
    }
  }
`;
