import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { injectIntl } from "react-intl";
import { Card } from "react-bootstrap";

const SiteTitle = styled("div")`
  font-size: 18px;
  font-weight: bold;
`;

const StyledContent = styled(Card.Body)`
  h1 {
    font-size: 16px !important;
    font-weight: bold !important;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px !important;
    font-weight: bold !important;
  }

  p,
  span {
    font-size: 14px !important;
    font-weight: normal !important;
  }
`;

class StaticContent extends React.PureComponent {
  render() {
    return (
      <StyledContent>
        <Helmet
          title={`${
            this.props.staticContent.title
          } - ${this.props.intl.formatMessage({ id: "app.title" })}`}
        />
        <SiteTitle>{this.props.staticContent.title}</SiteTitle>
        {React.createElement("p", {
          dangerouslySetInnerHTML: {
            __html: this.props.noParsing
              ? this.props.staticContent.content
              : JSON.parse(this.props.staticContent.content),
          },
        })}
      </StyledContent>
    );
  }
}

export default injectIntl(StaticContent);
