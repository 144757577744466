import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { FormattedMessage, injectIntl } from "react-intl";
import Conf from "../../Conf";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import Button from "../CustomBootstrap/Button";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import { CANCEL_RESERVATION_MUTATION } from "./CancelReservation.graphql";

class CancelReservation extends Component {
  constructor(props) {
    super(props);

    this.state = { cancelling: false };

    this.parseError = parseError.bind(this);
  }

  onClick() {
    if (
      window.confirm(
        this.props.intl.formatMessage({
          id: "app.appointments.confirmCancelReservation",
        })
      )
    ) {
      this.setState({ cancelling: true });

      this.props
        .mutate({ variables: { id: this.props.id } })
        .then(() => {
          this.setState({ cancelling: false });
          this.props.onDelete();
        })
        .catch((error) => {
          this.setState({ cancelling: false });
          const errorTextTranslationkey = this.parseError(
            error,
            Conf.errorContext.appointmentCancelDate
          );

          this.props.onError(error, errorTextTranslationkey);
        });
    }
  }

  render() {
    return this.state.cancelling ? (
      <LoadingCol height="50" />
    ) : (
      <div className="d-grid">
        <Button onClick={this.onClick.bind(this)} variant="danger" size="lg">
          <FormattedMessage id="app.appointments.cancelReservation" />
        </Button>
      </div>
    );
  }
}

export default graphql(CANCEL_RESERVATION_MUTATION)(
  injectIntl(CancelReservation)
);
