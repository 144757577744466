import React, { PureComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";
import styled from "styled-components";
import { Badge } from "react-bootstrap";

import { NEWS_BADGE_QUERY } from "./NewsBadge.graphql";

const Label = styled(Badge)`
  border-radius: 50% !important;
  min-width: 20px;
  padding: 5px 4px !important;
  position: absolute;
  left: -10px;
  top: -5px;
`;

class NewsBadge extends PureComponent {
  render() {
    return this.props.data.loading ? null : this.props.data.guest.news
        .length === 0 ? null : (
      <Label bg="danger">{this.props.data.guest.news.length}</Label>
    );
  }
}

export default graphql(NEWS_BADGE_QUERY, {
  options: { pollInterval: 60000 },
})(NewsBadge);
