import React, { PureComponent } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Event from "../Calendar/Event";
import Button from "../CustomBootstrap/Button";
import { Wrapper, CustomPanel, H4 } from "../../globalComponents/Panel";

class TodaysEventsWidget extends PureComponent {
  render() {
    return (
      <Wrapper className="widget widget-today">
        <H4>
          <FormattedMessage id="app.home.widget.todayEvents.title" />
        </H4>
        <CustomPanel>
          <H4>
            {this.props.data.events.length === 0 ? (
              <FormattedMessage id="app.home.noAppointmentsToday" />
            ) : (
              this.props.data.events.map((event, key) => (
                <Event
                  key={key}
                  data={{
                    title: event.offers[0].offer.title,
                    start: event.timeStart.substr(0, 5),
                    end: event.timeEnd.substr(0, 5),
                    desc: event.info,
                    id: event.id,
                    guestState: event.guestState,
                    icon: event.offers[0].offer.icon,
                    freeSlots: event.offers[0].freeSlots,
                    /* - Math.floor(Math.random() * 5), */
                    totalSlots: event.offers[0].totalSlots,
                    date: event.date,
                  }}
                  history={this.props.history}
                />
              ))
            )}
          </H4>
        </CustomPanel>
        <div className="d-grid">
          <Button variant="primary" size="lg">
            <FormattedMessage id="app.home.widget.todayEvents.button" />
          </Button>
        </div>
      </Wrapper>
    );
  }
}

export default injectIntl(TodaysEventsWidget);
