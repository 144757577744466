import React, { Component } from "react";
import styled from "styled-components";
import { Collapse } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import Button from "../CustomBootstrap/Button";
import { CustomFaAngleRight, Header, Title } from "../../globalComponents/List";
import Conf from "../../Conf";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import { DateFormatter } from "../../globalComponents/DateFormatter";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import { REFUND_QUERY } from "./Refund.graphql";

const CustomHeader = styled(Header)`
  padding-left: 15px;

  &.open {
    padding: 0 15px 0 15px;
  }
`;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 1px solid #d4d4d4;
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

class Refund extends Component {
  constructor(props) {
    super(props);

    this.state = { open: null };

    // this.getState = this.getState.bind(this);

    // this.fetchMore = this.fetchMore.bind(this);
  }

  canGetMore() {
    return (
      this.props.data.guest.refundRecordsConnection.edges.length !==
      this.props.data.guest.refundRecordsConnection.totalCount
    );
  }

  fetchMore() {
    return this.props.data.fetchMore();
  }

  formatPrice(number) {
    return this.props.intl.formatNumber(number, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  static getState(state, type) {
    switch (state) {
      case "IN_PROGRESS":
        return <FormattedMessage id={`app.budget.state.inProgress.${type}`} />;
      case "REJECTED_REQUIREMENTS":
        return (
          <FormattedMessage
            id={`app.budget.state.rejectedRequirements.${type}`}
          />
        );
      case "REJECTED_SUBVENTION_LIMIT":
        return (
          <FormattedMessage
            id={`app.budget.state.rejectedSubventionLimit.${type}`}
          />
        );
      case "REJECTED_UNKNOWN_OFFER":
        return (
          <FormattedMessage
            id={`app.budget.state.rejectedUnknownOffer.${type}`}
          />
        );
      case "REFUND":
        return <FormattedMessage id={`app.budget.state.refund.${type}`} />;
      case "APPROVED":
        return <FormattedMessage id={`app.budget.state.approved.${type}`} />;
      case "REJECTED_DUPLICATE":
        return (
          <FormattedMessage id={`app.budget.state.rejectedDuplicate.${type}`} />
        );
      case "WAITING_FOR_RESPONSE":
        return <FormattedMessage id={`app.budget.state.waiting.${type}`} />;
      default:
        return state;
    }
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    return (
      <div>
        {this.props.data.guest.refundRecordsConnection.edges.length === 0 ? (
          <VerticallyAlignedMiddle height={window.innerHeight / 2}>
            <span className="large">
              <FormattedMessage id="app.budget.noRefund" />
            </span>
          </VerticallyAlignedMiddle>
        ) : (
          this.props.data.guest.refundRecordsConnection.edges.map((edge) => (
            <div key={edge.cursor}>
              {edge.node.receiptTitle ? (
                <div className="budget-receipts-item">
                  <CustomHeader
                    className={[
                      "budget-receipts-item__handler",
                      this.state.open === edge.node.id ? "open" : "",
                    ].join(" ")}
                    onClick={() =>
                      this.state.open === edge.node.id
                        ? this.setState({ open: null })
                        : this.setState({ open: edge.node.id })
                    }
                  >
                    <Title>
                      {
                        edge.node.bgmConceptTherapyOfferRefund
                          .bgmConceptTherapyOffer.therapyOffer.title
                      }
                      <br />
                      <small className="text-muted">
                        <FormattedMessage id="app.global.from" />{" "}
                        <DateFormatter
                          date={new Date(edge.node.creationDate)}
                          type="DATE"
                        />
                        {" - "}
                        {Refund.getState(edge.node.state, "header")}
                      </small>
                    </Title>
                    <CustomFaAngleRight className="arrow" />
                  </CustomHeader>
                  <Wrapper>
                    <Collapse in={this.state.open === edge.node.id}>
                      <div>
                        <Content>
                          <p>
                            <b>
                              <FormattedMessage id="app.global.title" />{" "}
                            </b>
                            {edge.node.receiptTitle}
                            <br />
                            <b>
                              <FormattedMessage id="app.offers.date.date" />{" "}
                            </b>
                            <DateFormatter
                              date={new Date(edge.node.receiptDate)}
                              type="DATE"
                            />
                            <br />
                            <b>
                              <FormattedMessage id="app.offers.price.refund.referenceNumber" />{" "}
                            </b>
                            {edge.node.receiptNumber}
                          </p>
                          <p>
                            <b>
                              <FormattedMessage id="app.offers.price.refund.amount" />{" "}
                            </b>
                            {this.formatPrice(edge.node.gross)}€
                          </p>
                          <p>
                            <b>
                              <FormattedMessage id="app.offers.price.refund.submittedAt" />{" "}
                            </b>
                            <DateFormatter
                              date={new Date(edge.node.creationDate)}
                              type="DATE"
                            />
                          </p>
                          <p>
                            <b>
                              <FormattedMessage id="app.offers.price.refund.state" />{" "}
                            </b>
                            {Refund.getState(edge.node.state, "content")}
                          </p>
                        </Content>
                      </div>
                    </Collapse>
                  </Wrapper>
                </div>
              ) : (
                ""
              )}
            </div>
          ))
        )}
        {!this.props.data.loading && this.canGetMore() ? (
          <div>
            <br />
            <div className="d-grid">
              <Button
                onClick={() => this.fetchMore()}
                variant="primary"
                size="lg"
              >
                <FormattedMessage id="app.global.loadMore" />
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const ITEMS_PER_PAGE = Conf.general.itemsPerPage;
const withData = graphql(REFUND_QUERY, {
  options: () => ({
    variables: {
      cursor: null,
      limit: ITEMS_PER_PAGE,
    },
    fetchPolicy: "cache-and-network",
  }),
  props: ({ data: { loading, guest, config, fetchMore } }) => ({
    data: {
      loading,
      guest,
      config,
      fetchMore: () =>
        fetchMore({
          variables: {
            cursor:
              guest.refundRecordsConnection.edges[
                guest.refundRecordsConnection.edges.length - 1
              ].cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            return {
              ...prev,
              guest: {
                id: prev.guest.id,
                refundRecordsConnection: {
                  edges: [
                    ...prev.guest.refundRecordsConnection.edges,
                    ...fetchMoreResult.guest.refundRecordsConnection.edges,
                  ],
                  totalCount:
                    fetchMoreResult.guest.refundRecordsConnection.totalCount,
                  __typename:
                    fetchMoreResult.guest.refundRecordsConnection.__typename,
                },
                __typename: fetchMoreResult.guest.__typename,
              },
            };
          },
        }),
    },
  }),
});

export default withData(injectIntl(Refund));
