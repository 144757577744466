/**
 *  extract error code from error response
 *  and return translation key
 */
export function parseError(error, context, defaultVal) {
  /** regex to match error code (e.g. '400x02') * */
  // eslint-disable-next-line prefer-regex-literals
  let regex = new RegExp("[0-9]{3}x[0-9]{2}");
  let errorCode = error.message.match(regex);

  /** null when regex couldn't find a match * */
  if (errorCode === null) {
    /** regex to match error code (e.g. '403') * */
    // eslint-disable-next-line prefer-regex-literals
    regex = new RegExp("[0-9]{3}");
    errorCode = error.message.match(regex);
  }

  /**
   *  if after all regex the match is still null,
   *  return default error message
   */
  if (errorCode === null) {
    return (
      context +
      (defaultVal === null || defaultVal === undefined
        ? ".default"
        : `.${defaultVal}`)
    );
  }

  return `${context}.${errorCode[0]}`;
}
