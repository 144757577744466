import * as React from "react";
import { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { LanguageContext } from "./LanguageContext";
import {
  LANGUAGE_QUERY,
  AVAILABLE_LANGUAGES_QUERY,
} from "./LanguageProvider.graphql";

import { setLanguage, getLanguage } from "../../utils/localStorage";
import {
  getAvailableLanguages,
  getLocaleQueryLang,
  getLanguageFallback,
} from "../../utils/language";
import { LoadingCol } from "../../globalComponents/LoadingCol";

const getAvailableLanguagesFromQueryResult = (availableLanguages) =>
  availableLanguages?.config?.modules
    ?.find((e) => e.__typename === "ModuleConfigFrontend")
    ?.employee?.enabledLanguages?.map((lang) => lang.toLowerCase() ?? []);

export const LanguageProvider = (props) => {
  const [messages, setMessages] = React.useState(null);

  const language = useQuery(LANGUAGE_QUERY, {
    variables: { lang: getLocaleQueryLang(props.selectedLanguage) },
  });

  const availableLanguages = useQuery(AVAILABLE_LANGUAGES_QUERY);

  useEffect(() => {
    document.documentElement.lang = props.selectedLanguage;
  }, [props.selectedLanguage]);

  useEffect(() => {
    if (!availableLanguages?.data) return;
    const availableLanguagesFromQuery = getAvailableLanguagesFromQueryResult(
      availableLanguages.data
    );
    if (availableLanguagesFromQuery.includes(getLanguage())) return;
    if (!(availableLanguagesFromQuery?.length > 0)) return;
    props.setSelectedLanguage(availableLanguagesFromQuery[0]);
    setLanguage(availableLanguagesFromQuery[0]);
  }, [availableLanguages.data]);

  const value = useMemo(
    () => ({
      languageContext: {
        messages,
        availableLanguages: getAvailableLanguages(
          getAvailableLanguagesFromQueryResult(availableLanguages.data)
        ),
        selectionAvailable:
          getAvailableLanguages(
            getAvailableLanguagesFromQueryResult(availableLanguages.data)
          ).length > 1,
        setLanguage: props.setSelectedLanguage,
        language: props.selectedLanguage,
      },
    }),
    [
      props.selectedLanguage,
      props.setSelectedLanguage,
      messages,
      availableLanguages.data,
    ]
  );

  useEffect(() => {
    setLanguage(props.selectedLanguage);
    language.refetch({ lang: getLocaleQueryLang(props.selectedLanguage) });
  }, [props.selectedLanguage]);

  useEffect(() => {
    const locale = getLanguageFallback(props.selectedLanguage);
    if (
      !language.loading &&
      !language.error &&
      language.data?.frontend?.employee?.locales
    ) {
      language.data.frontend.employee.locales.forEach((elem) => {
        locale[elem.localeKey] = elem.localeValue;
      });
    }

    setMessages(locale);
  }, [language.data, language.error]);

  if (
    !value.languageContext.language ||
    !value.languageContext.messages ||
    !value.languageContext.availableLanguages ||
    language.loading
  )
    return <LoadingCol />;

  return (
    <LanguageContext.Provider value={value}>
      {props.children}
    </LanguageContext.Provider>
  );
};
