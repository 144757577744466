import { gql } from "@apollo/client";

export const BUDGET_QUERY = gql`
  query subvention($cursor: String, $limit: Int!) {
    guest {
      id
      subventionRecordsConnection(first: $limit, after: $cursor) {
        totalCount
        edges {
          cursor
          node {
            value
            gross
            date
            companySubvention {
              therapyOffer {
                title
              }
            }
            eventType
            object {
              id
              __typename
              ... on BgmConceptTherapyOfferExternUsage {
                payment
                note
                state
                salesPrice
                bgmConceptTherapyOfferExtern {
                  id
                  privacyPolicy
                  serviceProviderLocation {
                    id
                    name
                  }
                }
                creationDate
              }
              ... on EventGuest {
                __typename
                date
                timeStart
                timeEnd
                eventTherapist {
                  therapist {
                    displayName
                  }
                  roomEvent {
                    dateBegin
                    dateEnd
                    type
                  }
                }
              }
              ... on BgmConceptTherapyOfferRefundRecord {
                __typename
                gross
                receiptDate
                receiptTitle
                receiptNumber
                creationDate
              }
              ... on PartnerPracticeEventGuest {
                __typename
                date
                partnerPracticeEventList {
                  partnerPracticeLocation {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
