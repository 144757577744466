import { gql } from "@apollo/client";

export const PUBLIC_PRIVACY_POL_QUERY = gql`
  query {
    frontend {
      staticContent(type: EMPLOYEE_PRIVACY) {
        title
        content
      }
    }
  }
`;
