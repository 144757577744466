import { gql } from "@apollo/client";

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($accountOwner: String, $iban: String, $bic: String) {
    updateGuest(
      guest: {}
      debitor: { accountOwner: $accountOwner, iban: $iban, bic: $bic }
    ) {
      id
      debitor {
        id
        accountOwner
        iban
        bic
      }
    }
  }
`;
