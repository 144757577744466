import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { Alert, Col, Row, ListGroup } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import { Wrapper } from "../../globalComponents/Panel";
import SaveEmailSettings from "./SaveEmailSettings";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { EMAIL_SETTINGS_QUERY } from "./EmailSettings.graphql";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 15px 30px 15px;
  width: calc(100% + 15px);
`;

const Content = styled("div")`
  padding: 0;
  text-align: left;
`;

class EmailSettings extends Component {
  constructor(props) {
    super(props);

    this.state = { dataSaved: false };

    this.dataSaved = this.dataSaved.bind(this);
    this.onError = this.onError.bind(this);
  }

  dataSaved() {
    this.setState({ dataSaved: true });
    /** removes message box after 5 second delay */
    setTimeout(() => {
      this.setState({ dataSaved: false });
    }, 5000);
  }

  /**
   * opens modal box with error message
   *
   * @param error: returned from api
   * @param errorTextTranslationKey: returned form parseError
   */
  onError(error, errorTextTranslationKey) {
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    return (
      <ScrollableDiv>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <Wrapper>
          <Content>
            {this.state.dataSaved ? (
              <Alert variant="success">
                <p>
                  <FormattedMessage id="app.account.emailSettingsSaved" />
                </p>
              </Alert>
            ) : (
              ""
            )}
            <Row>
              <Col xs={12} sm={6}>
                <ListGroup variant="flush">
                  {this.props.data.guest.emailConfigs.map((emailConfig) => (
                    <ListGroup.Item>
                      <SaveEmailSettings
                        key={emailConfig.email.id}
                        emailConfig={emailConfig}
                        onFinish={this.dataSaved}
                        onError={this.onError}
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </Content>
        </Wrapper>
      </ScrollableDiv>
    );
  }
}

export default graphql(EMAIL_SETTINGS_QUERY, {
  options: { fetchPolicy: "network-only" },
})(injectIntl(EmailSettings));
