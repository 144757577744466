import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import isSameDay from "date-fns/isSameDay";

import Button from "../CustomBootstrap/Button";
import "./ExternalOfferVariant.scss";
import { EXTERNAL_VARIANTS } from "../../config/offer/external-variants";
import { DateFormatter } from "../../globalComponents/DateFormatter";

class ExternalOfferVariant extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
  }

  toggleOpen() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  bookingSelected(e) {
    e.stopPropagation();
    this.props.clickHandler(this.props.fields.id);
  }

  renderOverview({
    beginDateIsEndDate,
    dateBegin,
    dateEnd,
    city,
    plattformName,
    title,
    showDetails,
  }) {
    return (
      <ul className="external-offer-variant__overview">
        {dateBegin && (
          <li className="external-offer-variant__variant external-offer-variant__variant--date">
            {beginDateIsEndDate ? (
              <DateFormatter date={new Date(dateBegin)} type="DATE" />
            ) : (
              <>
                <DateFormatter date={new Date(dateBegin)} type="DATE" />
                {" - "}
                <DateFormatter date={new Date(dateEnd)} type="DATE" />
              </>
            )}
          </li>
        )}
        <li
          className={[
            "external-offer-variant__variant",
            "external-offer-variant__variant--where",
            title && "external-offer-variant__variant--custom",
          ].join(" ")}
        >
          {city || plattformName || title}
        </li>

        <li className="external-offer-variant__variant external-offer-variant__variant--show-details">
          {showDetails && (
            <span
              className={[
                "external-offer-variant__toggle",
                this.state.isOpen ? "is-open" : "",
              ].join(" ")}
            >
              <FormattedMessage id="app.offers.extern.variants.details" />
            </span>
          )}
        </li>

        <li className="external-offer-variant__variant external-offer-variant__variant--btn">
          <div className="d-grid">
            <Button variant="primary" onClick={this.bookingSelected.bind(this)}>
              <FormattedMessage id="app.offers.useExternal" />
            </Button>
          </div>
        </li>
      </ul>
    );
  }

  renderDetails({
    locationName,
    description,
    dateBegin,
    dateEnd,
    beginDateIsEndDate,
    street,
    zipCode,
    country,
    streetNumber,
    city,
  }) {
    return (
      <ul
        className={[
          "external-offer-variant__details",
          this.state.isOpen ? "is-open" : "",
        ].join(" ")}
      >
        {locationName && (
          <li className="external-offer-variant__title">{locationName}</li>
        )}
        {description && (
          <li className="external-offer-variant__detail external-offer-variant__detail--spacer">
            {description}
          </li>
        )}
        {dateBegin && beginDateIsEndDate ? (
          <li className="external-offer-variant__detail external-offer-variant__detail--spacer">
            <div>
              <strong>
                <FormattedMessage id="app.offers.extern.variants.dateBegin" />{" "}
              </strong>
            </div>
            <div>
              <DateFormatter date={new Date(dateBegin)} type="DAY_WITH_DATE" />
            </div>
            <div>
              <DateFormatter date={new Date(dateBegin)} type="TIME" /> -{" "}
              <DateFormatter date={new Date(dateEnd)} type="TIME" />
            </div>
          </li>
        ) : dateBegin || dateEnd ? (
          <li className="external-offer-variant__detail external-offer-variant__detail--spacer">
            <div>
              <strong>
                <FormattedMessage id="app.offers.extern.variants.dateBegin" />{" "}
              </strong>
            </div>
            <div className="external-offer-variant__detail external-offer-variant__detail--spacer">
              <DateFormatter
                date={new Date(dateBegin)}
                type="DAY_WITH_DATE_AND_TIME"
              />
            </div>
            <div>
              <strong>
                <FormattedMessage id="app.offers.extern.variants.dateEnd" />{" "}
              </strong>
            </div>
            <div>
              <DateFormatter
                date={new Date(dateEnd)}
                type="DAY_WITH_DATE_AND_TIME"
              />
            </div>
          </li>
        ) : (
          ""
        )}
        {this.renderAdress({
          street,
          zipCode,
          country,
          streetNumber,
          city,
        })}
      </ul>
    );
  }

  renderAdress({ street, zipCode, country, streetNumber, city }) {
    return [
      (street || city) && (
        <li key="place">
          <strong>
            <FormattedMessage id="app.offers.extern.variants.place" />:
          </strong>
        </li>
      ),
      street && (
        <li className="external-offer-variant__detail" key="street">
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${street}+${streetNumber}+${city}`}
            target="_blank"
            title={this.props.intl.formatMessage({
              id: "app.offers.extern.variants.maps",
            })}
            rel="noopener noreferrer"
          >
            {street} {streetNumber}
          </a>
        </li>
      ),
      zipCode && (
        <li className="external-offer-variant__detail" key="zipCode">
          {zipCode} {city}
        </li>
      ),
      country && country.toLowerCase() !== this.props.country.toLowerCase() && (
        <li className="external-offer-variant__detail" key="country">
          {country}
        </li>
      ),
    ];
  }

  render() {
    const { fields } = this.props;
    const {
      title,
      city,
      country,
      zipCode,
      dateBegin,
      dateEnd,
      description,
      locationName,
      street,
      streetNumber,
      id,
      plattformName,
      __typename,
    } = fields;

    const beginDateIsEndDate = isSameDay(
      new Date(dateBegin),
      new Date(dateEnd)
    );
    const showDetails = __typename !== EXTERNAL_VARIANTS.CUSTOM || description;

    return (
      <li
        className="external-offer-variant"
        onClick={showDetails && this.toggleOpen.bind(this)}
      >
        {this.renderOverview({
          beginDateIsEndDate,
          dateBegin,
          dateEnd,
          city,
          plattformName,
          title,
          showDetails,
        })}
        {this.renderDetails({
          locationName,
          title,
          description,
          dateBegin,
          dateEnd,
          beginDateIsEndDate,
          street,
          zipCode,
          country,
          streetNumber,
          city,
          id,
        })}
      </li>
    );
  }
}

ExternalOfferVariant.propTypes = {
  clickHandler: PropTypes.func,
  fields: PropTypes.shape({
    id: PropTypes.string,
    dateBegin: PropTypes.string,
    dateEnd: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    plattformName: PropTypes.string,
    __typename: PropTypes.string,
    lang: PropTypes.string,
    description: PropTypes.string,
    locationName: PropTypes.string,
    street: PropTypes.string,
    streetNumber: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    zipCode: PropTypes.number,
    intl: PropTypes.shape(Intl.shape),
  }).isRequired,
  country: PropTypes.string.isRequired,
};

export default injectIntl(ExternalOfferVariant);
