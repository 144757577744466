import React from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";

const CustomPanel = styled((props) => (
  <Card>
    <Card.Body {...props} />
  </Card>
))`
  border-radius: 8px !important;
  overflow: hidden;
`;

export default CustomPanel;
