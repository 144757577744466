import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import {
  Alert,
  Col,
  FormLabel,
  FormGroup,
  FormText,
  Row,
  Button,
  FormCheck,
  FormControl as BsFormControl,
  Form,
} from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import * as IBAN from "iban";
import * as BIC from "bic";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import { Wrapper } from "../../globalComponents/Panel";
import SaveAccounting from "./SaveAccounting";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { parseError } from "../../globalComponents/ErrorMessageHandler";

import {
  ACCOUNTING_QUERY,
  CREATE_SEPA_MANDATE_MUTATION,
  CANCEL_SEPA_MANDATE_MUTATION,
  UPDATE_USER_MUTATION,
} from "./Accounting.graphql";

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

const Title = styled("div")`
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
`;

const CustomFormControl = styled(BsFormControl)`
  border-color: ${(props) =>
    !props.bordercolor ? `${props.theme.btnBg} !important` : ""};
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

class Accounting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFilled: false,
      unBlock: null,
      dataSaved: false,
      dataFailed: false,
      accountOwner: "",
      iban: "",
      bic: "",
      accountOwnerValidationState: null,
      bicValidationState: null,
      ibanValidationState: null,
      moduleConfig: null,
      sepaMandate: null,
      directDebitCancellations: false,
      editable: false,
    };

    this.parseError = parseError.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.dataSaved = this.dataSaved.bind(this);
    this.validateData = this.validateData.bind(this);
    this.onError = this.onError.bind(this);
    this.createSepaMandate = this.createSepaMandate.bind(this);
    this.cancelSepaMandate = this.cancelSepaMandate.bind(this);
    this.updateCancelSepa = this.updateCancelSepa.bind(this);
  }

  componentDidUpdate() {
    if (!this.props.data.loading && !this.state.dataFilled) {
      let moduleConfig = null;

      this.props.data.config.modules.forEach((module) => {
        if (module.__typename === "ModuleConfigPayment") {
          moduleConfig = module;
        }
      });

      this.setState({
        sepaMandate: this.props.data.guest.debitor.currentSepaMandate,
        moduleConfig,
        dataFilled: true,
        accountOwner: this.props.data.guest.debitor.accountOwner,
        iban: this.props.data.guest.debitor.iban,
        bic: this.props.data.guest.debitor.bic,
        directDebitCancellations:
          this.props.data.guest.directDebitCancellations,
        editable: !this.props.enableDefaultEdit,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.unBlock) {
      this.state.unBlock();
    }
  }

  handleInputChange(event) {
    if (!this.state.unBlock && this.props.history) {
      this.setState({ unBlock: this.props.history.block(this.blockMessage) });
    }

    const { target } = event;
    const { name } = target;
    let value = "";

    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      default:
        value = target.value;
        break;
    }

    switch (name) {
      case "accountOwner":
        value = value.replace("ä", "ae");
        value = value.replace("ö", "oe");
        value = value.replace("ü", "ue");
        value = value.replace(/[^a-zA-Z0-9 -]/, "");
        break;
      case "iban":
        value = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
        value =
          value.substr(0, 2).replace(/[^A-Z]/g, "") +
          value.substr(2, 2).replace(/[^0-9]/g, "") +
          value.substr(4, 30).replace(/[^0-9A-Z]/g, "");
        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  }

  validateData() {
    let error = false;

    if (!this.state.accountOwner) {
      this.setState({ accountOwnerValidationState: "error", dataFailed: true });
      error = true;
    } else {
      this.setState({ accountOwnerValidationState: "success" });
    }

    if (!IBAN.isValid(this.state.iban)) {
      this.setState({ ibanValidationState: "error", dataFailed: true });
      error = true;
    } else {
      this.setState({ ibanValidationState: "success" });
    }

    if (!BIC.isValid(this.state.bic)) {
      this.setState({ bicValidationState: "error", dataFailed: true });
      error = true;
    } else {
      this.setState({ bicValidationState: "success" });
    }

    if (
      !this.props.isModal &&
      (!this.state.accountOwner || !this.state.bic || !this.state.iban)
    ) {
      this.setState({ dataFailed: true });
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: "app.account.incomplete" })
      );
      error = true;
    }
    return !error;
  }

  dataSaved() {
    if (this.state.unBlock) {
      this.state.unBlock();
    }

    this.setState({
      dataSaved: true,
      dataFailed: false,
      unBlock: null,
      editable: true,
    });

    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  /**
   * opens modal box with error message
   *
   * @param error: returned from api
   * @param errorTextTranslationKey: returned form parseError
   */
  onError(error, errorTextTranslationKey) {
    this.setState({ dataFailed: true });
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  }

  createSepaMandate() {
    if (
      !this.state.iban ||
      !this.state.bic ||
      !this.state.accountOwner ||
      !this.validateData()
    ) {
      this.onError(null, "app.account.sepaMandate.error.account");
    } else {
      this.props
        .createSepa()
        .then((props) => {
          if (props.data.createSepaMandate) {
            this.setState({ sepaMandate: props.data.createSepaMandate });
          }
        })
        .catch((error) => {
          this.onError(
            error,
            this.parseError(error, "app.account.sepaMandate.error")
          );
        });
    }
  }

  cancelSepaMandate() {
    this.props
      .cancelSepa({ variables: { id: this.state.sepaMandate.id } })
      .then((props) => {
        if (props.data.cancelSepaMandate) {
          this.setState({ sepaMandate: props.data.cancelSepaMandate });
        }
      })
      .catch((error) => {
        this.onError(
          error,
          this.parseError(error, "app.account.sepaMandate.error")
        );
      });
  }

  updateCancelSepa(event) {
    this.props
      .updateCancelSepa({
        variables: { directDebitCancellations: event.target.checked },
      })
      .then((props) => {
        if (props.data.updateGuest) {
          this.setState({
            dataSaved: true,
            directDebitCancellations:
              props.data.updateGuest.directDebitCancellations,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        this.onError(
          error,
          this.parseError(error, "app.account.sepaMandate.error")
        );
      });
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingCol />;
    }

    const sepa = (
      <div>
        {this.state.moduleConfig !== null &&
        this.state.moduleConfig.sepaIdentificationNumber !== null &&
        this.state.moduleConfig.enabledEventPaymentTypes.includes(
          "DIRECT_DEBIT"
        ) ? (
          <div style={{ marginTop: "30px" }}>
            <Row>
              <Col xs={12}>
                <Title>
                  <FormattedMessage id="app.account.accounting.sepaMandate" />
                </Title>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <strong>
                  <FormattedMessage id="app.account.accounting.sepaMandate.identifier" />
                </strong>{" "}
                &nbsp; {this.state.moduleConfig.sepaIdentificationNumber}
                <br />
                <strong>
                  <FormattedMessage id="app.account.accounting.sepaMandate.reference" />
                </strong>
                &nbsp;{" "}
                {this.state.sepaMandate !== null ? (
                  <span>{this.state.sepaMandate.mandateReference}</span>
                ) : (
                  <FormattedMessage id="app.account.accounting.sepaMandate.referenceTodo" />
                )}
                <p style={{ padding: "15px" }}>
                  <FormattedMessage id="app.account.accounting.sepaMandate.text" />
                </p>
                {this.state.sepaMandate !== null &&
                this.state.sepaMandate.cancelDate !== null ? (
                  <Alert variant="warning">
                    <FormattedMessage id="app.account.accounting.sepaMandate.canceled" />
                  </Alert>
                ) : (
                  ""
                )}
                {this.state.sepaMandate === null ? (
                  <div className="d-grid">
                    <Button
                      onClick={this.createSepaMandate}
                      variant="danger"
                      size="lg"
                    >
                      <FormattedMessage id="app.account.accounting.sepaMandate.create" />
                    </Button>
                  </div>
                ) : (
                  <div className="d-grid">
                    <Button
                      onClick={this.cancelSepaMandate}
                      variant="danger"
                      size="lg"
                      disabled={this.state.sepaMandate.cancelDate !== null}
                    >
                      <FormattedMessage id="app.account.accounting.sepaMandate.cancel" />
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            {this.state.sepaMandate !== null ? (
              <div style={{ marginTop: "30px" }}>
                <Row>
                  <Col xs={12}>
                    <Title>
                      <FormattedMessage id="app.account.accounting.cancellationFees" />
                    </Title>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <FormCheck
                      onChange={this.updateCancelSepa}
                      checked={this.state.directDebitCancellations}
                    >
                      <FormattedMessage id="app.account.accounting.sepaMandate.doCancellations" />
                    </FormCheck>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );

    return (
      <>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <Wrapper style={this.props.isModal ? { margin: 0 } : {}}>
          <Content>
            {this.state.dataSaved ? (
              <Alert variant="success">
                <p>
                  <FormattedMessage id="app.account.saved" />
                </p>
              </Alert>
            ) : (
              ""
            )}
            {this.state.dataFailed ? (
              <Alert variant="danger">
                <p>
                  <FormattedMessage id="app.account.failed" />
                </p>
              </Alert>
            ) : (
              ""
            )}
            {!this.props.isModal && (
              <Row>
                <Col xs={12}>
                  <Title>
                    <FormattedMessage id="app.account.bankAccount" />
                  </Title>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.accountOwner" />
                  </FormLabel>
                  <CustomFormControl
                    name="accountOwner"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.accountOwner}
                    readOnly={this.state.editable}
                    bordercolor={this.state.ibanValidationState}
                    isInvalid={
                      this.state.accountOwnerValidationState === "error"
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.bic" />
                  </FormLabel>
                  <CustomFormControl
                    name="bic"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.bic}
                    readOnly={this.state.editable}
                    bordercolor={this.state.ibanValidationState}
                    isInvalid={this.state.bicValidationState === "error"}
                  />{" "}
                  {this.state.bicValidationState === "error" && (
                    <Form.Control.Feedback type="invalid">
                      <FormattedMessage id="app.account.invalidBic" />
                    </Form.Control.Feedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <FormGroup className="mb-3">
                  <FormLabel>
                    <FormattedMessage id="app.account.iban" />
                  </FormLabel>
                  <CustomFormControl
                    name="iban"
                    onChange={this.handleInputChange}
                    type="text"
                    value={this.state.iban}
                    readOnly={this.state.editable}
                    bordercolor={this.state.ibanValidationState}
                    isInvalid={this.state.ibanValidationState === "error"}
                  />{" "}
                  <Form.Control.Feedback type="invalid">
                    <FormattedMessage id="app.account.invalidIban" />
                  </Form.Control.Feedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <FormGroup className="mb-3">
                  <FormText>
                    <FormattedMessage id="app.account.bank.help" />
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <SaveAccounting
              data={this.state}
              onFinish={this.dataSaved}
              onValidate={this.validateData}
              onError={this.onError}
              editable={this.state.editable}
              isModal={this.props.isModal}
              dataSaved={this.state.dataSaved}
              onClose={this.props.onClose}
              setEditable={() => {
                this.setState({ editable: false, dataSaved: false });
              }}
            />{" "}
            {!this.props.disableSepa && sepa}
          </Content>
        </Wrapper>
      </>
    );
  }
}

export default compose(
  graphql(UPDATE_USER_MUTATION, { name: "updateCancelSepa" }),
  graphql(CANCEL_SEPA_MANDATE_MUTATION, { name: "cancelSepa" }),
  graphql(CREATE_SEPA_MANDATE_MUTATION, { name: "createSepa" }),
  graphql(ACCOUNTING_QUERY, { options: { fetchPolicy: "network-only" } })
)(injectIntl(Accounting));
