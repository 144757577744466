import React, { Component } from "react";
import styled from "styled-components";
import {
  Collapse,
  Alert,
  FormControl,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { format, differenceInMilliseconds } from "date-fns";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { CustomFaAngleRight, Header, Title } from "../../globalComponents/List";
import Button from "../CustomBootstrap/Button";
import { LoadingCol } from "../../globalComponents/LoadingCol";
import {
  COLLECT_QUERY,
  SEND_GUEST_RECOMM_MUTATION,
  UPDATE_GUEST_MUTATION,
} from "./Collect.graphql";
import { DayPickerInput } from "../../globalComponents/DayPickerInput";

const CustomHeader = styled(Header)`
  padding: 15px;
  &.open {
    padding: 15px;
  }
`;

const Wrapper = styled("div")`
  margin: 0 -30px;
  padding: 0 30px;
  border-bottom: 1px solid #d4d4d4;
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

class Collect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openCollegues: false,
      openBirthday: false,
      birthday: null,
      recommand: {
        emailTo: "",
        nameTo: "",
        message: props.intl.formatMessage({
          id: "app.bonus.collect.recommand.message.template",
        }),
        ready: false,
        send: false,
        error: false,
      },
      loading: false,
    };

    this.fetchMore = this.fetchMore.bind(this);
    this.updateBirthday = this.updateBirthday.bind(this);
    this.sendRecommandation = this.sendRecommandation.bind(this);
    this.isRecommandValid = this.isRecommandValid.bind(this);
    this.saveBirthday = this.saveBirthday.bind(this);
  }

  fetchMore() {
    return this.props.data.fetchMore();
  }

  saveBirthday() {
    this.setState({ loading: true });

    this.props
      .updateGuest({
        variables: {
          guest: {
            birthday: format(this.state.birthday, "yyyy-MM-dd"),
          },
        },
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  isRecommandValid(field, val) {
    let value = val;
    if (value === null || value === undefined) {
      value = this.state.recommand[field];
    }
    if (field === "emailTo") {
      return /^[a-z0-9.\-_]{3,}@[a-z0-9.-]{3,}\.[a-z]{2,}$/.test(value);
    }
    if (field === "nameTo") {
      return value.length > 2;
    }
    if (field === "message") {
      return value.length > 30;
    }
  }

  updateRecommand(field, value) {
    let ready = this.isRecommandValid(field, value);
    if (ready) {
      if (field !== "nameTo") {
        ready = ready && this.isRecommandValid("nameTo");
      }
      if (field !== "emailTo") {
        ready = ready && this.isRecommandValid("emailTo");
      }
      if (field !== "message") {
        ready = ready && this.isRecommandValid("message");
      }
    }
    this.setState((prevState) => {
      const recommand = { ...prevState.recommand };
      recommand[field] = value;
      recommand.ready = ready;
      return { recommand };
    });
  }

  sendRecommandation() {
    this.setState({ loading: true });

    const { recommand } = this.state;

    this.props
      .sendGuestRecommandation({
        variables: {
          nameTo: recommand.nameTo,
          emailTo: recommand.emailTo,
          message: recommand.message,
        },
      })
      .then(() => {
        recommand.send = true;
        recommand.emailTo = "";
        recommand.nameTo = "";
        recommand.valid = false;
        this.setState({ loading: false, recommand });
      })
      .catch(() => {
        recommand.error = true;
        this.setState({ loading: false, recommand });
      });
  }

  updateBirthday = (date) => {
    this.setState({ birthday: date });
  };

  componentDidUpdate(prev) {
    if (prev.data.loading === true && this.props.data.loading !== true) {
      this.setState({
        birthday: this.props.data.guest.birthday
          ? new Date(this.props.data.guest.birthday)
          : null,
      });
    }
  }

  render() {
    if (this.props.data.loading === true || this.state.loading === true) {
      return <LoadingCol />;
    }

    return (
      <div>
        <CustomHeader
          className={this.state.openCollegues ? "open" : ""}
          onClick={() =>
            this.setState((prevState) => ({
              openCollegues: !prevState.openCollegues,
            }))
          }
        >
          <Title>
            <FormattedMessage id="app.bonus.collect.collegues.title" />
            <br />
            <small className="text-muted">
              <FormattedMessage id="app.bonus.collect.collegues.hint" />
            </small>
          </Title>
          <CustomFaAngleRight className="arrow" />
        </CustomHeader>
        <Wrapper>
          <Collapse in={this.state.openCollegues}>
            <div>
              <Content>
                <form
                  onSubmit={(event) => {
                    this.checkRegisterForm();
                    event.preventDefault();
                    return false;
                  }}
                >
                  {this.state.recommand.send === true ? (
                    <Alert variant="success">
                      <FormattedMessage id="app.bonus.collect.recommand.success" />
                    </Alert>
                  ) : (
                    ""
                  )}
                  {this.state.recommand.error === true ? (
                    <Alert variant="danger">
                      <FormattedMessage id="app.bonus.collect.recommand.error" />
                    </Alert>
                  ) : (
                    ""
                  )}
                  <FormLabel>
                    <FormattedMessage id="app.bonus.collect.recommand.contact" />
                  </FormLabel>
                  <FormGroup
                    controlId="formBasicText"
                    validationState={
                      this.isRecommandValid("emailTo") ? "success" : "error"
                    }
                    className="mb-3"
                  >
                    <FormLabel>
                      <FormattedMessage id="app.bonus.collect.recommand.emailTo.label" />
                    </FormLabel>
                    <FormControl
                      autoComplete="none"
                      type="text"
                      value={this.state.recommand.emailTo}
                      onChange={(e) => {
                        this.updateRecommand("emailTo", e.target.value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup
                    controlId="formBasicText"
                    validationState={
                      this.isRecommandValid("nameTo") ? "success" : "error"
                    }
                    className="mb-3"
                  >
                    <FormLabel>
                      <FormattedMessage id="app.bonus.collect.recommand.nameTo.label" />
                    </FormLabel>
                    <FormControl
                      autoComplete="none"
                      type="text"
                      value={this.state.recommand.nameTo}
                      onChange={(e) => {
                        this.updateRecommand("nameTo", e.target.value);
                      }}
                    />
                  </FormGroup>
                  <FormLabel>
                    <FormattedMessage id="app.bonus.collect.recommand.message" />
                  </FormLabel>
                  <FormGroup
                    controlId="formBasicText"
                    validationState={
                      this.isRecommandValid("message") ? "success" : "error"
                    }
                    className="mb-3"
                  >
                    <FormControl
                      rows={3}
                      as="textarea"
                      value={this.state.recommand.message}
                      onChange={(e) => {
                        this.updateRecommand("message", e.target.value);
                      }}
                    />
                  </FormGroup>
                  <FormLabel>
                    <FormattedMessage id="app.bonus.collect.recommand.preview" />
                  </FormLabel>
                  <p>
                    <FormattedMessage id="app.bonus.collect.recommand.preview.salutation" />{" "}
                    {this.state.recommand.nameTo},<br />
                    <br /> {this.state.recommand.message}
                    <br />
                    <br />
                    <FormattedMessage id="app.bonus.collect.recommand.preview.link" />
                    <br />
                    <br />
                    <FormattedMessage id="app.bonus.collect.recommand.preview.greeting" />
                    <br /> {this.props.data.guest.firstname}&nbsp;
                    {this.props.data.guest.lastname}
                    <br />
                    <br />
                    <FormattedMessage id="app.bonus.collect.recommand.preview.legal" />
                  </p>
                  <Button
                    onClick={() => {
                      this.sendRecommandation();
                    }}
                    disabled={!this.state.recommand.ready}
                  >
                    <FormattedMessage id="app.bonus.collect.recommend.send" />
                  </Button>
                </form>
              </Content>
            </div>
          </Collapse>
        </Wrapper>
        <CustomHeader
          className={this.state.openBirthday ? "open" : ""}
          onClick={() => {
            this.setState((prevState) => ({
              openBirthday: !prevState.openBirthday,
            }));
          }}
        >
          <Title>
            <FormattedMessage id="app.bonus.collect.birthday.title" />
            <br />
            <small className="text-muted">
              <FormattedMessage id="app.bonus.collect.birthday.hint" />
            </small>
          </Title>
          <CustomFaAngleRight className="arrow" />
        </CustomHeader>
        <Wrapper>
          <Collapse in={this.state.openBirthday}>
            <div>
              <Content>
                <DayPickerInput
                  onDayChange={this.updateBirthday}
                  disabledDays={(day) =>
                    differenceInMilliseconds(new Date(day), new Date()) >= 0
                  }
                  locale={this.props.intl.locale}
                  value={this.state.birthday}
                  disabled={this.props.data.guest.birthday}
                  dropdownEnabled
                  openByDefault
                />
                {this.props.data.guest.birthday !== null ? (
                  <small className="text-muted">
                    <FormattedMessage id="app.bonus.collect.birthday.existsHint" />
                  </small>
                ) : (
                  <span>
                    <br />
                    <Button onClick={this.saveBirthday}>
                      <FormattedMessage id="app.bonus.collect.birthday.save" />
                    </Button>
                  </span>
                )}
              </Content>
            </div>
          </Collapse>
        </Wrapper>
      </div>
    );
  }
}

export default compose(
  graphql(SEND_GUEST_RECOMM_MUTATION, { name: "sendGuestRecommandation" }),
  graphql(UPDATE_GUEST_MUTATION, { name: "updateGuest" }),
  graphql(COLLECT_QUERY)
)(injectIntl(Collect));
