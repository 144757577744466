import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import * as queryString from "query-string";
import { graphql } from "@apollo/client/react/hoc";
import Icon from "../../globalComponents/Icon";
import Button from "../CustomBootstrap/Button";
import Conf from "../../Conf";
import { Loading } from "../../globalComponents/Loading";

import { OFFER_FILTER_QUERY } from "./OfferFilter.graphql";

const FilterIcon = styled(Icon)`
  cursor: pointer;
  svg path,
  svg circle {
    fill: #8a8a8a !important;
  }
  &.active svg path,
  &.active svg circle {
    fill: #ff6200 !important;
  }
`;

const FilterValueIcon = styled(FilterIcon)`
  svg {
    height: 40px;
    width: 40px;
  }
  float: left;
  height: 40px;
  width: 40px;
`;

/* const FilterWrapper = styled("div")`
  position: relative;
`; */
const FilterPanel = styled("div")`
  position: absolute;
  top: 0;
  z-index: 99;
  background-color: #fff;
  //width: 100%;
  //height: 100%;
  //right: 0;
  border: 1px solid #d4d4d4;
  padding: 20px;
  .btn {
    margin-top: 10px;
  }
  overflow: auto;
`;
const FilterTitle = styled("h4")`
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 30px;
`;

const FilterSection = styled("div")`
  padding-bottom: 20px;
  margin-bottom: 20px;
  h5 {
    text-align: left;
    border-bottom: 1px solid #d4d4d4;
    font-weight: bold;
  }
  clear: both;
`;
const FilterOptions = styled("div")`
  text-align: left;
  padding-bottom: 20px;
  float: left;
  input {
    padding: 2px;
    line-height: 14px;
  }
`;
const FilterOption = styled("span")`
  display: block;
  float: left;
  height: 40px;
  width: 40px;
  margin: 10px;

  color: #8a8a8a;
  &.active {
    color: #ff6200;
  }
  &.active svg path,
  &.active svg circle {
    fill: #ff6200 !important;
  }
`;
const FilterOptionsPanel = styled("div")`
  float: left;
  margin: 10px;
  input[type="checkbox"] {
    margin-right: 10px;
  }
`;

class OfferFilter extends Component {
  constructor(props) {
    super(props);

    let filterValue =
      queryString.parse(this.props.history.location.search).filter !== undefined
        ? queryString.parse(this.props.history.location.search).filter
        : null;

    if (filterValue !== null) {
      filterValue = JSON.parse(filterValue);
    }

    this.state = {
      filter: filterValue,
      options: null,
      categories: null,
      price: {
        free: !!(filterValue && filterValue.price && filterValue.price.free),
        min: `${
          filterValue && filterValue.price && filterValue.price.min
            ? `${filterValue.price.min}`.replace(/[^0-9]/g, "")
            : ""
        }`,
        max: `${
          filterValue && filterValue.price && filterValue.price.max
            ? `${filterValue.price.max}`.replace(/[^0-9]/g, "")
            : ""
        }`,
      },
      priceActive: false,
      categoriesActive: false,
    };

    this.applyFilter = this.applyFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
  }

  applyFilter(event) {
    if (
      this.state.price.min > 0 &&
      this.state.price.max < this.state.price.min
    ) {
      this.setState((prevState) => ({
        price: {
          ...prevState.price,
          min: 0,
        },
      }));
    }

    const searchValue =
      queryString.parse(this.props.history.location.search).search !== undefined
        ? queryString.parse(this.props.history.location.search).search
        : "";

    const categories = {};
    this.props.data.bgmConcept.categories.forEach((category) => {
      if (this.state.categories[category.id]) {
        categories[category.id] = true;
      }
    });

    const options = {};
    this.props.data.bgmConcept.availableTherapyOfferAttributes.forEach(
      (attribute) => {
        options[attribute.id] = {};
        let set = false;
        attribute.availableTherapyOfferAttributeValues.forEach((value) => {
          if (this.state.options[attribute.id][value.id]) {
            options[attribute.id][value.id] = true;
            set = true;
          }
        });
        if (!set) {
          delete options[attribute.id];
        }
      }
    );

    const filter = {
      categories,
      options,
      // eslint-disable-next-line react/no-access-state-in-setstate
      price: this.state.price,
    };

    event.preventDefault();
    this.props.history.push(
      `/offers/?filter=${JSON.stringify(filter)}${
        searchValue ? `&search=${searchValue}` : ""
      }`
    );
    this.setState({
      filter,
    });
    this.props.setShowFilter(false);
    return false;
  }

  resetFilter() {
    const options = {};
    this.props.data.bgmConcept.availableTherapyOfferAttributes.forEach(
      (attribute) => {
        options[attribute.id] = {};
        attribute.availableTherapyOfferAttributeValues.forEach((value) => {
          options[attribute.id][value.id] = false;
        });
      }
    );

    const categories = {};
    this.props.data.bgmConcept.categories.forEach((category) => {
      categories[category.id] = true;
    });

    this.setState({
      filter: null,
      options,
      categories,
      price: {
        free: false,
        min: "0",
        max: "",
      },
      priceActive: false,
      categoriesActive: false,
    });
    this.props.setShowFilter(false);
    this.props.history.push("/offers/");
  }

  toggleOption(option, value) {
    const { options } = this.state;
    options[option][value] = !options[option][value];
    this.setState({ options });
  }

  toggleCategory(id) {
    const { categories } = this.state;
    categories[id] = !categories[id];

    let isOneActicve = false;
    let isAllActice = true;
    this.props.data.bgmConcept.categories.forEach((category) => {
      isOneActicve = isOneActicve || categories[category.id];
      isAllActice = isAllActice && categories[category.id];
    });

    if (!isOneActicve) {
      categories[id] = !categories[id];
    }
    this.setState({
      categories,
      categoriesActive: !isAllActice,
    });
  }

  updatePrice(key, val) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const price = { ...this.state.price };
    let value = val;
    if (key === "min") {
      value = val.replace(/[^0-9]/g, "") * 1;
      if (this.state.price.max > 0 && val > this.state.price.max) {
        price.max = val * 1 + 1;
      }
    }
    if (key === "max") {
      value = val.replace(/[^0-9]/g, "") * 1;
    }
    price[key] = value;
    this.setState({
      price,
      priceActive: price.free || price.min > 0 || price.max > 0,
    });
  }

  componentDidUpdate() {
    const filterValue = this.state.filter;

    if (this.state.options === null && this.props.data.loading === false) {
      const options = {};
      this.props.data.bgmConcept.availableTherapyOfferAttributes.forEach(
        (attribute) => {
          options[attribute.id] = {};
          attribute.availableTherapyOfferAttributeValues.forEach((value) => {
            options[attribute.id][value.id] = !!(
              filterValue &&
              filterValue.options &&
              filterValue.options[attribute.id] &&
              filterValue.options[attribute.id][value.id]
            );
          });
        }
      );
      this.setState({ options });
    }

    if (this.state.categories === null && this.props.data.loading === false) {
      const categories = {};
      this.props.data.bgmConcept.categories.forEach((category) => {
        categories[category.id] =
          filterValue && filterValue.categories
            ? !!filterValue.categories[category.id]
            : true;
      });
      this.setState({ categories });
    }
  }

  render() {
    let styles = {};
    if (this.props.isMobile) {
      styles = {
        width: "100%",
        height: "calc(100% - 1px)",
        right: "0",
      };
    } else {
      styles = {
        width: "400px",
        height: "100%",
        right: "5px",
        borderBottom: 0,
      };
    }

    return (
      <FilterPanel
        id="filter-panel"
        style={{
          display: this.props.showFilter ? "block" : "none",
          ...styles,
        }}
      >
        {this.props.data.loading === false && this.state.options !== null ? (
          <div>
            <FilterTitle>
              <FormattedMessage id="app.offers.filter.title" />
            </FilterTitle>
            <FilterSection>
              <h5>
                <FormattedMessage id="app.offers.filter.category" />
              </h5>
              <FilterOptions>
                <FilterOption
                  className={this.state.categoriesActive ? "active" : ""}
                >
                  <FilterValueIcon src={Conf.globalIcons.category} />
                </FilterOption>
                <FilterOptionsPanel>
                  {this.props.data.bgmConcept.categories.map((category) => (
                    <div key={category.id}>
                      <input
                        type="checkbox"
                        name={`filter_category_${category.id}`}
                        value="1"
                        id={`filter_category_${category.id}`}
                        checked={this.state.categories[category.id]}
                        onChange={() => {
                          this.toggleCategory(category.id);
                        }}
                      />
                      <label htmlFor={`filter_category_${category.id}`}>
                        {category.title}
                      </label>
                    </div>
                  ))}
                </FilterOptionsPanel>
              </FilterOptions>
            </FilterSection>

            {this.props.data.bgmConcept.availableTherapyOfferAttributes.map(
              (attribute) => (
                <FilterSection key={attribute.id}>
                  <h5>{attribute.title}</h5>
                  <FilterOptions>
                    {attribute.availableTherapyOfferAttributeValues.map(
                      (value) => (
                        <FilterOption
                          key={value.id}
                          className={
                            this.state.options[attribute.id][value.id]
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            this.toggleOption(attribute.id, value.id);
                          }}
                        >
                          <FilterValueIcon
                            src={JSON.parse(value.icon)}
                            title={value.title}
                          />
                        </FilterOption>
                      )
                    )}
                  </FilterOptions>
                </FilterSection>
              )
            )}

            <FilterSection>
              <h5>
                <FormattedMessage id="app.offers.filter.price" />
              </h5>
              <FilterOptions>
                <FilterOption
                  className={this.state.priceActive ? "active" : ""}
                >
                  <FilterValueIcon src={Conf.offerDetailIcons.price} />
                </FilterOption>
                <FilterOptionsPanel>
                  <input
                    disabled={this.state.price.free}
                    type="text"
                    size="10"
                    name="min"
                    value={this.state.price.min}
                    onChange={(elem) => {
                      this.updatePrice("min", elem.target.value);
                    }}
                  />
                  <FormattedMessage id="app.global.priceUnit" />
                  <FormattedMessage id="app.offers.filter.price.to" />
                  &nbsp;
                  <input
                    disabled={this.state.price.free}
                    type="text"
                    size="10"
                    name="max"
                    onChange={(elem) => {
                      this.updatePrice("max", elem.target.value);
                    }}
                    value={this.state.price.max}
                  />
                  <FormattedMessage id="app.global.priceUnit" />
                  <div style={{ paddingTop: "10px" }}>
                    <input
                      type="checkbox"
                      name="free"
                      value="1"
                      id="free"
                      checked={this.state.price.free}
                      onChange={() => {
                        this.updatePrice("free", !this.state.price.free);
                      }}
                    />{" "}
                    <label htmlFor="free">
                      <FormattedMessage id="app.offers.filter.price.onlyBudget" />
                    </label>
                  </div>
                </FilterOptionsPanel>
              </FilterOptions>
            </FilterSection>
            <div className="d-grid" style={{ width: "100%" }}>
              <Button onClick={this.applyFilter} variant="primary" size="lg">
                <FormattedMessage id="app.offers.filter.button" />
              </Button>
            </div>
            {this.state.filter ? (
              <div className="d-grid" style={{ width: "100%" }}>
                <Button
                  className="invers"
                  onClick={this.resetFilter}
                  variant="primary"
                  size="lg"
                >
                  <FormattedMessage id="app.offers.filter.reset" />
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <Loading />
        )}
      </FilterPanel>
    );
  }
}

export default graphql(OFFER_FILTER_QUERY, {
  options: { fetchPolicy: "cache-and-network" },
})(injectIntl(OfferFilter));
