import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { Form, FormGroup, FormCheck } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from "../../components/CustomBootstrap/Button";
import Conf from "../../Conf";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { parseError } from "../../globalComponents/ErrorMessageHandler";

import { ACCEPT_MUTATION } from "./PrivacyPolicy.graphql";

class PrivacyPolicyAccept extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      error: false,
      accepted: false,
      loading: false,
    };

    this.submit = this.submit.bind(this);
    this._alertModal = null;
    this.parseError = parseError.bind(this);
  }

  submit() {
    this.setState({ loading: true });
    this.props
      .mutate({ variables: { id: this.props.privacyPolicyId } })
      .then((data) => {
        if (data.data.acceptGuestPrivacyPolicy) {
          this.setState({
            done: true,
            loading: false,
            error: false,
          });
        } else {
          this.setState({
            done: true,
            error: true,
            loading: false,
          });
          this.onError();
        }
      })
      .catch((error) => {
        this.setState({
          done: true,
          error: true,
          loading: false,
        });
        this.onError(error);
      });
  }

  /**
   * opens modal box with error message
   *
   * @param error: returned from api
   * @param errorTextTranslationKey: returned form parseError
   */
  onError(error) {
    let errorTextTranslationKey = `${Conf.errorContext.accountSaveBasicData}.default`;
    if (error !== undefined && error !== null) {
      errorTextTranslationKey = this.parseError(
        error,
        Conf.errorContext.accountSaveBasicData
      );
    }
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  }

  render() {
    if (this.state.done && !this.state.error) {
      return (
        <Redirect
          push
          to={{
            pathname: "/",
            hash: "privacyPolicyAccepted",
          }}
        />
      );
    }
    return (
      <Form>
        <FormGroup>
          <FormCheck
            disabled={this.state.loading}
            onChange={() => {
              this.setState((prevState) => ({ accepted: !prevState.accepted }));
            }}
            label={<FormattedMessage id="app.privacyPolicy.accept" />}
            className="d-inline-block pb-4"
          />
          <div className="d-grid">
            <Button
              processing={this.state.loading}
              onClick={this.submit}
              disabled={this.state.loading || !this.state.accepted}
              variant="primary"
              size="lg"
            >
              <FormattedMessage id="app.privacyPolicy.continue" />
            </Button>
          </div>
          <AlertModalBox
            title="error"
            text="error"
            ref={(modal) => {
              this._alertModal = modal;
            }}
          />
        </FormGroup>
      </Form>
    );
  }
}

export default graphql(ACCEPT_MUTATION)(injectIntl(PrivacyPolicyAccept));
